import { GET_SAT, GET_SATS, GET_SATS_COUNT, GET_ALL_SATS, GET_FILTROS_SATS } from '../action'
// ** Initial State
const initialState = {
    allData: [],
    data: [],
    total: 1,
    params: {},
    sat: undefined,
    processing: false,
    filtros: {}
}

const sats = (state = initialState, action) => {
    switch (action.type) {
        case GET_SATS:
            return {
                ...state,
                data: action.data,
                params: action.params
            }
        case GET_SATS_COUNT:
            return {
                ...state,
                total: action.totalPages
            }
        case GET_SAT:
            return { ...state, sat: action.sat }
        case GET_ALL_SATS:
            return {
                ...state,
                allData: action.data
            }
        case GET_FILTROS_SATS:
            return {
                ...state,
                filtros: action.filtros
            }
        default:
            return { ...state }
    }
}
export default sats
