/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { automaticDownload, displayErrorMsg, displayInfoMsg, displaySuccessMsg, formatDateToDatabase, odataLogs, seleccionarIdiomaYFormatoRegional, csvmaker, devuelveBasePath } from '@src/utility/utils'
import { settings, Email, MailControllerApi, ObservacionesControllerApi, PortesControllerApi, NavisionControllerApi, ClientesControllerApi } from '@api/backend'
import Swal from 'sweetalert2'
import { getProductsDataNavision } from '@src/views/productos/store/actions'
import { getUserCode, isParentUser } from '@src/auth/utils'
import { FormattedMessage, useIntl } from 'react-intl'
import axios from 'axios'
import { Check, X } from 'react-feather'
import { getUserData } from '@utils'

const prefix = 'sales-quote'
const config = settings
export const GET_SALES_QUOTE = `${prefix}/GET_SALES_QUOTE`
export const GET_SALES_QUOTES = `${prefix}/GET_DATA`
export const GET_SALES_QUOTE_PDF = `${prefix}/GET_SALES_QUOTE_PDF`
export const INIT_REQUEST = `${prefix}/INIT_REQUEST`
export const INIT_SALES_QUOTE_PDF = `${prefix}/INIT_SALES_QUOTE_PDF`
export const INIT_DOWNLOAD_SALES_QUOTE_PDF = `${prefix}/INIT_DOWNLOAD_SALES_QUOTE_PDF`
export const DOWNLOAD_SALES_QUOTE_PDF = `${prefix}/DOWNLOAD_SALES_QUOTE_PDF`
export const SEND_SALES_QUOTE = `${prefix}/SEND_SALES_QUOTE`
export const SET_SALES_QUOTE_FROM_ALLDATA = `${prefix}/SET_SALES_QUOTE_FROM_ALLDATA`
export const SWITCH_BILLTO_SELLTO = `${prefix}/SWITCH_BILLTO_SELLTO`


// API Backend instance
const apiMail = new MailControllerApi(settings)
const apiObs = new ObservacionesControllerApi(settings)
const apiPortes = new PortesControllerApi(settings)
const apiNavisionNew = new NavisionControllerApi(settings)
const clienteasApi = new ClientesControllerApi(settings)

// ** INIT REQUEST
export const initRequest = () => {
  return async (dispatch) => {
    dispatch({
      type: INIT_REQUEST
    })
  }
}

export const enviarObservacion = (observacion, documentNo, cliente) => {
  return async (dispatch) => {
    const objObservacion = { oferta: documentNo, contenido: observacion, cliente }
    const whereFilters = {}
    whereFilters['oferta'] = { like: `%${documentNo}%` }
    const filter = {
      where: { ...whereFilters }
    }
    const { data: dataObservacion } = await apiObs.observacionesControllerFind(JSON.stringify(filter))
    if (dataObservacion != undefined && dataObservacion.length > 0) {
      await apiObs.observacionesControllerUpdateById(dataObservacion[0].id, objObservacion)
    } else {
      await apiObs.observacionesControllerCreate(objObservacion)
    }
  }
}

/*  Código obsoleto. Se ha creado la función csvmaker en /utility/utils.js para englobar las funciones csvmaker que hay, en una sola
const csvmaker = function (data) {
  const valorMinimoParaNoIncluirPortes = 750 // Por encima de este valor, no se añaden los portes al pedido
  let sumaTotal = 0
  const csvRows = []
  const headers = Object.keys(data[0])

  // Verificar si el header 'postes' existe en los datos que llegan, cambiarlo por 'portes'
  if (headers.includes('postes')) {   
    const indiceAReemplazar = headers.indexOf('postes');
    headers[indiceAReemplazar] = 'portes'; // Reemplazamos 'postes' por 'portes'
  }    

  const indiceAEliminar = headers.indexOf('salesQuoteLines');
  if (indiceAEliminar !== -1) {
    headers.splice(indiceAEliminar, 1); // Eliminamos la cabecera (salesQuoteLines)
  }
  headers.push('total') // Agregamos la cabecera (total)
  headers.push('total con portes') // Agregamos la cabecera (total con portes)

  let values
  csvRows.push(headers.splice(1, headers.length - 1).join(';'))
  Object.values(data).forEach(value => {

    // Suma todas las líneas que llegan, incluídos los portes, que llegan en una línea con propiedad->no y valor->'PORTES'
    const sumaTotalArticulosYPortes = (value.salesQuoteLines && value.salesQuoteLines.length > 0) ? value.salesQuoteLines.reduce((valorAcumulado, valorActual) => valorAcumulado + (valorActual.quantity * valorActual.unitPrice), 0) : 0; // Se añade este código para calcular el total, si no, al descargar el csv, pone [object]

    const valorPortes = (value.salesQuoteLines.find(linea => linea.no === 'PORTES') ?? { lineAmount: 0 }).lineAmount; // Obtiene el valor de la línea 'PORTES', es decir, si la propiedad 'no' tienen como valor 'PORTES', si no, devuelve 0
    const sumaTotalArticulosSinPortes = parseFloat(sumaTotalArticulosYPortes) - parseFloat(valorPortes) // Calcula el valor de todos los artículos sin los portes

    // Hay un valor mínimo para el que si los artículos comprados superan esa cifra, entonces no se incluyen los portes
    if (sumaTotalArticulosSinPortes > valorMinimoParaNoIncluirPortes) {
      sumaTotal = parseFloat(sumaTotalArticulosYPortes) - parseFloat(valorPortes) // Calcula la suma total sin los portes
    } else {
      sumaTotal = parseFloat(sumaTotalArticulosYPortes)   // Por no superar el valorMinimoParaNoIncluirPortes, se incluyen lor portes
    }

    delete value.salesQuoteLines; // Eliminamos la columna (salesQuoteLines)
    values = Object.values(value)
    values.push(sumaTotalArticulosSinPortes.toFixed(2).replace('.', ',')) // Agregamos la columna (total). Reemplaza los puntos que hay, por comas, para seguir la notación numérica española
    values.push(sumaTotal.toFixed(2).replace('.', ',')) // Agregamos la columna (total con portes)
    csvRows.push(values.splice(1, values.length - 1).join(';'))
  })
  return csvRows.join('\n')
}
*/
const descargaFichero = function (data) {
  const blob = new Blob([data], { type: 'text/csv' })
  const url = window.URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.setAttribute('href', url)
  a.setAttribute('download', '/ofertas.csv')
  a.click()
}

export const descargarCsv = (params, intl) => {
  return async (dispatch) => {
    dispatch(initRequest())
    const {
      page = 1,
      perPage = 10,
      documentNo = null,
      postingDate = null,
      shipmentDate = null,
      shipToCity = null,
      shipToAddress = null,
      sellToCustomerName = null,
      customerNo = null,
      vendedor = null,
      gerente = null
    } = params

    const filterList = []
    if (documentNo) {
      filterList.push(`documentNo eq '*${documentNo}*'`)
    }
    if (postingDate && postingDate.length) {
      if (postingDate.length === 2) {
        filterList.push(`postingDate ge ${formatDateToDatabase(postingDate[0])}`)
        filterList.push(`postingDate le ${formatDateToDatabase(postingDate[1])}`)
      } else {
        filterList.push(`postingDate eq ${formatDateToDatabase(postingDate[0])}`)
      }
    }
    if (shipmentDate && shipmentDate.length) {
      if (shipmentDate.length === 2) {
        filterList.push(`shipmentDate ge ${formatDateToDatabase(shipmentDate[0])}`)
        filterList.push(`shipmentDate le ${formatDateToDatabase(shipmentDate[1])}`)
      } else {
        filterList.push(`shipmentDate eq ${formatDateToDatabase(shipmentDate[0])}`)
      }
    }
    if (shipToCity) {
      filterList.push(`shiptoCity eq '*${shipToCity}*'`)
    }
    if (shipToAddress) {
      filterList.push(`shipToAddress eq '*${shipToAddress}*'`)
    }
    if (sellToCustomerName) {
      filterList.push(`sellToCustomerName eq '*${sellToCustomerName}*'`)
    }
    if (gerente === "off" || gerente === "" || gerente === null || gerente === undefined) {
      if (vendedor) {
        filterList.push(`salespersonCode eq '${vendedor}'`) //->Filtramos por el codigo exacto del vendedor, sin añadir *__*
      }
    }
    if (customerNo) {
      filterList.push(`sellToCustomerNo eq '*${customerNo}*'`)
    }

    const $filter = filterList.join(' and ')
    const $top = perPage
    const $skip = (page - 1) * perPage
    const $orderby = 'documentNo desc'
    const $expand = 'salesQuoteLines'
    const $count = true
    Swal.fire({
      title: intl.formatMessage({ id: 'Generating csv' }),
      html: intl.formatMessage({ id:  'Please wait'}),
      timer: $top * 1.5,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading()
      }
    })
      await apiNavisionNew.navisionControllerGetendPoint('salesQuoteHeader', $filter, $top, $skip, $orderby, $expand, $count).then((response) => {
        const { data } = response;
        //
        //Calculamos la suma de los volumenes de cada línea y actualizamos el registro cabecera que es el que mostramos 
        //
        let nuevoVolumenCalculado = 0;
        (data.value).forEach(lines => {        
          if (lines.salesQuoteLines.length > 0) {
            let volumenTotal = 0, decimal = "0"
            lines.salesQuoteLines?.forEach(el => {
              volumenTotal += el.totalVolume
            })
            const volString = volumenTotal.toString().split(".")
            const entero = volString[0]
            if (volString.length > 1) {
              decimal = volString[1].substring(0, 2)
            }
            if (decimal.length === 1) {
              decimal = decimal.concat("0"); // Completar con un 0 si la parte decimal es de un solo dígito
            }
            nuevoVolumenCalculado = entero.concat(",").concat(decimal)
          } 
          lines.volumen = nuevoVolumenCalculado
        })
        //
        //Generamos el fichero
        //
        const csvdata = csvmaker(data.value)
        descargaFichero(csvdata)
        location.reload()
      })
  }
}

export const descargarCsvCartera = (params, intl) => {
  return async (dispatch) => {
    dispatch(initRequest())
    const {
      page = 1,
      perPage = 10,
      customerNo = null,
      vendedor = null,
      gerente = null
    } = params

    const filterList = []
    if (gerente === "off" || gerente === "" || gerente === null || gerente === undefined) {
      if (vendedor) {
        filterList.push(`salespersonCode eq '${vendedor}'`) //->Filtramos por el codigo exacto del vendedor, sin añadir *__*
      }
    }
    if (customerNo) {
      filterList.push(`sellToCustomerNo eq '*${customerNo}*'`)
    }

    const $filter = filterList.join(' and ')
    const $top = perPage
    const $skip = (page - 1) * perPage
    const $orderby = 'documentNo desc'
    Swal.fire({
      title: intl.formatMessage({ id: 'Generating csv' }),
      html: intl.formatMessage({ id:  'Please wait'}),
      timer: $top * 1.5,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading()
      }
    })
    await apiNavisionNew.navisionControllerGetendPoint('salesQuoteLines', $filter, $top, $skip, $orderby, undefined, undefined).then((response) => {
      const { data } = response
      const csvdata = csvmaker(data.value)
      descargaFichero(csvdata)
      location.reload()
    })
  }
}

// ** Get Delivery Notes list
export const getData = (intl, params) => {
  return async (dispatch, getState) => {
    dispatch(initRequest())
    const {
      page = 1,
      perPage = 10,
      status = null,
      documentNo = null,
      postingDate = null,
      shipmentDate = null,
      shipToCity = null,
      shipToAddress = null,
      ean = null,
      description = null,
      productCode = null,
      sellToCustomerName = null,
      customerNo = null,
      vendedor = null,
      gerente = null
    } = params

    try {
      let $filter
      let total
      let $top = perPage
      let $skip = (page - 1) * perPage
      const inicio = ((page - 1) * perPage)
      const fin = inicio + perPage
      const $orderby = 'documentNo desc'
      const $expand = 'salesQuoteLines'
      const $count = true
      const filterList = []
      const customerNoFilter = getUserCode()
      const isParent = await isParentUser()

      // Filtros para Lineas
      if (ean || description || productCode || vendedor || customerNo) {
        const filterLines = [...filterList]

        if (ean) {
          filterLines.push(`no eq '${ean}'`)
        }
        if (description) {
          filterLines.push(`description eq '*${description}*'`)
        }
        if (productCode) {
          filterLines.push(`barCode eq '*${productCode}*'`)
        }
        //if (gerente === "off" || gerente === "" || gerente === null || gerente === undefined) {
          if (vendedor) {
            filterList.push(`salespersonCode eq '${vendedor}'`) //->Filtramos por el codigo exacto del vendedor, sin añadir *__*
          }
        //}
        //if (gerente === "off" || gerente === "" || gerente === null || gerente === undefined) {
          if (customerNo) {
            filterList.push(`sellToCustomerNo eq '*${customerNo}*'`)
          }
        //}

        // Join array de Filtros y reemplazo de campos 'shiptoCity' a 'shipToCity' por diferencia entre cabecera y lineas
        const $filterLines = filterLines.join(' and ')
          .replace('documentNo', 'no') //? CREO QUE NO VA
          .replace('shiptoCity', 'shipToCity')

        // Consulta API de query (líneas y cabecera): Máximo 100000 registros
        let ids = []
        if ($filterLines != '') {
          const linesData = await apiNavisionNew.navisionControllerGetendPoint('salesQuoteLines', $filterLines, 100000, 0, undefined, undefined, undefined)
          ids = [...new Set(linesData.data.value?.map(line => line.no))]
        }
        // Obtener array único de Documentos
        if (ids.length > 0) {
          // Variables de Paginacion cuando busca lineas
          const start = ((page - 1) * perPage)
          const end = start + perPage

          // Calcula total de registros para paginación
          total = ids.length

          // Obtener la parte del array de DocumentNo para la pagina solicitada
          const idsPaginated = total > perPage ? ids.slice(start, end) : ids

          // Agrega filtros por los documentos de la pagina
          const list = idsPaginated.map(ids => `documentNo eq '${ids}'`).join(' or ')

          if (list) {
            // Agrega filtros por los documentos de la pagina y modifica parametros de paginación
            filterList.push(`(${list})`)
            $top = perPage
            $skip = 0
          }
        }
        // Filtros para Cabecera
        if (status || documentNo || postingDate || shipmentDate || shipToCity || shipToAddress || customerNoFilter || sellToCustomerName || gerente) {
          if (customerNo) {
            if (isParent) {
              const showFromHijos = getState().salesQuote.showFromHijos
              if (showFromHijos) {
                filterList.push(`billtoCustomerNo eq '*${customerNoFilter}*'`)
                // filterList.push(`sellToCustomerNo <> '*${customerNo}*'`)
              } // else filterList.push(`sellToCustomerNo eq '*${customerNoFilter}*'`)
            } // else filterList.push(`sellToCustomerNo eq '*${customerNoFilter}*'`)
          }
          if (documentNo) {
            filterList.push(`documentNo eq '*${documentNo}*'`)
          }
          if (postingDate && postingDate.length) {
            if (postingDate.length === 2) {
              filterList.push(`orderDate ge ${formatDateToDatabase(postingDate[0])}`)
              filterList.push(`orderDate le ${formatDateToDatabase(postingDate[1])}`)
            } else {
              filterList.push(`orderDate eq ${formatDateToDatabase(postingDate[0])}`)
            }
          }
          if (shipmentDate && shipmentDate.length) {
            if (shipmentDate.length === 2) {
              filterList.push(`shipmentDate ge ${formatDateToDatabase(shipmentDate[0])}`)
              filterList.push(`shipmentDate le ${formatDateToDatabase(shipmentDate[1])}`)
            } else {
              filterList.push(`shipmentDate eq ${formatDateToDatabase(shipmentDate[0])}`)
            }
          }
          if (shipToCity) {
            filterList.push(`shiptoCity eq '*${shipToCity}*'`)
          }
          if (shipToAddress) {
            filterList.push(`shipToAddress eq '*${shipToAddress}*'`)
          }
          if (sellToCustomerName) {
            filterList.push(`billtoName eq '*${sellToCustomerName}*'`)
          }
          if (filterList.length) {
            $filter = filterList.join(' and ')
          }
        }
        $filter = filterList.join(' and ')
      }
      await apiNavisionNew.navisionControllerGetendPoint('salesQuoteHeader', $filter, undefined, undefined, $orderby, $expand, $count).then((response) => {
        const { data } = response

        const registrosPaginados = data.value.slice(inicio, fin); // -> Filtra pagina
        console.log('Ofertas: ', registrosPaginados)

        dispatch({
          type: GET_SALES_QUOTES,
          allData: data.value,
          data: registrosPaginados,
          totalPages: total || data['@odata.count'] || 0,
          params
        })
        odataLogs(response.config.url, response.status, response.config.method, GET_SALES_QUOTES, config)
      }).catch((err) => odataLogs(err.message, "ERROR", "", GET_SALES_QUOTES, config))
    } catch (err) {
      console.error(err.message)
      Swal.fire({
        title: intl.formatMessage({ id: "Error in Navision"}),
        icon: "error"
      })
    }
  }
}

export const cancelQuote = (documentNo, productsArr, intl) => {
  return async () => {
    try {
      const objNew = { documentNo };
      const objJSON = JSON.stringify(objNew); // <-- creamos el JSON a enviar
      await apiNavisionNew.navisionControllerPostEndpointBC("TW_Functions_CancelQuote", objJSON).then((response) => {
        const { data } = response
        if (data == 'OK') {
          displaySuccessMsg(`${intl.formatMessage({ id: 'Sales Quote'})} ${documentNo} ${intl.formatMessage({ id: 'canceled'})}`)
          return data
        }
        //
        //Si ha ocurrido algún problema mostramos el error
        //
        if (data.mensaje.message) { 
          return displayErrorMsg(data.mensaje.message)
        }
      })
    } catch (err) {
      console.error(err.message)
    }
  }
}

// ** Get Delivery Note
export const getSalesQuote = (params) => {
  return async (dispatch) => {
    const {
      page = 1,
      perPage = 1,
      documentNo = null
    } = params

    try {
      const $top = perPage
      const $skip = (page - 1) * perPage
      const $orderby = 'documentNo desc'
      const $expand = 'salesQuoteLines'
      //const $expand = undefined
      const $count = undefined
      const $filter = `documentNo eq '*${documentNo}*'`
      await apiNavisionNew.navisionControllerGetendPoint('salesQuoteHeader', $filter, $top, $skip, $orderby, $expand, $count).then(async (response) => {
        const { data } = response
        const whereFilters = {}
        whereFilters['oferta'] = { like: `%${documentNo}%` }
        const filter = { where: { ...whereFilters } }
        const { data: dataObservacion } = await apiObs.observacionesControllerFind(JSON.stringify(filter))
        const { data: dataPortes } = await apiPortes.portesControllerFind()
        dispatch({
          type: GET_SALES_QUOTE,
          salesQuote: data.value?.pop(),
          portes: dataPortes,
          observacion: dataObservacion[0]
        })
        odataLogs(response.config.url, response.status, response.config.method, GET_SALES_QUOTE, config)
      }).catch((err) => odataLogs(err.message, "ERROR", "", GET_SALES_QUOTE, config))
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const consultarSiExisteOferta = (params) => {
  return async () => {
    const {
      page = 1,
      perPage = 1,
      documentNo = null
    } = params

    try {
      const $top = perPage
      const $skip = (page - 1) * perPage
      const $orderby = 'documentNo desc'
      const $expand = 'salesQuoteLines'
      const $count = undefined
      const $filter = `documentNo eq '*${documentNo}*'`
      return await apiNavisionNew.navisionControllerGetendPoint('salesQuoteHeader', $filter, $top, $skip, $orderby, $expand, $count).then(async (response) => {
        return response
      }).catch((err) => odataLogs(err.message, "ERROR", "", GET_SALES_QUOTE, config))
    } catch (err) {
      console.error(err.message)
    }
  }
}

// ** INIT PDF Albaran
export const initDeliveryNotePdf = () => {
  return async (dispatch) => {
    dispatch({
      type: INIT_SALES_QUOTE_PDF
    })
  }
}

// ** Get Delivery Note PDF
export const getSalesQuotePdf = (documentNo) => {
  return async (dispatch) => {
    try {
      const objNew = { DocNo: documentNo, DocType: 'Oferta' };
      const objJSON = JSON.stringify(objNew); // <-- creamos el JSON a enviar
      await apiNavisionNew.navisionControllerPostEndpointBC("TW_Functions_PrintDocument", objJSON).then((response) => {
        const { data } = response
        dispatch({
          type: GET_SALES_QUOTE_PDF,
          salesQuotePdf: data
        })
        odataLogs(response.config.url, response.status, response.config.method, GET_SALES_QUOTE_PDF, config)
      })
        .catch((err) => odataLogs(err.message, "ERROR", "", GET_SALES_QUOTE, config))
    } catch (err) {
      console.error(err.message)
    }
  }
}


export const initDownloadSalesQuotePdf = () => {
  return async (dispatch) => {
    dispatch({
      type: INIT_DOWNLOAD_SALES_QUOTE_PDF
    })
  }
}

// ** Descargar PDF Oferta
export const downloadSalesQuotePdf = (documentNo, processing, intl) => {
  return async (dispatch) => {
    try {
 
      if (processing && processing === true) dispatch(initRequest())
      // const objNew = { DocNo: documentNo, DocType: 'OFERTAVENTA' };
      const objNew = { DocNo: documentNo, DocType: 'OFERTAVENTA', idioma: seleccionarIdiomaYFormatoRegional() }; // Llama a la función que devuelve el idioma seleccionado por el usuario en el desplegable de la web

      const objJSON = JSON.stringify(objNew); // <-- creamos el JSON a enviar
      await apiNavisionNew.navisionControllerPostEndpointBC("TW_Functions_PrintDocument", objJSON).then((response) => {
        const { data } = response
        // Escapa con notificacion error si encuentra error en la respuesta de navision
        if (data.status !== 'OK') return displayErrorMsg(`${intl.formatMessage({ id: 'Error al generar PDF en Navision'})}:${data.mensaje.message}`)

        dispatch({
          type: DOWNLOAD_SALES_QUOTE_PDF,
          salesQuotePdf: data.mensaje,
          documentNo
        })
        automaticDownload(`${documentNo}.pdf`, `data:application/pdf;base64,${data.mensaje}`)
        odataLogs(response.config.url, response.status, response.config.method, DOWNLOAD_SALES_QUOTE_PDF, config)
        displaySuccessMsg(intl.formatMessage({ id: 'Downloaded PDF'}))
      }).catch((err) => odataLogs(err.message, "ERROR", "", DOWNLOAD_SALES_QUOTE_PDF, config))
    } catch (err) {
      console.error(err.message)
    }
  }
}

// ** Fetch pdf y lo envia por correo
export const sendSalesQuotePdfEmail = (mailObject, documentNo, intl) => {
  return async (dispatch) => {
    try {
      Swal.fire({
        position: 'center',
        title: intl.formatMessage({ id: 'Process started on the server, a warning will be displayed on the screen at the end of the process.'}),
        icon: 'info'
      })
      await dispatch(getSalesQuotePdf(documentNo))
      await apiMail.mailControllerSendMail(mailObject).then((response) => {
        dispatch({
          type: SEND_SALES_QUOTE
        })
        odataLogs(response.config.url, response.status, response.config.method, SEND_SALES_QUOTE, config)
      })
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: intl.formatMessage({ id: 'E-mail sent'})
      }).catch((err) => odataLogs(err.message, "ERROR", "", SEND_SALES_QUOTE, config))
    } catch (err) {
      console.error(err.message)
    }
  }
}

// ** Set albaran desde alldata
export const setSalesQuoteFromAllData = (documentNo) => {
  return async (dispatch) => {
    dispatch({
      type: SET_SALES_QUOTE_FROM_ALLDATA,
      data: documentNo
    })
  }
}

// ** Convertir oferta a pedido
export const convertSQToOrder = (documentNo, products, intl) => {
  return async (dispatch) => {
    displayInfoMsg(intl.formatMessage({ id: "Process initiated"}))

    const productsNoArr = products.map(pr => pr.no).join("|")
    // const data = await dispatch(getProductsDataNavision(productsNoArr))
    // const stocks = data?.map((p) => {
    //   return { no: p.Model, stock: parseInt(p.InventoryAvailable) }
    // })

    // Comparamos stock en navision con la cantidad pedida en oferta, mapeamos y retornamos bool si todos tienen suficiente stock
    // const isEnough = !products.filter(prod => prod.no != 'PORTES').map(producto => {
    //   if (stocks != undefined) {
    //     const enNav = stocks.find((p) => p.no === producto.no)
    //     if (!enNav) return false
    //     if (enNav.stock >= producto.qty) return true
    //     else return false
    //   } else {
    //     return false
    //   }
    // }).includes(false)

    // Si hay stock en todo pasamos a convertir, sino popeamos mensaje
    // if (isEnough) {

      try {
        const objNew = { documentNo };
        const objJSON = JSON.stringify(objNew); // <-- creamos el JSON a enviar
        return await apiNavisionNew.navisionControllerPostEndpointBC("TW_Functions_QuoteToOrder", objJSON).then((response) => {
          const { data } = response

          // Si vuelve como un código NUMERO es que se creo exitosamente
          if (data.status == 'OK') {
            displaySuccessMsg(`${intl.formatMessage({ id: 'Sales Quote'})} ${documentNo} ${intl.formatMessage({ id: 'converted to Order'})}: ${data.mensaje}`)
            return data
          }
        //
        //Si ha ocurrido algún problema mostramos el error
        //

        if (data.mensaje.message) { // falta añadir la comprobacion en castellano del segundo caso
          let texto = `${intl.formatMessage({ id: "A problem has occurred, contact the administrator"})}. ${data.mensaje.message}`
          const mensaje = data.mensaje.message
          if (mensaje.includes('There are not inventory to create order.') || mensaje.includes('No hay stock suficiente para crear el pedido.')) {
            texto = intl.formatMessage({ id: "Order out of stock"})
          } else if (mensaje.includes('This customer allows you to split the quantities of the quotation when converting it to order.') || mensaje.includes('Este cliente permite dividir las cantidades de la oferta al convertirla a pedido.')) {
            texto = intl.formatMessage({ id: "The customer does not allow partial deliveries, there is not enough stock for the entire order."})
          } else if (mensaje.includes('contains lines with a sale price below cost.') || mensaje.includes('contiene líneas con precio venta inferior al coste.')) {
            texto = intl.formatMessage({ id: "The offer contains lines with a selling price below cost."})
          }
          Swal.fire({
            title: texto,
            icon: 'error',
            showCancelButton: false,
            reverseButtons: true,
            confirmButtonColor: '#000000',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok'
          })
        }
          odataLogs(response.config.url, response.status, response.config.method, "Convertir oferta a pedido", config)
        }).catch((err) => odataLogs(err.message, "ERROR", "", "Convertir oferta a pedido", config))
      } catch (err) {
        console.error(err.message)
        return displayErrorMsg(err.message)
      }

    // } else {
    //   return displayErrorMsg(intl.formatMessage({ id: 'Error when checking stock availability, please modify the offer in cart'}))
    // }
  }
}

// ** Convertir oferta a pedido
export const convertSQToOrderMultiple = (documentNo, intl) => {
  return async () => {
      try {
        const objNew = { documentNo };
        const objJSON = JSON.stringify(objNew); // <-- creamos el JSON a enviar
        return await apiNavisionNew.navisionControllerPostEndpointBC("TW_Functions_QuoteToOrder", objJSON).then((response) => {
          const { data } = response
          // Si vuelve como un código NUMERO es que se creo exitosamente
          if (data.status == 'OK') {
            return data
          }
          //Si ha ocurrido algún problema mostramos el error
          if (data.mensaje.message) { // falta añadir la comprobacion en castellano del segundo caso
            const mensaje = data.mensaje.message
            if (mensaje.includes('There are not inventory to create order.') || mensaje.includes('No hay stock suficiente para crear el pedido.')) {
              data.mensaje.message = intl.formatMessage({ id: "Order out of stock"})
            } else if (mensaje.includes('This customer allows you to split the quantities of the quotation when converting it to order.') || mensaje.includes('Este cliente permite dividir las cantidades de la oferta al convertirla a pedido.')) {
              data.mensaje.message = intl.formatMessage({ id: "The customer does not allow partial deliveries, there is not enough stock for the entire order."})
            } else if (mensaje.includes('contains lines with a sale price below cost.') || mensaje.includes('contiene líneas con precio venta inferior al coste.')) {
              data.mensaje.message = intl.formatMessage({ id: "The offer contains lines with a selling price below cost."})
            } else {
              data.mensaje.message = `${intl.formatMessage({ id: "A problem has occurred, contact the administrator"})}. ${data.mensaje.message}`
            }
            return data
          }
          odataLogs(response.config.url, response.status, response.config.method, "Convertir oferta a pedido", config)
        }).catch((err) => odataLogs(err.message, "ERROR", "", "Convertir oferta a pedido", config))
      } catch (err) {
        console.error(err.message)
        return displayErrorMsg(err.message)
      }
  }
}

export const switchBilltoSellto = () => {
  return async (dispatch) => {
    dispatch({
      type: SWITCH_BILLTO_SELLTO
    })
  }
}

export async function stockProductos (documentNo, producto, tarifa, cantidad) {
  if (producto !== 'PORTES') {
    const {data: productoNav } = await axios.get(`${devuelveBasePath()}/query-page-products/${documentNo}/${producto}/?customerPriceGroup=${tarifa}`)
      const productoParsed = JSON.parse(productoNav.result?.return_value ? productoNav.result.return_value : 0)
      let cantidadStock
      if (productoParsed === 0) {
        cantidadStock = 0
      } else {
        cantidadStock = productoParsed.Items[0]['InventoryAvailable']
      }
      if (cantidadStock < cantidad) {
        return <X size={25} className='mx-1' style={{ color: "red" }}/>;
      } else {
        return <Check size={25} className='mx-1' style={{ color: "green" }}/>;
      }
  }
}

export async function stockRepuestos (documentNo, producto, tarifa, cantidad) {
  if (producto !== 'PORTES') {
    const jsonParaObtenerStock = {
      //pCustomer,
      pCustomer: documentNo,
      pcustomerPriceGroup: '',
      pItem: producto,
      pRepuestos: true
    }  

    const objJSON = JSON.stringify(jsonParaObtenerStock) // <-- creamos el JSON a enviar
    const dataStock  = await apiNavisionNew.navisionControllerPostEndpointBC("TW_Functions_QueryExternalStock", objJSON)

    if (dataStock.data.status === "OK") {
        const productoParsed = JSON.parse(dataStock.data.mensaje);
        let cantidadStock
        if (productoParsed === 0) {
          cantidadStock = 0
        } else {
          cantidadStock = productoParsed.Items[0]['InventoryAvailable']
        }
        if (cantidadStock < cantidad) {
          return <X size={25} className='mx-1' style={{ color: "red" }}/>;
        } else {
          return <Check size={25} className='mx-1' style={{ color: "green" }}/>;
        }
    } else {
        const resultJson = JSON.stringify({ status: "EMPTY", mensaje: 'NO STOCK'});
        console.log('ERROR: Consultando el stock del producto sustitutivo: ', dataStock)
        return JSON.parse(resultJson);
    }
  }
}

// ** Get Query Customer
export const getDireccionesCliente = (cliente, shop = true) => {
  return async (dispatch) => {
    try {
      const cod = getUserData()
      const filter = {where: {
          ['customerNo']:`${cliente.split(' ')[0]}`,
          ['shop']: shop,
          ['companyId'] : cod.companyId
        }
      }
      const data = await clienteasApi.clientesControllerFind(JSON.stringify(filter));
      return data;
    } catch (err) {
      console.error(err.message)
    }
  }
}