// ** Redux Imports
import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import ecommerce from '@src/views/productos/store/reducer'
import invoice from '@src/views/facturas/store/reducer'
import payment from '@src/views/abonos/store/reducer'
import deliveryNote from '@src/views/albaranes/store/reducer'
import order from '@src/views/pedidos/store/reducer'
import salesQuote from '@src/views/ofertas/store/reducer'
import estadisticasdeclientes from '@src/views/clientes/store/reducer'

import announcementsAdmin from '@src/views/desplegable-admin/anuncios/store/reducer'
import announcements from '@src/views/anuncios/store/reducer'
import promotionsAdmin from '@src/views/desplegable-admin/promociones/store/reducer'
import promotions from '@src/views/promociones/store/reducer'
import sats from '@src/views/desplegable-admin/sats/store/reducer'
import users from '@src/views/usuario/store/reducer'
import roles from '@src/views/desplegable-admin/roles/store/reducer'
import languages from '@src/views/desplegable-admin/languages/store/reducer'
import rolesPermissions from '@src/views/desplegable-admin/permisos/store/reducer'
import translations from '@src/views/desplegable-admin/traducciones/store/reducer'
import companies from '@src/views/desplegable-admin/empresas/store/reducer'
import productosdisponibles from '@src/views/desplegable-admin/productosdisponibles/store/reducer'
import comisiones from '@src/views/desplegable-admin/comisiones/store/reducer'

const ecommercePersistConfig = {
  key: 'ecommerce',
  storage,
  whitelist: ['cart', 'categorias'] //'salesQuoteIdentifier' // AGREGAR 'atributos'
}

const admin = combineReducers({
  announcements: announcementsAdmin,
  promotions: promotionsAdmin,
  users,
  roles,
  languages,
  rolesPermissions,
  translations,
  companies,
  sats,
  productosdisponibles,
  comisiones
})

const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  ecommerce: persistReducer(ecommercePersistConfig, ecommerce),
  invoice,
  payment,
  deliveryNote,
  order,
  admin,
  announcements,
  promotions,
  salesQuote,
  estadisticasdeclientes
})

export default rootReducer
