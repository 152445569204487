import { ADD_USER, DELETE_USER, GET_TARIFAS, GET_USERS_NAV, GET_USER, GET_USERS, INIT_USER, INIT_REQUEST, GET_USERS_COUNT, GET_VENDEDORES, SET_TARIFA, SET_CLIENTETARIFA, GET_VENDEDORES_CLIENTES } from "../action"

// ** Initial State
const initialState = {
  allData: [],
  data: [],
  vendedores: [],
  clientes: [],
  total: 1,
  params: {},
  usersFilter: [],
  selectedUser: null,
  processing: false,
  clientesVendedores: [],
  tarifas: []
}

const prefix = 'usuarios/'

const users = (state = initialState, action) => {
  switch (action.type) {
    case `${prefix}/GET_ALL_DATA`:
      return { ...state, allData: action.data }
    case INIT_REQUEST:
      return {
        ...state,
        processing: true
      }
    case INIT_USER:
      return { ...state, selectedUser: {} }
    case GET_VENDEDORES:
      return {
        ...state,
        vendedores: action.vendedores.value,
        clientes: action.arrCliente
      }
    case GET_TARIFAS:
      return { ...state, tarifas: action.tarifas }
    case SET_CLIENTETARIFA:
      return { ...state, clienteSelected: action.clienteSelected}
    case SET_TARIFA:
      return { ...state, tarifaSelected: action.tarifaSelected }
    case GET_USERS:
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params,
        processing: false,
        clientesVendedores: action.arrClientesVendedores
      }
    case GET_USER:
      return { ...state, selectedUser: action.selectedUser, clientesVendedores: action.vendedoresClientes }
    case GET_VENDEDORES_CLIENTES:
      return { ...state, clientesVendedores: action.vendedoresClientes }
    case GET_USERS_NAV:
      return { ...state, usersFilter: action.usersFilter }
    case ADD_USER:
      return { ...state }
    case DELETE_USER:
      return { ...state }
    case GET_USERS_COUNT:
      return { ...state, total: action.total }
    default:
      return { ...state }
  }
}
export default users
