import { GET_PAYMENTS, GET_PAYMENT, INIT_REQUEST, DELETE_PAYMENT, SEND_PAYMENT, GET_FILTROS_PAYMENTS, GET_PAYMENT_PDF, INIT_PAYMENT_PDF, DOWNLOAD_PAYMENT_PDF, SET_PAYMENT_FROM_ALLDATA, SWITCH_BILLTO_SELLTO } from '../action'

const initialState = {
  data: [],
  total: 1,
  params: {},
  filtros: {},
  allData: [],
  payment: undefined,
  paymentPdf: undefined,
  documentNoPdf: '',
  processing: false,
  showFromHijos: false
}


const paymentReducer = (state = initialState, action) => {
  switch (action.type) {
    case INIT_REQUEST:
      return {
        ...state,
        processing: true
      }
    case GET_PAYMENTS:
      return {
        ...state,
        allData: action.allData,
        data: action.data,
        total: action.totalPages,
        params: action.params,
        processing: false
      }
    case GET_PAYMENT:
      return {
        ...state,
        payment: action.payment,
        processing: false
      }
    case GET_PAYMENT_PDF:
      return {
        ...state,
        paymentPdf: action.paymentPdf,
        documentNoPdf: action.documentNo
      }
    case INIT_PAYMENT_PDF:
      return {
        ...state,
        paymentPdf: undefined
      }
    case DELETE_PAYMENT:
      return { ...state }
    case SEND_PAYMENT:
      return {
        ...state,
        processing: false
      }
    case DOWNLOAD_PAYMENT_PDF:
      return {
        ...state,
        paymentPdf: action.paymentPdf,
        documentNoPdf: action.documentNo,
        processing: false
      }
    case SET_PAYMENT_FROM_ALLDATA:
      return {
        ...state,
        payment: state.allData.find(payment => payment.documentNo === action.data)
      }
    case SWITCH_BILLTO_SELLTO:
      return {
        ...state,
        showFromHijos: !state.showFromHijos
      }
    case GET_FILTROS_PAYMENTS:
      return {
        ...state,
        filtros: action.filtros
      }

    default:
      return { ...state }
  }
}
export default paymentReducer
