/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { MailControllerApi, PortesControllerApi, settings, UsersControllerApi, NavisionControllerApi, ClientesControllerApi, ConexionSatControllerApi } from '@api/backend'
import instance from '@api/pim'
import { getUserCode } from '@src/auth/utils'
import { odataLogs, displayErrorMsg, displayInfoMsg, displaySuccessMsg, getUserData, testJSON, devuelveBasePath, devuelveBasePathPim, eliminaRepetidos, modeloProductoParaRepuesto } from '@utils'
import axios from 'axios'
import Swal from 'sweetalert2'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
const prefix = 'ecommerce'

const apiUser = new UsersControllerApi(settings)
const apiMail = new MailControllerApi(settings)
const apiPortes = new PortesControllerApi(settings)
const config = settings
const apiNavisionNew = new NavisionControllerApi(settings)
const clienteasApi = new ClientesControllerApi(settings)
const apiSat = new ConexionSatControllerApi(settings)

export const SEND_DELIVERY_NOTE = `${prefix}/SEND_DELIVERY_NOTE`
export const GET_PRODUCTS_PAG = `${prefix}/GET_PRODUCTS_PAG`

//O B T I E N E - S I T U A C I O N E S - A P I - (P I M)
export const getSituaciones = () => {
  return async () => {
      const { data: SituacionesPim } = await instance().get(`/Situaciones`)
      const arraySituaciones = SituacionesPim.map(situacion => ({
          value: situacion.id,
          label: situacion.nombre
      }));
      const etiquetasPermitidas = ['ACTIVA', 'FIN EXISTENCIAS', 'PROXIMAMENTE', 'DESCATALOGADA'];
      const arraySituacionesFiltrado = arraySituaciones.filter(
        situacion => etiquetasPermitidas.includes(situacion.label)
      );      
      return arraySituacionesFiltrado
  }
}

export const getPortes = () => {
  return async () => {
    try {
      const {data: dataPortes} = await apiNavisionNew.navisionControllerGetendPoint('shippingCosts')
      await apiPortes.portesControllerFind().then(resp => {
        resp.data.forEach(async el => {
          await apiPortes.portesControllerDeleteById(el.id)
        })
        dataPortes.value.forEach(async el => {
          await apiPortes.portesControllerCreate(JSON.stringify(el))
        })
      })
      
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const getPrecioPortes = () => {
  return async (dispatch) => {
    try {
      const {data: dataPortes} = await apiPortes.portesControllerFind()
      dispatch({
        type: `${prefix}/GET_PORTES`,
        data: dataPortes
      })
    } catch (err) {
      console.error(err)
    }
  }
}

// ** Get Query Customer
export const getCustomerAddresses = (cliente, shop = true) => {
  return async (dispatch) => {
    try {
      const cod = getUserData()
      const filter = {where: {
        ['customerNo']:`${cliente.split(' ')[0]}`,
        ['shop']: shop,
        ['companyId'] : cod.companyId
      }
      }
      await clienteasApi.clientesControllerFind(JSON.stringify(filter)).then((response) => {
        const { data } = response
        dispatch({
          type: `${prefix}/GET_CUSTOMER_ADDRESSES`,
          data
        })
      }).catch((err) => console.error(err.message))
    } catch (err) {
      console.error(err.message)
    }
  }
}

// ** SET direccion de envio
export const setShippingAddress = (data) => {
  return async (dispatch) => {
    dispatch({ type: `${prefix}/NEW_ADRESS_ACTIVE`, data: false })
    return dispatch({ type: `${prefix}/SET_SHIPPING_ADDRESS`, data })
  }
}

// ** Crear nueva direccion y setearla
export const createNewShippingAddress = (shippingAddress, intl) => {
  return async (dispatch, getState) => {
    try {
      displayInfoMsg(intl.formatMessage({ id: "New address being created"}))
      const objJSON = JSON.stringify(shippingAddress) // <-- creamos el JSON a enviar
      const { data } = await apiNavisionNew.navisionControllerPostEndpointBC("TW_Functions_InsertShipToCode", objJSON)
        //
        //Si ha ocurrido algún problema mostramos el error
        //
        if (data.mensaje.message) { 
        return displayErrorMsg(data.mensaje.message)
      }
      displaySuccessMsg(intl.formatMessage({ id: "New address successfully created"}))
      // Refetcheamos direcciones, buscamos la nueva creada por código y la seteamos
      await dispatch(getCustomerAddresses({}))
      // dispatch({ type: `${prefix}/CLEAR_SHIPPING_ADDRESS` })
      dispatch({ type: `${prefix}/NEW_SHIPPING_ADDRESS`, data: JSON.parse(data.mensaje).Resultado[0].shipToCode })
    } catch (err) {
      displayErrorMsg(err)
    }
  }
}

// ** Editar direccion
export const editarDireccion = (shippingAddress, intl) => {
  return async (dispatch, getState) => {
    try {
      displayInfoMsg(intl.formatMessage({ id: "Editing address"}))
      const objJSON = JSON.stringify(shippingAddress) // <-- creamos el JSON a enviar
      const { data } = await apiNavisionNew.navisionControllerPostEndpointBC("TW_Functions_InsertShipToCode", objJSON)
        //
        //Si ha ocurrido algún problema mostramos el error
        //
        if (data.mensaje.message) { 
        return displayErrorMsg(data.mensaje.message)
      }
      displaySuccessMsg(intl.formatMessage({ id: "Correctly edited address"}))
      // Refetcheamos direcciones, buscamos la nueva creada por código y la seteamos
      await dispatch(getCustomerAddresses({}))
      // dispatch({ type: `${prefix}/CLEAR_SHIPPING_ADDRESS` })
      dispatch({ type: `${prefix}/NEW_SHIPPING_ADDRESS`, data: JSON.parse(data.mensaje).Resultado[0].shipToCode })
    } catch (err) {
      displayErrorMsg(err)
    }
  }
}

// ** GET Categorias y familias del PIM
export const getFilters = () => {
  return async (dispatch) => {
    instance().get('/categorias').then(response => {
      // Los labels vienen en formato json, los parseamos para poder utilizarlos luego
      response.data = response.data.map(cat => {
        // Si no tiene label asignados vienen con array vacio, y hay algunas que hacen
        // porque tienen dos "" para cerrar por ejemplo y son json inválido
        const nombre = testJSON(cat.nombre) ? JSON.parse(cat.nombre) : cat.nombre
        return { ...cat, nombre }
      })
      let categories = response.data.filter(cat => (cat.padre === "" || cat.padre === cat.codigo || cat.padre === 'master') && cat.codigo !== "master")
      let mainChilds, subCats
      categories.forEach(mainCat => {
        mainChilds = []
        subCats = response.data.filter(cat => cat.padre === mainCat.codigo)
        subCats.forEach(subCat => {
          mainChilds.push(subCat.codigo)
          const subCats2 = response.data.filter(cat => cat.padre === subCat.codigo)
          mainChilds = [...mainChilds, ...subCats2.map(cat => cat.codigo)]
          const subChilds = subCats2.map(cat => cat.codigo)
          subCats = subCats.map(cat => (cat.codigo === subCat.codigo ? { ...cat, subs: subCats2, childs: subChilds } : cat))
        })
        categories = categories.map(cat => (cat.codigo === mainCat.codigo ? { ...cat, subs: subCats, childs: mainChilds } : cat))
      })
      dispatch({ type: `${prefix}/SET_CATEGORIES`, categories })
    })
  }
}

// ** GET Atributos del PIM y data relacionada necesaria
export const getAtributos = () => {
  return async (dispatch) => {
    const { data } = await instance().get('/atributos')
    //
    //Recupera los datos de Mysql
    //
    const atributos = {}
    //
    //Bucle para montar la información que necesitamos en la Ficha Técnica
    //
    data.forEach(att => {
      //
      //Busca en los nombre de los atributos de Nav el atributo actual
      //
      //Añade un registro al array "atributos"
      //
      //Compruebo el tipo de campo que para poder devolver el valor correcto
      let tipoCampo = "";
      if (att.tipoCampo.toLowerCase() == "o") {
        tipoCampo = "Option"
      } else 
      if (att.tipoCampo.toLowerCase() == "t") {
        tipoCampo = "Text"
      } else if (att.tipoCampo.toLowerCase() == "n") {
        tipoCampo = "Num"
      }

      atributos[att.id] = {
        id: att.id,
        label: att.nombre,
        group: (att.tipo === 'file') ? 'adjuntos' : 'Producto',
        code: att.codigo,
        type: tipoCampo,
        principalFilter: true,
        values: att.posiblesValores.split(','),
        categorias: att.categorias
      }
    })
    //
    //Devuelve la información
    //
    dispatch({ type: `${prefix}/SET_ATRIBUTOS`, atributos })
  }
}

// ** GET Products
export const getProducts = (params) => {
  return async (dispatch, getState) => {
    const { perPage, page, sortBy, q, code } = params
    let { tarifa } = params
    const allProducts = getState().ecommerce.allProducts
    let productosNav = []
    const storeAdmin = await getState().admin

    if (sortBy) {
      //
      //Ordenamos alfabéticamente 
      //
      if (sortBy === 'alfabeticamente') {
        allProducts.sort((a, b) => {
          const nameA = a.sku.toUpperCase(); // Convertir a mayúsculas para ordenar insensible a mayúsculas/minúsculas
          const nameB = b.sku.toUpperCase(); // Convertir a mayúsculas para ordenar insensible a mayúsculas/minúsculas
        
          if (nameA < nameB) {
            return -1; // a debe venir antes que b en el orden
          }
          if (nameA > nameB) {
            return 1; // b debe venir antes que a en el orden
          }
          return 0; // son iguales, no se cambia el orden
        })
      }
      //
      //Ordenamos por fecha
      //
      if (sortBy === 'fechaCreacion') {
        //
        //Anters de ordenar tratamos la fecha para que solo lleve año, mes y día y tenga el formato que necesita JS (YYY-MM-DD) para realizar bien la ordenación
        //
        allProducts.forEach((product) => {
          const fechaStr = product.fecha_creacion_producto;
          const partesFecha = fechaStr.split(' ');
          const partesFechaYMD = partesFecha[0].split('/');
          product.fecha_creacion_producto_cortada = `${partesFechaYMD[2]}-${partesFechaYMD[1]}-${partesFechaYMD[0]}`
        });
        allProducts.sort((a, b) => {
          const fechaA = new Date(a.fecha_creacion_producto_cortada);
          const fechaB = new Date(b.fecha_creacion_producto_cortada);
          return fechaB - fechaA;
        });
      }
    }

    const slicedProducts = allProducts.slice(((page || 1) * (perPage || 9)) - (perPage || 9), (page || 1) * (perPage || 9))
    const listaSlugs = slicedProducts.map(item => item.slug);
    if (slicedProducts.length > 0) {
      if (storeAdmin.users.selectedUser.tipoUsuario != "V" || storeAdmin.users.selectedUser.tipoUsuario != "Vendedor") {
        tarifa = ''
      }
      await axios.get(`${devuelveBasePath()}/query-page-products/${code.split(' ')[0]}/${listaSlugs.join("|")}/?customerPriceGroup=${tarifa}`).then(response => {
        if (response.data.result && response.data.result.return_value != undefined) {
          productosNav = JSON.parse(response.data.result.return_value).Items
        }
      })
      if (productosNav) {
        slicedProducts.forEach((product) => {
          const datoPrecios = productosNav.find(dato => dato.Model === product.slug);
          product.stock = datoPrecios?.InventoryAvailable ? datoPrecios?.InventoryAvailable : '0'
          product.precioPVR = datoPrecios?.PVRSalesPrice ? datoPrecios?.PVRSalesPrice : '0'
          product.precioPVC = datoPrecios?.CustomerSalesPrice ? datoPrecios?.CustomerSalesPrice : '0'
        });
        localStorage.setItem('cargandoPantalla', 'NO')
      }
    }
    return dispatch({ type: `${prefix}/GET_PRODUCTS`, slicedProducts, allProducts, params })
  }
}

// ** GET Products
export const refreshDisplayedProducts = () => {
  return async (dispatch, getState) => {
    const { perPage, page, sortBy, q } = getState().ecommerce.params
    let allProducts = getState().ecommerce.allProducts
    let productosNav = []
    const storeAdmin = await getState().admin

    if (q && q === "stockonly") allProducts = allProducts.filter(p => p.inventoryAvailable >= 1)
    if (sortBy) {
      if (sortBy === 'alfabeticamente') {
        allProducts.sort((a, b) => {
          const nameA = a.name.toUpperCase(); // Convertir a mayúsculas para ordenar insensible a mayúsculas/minúsculas
          const nameB = b.name.toUpperCase(); // Convertir a mayúsculas para ordenar insensible a mayúsculas/minúsculas
        
          if (nameA < nameB) {
            return -1; // a debe venir antes que b en el orden
          }
          if (nameA > nameB) {
            return 1; // b debe venir antes que a en el orden
          }
          return 0; // son iguales, no se cambia el orden
        })
      }
      if (sortBy === 'price-desc') allProducts = allProducts.sort((a, b) => b.PVR - a.PVR)
    }

    const products = allProducts.slice(((page || 1) * (perPage || 9)) - (perPage || 9), (page || 1) * (perPage || 9))
    if (products.length > 0) {
      let tarifa = ''
      if (storeAdmin.users.selectedUser.tipoUsuario == "V" || storeAdmin.users.selectedUser.tipoUsuario == "Vendedor") {
        tarifa = localStorage.getItem('tarifaDefecto')
      }
      const listaSlugs = products.map(item => item.slug);
      await axios.get(`${devuelveBasePath()}/query-page-products/${localStorage.getItem('clienteDefecto').split(' ')[0]}/${listaSlugs.join("|")}/?customerPriceGroup=${tarifa}`).then(response => {
        if (response.data.result && response.data.result.return_value != undefined) {
          productosNav = JSON.parse(response.data.result.return_value).Items
        }
      })
      if (productosNav) {
        products.forEach((product) => {
          const datoPrecios = productosNav.find(dato => dato.Model === product.slug);
          product.stock = datoPrecios?.InventoryAvailable ? datoPrecios?.InventoryAvailable : '0'
          product.precioPVR = datoPrecios?.PVRSalesPrice ? datoPrecios?.PVRSalesPrice : '0'
          product.precioPVC = datoPrecios?.CustomerSalesPrice ? datoPrecios?.CustomerSalesPrice : '0'
        });
        localStorage.setItem('cargandoPantalla', 'NO')
      }
    }
    return dispatch({ type: `${prefix}/REFRESH_PRODUCTS`, products, allProducts, params: { perPage, page, sortBy, q } })
  }
}

export const getProductoNav = (cliente, producto, tarifa) => {
  return async (dispatch, getState) => {
    try {
      if (tarifa == undefined || tarifa ==  "") { tarifa = "" }
      let productoParsed, productoCompleto
      const {data: productoNav } = await axios.get(`${devuelveBasePath()}/query-page-products/${cliente}/${producto}/?customerPriceGroup=${tarifa}`)
      const {data: productosPim } = await instance().get(`/B2bProductos/${producto}`)
      productosPim[0].imagen = `${devuelveBasePathPim()}/${productosPim[0].imagen}`
      if (productoNav.result.return_value != undefined) {
        productoParsed = JSON.parse(productoNav.result.return_value)
        productoCompleto = {...productoParsed.Items[0], ...productosPim[0]}
      } 
      dispatch({ 
        type: `${prefix}/GET_PRODUCT`, 
        data: productoCompleto
      })
    } catch (err) {
      console.error(err)
    }
  }
}

export const getDatosNavProductos = (productos, code, tarifa) => {
  return async (dispatch, getState) => {
    try {
      let productosNav = []
      //
      //Si la tarifa no está definida le pasamos *
      //
      if (tarifa == undefined || tarifa ==  "") { tarifa = "" }
      //
      //Como son 9 productos los agrupamos consecutivamente y los pasamos de una a query-page-products (que al final llama a TW_Functions_QueryExternalStock)
      //
      const storeAdmin = await getState().admin
      if (storeAdmin.users.selectedUser.tipoUsuario == "V" || storeAdmin.users.selectedUser.tipoUsuario == "Vendedor") {
        tarifa = localStorage.getItem('tarifaDefecto')
      }
      await axios.get(`${devuelveBasePath()}/query-page-products/${code}/${productos.join("|")}/?customerPriceGroup=${tarifa}`).then(response => {
        if (response.data.result && response.data.result.return_value != undefined) {
          productosNav = JSON.parse(response.data.result.return_value).Items
        }
      })
      //
      //Metemos los productos que no haya devuelto NAV como vacios
      //
      productos.forEach(async producto => {
        if ((productosNav.find(e => e.Model == producto) == undefined)) {
          productosNav.push({Model:producto,
                             Description:"",
                             Mark:"",
                             CategoryDescription:"",
                             BarCode:"",
                             InventoryAvailable:"0",
                             InventoryDeposit:"0",
                             Status:"",
                             EntryDate1:"",
                             EntryDate2:"",
                             EntryDate3:"",
                             EntryQty1:"",
                             EntryQty2:"",
                             EntryQty3:"",
                             CustomerSalesPrice:"0",
                             CustomerSalesDiscount:"",
                             PVRSalesPrice:"0",
                             sinDatosNav: true})
        }
      })
      dispatch({ 
        type: `${prefix}/GET_PRODUCTS_PAG`, 
        data: productosNav 
      })
    } catch (err) {
      
      console.error(err)
    }
  }
}

const getCodigoMarca = (marca) => {
  const codMarcas = [
  {cod: 'SV', nombre: 'SVAN'}, 
  {cod: 'NL', nombre: 'NILSON'}, 
  {cod: 'AS', nombre: 'ASPES'}, 
  {cod: 'WD', nombre: 'WONDER'}, 
  {cod: 'TO', nombre: 'TOSHIBA'}, 
  {cod: 'SH', nombre: 'SHARP'},
  {cod: 'HY', nombre: 'HYUNDAI'}
  ]
  const codigo = codMarcas.find(el => el.nombre.toLowerCase() == marca.toLowerCase())
  return codigo?.cod
}

const getNombreMarca = (marca) => {
  const codMarcas = [
  {cod: 'SV', nombre: 'SVAN'}, 
  {cod: 'NL', nombre: 'NILSON'}, 
  {cod: 'AS', nombre: 'ASPES'}, 
  {cod: 'WD', nombre: 'WONDER'}, 
  {cod: 'TO', nombre: 'TOSHIBA'}, 
  {cod: 'SH', nombre: 'SHARP'},
  {cod: 'HY', nombre: 'HYUNDAI'}
  ]
  const nombre = codMarcas.find(el => el.cod.toLowerCase() == marca.toLowerCase())
  return nombre?.nombre
}

// ** GET Products
export const getAllProducts = (cliente, intl) => {
  return async (dispatch, getState) => {
    try {
      const {data: productos } = await instance().get(`/B2bProductos/*`)
      const cod = getUserData()

      const filter = {where: {
        ['customerNo']:`${cliente}`,
        ['companyId'] : cod.companyId
        }
      }
      const { data: clientes} = await clienteasApi.clientesControllerFind(JSON.stringify(filter))

      //const { data: filtros} = await apiNavisionNew.navisionControllerGetendPoint('querySalesSetup')
      if (!(productos instanceof Array)) {
        return displayErrorMsg(intl.formatMessage({ id: 'Error bringing product data'}))
      }
      const products = productos.map(product => {
        return { ...product, imagen: `${devuelveBasePathPim()}/${product.imagen}` }
      })
      //const filtrosDefecto = filtros.value[0]
      const clienteNav = clientes[0]
      // const filtroMarca = (clienteNav == "" || clienteNav == null || clienteNav == undefined) ? filtrosDefecto.filterDefaultMarcs : clienteNav.markFilter
      // const filtroEstados = (clienteNav == "" || clienteNav == null || clienteNav == undefined) ? filtrosDefecto.filterDefaultStatus : clienteNav.referenceStatus
      // const filtroCategorias = (clienteNav == "" || clienteNav == null || clienteNav == undefined) ? filtrosDefecto.filterDefaultCategories : clienteNav.filtercategories

      let arrayProductosFiltroUno = products
      let arrayProductosFiltroDos = products
      let arrayFinalDeProductos = []
      let filtroUno = ''
      let filtroDos = ''
      if (clienteNav.itemFilter01 !== '' && clienteNav.itemFilter01 !== '###') {
        filtroUno = clienteNav.itemFilter01.split('#');
      }

      if (clienteNav.itemFilter02 !== '' && clienteNav.itemFilter02 !== '###') {
        filtroDos = clienteNav.itemFilter02.split('#');
      }

      // F I L T R O - U N O
      if (filtroUno.length === 4) {
        const filtroUnoMarcas = filtroUno[1]
        const filtroUnoCategorias = filtroUno[2]
        const filtroUnoEstados = filtroUno[3]

        if (filtroUnoMarcas.length) {
          const marcas = filtroUnoMarcas.split('|')
          arrayProductosFiltroUno = arrayProductosFiltroUno.filter(p => marcas.includes(getCodigoMarca(p.brand)))
        }
        if (filtroUnoEstados.length) {
          const estados = filtroUnoEstados.split('|')
          arrayProductosFiltroUno = arrayProductosFiltroUno.filter(p => estados.includes(p.status))
        }
        if (filtroUnoCategorias.length) {
          const filtroCategoriasFormateado = filtroUnoCategorias.replace(/&/g, '|');
          const categorias = filtroCategoriasFormateado.split('|')
  
          const categoriasSinOperador = categorias.filter(c => !c.startsWith('<>'));
          let categoriasConOperador = categorias.filter(c => c.startsWith('<>'));
          
          if (categoriasSinOperador.length > 0) {
            arrayProductosFiltroUno = arrayProductosFiltroUno.filter(p => categoriasSinOperador.includes(p.category))
          }
          if (categoriasConOperador.length > 0) {
            categoriasConOperador = categoriasConOperador.map(cat => cat.replace(/[<>]/g, '')); // Quita los caracteres '<' y '>' de cada elemento del array
            categoriasConOperador = categoriasConOperador.filter(cat => cat.trim() !== ''); // Elimina los elementos vacíos del array resultante
            arrayProductosFiltroUno = arrayProductosFiltroUno.filter(p => !categoriasConOperador.includes(p.category))
          } 
        }
      }

      // F I L T R O - D O S
      if (filtroDos.length === 4) {
        const filtroDosMarcas = filtroDos[1]
        const filtroDosCategorias = filtroDos[2]
        const filtroDosEstados = filtroDos[3]

        if (filtroDosMarcas.length) {
          const marcas = filtroDosMarcas.split('|')
          arrayProductosFiltroDos = arrayProductosFiltroDos.filter(p => marcas.includes(getCodigoMarca(p.brand)))
        }
        if (filtroDosEstados.length) {
          const estados = filtroDosEstados.split('|')
          arrayProductosFiltroDos = arrayProductosFiltroDos.filter(p => estados.includes(p.status))
        }
        if (filtroDosCategorias.length) {
          const filtroCategoriasFormateado = filtroDosCategorias.replace(/&/g, '|');
          const categorias = filtroCategoriasFormateado.split('|')
  
          const categoriasSinOperador = categorias.filter(c => !c.startsWith('<>'));
          let categoriasConOperador = categorias.filter(c => c.startsWith('<>'));
          
          if (categoriasSinOperador.length > 0) {
            arrayProductosFiltroDos = arrayProductosFiltroDos.filter(p => categoriasSinOperador.includes(p.category))
          }
          if (categoriasConOperador.length > 0) {
            categoriasConOperador = categoriasConOperador.map(cat => cat.replace(/[<>]/g, '')); // Quita los caracteres '<' y '>' de cada elemento del array
            categoriasConOperador = categoriasConOperador.filter(cat => cat.trim() !== ''); // Elimina los elementos vacíos del array resultante
            arrayProductosFiltroDos = arrayProductosFiltroDos.filter(p => !categoriasConOperador.includes(p.category))
          } 
        }
      }

      // ¿ Tiene ambos filtros ?
      if (filtroUno.length === 4 && filtroDos.length === 4) {
        // Combina los dos arrays mientras evita duplicados basados en la propiedad 'id'
        arrayFinalDeProductos = [
          ...arrayProductosFiltroUno, ...arrayProductosFiltroDos.reduce((acc, obj) => {
            // Verifica si el objeto no está presente en acc (resultado acumulado)
            if (!acc.some(item => item.id === obj.id)) {
              acc.push(obj); // Agrega el objeto a acc
            }
            return acc;
          }, [])
        ];
      } else {
        if (filtroUno.length === 4) { // ¿ Solo tiene filtro Uno ?
          arrayFinalDeProductos = arrayProductosFiltroUno
        } else {
          if (filtroDos.length === 4) { // ¿ Solo tiene filtro Dos ?
            arrayFinalDeProductos = arrayProductosFiltroDos
          }
        }
      }
      
      arrayFinalDeProductos = eliminaRepetidos(arrayFinalDeProductos)
      if (arrayFinalDeProductos.length === 0) {
        displayInfoMsg(intl.formatMessage({ id: 'There are no products that match the filters configured in BC for this customer.'}))
      }
      dispatch({ type: `${prefix}/GET_ALL_PRODUCTS`, data: arrayFinalDeProductos })
      dispatch({ type: `${prefix}/SET_MARCAS`, data: clienteNav.markFilter.split('|').map(elemento => getNombreMarca(elemento)).sort() })
      dispatch(getFilters())
      return dispatch(refreshDisplayedProducts())
    } catch (err) {
      console.error(err.message)
    }
  }
}

// ** GET Products
export const getAllProductsDocumentacion = (model, intl) => {
  return async (dispatch, getState) => {
    try {
      const { data: productos } = await instance().get(`/B2bProductos/${model}`)
      if (!(productos instanceof Array)) {
        return displayErrorMsg(intl.formatMessage({ id: 'Error bringing product data'}))
      }
      let products = productos.map(product => {
        return { ...product, imagen: `${devuelveBasePathPim()}/${product.imagen}` }
      })
      products = eliminaRepetidos(products)
      dispatch({ type: `${prefix}/GET_ALL_PRODUCTS`, data: products })
      // dispatch(getFilters())
      return dispatch(refreshDisplayedProducts())
    } catch (err) {
      console.error(err.message)
    }
  }
}

export async function getUsers() {
  //
  //Cambiamos la funcionalidad para que busque por id de la tabla ya que el code está obsoleto
  //
  try {
    const { id } = getUserData()
    const filter = { where: { id: { eq: id } } }
    const { data } = await apiUser.usersControllerFind(JSON.stringify(filter))
    return data
  } catch (err) {
    console.error(err.message)
  }
}

export async function getNavUsersData() {
  try {
    const { data } = await apiNavisionNew.navisionControllerGetendPoint('querySalesPersons')
    return data
  } catch (err) {
    console.error(err.message)
  }
}

// ** Add Item to Cart
export const addToCart = (model, intl) => {
  return async (dispatch, getState) => {
    try {
      if (localStorage.getItem("carritoRepuestos")) {
        return Swal.fire({
          position: 'center',
          icon: 'warning',
          title: intl.formatMessage({ id: 'Shopping cart'}),
          text: intl.formatMessage({ id: 'The current cart is a spare parts cart. To add an entire product, the current cart will be emptied first.'}),
          showConfirmButton: true,
          showCancelButton: true,
          cancelButtonColor: '#808080',
          cancelButtonText: 'Cancel'
        }).then(async (result) => {
          if (result.isConfirmed) {
            localStorage.setItem("carritoRepuestos", "")
            return dispatch({ type: `${prefix}/CLEAR_CART` })
          }
        })
      } else {
        if (!localStorage.getItem("carritoNormal")) {
          localStorage.setItem("carritoNormal", true)
        }
        const { cart } = getState().ecommerce
        const storeAdmin = await getState().admin
        let tarifa = ''
        if (storeAdmin.users.selectedUser.tipoUsuario == "V" || storeAdmin.users.selectedUser.tipoUsuario == "Vendedor") {
          tarifa = localStorage.getItem('tarifaDefecto')
        }
        const usuario = storeAdmin.users.selectedUser.tipoUsuario == "V" || storeAdmin.users.selectedUser.tipoUsuario == "Vendedor" ? storeAdmin.users.clienteSelected : storeAdmin.users.selectedUser.codVendedorCliente

        const { data } = await axios.get(`${devuelveBasePath()}/query-page-products/${usuario.split(' ')[0]}/${model}/?customerPriceGroup=${tarifa}`)
        const productoNav = JSON.parse(data.result.return_value)
        const {data: productosPim } = await instance().get(`/B2bProductos/${model}`)
        const producto = {...productoNav.Items[0], ...productosPim[0]}
        producto.PVR = producto.PVRSalesPrice
        producto.PVC = producto.CustomerSalesPrice
        producto.imagen = `${devuelveBasePathPim()}/${producto.imagen}`
        producto.PVC_OriginalBC = producto.CustomerSalesPrice

        let updatedCart = []
        // Si existe aumenta cantidad
        if (cart.find(item => item.model === model)) updatedCart = cart.map(item => (item.model === model ? { ...item, qty: item.qty + 1 } : item))
        // Si no existe en carro lo añade
        else updatedCart = cart.concat({ ...producto, qty: 1 })
        // Dispatch del carrito actualizado
        dispatch({ type: `${prefix}/ADD_TO_CART`, data: updatedCart })
      }
    } catch (err) {
      console.error(err)
    }
  }
}

export const addToCartRepuestos = (model, intl) => {
  return async (dispatch, getState) => {
    try {
      if (localStorage.getItem("carritoNormal")) {
        return Swal.fire({
          position: 'center',
          icon: 'warning',
          title: intl.formatMessage({ id: 'Shopping cart'}),
          text: intl.formatMessage({ id: 'The current cart is full of products. To add a replacement, the current cart will be emptied first.'}),
          showConfirmButton: true,
          showCancelButton: true,
          cancelButtonColor: '#808080',
          cancelButtonText: 'Cancel'
        }).then(async (result) => {
          if (result.isConfirmed) {
            localStorage.setItem("carritoNormal", "")
            return dispatch({ type: `${prefix}/CLEAR_CART` })
          }
        })
      } else {
        if (!localStorage.getItem("carritoRepuestos")) {
          localStorage.setItem("carritoRepuestos", true)
        }
        const getDocumentId = `almacenPropio_${model.Model}`;
        const cantidad = document.getElementById(getDocumentId).value === '' ? 1 : document.getElementById(getDocumentId).value
        const { cart } = getState().ecommerce
        const modeloProductoRepuesto = modeloProductoParaRepuesto 
        modeloProductoRepuesto.id = getState().ecommerce.cart.length
        modeloProductoRepuesto.model = model.Model
        modeloProductoRepuesto.Model = model.Model
        const nombre = `${model.no} ${model.description ? model.description : model.Description}`
        modeloProductoRepuesto.BarCode = model.BarCode ? model.BarCode : ''
        modeloProductoRepuesto.name = nombre
        modeloProductoRepuesto.sku = model.Model
        modeloProductoRepuesto.slug = model.Model
        modeloProductoRepuesto.Mark = model.Mark ? model.Mark : ''
        modeloProductoRepuesto.category = model.CategoryDescription === 'RPT' ? 'REPUESTOS' : ''
        modeloProductoRepuesto.CategoryDescription = model.CategoryDescription ? model.CategoryDescription : ''
        modeloProductoRepuesto.description = model.description ? model.description : model.Description
        modeloProductoRepuesto.Description = model.description ? model.description : model.Description
        modeloProductoRepuesto.fullDescription = model.description ? model.description : model.Description
        modeloProductoRepuesto.InventoryAvailable = model.InventoryAvailable ? model.InventoryAvailable : '0'
        modeloProductoRepuesto.InventoryDeposit = model.InventoryDeposit ? model.InventoryDeposit : '0'
        modeloProductoRepuesto.PVC = model.CustomerSalesPrice ? model.CustomerSalesPrice : '0'
        modeloProductoRepuesto.PVC_OriginalBC = model.CustomerSalesPrice ? model.CustomerSalesPrice : '0'
        modeloProductoRepuesto.PVR = model.PVRSalesPrice ? model.PVRSalesPrice : '0'
        modeloProductoRepuesto.PVRSalesPrice = model.PVRSalesPrice ? model.PVRSalesPrice : '0'
        modeloProductoRepuesto.CustomerSalesDiscount = model.CustomerSalesDiscount ? model.CustomerSalesDiscount : '0'
        modeloProductoRepuesto.status = model.Status ? model.Status : ''
        modeloProductoRepuesto.volumen = '0'
        const {data: productoPim } = await instance().get(`/B2bProductos/${model.Model}`)
        const producto = {...productoPim[0]}
        modeloProductoRepuesto.imagen = `${devuelveBasePathPim()}/${producto.imagen}`
        modeloProductoRepuesto.qty = cantidad
        let updatedCart = []
        // Si existe aumenta cantidad
        if (cart.find(item => item.model === modeloProductoRepuesto.model)) updatedCart = cart.map(item => (item.model === modeloProductoRepuesto.model ? { ...item, qty: item.qty + 1 } : item))
        // Si no existe en carro lo añade
        else updatedCart = cart.concat({ ...modeloProductoRepuesto, qty: cantidad })
        // Dispatch del carrito actualizado
        dispatch({ type: `${prefix}/ADD_TO_CART`, data: updatedCart })
      }
    } catch (err) {
      console.error(err)
    }
  }
}

// ** Añadir item/items al carrito con cantidad seteada
export const addToCartQty = (arrItems, PVRSalesPrice, InventoryAvailable, intl) => {
  return async (dispatch, getState) => {

    if (localStorage.getItem("carritoRepuestos")) {
      return Swal.fire({
        position: 'center',
        icon: 'warning',
        title: intl.formatMessage({ id: 'Shopping cart'}),
        text: intl.formatMessage({ id: 'The current cart is a spare parts cart. To add an entire product, the current cart will be emptied first.'}),
        showConfirmButton: true,
        showCancelButton: true,
        cancelButtonColor: '#808080',
        cancelButtonText: 'Cancel'
      }).then(async (result) => {
        if (result.isConfirmed) {
          localStorage.setItem("carritoRepuestos", "")
          return dispatch({ type: `${prefix}/CLEAR_CART` })
        }
      })
    } else {
      if (!localStorage.getItem("carritoNormal")) {
        localStorage.setItem("carritoNormal", true)
      }
      const { cart } = await getState().ecommerce
      const storeAdmin = await getState().admin
      const dataUsers = storeAdmin.users
      //const tarifa = dataUsers.tarifaSelected == "" ? "*" : dataUsers.tarifaSelected
      let tarifa = ''
      if (storeAdmin.users.selectedUser.tipoUsuario == "V" || storeAdmin.users.selectedUser.tipoUsuario == "Vendedor") {
        tarifa = localStorage.getItem('tarifaDefecto')
      }
      const usuario = dataUsers.selectedUser.tipoUsuario == "V" ? dataUsers.clienteSelected : dataUsers.selectedUser.codVendedorCliente
      let updatedCart = [...cart]
  
      await arrItems.forEach(async pr => {
        const { model, qty } = pr
  
        const { data } = await axios.get(`${devuelveBasePath()}/query-page-products/${usuario}/${model}/?customerPriceGroup=${tarifa}`)
        const productoNav = JSON.parse(data.result.return_value)
        const {data: productosPim } = await instance().get(`/B2bProductos/${model}`)
        const producto = {...productoNav.Items[0], ...productosPim[0]}
        producto.PVR = producto.PVRSalesPrice
        producto.PVC = producto.CustomerSalesPrice
        producto.imagen = `${devuelveBasePathPim()}/${producto.imagen}`
        producto.PVC_OriginalBC = producto.CustomerSalesPrice
  
        if (!producto) displayErrorMsg(`${intl.formatMessage({ id: 'Product' })} ${model} ${intl.formatMessage({ id: 'not found' })}`)
        // Si existe aumenta cantidad
        if (updatedCart.find(item => item.model === model)) updatedCart = updatedCart.map(item => (item.model === model ? { ...item, qty: item.qty + qty } : item))
        // Si no existe en carro lo añade
        else updatedCart = updatedCart.concat({ ...producto, qty })
        // Dispatch del carrito actualizado
        dispatch({ type: `${prefix}/ADD_TO_CART`, data: updatedCart })
      })
      return dispatch({ type: `${prefix}/ADD_TO_CART`, data: updatedCart }) 
    }
  }
}

// ** Editar cantidad de item en carrito
export const editCartQty = (model, qty) => {
  return async (dispatch, getState) => {
    const cart = getState().ecommerce.cart
    //
    //Con esto filter(a => a) hacemos que filtre solo por los que sean elementos del carrito, por si se ha metido algo raro con PORTES...
    //
    const updatedCart = [...cart].filter(a => a).map(item => (item.model === model ? { ...item, qty } : item))
    return dispatch({ type: `${prefix}/SWITCH_QTY`, data: updatedCart })
  }
}

// ** Editar cantidad de item en carrito
export const editCartDescuento = (model, descuento) => {
  return async (dispatch, getState) => {
    const cart = getState().ecommerce.cart
    //
    //Con esto filter(a => a) hacemos que filtre solo por los que sean elementos del carrito, por si se ha metido algo raro con PORTES...
    //
    const updatedCart = [...cart].filter(a => a).map(item => (item.model === model ? { ...item, porcentajeDescuento: descuento } : item))
    //
    //Tanto el SWITCH_QTY, como el SWITCH_PVR o el UPDATE_PVC... actualizan el carrito por lo que utilizo uno de ellos sin necesidad de crear otro
    //
    return dispatch({ type: `${prefix}/SWITCH_QTY`, data: updatedCart })
  }
}

export const editCartPrice = (model, CustomerSalesPrice, CustomerSalesDiscount) => {
  return async (dispatch, getState) => {
    const cart = getState().ecommerce.cart
    const updatedCart = [...cart].map(item => (item.model === model ? { ...item, CustomerSalesPrice, CustomerSalesDiscount, porcentajeDescuento: CustomerSalesDiscount } : item))
    return dispatch({ type: `${prefix}/SWITCH_PVR`, data: updatedCart })
  }
}

// FUNCION PARA PODER ACTUALIZAR EL PVC
export const updateItemPVC = (model, PVC) => {
  return async (dispatch, getState) => {
    const cart = getState().ecommerce.cart
    const updatedCart = [...cart].map(item => (item.model === model ? { ...item, PVC } : item))
    return dispatch({ type: `${prefix}/UPDATE_PVC`, data: updatedCart })
  }
}

// ** DELETE Cart Items
export const deleteCartItem = id => {
  return (dispatch, getState) => {
    const updatedCart = getState().ecommerce.cart.filter(item => item.id !== id)
    dispatch({ type: `${prefix}/DELETE_CART_ITEM`, data: updatedCart })
  }
}

// ** CHANGE Items Rate
export const changeRate = rate => {
  return dispatch => dispatch({ type: `${prefix}/CHANGE_RATE`, data: rate })
}

// ** CHANGE Items Rate
export const changeGerenteSuper = gerenteSuper => {
  return dispatch => dispatch({ type: `${prefix}/GERENTE_SUPER`, data: gerenteSuper })
}

// Clear cart items
export const clearCart = () => {
  return dispatch => dispatch({ type: `${prefix}/CLEAR_CART` })
}

// ** Set carrito e identificador de oferta
export const setSalesQuoteCart = (productosaPasar, salesQuoteIdentifier, yourReference, tarifa, code, fechaRequerida) => {
  return async (dispatch) => {
    let productosNav = []    
    //
    //Llamamos a query-page-products (que al final llama a TW_Functions_QueryExternalStock)
    //
    const productosaPasarSoloIdentificadores = productosaPasar.filter(producto => producto.id).map(producto => producto.model).join("|")

    await axios.get(`${devuelveBasePath()}/query-page-products/${code}/${productosaPasarSoloIdentificadores}/?customerPriceGroup=${tarifa}`).then(async response => {
      if (response.data.result && response.data.result.return_value != undefined) {
        //
        //Arreglamos los productos devueltos
        //
        productosNav = JSON.parse(response.data.result.return_value).Items
        //
        //Devolvemos los datos de Nav junto con la información del Pim
        //
        const cargaProductos = async (productosNav) => {
          return await Promise.all(productosNav.map(async (producto) => {
            if (producto != "" && producto != undefined && producto != null) {
              const { data: productosPim } = await instance().get(`/B2bProductos/${producto.Model}`);
              const datosProductoaPasar = productosaPasar.find(productoaPasar => productoaPasar.model == producto.Model)
              return { ...producto, 
                       ...productosPim[0], 
                       PVR: producto.PVRSalesPrice, 
                       PVC: producto.CustomerSalesPrice, 
                       imagen: `${devuelveBasePathPim()}/${producto.imagen}`, 
                       PVC_OriginalBC: producto.CustomerSalesPrice,
                       qty: datosProductoaPasar.qty,
                       porcentajeDescuento: datosProductoaPasar.porcentajeDescuento }
            }
          }));          
        };
        //
        //Llamamos a cargaProductos en modo await para que no continue hasta que termine 
        //
        const productosActualizados = await cargaProductos(productosNav)
        //
        // Los metemos en el carrito
        //
        //else updatedCart = cart.concat({ ...producto, qty: 1 })

        dispatch({ type: `${prefix}/ADD_TO_CART`, data: productosActualizados})
        //
        //Llamamos a SET_SALES_QUOTE_CART para quitarlo de oferta 
        //
        return dispatch({ type: `${prefix}/SET_SALES_QUOTE_CART`, salesQuoteIdentifier, yourReference, fechaRequerida })
      }
    })
  } 
}

// ** Clear salesquote identifier from cart
export const clearSalesQuoteCart = () => {
  return (dispatch) => {
    return dispatch({ type: `${prefix}/CLEAR_SALES_QUOTE_CART` })
  }
}

// ** Crea/edita oferta
export const createNewSalesOrder = (yourReference, date, programado, especial, depositoOrder, porte, volumen, intl) => {
  return async (dispatch, getState) => {
    try {
      //getPrecioPortes(countryCode, regionCode, unitPrice)
      const store = getState().ecommerce
      const storeAdmin = getState().admin.users
      // Recojo tipo y codigo, codVendedor(si es vendedor) y selecciono uno u otro dependiendo del caso
      let tipoUsuario, codigoCliente, codVendedor
      if (storeAdmin.selectedUser.tipoUsuario === "V") {
        tipoUsuario = "B2B_Comercial"
        codigoCliente = storeAdmin.clienteSelected
        codVendedor = storeAdmin.selectedUser.codVendedorCliente
      } else {
        tipoUsuario = "B2B_Cliente"
        codigoCliente = storeAdmin.selectedUser.codVendedorCliente
        codVendedor = ""
      }
      const { cart, shippingAddress, newShipToCode } = store
      const lineas = cart.map(cartItem => {
        return {
          no: cartItem.model,
          quantity: cartItem.qty,
          unitPrice: cartItem.PVC_OriginalBC || '0.00',
          discountLine: cartItem.CustomerSalesDiscount || 0,
          totalVolume: cartItem.volumen || 0
        }
      })
      let shipCode = shippingAddress.shipToCode == undefined ? newShipToCode : shippingAddress.shipToCode
      const volumenValue = (volumen == undefined || volumen == null) ? 0 : parseInt(volumen)
      let tarifa = ''
      if (localStorage.getItem('tarifaDefecto').indexOf('Selec') < 0) {
        tarifa = localStorage.getItem('tarifaDefecto')
      }
      
      // Crea el elemento 'shipmentMethod' y le asigna un valor, en función de si es 'Recogida en tienda' o no
      let metodoDeEnvio = '' // Valor por defecto
      if (store.shippingAddress.name == "" && store.shippingAddress.address == "" && porte === 0) {  // Comprueba si el nombre y la dirección están vacíos, además de si los portes son 0, que es el caso 'Recogida en tienda'
        metodoDeEnvio = "RTIRCTE"  // Valor que se envía a BC para indicar que es 'Recogida en tienda'
        shipCode = "" // Es un valor que se obtiene de la dirección de envío, como no hay porque es 'Recogida en tienda' he puesto "", si no, devolvería null causando un error
      }
      let newSalesOrder = ""
      if (localStorage.getItem("carritoRepuestos") === 'true') {
        newSalesOrder = {
          orderDate: (new Date).toISOString().slice(0, 10),
          sellToCustomerNo: codigoCliente,
          requestDeliveryDate: date,
          salesPerson: codVendedor,
          especialOrder: especial,
          programedOrder: programado,
          shipToCode: shipCode,
          yourReference,
          lines: lineas,
          depositOrder: depositoOrder,
          isRepair: true,
          fromSAT: false,
          sourceOrder: tipoUsuario,
          portes: parseInt(porte),
          volumen: volumenValue,
          customerPriceGroup: tarifa,
          shipmentMethod: metodoDeEnvio  // Valor que indica el método de envío y evalúa si es 'Recogida en tienda'
        }
      } else {
        newSalesOrder = {
          orderDate: (new Date).toISOString().slice(0, 10),
          sellToCustomerNo: codigoCliente,
          requestDeliveryDate: date,
          salesPerson: codVendedor,
          especialOrder: especial,
          programedOrder: programado,
          shipToCode: shipCode,
          yourReference,
          lines: lineas,
          depositOrder: depositoOrder,
          isRepair: false,
          fromSAT: false,
          sourceOrder: tipoUsuario,
          portes: parseInt(porte),
          volumen: volumenValue,
          customerPriceGroup: tarifa,
          shipmentMethod: metodoDeEnvio  // Valor que indica el método de envío y evalúa si es 'Recogida en tienda'
        }
      }
      const code = newSalesOrder.sellToCustomerNo
      // Si existe es que se está editando una oferta existente
      if (store.salesQuoteIdentifier) newSalesOrder = { ...newSalesOrder, orderNo: store.salesQuoteIdentifier }
      displayInfoMsg(intl.formatMessage({ id:"Process initiated"}))
      const objJSON = JSON.stringify(newSalesOrder) // <-- creamos el JSON a enviar
      const { data } = await apiNavisionNew.navisionControllerPostEndpointBC("TW_Functions_InsertSalesOrder", objJSON)
      // Éxito en la creación
      if (data.status == 'OK') {
        //Envio de notificación
        const oferta = JSON.parse(data.mensaje).message.replace("OK, Order ", "").replace(" has been created", "")
        // const { code } = getUserData()
        await axios.post(`${devuelveBasePath()}/notificaciones?codigoNotificacion=1&noDocumento=${oferta}&codigoCliente=${code}`)
        //
        //Mostramos el mensaje de Oferta insertada o modificada dependiendo de lo que estemos haciendo
        //
        let respuesta
        if (store.salesQuoteIdentifier) {
          respuesta = `${intl.formatMessage({ id:' Sales Quote'})} ${store.salesQuoteIdentifier} ${intl.formatMessage({ id: 'successfully modified'})}. `
        } else {
          respuesta = `${intl.formatMessage({ id: 'The offer was generated'})} ${oferta} ${intl.formatMessage({ id: 'successfully'})}`
        }

        dispatch(clearCart())
        dispatch({ type: `${prefix}/CLEAR_SHIPPING_ADDRESS` })
        dispatch(clearSalesQuoteCart())

        return {respuesta, oferta}
      }
      //
      //Si ha ocurrido algún problema mostramos el error
      //
      if (data.mensaje.message) { 
        let texto = ""
        const mensaje = data.mensaje.message
        if (mensaje.includes('You cannot create this type of document when') || mensaje.includes('No puede crear este tipo de documento cuando')) {
          texto = intl.formatMessage({ id: "Customer blocked, contact administration."}) // aqui es el otro mensaje que hay que cambiar el mensaje del include que compruebe si es en castellano 
        }
        if (texto != '') {
          return displayErrorMsg(texto)
        } else {
          return displayErrorMsg(data.mensaje.message)
        }
      }
    } catch (err) {
      displayErrorMsg(err)
    }
  }
}

// ** Crea/edita oferta
export const editarReferenciaYDireccionSalesOrder = (nuevaReferencia, nuevaDireccion, store, intl) => {
  return async (dispatch, getState) => {
    try {
      const storeAdmin = getState().admin.users
      // Recojo tipo y codigo, codVendedor(si es vendedor) y selecciono uno u otro dependiendo del caso
      let tipoUsuario
      if (storeAdmin.selectedUser.tipoUsuario === "V") {
        tipoUsuario = "B2B_Comercial"
      } else {
        tipoUsuario = "B2B_Cliente"
      }
      const productsArr = store.salesQuoteLines.map((line) => {
        return {no: line.no, quantity: line.quantity, discountLine: line.lineDiscountPercent, totalVolume:line.totalVolume, unitPrice:line.unitPrice}
      })
      let newSalesOrder = ""
      newSalesOrder = {
        orderNo: store.documentNo,
        orderDate: store.orderDate,
        sellToCustomerNo: store.sellToCustomerNo,
        requestDeliveryDate: store.requestedDeliveryDate,
        salesPerson: store.salespersonCode,
        especialOrder: store.specialOrder,
        programedOrder: store.programedOrder,
        shipToCode: nuevaDireccion.shipToCode,
        yourReference: nuevaReferencia,
        lines: productsArr,
        depositOrder: store.depositOrder,
        isRepair: false,
        fromSAT: false,
        sourceOrder: tipoUsuario,
        portes: store.postes,
        volumen: store.volumen,
        customerPriceGroup: store.customerPriceGroup,
        shipmentMethod: ''
      }
      const code = newSalesOrder.sellToCustomerNo
      displayInfoMsg(intl.formatMessage({ id:"Process initiated"}))
      const objJSON = JSON.stringify(newSalesOrder) // <-- creamos el JSON a enviar
      const { data } = await apiNavisionNew.navisionControllerPostEndpointBC("TW_Functions_InsertSalesOrder", objJSON)
      // Éxito en la creación
      if (data.status == 'OK') {
        //Envio de notificación
        const oferta = JSON.parse(data.mensaje).message.replace("OK, Order ", "").replace(" has been created", "")
        // const { code } = getUserData()
        await axios.post(`${devuelveBasePath()}/notificaciones?codigoNotificacion=1&noDocumento=${oferta}&codigoCliente=${code}`)
        //
        //Mostramos el mensaje de Oferta insertada o modificada dependiendo de lo que estemos haciendo
        //
        let respuesta
        if (store.salesQuoteIdentifier) {
          respuesta = `${intl.formatMessage({ id:' Sales Quote'})} ${store.salesQuoteIdentifier} ${intl.formatMessage({ id: 'successfully modified'})}. `
        } else {
          respuesta = `${intl.formatMessage({ id: 'The offer was generated'})} ${oferta} ${intl.formatMessage({ id: 'successfully'})}`
        }

        return {respuesta, oferta}
      }
      //
      //Si ha ocurrido algún problema mostramos el error
      //
      if (data.mensaje.message) { 
        let texto = ""
        const mensaje = data.mensaje.message
        if (mensaje.includes('You cannot create this type of document when') || mensaje.includes('No puede crear este tipo de documento cuando')) {
          texto = intl.formatMessage({ id: "Customer blocked, contact administration."}) // aqui es el otro mensaje que hay que cambiar el mensaje del include que compruebe si es en castellano 
        }
        if (texto != '') {
          return displayErrorMsg(texto)
        } else {
          return displayErrorMsg(data.mensaje.message)
        }
      }
    } catch (err) {
      displayErrorMsg(err)
    }
  }
}


// ** Set filtros aplicados a la tienda
export const setFilters = (searchFilters) => {
  return (dispatch) => {
    return dispatch({ type: `${prefix}/SET_FILTERS`, data: searchFilters })
  }
}

// ** CLEAR filtros aplicados a la tienda
export const clearFilters = () => {
  return (dispatch) => {
    return dispatch({ type: `${prefix}/CLEAR_FILTERS` })
  }
}

// ** Set productos filtrados
export const setFilteredProducts = (products) => {
  return (dispatch) => {
    dispatch({ type: `${prefix}/SET_PRODUCTS`, data: products })
    if (localStorage.getItem('noEjecutar') === 'SI') {
      return dispatch(refreshDisplayedProducts())
    } else {
      return 'no hagas nada'
    }
  }
}


// ** Set productos filtrados
/*export const getProductsDataNavision = (productsString) => {
  return async (dispatch) => {
    try {
      const pCustomer = getUserCode()
      //
      //Limpiamos el parámetro productsString para que no tenga el símbolo | en la primera posición
      //
      if (productsString.startsWith("|")) {
        productsString = productsString.slice(1);
      }

      const datosParaObtenerStock = {
        pCustomer, 
        pcustomerPriceGroup: '', 
        pItem: productsString,
        pRepuestos: false
      }

      const objJSON = JSON.stringify(datosParaObtenerStock) // <-- creamos el JSON a enviar
      const { data } = await apiNavisionNew.navisionControllerPostEndpointBC("TW_Functions_QueryExternalStock", objJSON)
      return JSON.parse(data.mensaje).Items
    } catch (err) {
      console.error(err.message)
    }
  }
}*/

// ** Filtro direcciones envio
export const setAdressTypeFilter = (value) => {
  return dispatch => {
    return dispatch({ type: `${prefix}/ADRESS_TYPE_SHOP`, data: value })
  }
}

// ** Filtro direcciones envio
export const setNewAdressActive = (value) => {
  return dispatch => {
    if (value === true) dispatch({ type: `${prefix}/CLEAR_SHIPPING_ADDRESS` })
    return dispatch({ type: `${prefix}/NEW_ADRESS_ACTIVE`, data: value })
  }
}

// ** Vacía los datos de la dirección de envío 'shippingAddress'
export const resetAddressData = () => {
  return dispatch => {
    return dispatch({ type: `${prefix}/CLEAR_SHIPPING_ADDRESS` })
  }
}

export const getProductoNavFiltrado = (tarifa, precios, stock, code, selectedBrands, productos, intl) => {
  return async (dispatch, getState) => {
    try {
      //
      //Definimos las variables y mostramos un mensaje de que empieza el proceso
      //
      if (tarifa == undefined || tarifa ==  "") { tarifa = "" }
      const defecto = (precios.min == 0 && precios.max == 9999 && !stock) 
      // Definimos una función para cerrar el mensaje de Swal
      const closeSwal = () => {
        Swal.close();
      };
      Swal.fire({
        position: 'center',
        icon: 'info',
        showCloseButton: false,
        showConfirmButton: false,
        timerProgressBar: true,
        title: intl.formatMessage({ id: 'We are looking for the products...'}),
        didClose: closeSwal,
        timer: 3000, // Duración en milisegundos
        onBeforeOpen: () => {
          Swal.showLoading(); // Muestra la barra de progreso
        }
      })
      //
      //Preparamos los filtros 
      //
      const filtroMarca = selectedBrands.map(marca => getCodigoMarca(marca)).join('|')

      const objNew = { 
        pCustomer: code,
        pcustomerPriceGroup: tarifa,
        pItem: "",
        pMarcas: filtroMarca,
        pOnlyStock: stock,
        pImpMinmo : parseFloat(precios.min),
        pImpMaximo: parseFloat(precios.max)
      }
      const objJSON = JSON.stringify(objNew)
      //
      //Hacemos una llamada a Nav con los filtros para que nos devuelva los que podemos mostrar según los filtros
      //
      await apiNavisionNew.navisionControllerPostEndpointBC('TW_Functions_GetItemListFiltered', objJSON).then((response) => {
        closeSwal()
        const { data } = response
        if (data.status == 'OK') {
          const productosDevueltos = data.mensaje.split('|')
          const productosFiltrados = productos.filter(producto => productosDevueltos.includes(producto.name));
          console.log(productosFiltrados)
          dispatch(setFilteredProducts(productosFiltrados)) 
        }
        //
        //Si ha ocurrido algún problema mostramos el error
        //
        if (data.mensaje.message) { 
          return displayErrorMsg(data.mensaje.message)
        }
      })
      
    } catch (err) {
      displayErrorMsg(err)
    }
  }
}

// ** Fetch pdf y lo envia por correo
export const sendDeliveryNoteEmail = (mailObject, intl) => {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return async (dispatch) => {
    try {
      Swal.fire({
        position: 'center',
        title: intl.formatMessage({ id: 'Process started on the server, a warning will be displayed on the screen at the end of the process.'}),
        icon: 'info'
      })
      await apiMail.mailControllerSendMail(mailObject).then((response) => {
        setTimeout(() =>  {
          const navpills = document.querySelectorAll('.nav-pills')
          const navItems = navpills[0].childNodes
          navItems.forEach(el => {
            el.firstChild.style = ""
          })
        }, 500)
        dispatch({
          type: SEND_DELIVERY_NOTE
        })
        odataLogs(response.config.url, response.status, response.config.method, SEND_DELIVERY_NOTE, config)
      }).catch((err) => odataLogs(err.message, "ERROR", "", SEND_DELIVERY_NOTE, config))
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: intl.formatMessage({ id: 'E-mail sent'})
      })
    } catch (err) {
      console.error(err.message)
    }
  }

}

export const getRepuestosBC = (nombreProducto) => {
  return async () => {
      const select = "";
      const filter = `?$filter=parentItemNo eq '${nombreProducto}'`;
      const orderBy = "";
      const top = "";
      return await apiSat.conexionSatControllerGetRepuestosBC("repuestos", select, filter, orderBy, top)
  }
}
