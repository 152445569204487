import { GET_ESTADISTICA_CLIENTE, GET_ESTADISTICAS_CLIENTES, GET_ESTADISTICAS_CLIENTES_COUNT, GET_ALL_ESTADISTICAS_CLIENTES, GET_FILTROS_ESTADISTICAS_CLIENTES, GET_OFERTAS_CLIENTE, GET_PEDIDOS_CLIENTE, GET_ALBARANES_CLIENTE, GET_FACTURAS_CLIENTE, GET_VENTAS_TOTALES, GET_VENTAS_TOTALES_GRAFICA, GET_VENTAS_CATEGORIAS, GET_FILTROS_VENTAS_CATEGORIAS, GET_VENTAS_PRECIOS, GET_FILTROS_VENTAS_PRECIOS, GET_ABONOS_CLIENTE, INIT_REQUEST} from '../actions'
// ** Initial State
const initialState = {
    allData: [],
    data: [],
    total: 1,
    params: {},
    filtros: {},
    ofertas: [],
    pedidos: [],
    facturas: [],
    albaranes: [],
    abonos: [],
    totalOfertas: 1,
    totalPedidos: 1,
    totalAlbaranes: 1,
    totalAbonos: 1,
    totalFacturas: 1,
    ventasTotales: [],
    ventasTotalesGrafica: [],
    ventasCategorias: [],
    ventasPrecios: []
}

const estadisticasdeclientes = (state = initialState, action) => {
    switch (action.type) {
        case INIT_REQUEST:
            return {
              ...state,
              processing: true
            }
        case GET_ESTADISTICAS_CLIENTES:
            return {
                ...state,
                data: action.data,
                params: action.params
            }
        case GET_ESTADISTICAS_CLIENTES_COUNT:
            return {
                ...state,
                total: action.totalPages
            }
        case GET_ESTADISTICA_CLIENTE:
            return { ...state, sat: action.sat }
        case GET_ALL_ESTADISTICAS_CLIENTES:
            return {
                ...state,
                allData: action.data
            }
        case GET_FILTROS_ESTADISTICAS_CLIENTES:
            return {
                ...state,
                filtros: action.filtros
            }
        case GET_OFERTAS_CLIENTE:
            return {
                ...state,
                ofertas: action.ofertas,
                totalOfertas: action.totalOfertas,
                processing: false
            }
        case GET_PEDIDOS_CLIENTE:
            return {
                ...state,
                pedidos: action.pedidos,
                totalPedidos: action.totalPedidos,
                processing: false
            }
        case GET_FACTURAS_CLIENTE:
            return {
                ...state,
                facturas: action.facturas,
                totalFacturas: action.totalFacturas,
                processing: false
            }
        case GET_ALBARANES_CLIENTE:
            return {
                ...state,
                albaranes: action.albaranes,
                totalAlbaranes: action.totalAlbaranes,
                processing: false
            }
        case GET_ABONOS_CLIENTE:
            return {
                ...state,
                abonos: action.abonos,
                totalAbonos: action.totalAbonos,
                processing: false
            }
        case GET_VENTAS_TOTALES:
            return {
                ...state,
                ventasTotales: action.ventasTotales,
                totalVentasTotales: action.totalVentasTotales
            }
        case GET_VENTAS_TOTALES_GRAFICA:
            return {
                ...state,
                ventasTotalesGrafica: action.ventasTotalesGrafica
            }
        case GET_VENTAS_CATEGORIAS:
            return {
                ...state,
                ventasCategorias: action.ventasCategorias,
                totalVentasCategorias: action.totalVentasCategorias
            }
        case GET_FILTROS_VENTAS_CATEGORIAS:
            return {
                ...state,
                FiltrosVentasCategorias: action.FiltrosVentasCategorias,
                processing: false
            }
        case GET_VENTAS_PRECIOS:
            return {
                ...state,
                ventasPrecios: action.ventasPrecios,
                totalVentasPrecios: action.totalVentasPrecios,
                processing: false
            }
        case GET_FILTROS_VENTAS_PRECIOS:
            return {
                ...state,
                FiltrosVentasPrecios: action.FiltrosVentasPrecios
            }
        default:
            return { ...state }
    }
}
export default estadisticasdeclientes
