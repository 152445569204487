/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { settings, AnnouncementsControllerApi, CategoriesControllerApi } from '@api/backend'
import { odataLogs } from '../../../../utility/utils'
const prefix = 'announcement'

export const GET_ANNOUNCEMENT = `${prefix}/GET_ANNOUNCEMENT`
export const GET_ANNOUNCEMENTS = `${prefix}/GET_DATA`
export const GET_ANNOUNCEMENTS_COUNT = `${prefix}/GET_DATA_COUNT`
export const GET_ANNOUNCEMENTS_RECENT = `${prefix}/GET_ANNOUNCEMENTS_RECENT`
export const GET_CATEGORIES = `${prefix}/GET_CATEGORIES`
export const INIT_REQUEST = `${prefix}/INIT_REQUEST`
const config = settings
const api = new AnnouncementsControllerApi(settings)
const apiCategories = new CategoriesControllerApi(settings)


// ** Get Categories
export const getCategories = () => {
    return async (dispatch) => {
        try {
            await apiCategories.categoriesControllerFind().then(response => {
                dispatch({
                    type: GET_CATEGORIES,
                    categories: response.data
                })
                odataLogs(response.config.url, response.status, response.config.method, GET_CATEGORIES, config)
            })
        } catch (error) {
            if (error instanceof Error) {
                odataLogs(error.message, "ERROR", "", GET_CATEGORIES, config)
            } else {
                odataLogs("ERROR", "ERROR", "", GET_CATEGORIES, config)
            }
        }
    }
}

// ** Get recent announcements
export const getRecentAnnouncements = (limit = 4) => {
    return async (dispatch) => {
        const filter = {
            limit,
            offset: 0,
            order: ['publishedAt DESC'],
            include: [
                {
                    relation: 'categories'
                },
                {
                    relation: 'announcementMetas'
                },
                {
                    relation: 'author'
                }
            ]
        }
        try {
            await api.announcementsControllerFind(JSON.stringify(filter)).then(response => {
                dispatch({
                    type: GET_ANNOUNCEMENTS_RECENT,
                    data: response.data
                })
                odataLogs(response.config.url, response.status, response.config.method, GET_ANNOUNCEMENTS_RECENT, config)
            })
        } catch (error) {
            if (error instanceof Error) {
                odataLogs(error.message, "ERROR", "", GET_ANNOUNCEMENTS_RECENT, config)
            } else {
                odataLogs("ERROR", "ERROR", "", GET_ANNOUNCEMENTS_RECENT, config)
            }
        }

    }
}


// ** Get data on page or row change
export const getData = (params) => {
    return async (dispatch) => {
        const { page = 1, perPage = 10, q = '', sortBy } = params

        const filter = {
            offset: (page - 1) * perPage,
            limit: perPage,
            skip: ((page - 1) * perPage),
            order: sortBy || "publishedAt DESC",
            where: {
                ['title']: { like: `%${q}%` },
                ['published']: { eq: 1 }
            },
            include: [
                {
                    relation: 'categories'
                },
                {
                    relation: 'announcementMetas'
                },
                {
                    relation: 'author'
                }
            ]
        }
        try {
            await api.announcementsControllerFind(JSON.stringify(filter)).then(response => {
                dispatch({
                    type: GET_ANNOUNCEMENTS,
                    data: response.data,
                    params
                })
                // url, resultado,tipo,compañia y settings
                odataLogs(response.config.url, response.status, response.config.method, GET_ANNOUNCEMENTS, config)
            })
        } catch (error) {
            if (error instanceof Error) {
                odataLogs(error.message, "ERROR", "", GET_ANNOUNCEMENTS, config)
            } else {
                odataLogs("ERROR", "ERROR", "", GET_ANNOUNCEMENTS, config)
            }
        }

        try {
            await api.announcementsControllerCount(JSON.stringify(filter.where)).then(response => {
                dispatch({
                    type: GET_ANNOUNCEMENTS_COUNT,
                    totalPages: response.data.count || 0
                })
                odataLogs(response.config.url, response.status, response.config.method, GET_ANNOUNCEMENTS_COUNT, config)

            })
        } catch (error) {
            if (error instanceof Error) {
                odataLogs(error.message, "ERROR", "", GET_ANNOUNCEMENTS_COUNT, config)
            } else {
                odataLogs("ERROR", "ERROR", "", GET_ANNOUNCEMENTS_COUNT, config)
            }
        }

    }
}


export const initAnnouncement = () => {
    return async (dispatch) => {
        dispatch({
            type: GET_ANNOUNCEMENT
        })
    }
}
// ** Get Announcement
export const getAnnouncement = (id) => {
    return async (dispatch) => {
        const filter = {
            include: [
                {
                    relation: 'categories'
                },
                {
                    relation: 'author'
                },
                {
                    relation: 'announcementMetas'
                }
            ]
        }
        try {
            await api.announcementsControllerFindById(id, JSON.stringify(filter)).then(response => {
                dispatch({
                    type: GET_ANNOUNCEMENT,
                    announcement: response.data
                })
                odataLogs(response.config.url, response.status, response.config.method, GET_ANNOUNCEMENT, config)
            })
        } catch (error) {
            if (error instanceof Error) {
                odataLogs(error.message, "ERROR", "", GET_ANNOUNCEMENT, config)
            } else {
                odataLogs("ERROR", "ERROR", "", GET_ANNOUNCEMENT, config)
            }
        }

    }
}

export const updateAnnouncementBySlug = (slug, cliente) => {
    return async (dispatch) => {
        const filter = {
            where: {
                ['slug']: { eq: slug }
            },
            include: [
                {
                    relation: 'categories'
                },
                {
                    relation: 'author'
                },
                {
                    relation: 'announcementMetas'
                }
            ]
        }
        await api.announcementsControllerFind(JSON.stringify(filter)).then(async response => {
            const [announcement] = response.data
            const visto = announcement.vistoPor || ''
            if (!visto.includes(cliente)) {
                const newAnnouncement = announcement
                newAnnouncement.vistoPor = visto.concat(';').concat(cliente)
                if (newAnnouncement.metaTitle == null) { newAnnouncement.metaTitle = '' }
                if (newAnnouncement.summary == null) { newAnnouncement.summary = '' }
                if (newAnnouncement.parentId == null) { newAnnouncement.parentId = 0 }
                delete newAnnouncement.author
                delete newAnnouncement.announcementMetas
                delete newAnnouncement.categories
                await api.announcementsControllerUpdateById(announcement.id, newAnnouncement)
            }
        })
        .catch((err) => odataLogs(err.message, "ERROR", "", GET_ANNOUNCEMENT, config))
    }
}

// ** Get Announcement by slug
export const getAnnouncementBySlug = (slug) => {
    return async (dispatch) => {
        const filter = {
            where: {
                ['slug']: { eq: slug }
            },
            include: [
                {
                    relation: 'categories'
                },
                {
                    relation: 'author'
                },
                {
                    relation: 'announcementMetas'
                }
            ]
        }
        try {
            await api.announcementsControllerFind(JSON.stringify(filter)).then(response => {
                dispatch({
                    type: GET_ANNOUNCEMENT,
                    announcement: response.data
                })
                odataLogs(response.config.url, response.status, response.config.method, GET_ANNOUNCEMENT, config)
            })
        } catch (error) {
            if (error instanceof Error) {
                odataLogs(error.message, "ERROR", "", GET_ANNOUNCEMENT, config)
            } else {
                odataLogs("ERROR", "ERROR", "", GET_ANNOUNCEMENT, config)
            }
        }

    }
}

