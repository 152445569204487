/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { settings, NavisionControllerApi } from '@api/backend'
import { getCodVendedor, odataLogs } from '@utils'
import instance from '@api/pim'
const prefix = 'estadisticasdeclientes'
const config = settings
export const GET_ESTADISTICA_CLIENTE = `${prefix}/GET_DATA`
export const GET_ESTADISTICAS_CLIENTES = `${prefix}/GET_DATAS`
export const GET_ESTADISTICAS_CLIENTES_COUNT = `${prefix}/GET_DATA_COUNT`
export const GET_ALL_ESTADISTICAS_CLIENTES = `${prefix}/GET_ALL_DATAS`
export const GET_FILTROS_ESTADISTICAS_CLIENTES = `${prefix}/GET_FILTROS_DATAS`
export const GET_OFERTAS_CLIENTE = `${prefix}/GET_OFERTAS_CLIENTE`
export const GET_PEDIDOS_CLIENTE = `${prefix}/GET_PEDIDOS_CLIENTE`
export const GET_FACTURAS_CLIENTE = `${prefix}/GET_FACTURAS_CLIENTE`
export const GET_ALBARANES_CLIENTE = `${prefix}/GET_ALBARANES_CLIENTE`
export const GET_VENTAS_TOTALES = `${prefix}/GET_VENTAS_TOTALES`
export const GET_VENTAS_TOTALES_GRAFICA = `${prefix}/GET_VENTAS_TOTALES_GRAFICA`
export const GET_VENTAS_CATEGORIAS = `${prefix}/GET_VENTAS_CATEGORIAS`
export const GET_FILTROS_VENTAS_CATEGORIAS = `${prefix}/GET_FILTROS_VENTAS_CATEGORIAS`
export const GET_VENTAS_PRECIOS = `${prefix}/GET_VENTAS_PRECIOS`
export const GET_FILTROS_VENTAS_PRECIOS = `${prefix}/GET_FILTROS_VENTAS_PRECIOS`
export const GET_ABONOS_CLIENTE = `${prefix}/GET_ABONOS_CLIENTE`
export const INIT_REQUEST = `${prefix}/INIT_REQUEST`

const apiNavisionNew = new NavisionControllerApi(settings)

// ** INIT REQUEST
export const initRequest = () => {
    return async (dispatch) => {
      dispatch({
        type: INIT_REQUEST
      })
    }
  }

// Función auxiliar para formatear el precio
function formatearPrecio(precio) {
    return precio.toLocaleString('es-ES', { style: 'currency', currency: 'EUR', minimumFractionDigits: 2, maximumFractionDigits: 2, useGrouping: true });
}

//O B T I E N E - C A T E G O R I A S - A P I - (P I M)
export const getCategorias = () => {
    return async () => {
        const { data: categoriasPim } = await instance().get(`/categorias`)
        const arrayCategorias = categoriasPim.map(categoria => ({
            value: categoria.padre,
            label: categoria.codigo
        }));
        return arrayCategorias
    }
}

// ** Get data on page or row change
export const getData = (params) => {
    const { page = 1, perPage = 10, usuario, verTodo, filtrosBusqueda, codigoCliente } = params

    const limit = perPage
    const skip = ((page - 1) * perPage)
    const order = ''
    const expand = '';
    const count = true;

    if (codigoCliente) {
        return async () => {
            let filtro = ''
            const listaDeFiltros = []
            listaDeFiltros.push(`salespersonCode eq '*'`)
            listaDeFiltros.push(`no eq '${codigoCliente}'`)
            filtro = listaDeFiltros.join(' and ')
            //return await apiNavisionNew.navisionControllerGetendPoint('customerStadistics', filtro, limit, skip, order, expand, count)
            return await apiNavisionNew.navisionControllerGetendPoint('customerStadistics', filtro, limit, skip, order, expand, count).then(response => {
                console.log(response.data.value)
                const registros = response.data.value.map(objeto => ({
                    ...objeto,
                    creditLimitFormateado: formatearPrecio(objeto.creditLimit),
                    balanceDueFormateado: formatearPrecio(objeto.balanceDue),
                    outstandingOrdersFormateado: formatearPrecio(objeto.outstandingOrders),
                    salesLCYActFormateado: formatearPrecio(objeto.salesLCYAct),
                    salesLCYAntFormateado: formatearPrecio(objeto.salesLCYAnt),
                    salesLCYFormateado: formatearPrecio(objeto.salesLCY),
                    balanceFormateado: formatearPrecio(objeto.balance)
                }));
                return registros
            }).catch((err) => odataLogs(err.message, "ERROR", "", GET_ESTADISTICAS_CLIENTES, config))
        }
    } else {
        return async (dispatch) => {
            const { clienteNo, clienteNombre } = filtrosBusqueda || {};

            let filtro = ''
            const listaDeFiltros = []
            if (verTodo === 'off') {
                listaDeFiltros.push(`salespersonCode eq '${usuario.codVendedorCliente}'`)
            }

            if (clienteNo || clienteNombre) {
                if (clienteNo) {
                    if (verTodo === 'on') {
                        listaDeFiltros.push(`salespersonCode eq '*'`)
                    }
                    listaDeFiltros.push(`no eq '${clienteNo}'`)
                }
                if (clienteNombre) {
                    listaDeFiltros.push(`name eq '*${clienteNombre.toUpperCase()}*'`)
                }
            }

            filtro = listaDeFiltros.join(' and ')

            await apiNavisionNew.navisionControllerGetendPoint('customerStadistics', filtro, limit, skip, order, expand, count).then(response => {

                //const registros = response.data.value
                // Recorrer la lista y formatear los precios
                const registros = response.data.value.map(objeto => ({
                    ...objeto,
                    creditLimitFormateado: formatearPrecio(objeto.creditLimit),
                    balanceDueFormateado: formatearPrecio(objeto.balanceDue),
                    outstandingOrdersFormateado: formatearPrecio(objeto.outstandingOrders),
                    salesLCYActFormateado: formatearPrecio(objeto.salesLCYAct),
                    salesLCYAntFormateado: formatearPrecio(objeto.salesLCYAnt),
                    salesLCYFormateado: formatearPrecio(objeto.salesLCY),
                    balanceFormateado: formatearPrecio(objeto.balance)
                }));

                const registrosPaginados = registros.slice(0, limit); // -> Filtra solo primera pagina
                console.log('Clientes: ', registrosPaginados)

                //Guardamos los datos que se van a mostrar en la pagina
                dispatch({
                    type: GET_ESTADISTICAS_CLIENTES,
                    data: registrosPaginados,
                    params
                })

                //Guardamos el numero de registros
                dispatch({
                    type: GET_ESTADISTICAS_CLIENTES_COUNT,
                    totalPages: response.data['@odata.count'] || 0
                    //totalPages: registros.length || 0
                })

                //Guardamos todos los productos disponibles//
                dispatch({
                    type: GET_ALL_ESTADISTICAS_CLIENTES,
                    data: registros
                })

                //Guardamos los filtros de busqueda (Inputs)
                dispatch({
                    type: GET_FILTROS_ESTADISTICAS_CLIENTES,
                    filtros: filtrosBusqueda
                })

                odataLogs(response.config.url, response.status, response.config.method, GET_ESTADISTICAS_CLIENTES, config)

            }).catch((err) => odataLogs(err.message, "ERROR", "", GET_ESTADISTICAS_CLIENTES, config))

        }
    }
}

// ** Get data on page or row change
export const getDataOfertas = (params) => {

    return async (dispatch) => {
        dispatch(initRequest())
        const { page, perPage, usuario, filtrosBusqueda } = params

        const limit = perPage
        const skip = ((page - 1) * perPage)
        // const order = sortBy || "createdAt DESC"
        const order = ''
        const expand = 'salesQuoteLines';
        const count = true;
        const { no, gerente } = filtrosBusqueda || {};

        let filtro = ''
        const listaDeFiltros = []

        if (usuario.tipoUsuario === 'V') {
            if (no) {
                listaDeFiltros.push(`billToCustomerNo eq '*${no}*'`)
                listaDeFiltros.push(`salespersonCode eq '${usuario.codVendedorCliente}'`)
            }
        } else if (usuario.tipoUsuario === 'C') {
            let vend = ''
            await getCodVendedor(usuario.codVendedorCliente).then(response => {
                vend = response[0].code
              })
            if (no) {
                listaDeFiltros.push(`billToCustomerNo eq '*${no}*'`)
                listaDeFiltros.push(`salespersonCode eq '${vend}'`)
            }
        }
        // if (gerente === "off" || gerente === "" || gerente === null || gerente === undefined) {
        //     if (usuario && usuario.tipoUsuario === 'V') {
        //         listaDeFiltros.push(`salespersonCode eq '${usuario.codVendedorCliente}'`) //->Filtramos por el codigo exacto del vendedor, sin añadir *__*
        //     }
        // }

        filtro = listaDeFiltros.join(' and ')


        await apiNavisionNew.navisionControllerGetendPoint('salesQuoteHeader', filtro, limit, skip, order, expand, count).then(response => {

            const datosAmostrar = response.data.value

            dispatch({
                type: GET_OFERTAS_CLIENTE,
                ofertas: datosAmostrar,
                totalOfertas: response.data['@odata.count'] || 0,
                params
            })

            odataLogs(response.config.url, response.status, response.config.method, GET_ESTADISTICAS_CLIENTES, config)
        }).catch((err) => odataLogs(err.message, "ERROR", "", GET_ESTADISTICAS_CLIENTES, config))

    }
}

// ** Get data on page or row change
export const getDataPedidos = (params) => {

    return async (dispatch) => {
        dispatch(initRequest())
        const { page, perPage, usuario, filtrosBusqueda } = params

        const limit = perPage
        const skip = ((page - 1) * perPage)
        // const order = sortBy || "createdAt DESC"
        const order = ''
        const expand = 'salesOrderLines'
        const count = true;
        const { no, gerente } = filtrosBusqueda || {};

        let filtro = ''
        const listaDeFiltros = []
        if (usuario.tipoUsuario === 'V') {
            if (no) {
                listaDeFiltros.push(`billToCustomerNo eq '*${no}*'`)
                listaDeFiltros.push(`salespersonCode eq '${usuario.codVendedorCliente}'`)
            }
        } else if (usuario.tipoUsuario === 'C') {
            let vend = ''
            await getCodVendedor(usuario.codVendedorCliente).then(response => {
                vend = response[0].code
              })
            if (no) {
                listaDeFiltros.push(`billToCustomerNo eq '*${no}*'`)
                listaDeFiltros.push(`salespersonCode eq '${vend}'`)
            }
        }
        // if (gerente === "off" || gerente === "" || gerente === null || gerente === undefined) {
        //     if (usuario) {
        //         listaDeFiltros.push(`salespersonCode eq '${usuario.codVendedorCliente}'`) //->Filtramos por el codigo exacto del vendedor, sin añadir *__*
        //     }
        // }

        filtro = listaDeFiltros.join(' and ')


        await apiNavisionNew.navisionControllerGetendPoint('salesOrderHeader', filtro, limit, skip, order, expand, count).then(response => {

            const datosAmostrar = response.data.value

            dispatch({
                type: GET_PEDIDOS_CLIENTE,
                pedidos: datosAmostrar,
                totalPedidos: response.data['@odata.count'] || 0,
                params
            })


            odataLogs(response.config.url, response.status, response.config.method, GET_ESTADISTICAS_CLIENTES, config)
        }).catch((err) => odataLogs(err.message, "ERROR", "", GET_ESTADISTICAS_CLIENTES, config))

    }
}

// ** Get data on page or row change
export const getDataFacturas = (params) => {

    return async (dispatch) => {
        dispatch(initRequest())
        const { page, perPage, usuario, filtrosBusqueda } = params

        const limit = perPage
        const skip = ((page - 1) * perPage)
        // const order = sortBy || "createdAt DESC"
        const order = ''
        const expand = 'salesInvoiceLines';
        const count = true;
        const { no, gerente } = filtrosBusqueda || {};

        let filtro = ''
        const listaDeFiltros = []

        if (usuario.tipoUsuario === 'V') {
            if (no) {
                listaDeFiltros.push(`billToCustomerNo eq '*${no}*'`)
                listaDeFiltros.push(`salespersonCode eq '${usuario.codVendedorCliente}'`)
            }
        } else if (usuario.tipoUsuario === 'C') {
            let vend = ''
            await getCodVendedor(usuario.codVendedorCliente).then(response => {
                vend = response[0].code
              })
            if (no) {
                listaDeFiltros.push(`billToCustomerNo eq '*${no}*'`)
                listaDeFiltros.push(`salespersonCode eq '${vend}'`)
            }
        }
        // if (gerente === "off" || gerente === "" || gerente === null || gerente === undefined) {
        //     if (usuario && usuario.tipoUsuario === 'V') {
        //         listaDeFiltros.push(`salespersonCode eq '${usuario.codVendedorCliente}'`) //->Filtramos por el codigo exacto del vendedor, sin añadir *__*
        //     }
        // }

        filtro = listaDeFiltros.join(' and ')


        await apiNavisionNew.navisionControllerGetendPoint('salesInvoiceHeader', filtro, limit, skip, order, expand, count).then(response => {

            const datosAmostrar = response.data.value
            dispatch({
                type: GET_FACTURAS_CLIENTE,
                facturas: datosAmostrar,
                totalFacturas: response.data['@odata.count'] || 0,
                params
            })

            odataLogs(response.config.url, response.status, response.config.method, GET_ESTADISTICAS_CLIENTES, config)
        }).catch((err) => odataLogs(err.message, "ERROR", "", GET_ESTADISTICAS_CLIENTES, config))

    }
}

// ** Get data on page or row change
export const getDataAlbaranes = (params) => {

    return async (dispatch) => {
        dispatch(initRequest())
        const { page, perPage, usuario, filtrosBusqueda } = params

        const limit = perPage
        const skip = ((page - 1) * perPage)
        // const order = sortBy || "createdAt DESC"
        const order = ''
        const expand = 'salesShipmentLines';
        const count = true;
        const { no, gerente } = filtrosBusqueda || {};

        let filtro = ''
        const listaDeFiltros = []

        if (usuario.tipoUsuario === 'V') {
            if (no) {
                listaDeFiltros.push(`billToCustomerNo eq '*${no}*'`)
                listaDeFiltros.push(`salespersonCode eq '${usuario.codVendedorCliente}'`)
            }
        } else if (usuario.tipoUsuario === 'C') {
            let vend = ''
            await getCodVendedor(usuario.codVendedorCliente).then(response => {
                vend = response[0].code
              })
            if (no) {
                listaDeFiltros.push(`billToCustomerNo eq '*${no}*'`)
                listaDeFiltros.push(`salespersonCode eq '${vend}'`)
            }
        }
        // if (gerente === "off" || gerente === "" || gerente === null || gerente === undefined) {
        //     if (usuario) {
        //         listaDeFiltros.push(`salespersonCode eq '${usuario.codVendedorCliente}'`) //->Filtramos por el codigo exacto del vendedor, sin añadir *__*
        //     }
        // }

        filtro = listaDeFiltros.join(' and ')


        await apiNavisionNew.navisionControllerGetendPoint('salesShipmentsHeader', filtro, limit, skip, order, expand, count).then(response => {

            const datosAmostrar = response.data.value
            dispatch({
                type: GET_ALBARANES_CLIENTE,
                albaranes: datosAmostrar,
                totalAlbaranes: response.data['@odata.count'] || 0,
                params
            })

            odataLogs(response.config.url, response.status, response.config.method, GET_ESTADISTICAS_CLIENTES, config)
        }).catch((err) => odataLogs(err.message, "ERROR", "", GET_ESTADISTICAS_CLIENTES, config))

    }
}

// ** Get data on page or row change
export const getDataAbonos = (params) => {

    return async (dispatch) => {
        dispatch(initRequest())
        const { page, perPage, usuario, filtrosBusqueda } = params

        const limit = perPage
        const skip = ((page - 1) * perPage)
        // const order = sortBy || "createdAt DESC"
        const order = ''
        const expand = 'salescrMemoLines';
        const count = true;
        const { no, gerente } = filtrosBusqueda || {};

        let filtro = ''
        const listaDeFiltros = []

        if (usuario.tipoUsuario === 'V') {
            if (no) {
                listaDeFiltros.push(`billToCustomerNo eq '*${no}*'`)
                listaDeFiltros.push(`salespersonCode eq '${usuario.codVendedorCliente}'`)
            }
        } else if (usuario.tipoUsuario === 'C') {
            let vend = ''
            await getCodVendedor(usuario.codVendedorCliente).then(response => {
                vend = response[0].code
              })
            if (no) {
                listaDeFiltros.push(`billToCustomerNo eq '*${no}*'`)
                listaDeFiltros.push(`salespersonCode eq '${vend}'`)
            }
        }
        // if (gerente === "off" || gerente === "" || gerente === null || gerente === undefined) {
        //     if (usuario) {
        //         listaDeFiltros.push(`salespersonCode eq '${usuario.codVendedorCliente}'`) //->Filtramos por el codigo exacto del vendedor, sin añadir *__*
        //     }
        // }

        filtro = listaDeFiltros.join(' and ')

        await apiNavisionNew.navisionControllerGetendPoint('salesCrMemosHeader', filtro, limit, skip, order, expand, count).then(response => {

            const datosAmostrar = response.data.value
            dispatch({
                type: GET_ABONOS_CLIENTE,
                abonos: datosAmostrar,
                totalAbonos: response.data['@odata.count'] || 0,
                params
            })

            odataLogs(response.config.url, response.status, response.config.method, GET_ESTADISTICAS_CLIENTES, config)
        }).catch((err) => odataLogs(err.message, "ERROR", "", GET_ESTADISTICAS_CLIENTES, config))

    }
}

// Función auxiliar para obtener el nombre del mes
function nombreMes(month) {
    const meses = [
        "enero", "febrero", "marzo", "abril", "mayo", "junio",
        "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"
    ];
    return meses[month - 1];
}

export const getDataVentasTotales = (params) => {
    const { page = 1, perPage = 10, codigoCliente } = params

    const limit = perPage
    const skip = ((page - 1) * perPage)
    const order = ''
    const expand = '';
    const count = true;

    return async (dispatch) => {

        const filtro = `customerNo eq '${codigoCliente}'`

        await apiNavisionNew.navisionControllerGetendPoint('movCliente', filtro, 10000, skip, order, expand, count).then(response => {

            // Recorrer la lista y formatear los precios
            const registros = response.data.value.map(objeto => ({
                ...objeto,
                amountFormateado: formatearPrecio(objeto.amount)
            }));

            const listaAños = Array.from(new Set(registros.map(item => item.year))); //-> Obtengo solo los años en una lista

            // Solo dejo los ultimos 4 años en la lista
            if (listaAños.length > 4) {
                const elementosAEliminar = listaAños.length - 4; //-> Calculo cuantos años debo eliminar
                listaAños.splice(0, elementosAEliminar); //-> Elimino los primeros elementos
            }

            // T R A N S F O R M A R - L O S - D A T O S - P A R A - L A - T A B L A
            const listaMesValorPorAño = [];
            for (let mes = 1; mes <= 13; mes++) { //-> Estructuro la nueva lista con los 12 meses y los amounts de los ultimos 4 años
                const fila = {
                    mes
                };

                listaAños.forEach((año, index) => {
                    const amountParaAño = registros.find(obj => obj.year === año && obj.month === mes)?.amountFormateado || 0; //-> Mostramos en la tabla el amount formateado
                    fila[`año_${año}`] = amountParaAño;

                    if (mes === 13) {
                        // Calcular el total de los registros anteriores para cada año
                        listaAños.forEach((año) => {
                            const totalParaAño = registros
                                .filter(obj => obj.year === año && obj.month <= 12)
                                .reduce((total, obj) => parseFloat(total) + parseFloat(obj.amount), 0) || 0;
                            fila[`año_${año}`] = formatearPrecio(totalParaAño);
                        });
                    }
                });

                listaMesValorPorAño.push(fila);
            }

            console.log('Ventas Totales: ', listaMesValorPorAño)

            // T R A N S F O R M A R - L O S - D A T O S - P A R A - L A - G R A F I C A
            const datosFiltrados = registros.filter(item => listaAños.includes(item.year)); //-> Filtrar los datos para obtener solo los últimos 4 años

            const listaValoresDeCadaMesEnCadaAño = datosFiltrados.map(item => {
                const nombre_del_mes = `${nombreMes(item.month)}`;
                const nombre_del_año = `${item.year}`
                return {
                    mes: nombre_del_mes,
                    año: nombre_del_año,
                    cantidad: item.amount
                };
            });

            //Guardamos los datos que se van a mostrar en la tabla
            dispatch({
                type: GET_VENTAS_TOTALES,
                ventasTotales: listaMesValorPorAño,
                totalVentasTotales: 13
            })

            //Guardamos los datos que se van a mostrar en la gráfica
            dispatch({
                type: GET_VENTAS_TOTALES_GRAFICA,
                ventasTotalesGrafica: listaValoresDeCadaMesEnCadaAño
            })

            odataLogs(response.config.url, response.status, response.config.method, GET_VENTAS_TOTALES, config)

        }).catch((err) => odataLogs(err.message, "ERROR", "", GET_VENTAS_TOTALES, config))

    }
}

export const getDataVentasCategorias = (params) => {
    const { page, perPage, codigoCliente, filtrosVentasCategoria } = params

    const limit = perPage
    const skip = ((page - 1) * perPage)
    const order = ''
    const expand = '';
    const count = true;

    return async (dispatch) => {
        dispatch(initRequest())

        //const filtro = `customerNo eq '${codigoCliente}'`
        const { Desde, Hasta, Modelo, Categorias } = filtrosVentasCategoria || {};

        let filtro = ''
        const listaDeFiltros = []
        if (codigoCliente) {
            listaDeFiltros.push(`customerNo eq '${codigoCliente}'`)
        }

        if (Modelo) {
            listaDeFiltros.push(`itemNo eq '${Modelo}'`)
            //Filtro para sacar el count correcto
        }
        //Filtro de categorias en caso de que sea multiselect o un solo valor
        if (Categorias) {
            if (Categorias.length > 1) {
                listaDeFiltros.push(`(${Categorias.map(cat => `itemCategoryCode eq '${cat.label}'`).join(' or ')})`)
            } else if (Categorias.length == 1) {
                listaDeFiltros.push(`itemCategoryCode eq '${Categorias[0].label}'`)
            }
        }
        //Filtro de fechas para la llamada de BC para tener datos a mostrar 
        if (Desde && Hasta) { //Si existe un inicio y un fin entra aqui
            const [añoInicio, mesInicio] = Desde.split('-');
            const [añoFin, mesFin] = Hasta.split('-');
            listaDeFiltros.push(`year ge ${añoInicio - 1} and year le ${añoFin}`)
            listaDeFiltros.push(`month ge ${mesInicio} and month le ${mesFin}`)
        } else if (Desde) { //Si solo existe un inicio aqui
            const [añoInicio, mesInicio] = Desde.split('-');
            listaDeFiltros.push(`year ge ${añoInicio - 1} and year le 9999`)
            listaDeFiltros.push(`month ge ${mesInicio} and month le 12`)
        } else if (!Desde) { //Si es la primera vez que carga que salga predefinido como el año actual 
            const fechaActual = new Date();
            const añoActual = fechaActual.getFullYear();
            const DesdeYear = Desde ? parseInt(Desde.split('-')[0]) : añoActual;
            listaDeFiltros.push(`year ge ${DesdeYear - 1} and year le 9999`)
        } else if (Hasta) { //Si no tiene inicio pero tiene solo fin
            const [añoFin, mesFin] = Hasta.split('-');
            listaDeFiltros.push(`year ge 0000 and year le ${añoFin}`)
            listaDeFiltros.push(`month ge 1 and month le ${mesFin}`)
        } //En algunos casos se les hace año - 1 para recuperar los datos del año actual y del año pasado para la tabla

        filtro = listaDeFiltros.join(' and ')
        await apiNavisionNew.navisionControllerGetendPoint('movProdCliente', filtro, limit, skip, order, expand, count).then(async response => {
            //Llamada a BC para sacar el count correcto de las paginas
            // Crear un conjunto para almacenar claves únicas
            const clavesUnicas = new Set();

            // Crear un array para almacenar los registros finales
            const registrosFinales = [];

            // Crear un mapa para almacenar los totales anuales por producto
            const totalesAnualesPorProducto = new Map();

            // Calcular totales anuales por producto
            response.data.value.forEach(registroActual => {
                const claveProducto = `${registroActual.itemNo}_${registroActual.year}`;

                if (!totalesAnualesPorProducto.has(claveProducto)) {
                    totalesAnualesPorProducto.set(claveProducto, {
                        itemNo: registroActual.itemNo,
                        year: registroActual.year,
                        salesActualTotal: 0,
                        salesExpectedTotal: 0,
                        quantityTotal: 0,
                        itemCategoryCode: registroActual.itemCategoryCode,
                        refAgrupacion: registroActual.refAgrupacion
                    });
                }

                // Actualizar los totales anuales
                totalesAnualesPorProducto.get(claveProducto).salesActualTotal += Math.abs(registroActual.salesActual);
                totalesAnualesPorProducto.get(claveProducto).salesExpectedTotal += Math.abs(registroActual.salesExpected);
                totalesAnualesPorProducto.get(claveProducto).quantityTotal += Math.abs(registroActual.quantity);

                // Agregar la clave al conjunto de claves únicas
                clavesUnicas.add(claveProducto);
            });
            // Agregar los totales anuales al array registrosFinales
            const totalesAnualesArray = Array.from(totalesAnualesPorProducto.values());

            totalesAnualesPorProducto.forEach(totalAnual => {
                // Crear el objeto con las propiedades comunes
                const totalAnterior = totalesAnualesArray.find(ant => ant.itemNo === totalAnual.itemNo && ant.year === totalAnual.year - 1);

                const nuevoRegistro = {
                    itemNo: totalAnual.itemNo,
                    year: totalAnual.year,
                    totalSumado: formatearPrecio(totalAnual.salesActualTotal + totalAnual.salesExpectedTotal),
                    quantityTotal: totalAnual.quantityTotal,
                    itemCategoryCode: totalAnual.itemCategoryCode,
                    refAgrupacion: totalAnual.refAgrupacion,
                    totalVendidoAñoAnterior: totalAnterior ? formatearPrecio(totalAnterior.salesActualTotal + totalAnterior.salesExpectedTotal) : 0,
                    totalCantidadAñoAnterior: totalAnterior ? totalAnterior.quantityTotal : 0
                };
                registrosFinales.push(nuevoRegistro);
            });
            const fechaActual = new Date();
            const añoActual = fechaActual.getFullYear();
            // Extraer el año de la fecha "Desde" y "Hasta" si están presentes
            const DesdeYear = Desde ? parseInt(Desde.split('-')[0]) : añoActual;
            const HastaYear = Hasta ? parseInt(Hasta.split('-')[0]) : null;

            // Aplica filtros de Desde y Hasta a registrosFinales, 
            //este filtro se aplica para mostrar los datos en la tabla correctamente en base al filtro.
            //Misma logica que el filtro de la llamada de BC.
            let registrosFiltrados = registrosFinales;
            if (Desde && Hasta) {
                registrosFiltrados = registrosFinales.filter(registro => {
                    return registro.year >= DesdeYear && registro.year <= HastaYear;
                });
            } else if (Desde) {
                registrosFiltrados = registrosFinales.filter(registro => {
                    return registro.year >= DesdeYear;
                });
            } else if (!Desde) {
                registrosFiltrados = registrosFinales.filter(registro => {
                    return registro.year >= DesdeYear;
                });
            } else if (Hasta) {
                registrosFiltrados = registrosFinales.filter(registro => {
                    return registro.year <= HastaYear;
                });
            }
            const registrosPaginados = registrosFiltrados.slice(0, limit);
            console.log('Ventas Categorias: ', registrosPaginados)

            //Guardamos los datos que se van a mostrar en la pagina
            dispatch({
                type: GET_VENTAS_CATEGORIAS,
                ventasCategorias: registrosPaginados,
                totalVentasCategorias: response.data["@odata.count"] || 0,
                params
            })

            //Guardamos los filtros que se van a mostrar en la pagina
            dispatch({
                type: GET_FILTROS_VENTAS_CATEGORIAS,
                FiltrosVentasCategorias: filtrosVentasCategoria || '',
                params
            })

            odataLogs(response.config.url, response.status, response.config.method, GET_VENTAS_TOTALES, config)

        }).catch((err) => odataLogs(err.message, "ERROR", "", GET_VENTAS_TOTALES, config))

    }
}

export const getDataPrecios = (params) => {
    const { page, perPage, codigoCliente, FiltrosVentasPrecios } = params

    const limit = perPage
    const skip = ((page - 1) * perPage)
    const order = ''
    const expand = '';
    const count = true;

    return async (dispatch) => {
        dispatch(initRequest())
        //--Apartado donde sacamos las fechas--//
        const { Modelo, Categorias } = FiltrosVentasPrecios || {};
        let filtro = ''
        const listaDeFiltros = []
        //--Apartado donde sacamos las fechas--//
        const fechaActual = new Date();
        const fechaInicio = new Date(fechaActual.getFullYear(), fechaActual.getMonth() - 6, 1);  // Restar 6 meses a la fecha actual
        const fechaFin = new Date(fechaActual.getFullYear(), fechaActual.getMonth() + 1, 0);  // Último día del mes actual
        const formatoFecha = fecha => `${fecha.getFullYear()}-${(fecha.getMonth() + 1).toString().padStart(2, '0')}-${fecha.getDate().toString().padStart(2, '0')}`;
        if (codigoCliente) {
            listaDeFiltros.push(`customerNo eq '${codigoCliente}'`)
            listaDeFiltros.push(`date ge ${formatoFecha(fechaInicio)} and date le ${formatoFecha(fechaFin)}`)
            listaDeFiltros.push(`quantity lt 0`)
        }

        if (Modelo) {
            listaDeFiltros.push(`itemNo eq '${Modelo}'`)
        }
        //Filtro de categorias en caso de que sea multiselect o un solo valor
        if (Categorias) {
            if (Categorias.length > 1) {
                listaDeFiltros.push(`(${Categorias.map(cat => `itemCategoryCode eq '${cat.label}'`).join(' or ')})`)
            } else if (Categorias.length == 1) {
                listaDeFiltros.push(`itemCategoryCode eq '${Categorias[0].label}'`)
            }
        }

        filtro = listaDeFiltros.join(' and ')

        await apiNavisionNew.navisionControllerGetendPoint('movProdClientePrice', filtro, limit, skip, order, expand, count).then(response => {

            const registros = response.data.value.map(objeto => {
                const fecha = new Date(objeto.date);
                const mesProducto = fecha.toLocaleString('default', { month: 'long' });

                return {
                    ...objeto,
                    salesExpectedFormateado: formatearPrecio(objeto.salesExpected),
                    salesActualFormateado: formatearPrecio(objeto.salesActual),
                    totalSumado: formatearPrecio((objeto.salesExpected + objeto.salesActual) / Math.abs(objeto.quantity)),
                    mes: mesProducto
                };
            });
            //Guardamos los datos que se van a mostrar en la pagina
            dispatch({
                type: GET_VENTAS_PRECIOS,
                ventasPrecios: registros,
                totalVentasPrecios: response.data['@odata.count'] || 0,
                params
            })

            //Guardamos los filtros que se van a mostrar en la pagina
            dispatch({
                type: GET_FILTROS_VENTAS_PRECIOS,
                FiltrosVentasPrecios: FiltrosVentasPrecios || '',
                params
            })

            odataLogs(response.config.url, response.status, response.config.method, GET_VENTAS_TOTALES, config)

        }).catch((err) => odataLogs(err.message, "ERROR", "", GET_VENTAS_TOTALES, config))

    }
}