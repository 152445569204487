import {ADD_ANNOUNCEMENT, ADD_ANNOUNCEMENT_CATEGORIES, ADD_ANNOUNCEMENT_META, DELETE_ANNOUNCEMENT, DELETE_ANNOUNCEMENT_CATEGORIES, DELETE_ANNOUNCEMENT_META, GET_ANNOUNCEMENT, GET_ANNOUNCEMENTS, GET_ANNOUNCEMENTS_COUNT, GET_CATEGORIES, INIT_ANNOUNCEMENT, UPDATE_ANNOUNCEMENT} from '../action'

// ** Initial State
const initialState = {
    allData: [],
    data: [],
    categories: [],
    recentAnnouncements: [],
    total: 1,
    params: {},
    announcement: undefined,
    processing: false
}

const announcements = (state = initialState, action) => {
    switch (action.type) {
        // case `${prefix}/GET_ALL_DATA`:
        //     return { ...state, allData: action.data }
        case GET_ANNOUNCEMENTS:
            return {
                ...state,
                data: action.data,
                params: action.params
            }
        case GET_ANNOUNCEMENTS_COUNT:
            return {
                ...state,
                total: action.totalPages
            }
        case INIT_ANNOUNCEMENT:
            return {...state, announcement: {} }
        case GET_ANNOUNCEMENT:
            return {...state, announcement: action.announcement}
        case ADD_ANNOUNCEMENT:
            // return { ...state }
            return {...state, announcement: action.announcement}
        case DELETE_ANNOUNCEMENT:
            return {...state}
        case UPDATE_ANNOUNCEMENT:
            return {...state}
        case GET_CATEGORIES:
            return {
                ...state,
                categories: action.categories
            }
        case ADD_ANNOUNCEMENT_CATEGORIES:
            return {...state}
        case DELETE_ANNOUNCEMENT_CATEGORIES:
            return {...state}
        case ADD_ANNOUNCEMENT_META:
            return {...state}
        case DELETE_ANNOUNCEMENT_META:
            return {...state}

        default:
            return {...state}
    }
}
export default announcements
