import { automaticDownload, formatDateToDatabase, displayErrorMsg, odataLogs, seleccionarIdiomaYFormatoRegional, csvmaker } from '@src/utility/utils'
import { settings, Email, MailControllerApi, NavisionControllerApi } from '@api/backend'
import Swal from 'sweetalert2'
import { getUserCode, isParentUser } from '@src/auth/utils'
import { FormattedMessage, useIntl } from 'react-intl'
const prefix = '/albaranes'
const config = settings
export const GET_DELIVERY_NOTE = `${prefix}/GET_DELIVERY_NOTE`
export const GET_DELIVERY_NOTES = `${prefix}/GET_DATA`
export const GET_DELIVERY_NOTE_PDF = `${prefix}/GET_DELIVERY_NOTE_PDF`
export const INIT_REQUEST = `${prefix}/INIT_REQUEST`
export const INIT_DELIVERY_NOTE_PDF = `${prefix}/INIT_DELIVERY_NOTE_PDF`
export const INIT_DOWNLOAD_DELIVERY_NOTE_PDF = `${prefix}/INIT_DOWNLOAD_DELIVERY_NOTE_PDF`
export const DOWNLOAD_DELIVERY_NOTE_PDF = `${prefix}/DOWNLOAD_DELIVERY_NOTE_PDF`
export const SEND_DELIVERY_NOTE = `${prefix}/SEND_DELIVERY_NOTE`
export const SET_DELIVERY_NOTE_FROM_ALLDATA = `${prefix}/SET_DELIVERY_NOTE_FROM_ALLDATA`
export const SWITCH_BILLTO_SELLTO = `${prefix}/SWITCH_BILLTO_SELLTO`

// API Backend instance
const apiMail = new MailControllerApi(settings)
const apiNavisionNew = new NavisionControllerApi(settings)

// ** INIT REQUEST
export const initRequest = () => {
  return async (dispatch) => {
    dispatch({
      type: INIT_REQUEST
    })
  }
}
/*  Código obsoleto. Se ha creado la función csvmaker en /utility/utils.js para englobar las funciones csvmaker que hay, en una sola
const csvmaker = function (data) {
  const csvRows = []
  const headers = Object.keys(data[0])

  const indiceAEliminar = headers.indexOf('salesShipmentLines');
  if (indiceAEliminar !== -1) {
    headers.splice(indiceAEliminar, 1); // Eliminamos la cabecera (salesShipmentLines)
  }
  headers.push('total') // Agregamos la cabecera (total)
  let values
  csvRows.push(headers.splice(1, headers.length - 1).join(';'))
  Object.values(data).forEach(value => {

    const sumaTotalConDescuento = (value.salesShipmentLines.reduce((p, c) => p + (((c.unitPrice || 0) * (c.quantity || 0)) - ((c.unitPrice || 0) * (c.quantity || 0) * (c.lineDiscountPercent || 0) / 100)), 0) || 0).toFixed(2).replace('.', ','); // Se añade este código porque si no, al descargar el csv, pone [object], de ese modo calcula el total con el descuento aplicado y además, reemplaza los puntos que hay, por comas, para seguir la notación numérica española

    delete value.salesShipmentLines; // Eliminamos la columna (salesShipmentLines)
    values = Object.values(value)
    values.push(sumaTotalConDescuento) // Agregamos la columna (total)
    csvRows.push(values.splice(1, values.length - 1).join(';'))
  })
  return csvRows.join('\n')
}
*/
const descargaFichero = function (data) {
  const blob = new Blob([data], { type: 'text/csv' })
  const url = window.URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.setAttribute('href', url)
  a.setAttribute('download', 'albaranes.csv')
  a.click()
}

export const descargarCsv = (params, intl) => {
  return async (dispatch) => {
    dispatch(initRequest())
    const {
      page = 1,
      perPage = 10,
      documentNo = null,
      postingDate = null,
      shipmentDate = null,
      shipToCity = null,
      shipToAddress = null,
      sellToCustomerName = null,
      customerNo = null,
      vendedor = null,
      gerente = null
    } = params

    const filterList = []
    if (documentNo) {
      filterList.push(`documentNo eq '*${documentNo}*'`)
    }
    if (postingDate && postingDate.length) {
      if (postingDate.length === 2) {
        filterList.push(`postingDate ge ${formatDateToDatabase(postingDate[0])}`)
        filterList.push(`postingDate le ${formatDateToDatabase(postingDate[1])}`)
      } else {
        filterList.push(`postingDate eq ${formatDateToDatabase(postingDate[0])}`)
      }
    }
    if (shipmentDate && shipmentDate.length) {
      if (shipmentDate.length === 2) {
        filterList.push(`shipmentDate ge ${formatDateToDatabase(shipmentDate[0])}`)
        filterList.push(`shipmentDate le ${formatDateToDatabase(shipmentDate[1])}`)
      } else {
        filterList.push(`shipmentDate eq ${formatDateToDatabase(shipmentDate[0])}`)
      }
    }
    if (shipToCity) {
      filterList.push(`shiptoCity eq '*${shipToCity}*'`)
    }
    if (shipToAddress) {
      filterList.push(`shipToAddress eq '*${shipToAddress}*'`)
    }
    if (sellToCustomerName) {
      filterList.push(`sellToCustomerName eq '*${sellToCustomerName}*'`)
    }
    if (gerente === "off" || gerente === "" || gerente === null || gerente === undefined) {
      if (vendedor) {
        filterList.push(`salespersonCode eq '${vendedor}'`) //->Filtramos por el codigo exacto del vendedor, sin añadir *__*
      }
    }
    //if (gerente === "off" || gerente === "" || gerente === null || gerente === undefined) {
      if (customerNo) {
        filterList.push(`sellToCustomerNo eq '*${customerNo}*'`)
      }
    //}
    
    const $filter = filterList.join(' and ')
    const $top = perPage
    const $skip = (page - 1) * perPage
    const $orderby = 'documentNo desc'
    const $expand = 'salesShipmentLines'
    const $count = true
    Swal.fire({
      title: intl.formatMessage({ id: 'Generating csv' }),
      html: intl.formatMessage({ id:  'Please wait'}),
      timer: $top * 1.5,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading()
      }
    })
    await apiNavisionNew.navisionControllerGetendPoint('salesShipmentsHeader', $filter, $top, $skip, $orderby, $expand, $count).then((response) => {
      const { data } = response
      const csvdata = csvmaker(data.value)
      descargaFichero(csvdata)
      location.reload()
    })
  }
}

// ** Get Delivery Notes list
export const getData = (intl, params) => {
  return async (dispatch, getState) => {
    dispatch(initRequest())
    const {
      page = 1,
      perPage = 10,
      status = null,
      documentNo = null,
      postingDate = null,
      shipmentDate = null,
      shipToCity = null,
      shipToAddress = null,
      ean = null,
      description = null,
      productCode = null,
      sellToCustomerName = null,
      customerNo = null,
      vendedor = null,
      gerente = null
    } = params

    try {
      let $filter
      let total

      let $top = perPage
      let $skip = (page - 1) * perPage
      const $orderby = 'documentNo desc'
      const $expand = 'salesShipmentLines'
      const $count = true

      const filterList = []

      const customerNoFilter = getUserCode()
      const isParent = await isParentUser()

      // Filtros para Lineas
      if (ean || description || productCode || vendedor || customerNo) {
        const filterLines = [...filterList]

        if (ean) {
          filterLines.push(`no eq '${ean}'`)
        }
        if (description) {
          filterLines.push(`description eq '*${description}*'`)
        }
        if (productCode) {
          filterLines.push(`barCode eq '*${productCode}*'`)
        }
        //if (gerente === "off" || gerente === "" || gerente === null || gerente === undefined) {
          if (vendedor) {
            filterList.push(`salespersonCode eq '${vendedor}'`) //->Filtramos por el codigo exacto del vendedor, sin añadir *__*
          }
        //}
        //if (gerente === "off" || gerente === "" || gerente === null || gerente === undefined) {
          if (customerNo) {
            filterList.push(`sellToCustomerNo eq '*${customerNo}*'`)
          }
        //}

        // Join array de Filtros y reemplazo de campos 'shiptoCity' a 'shipToCity' por diferencia entre cabecera y lineas
        const $filterLines = filterLines.join(' and ')
          .replace('documentNo', 'no') //? CREO QUE NO VA
          .replace('shiptoCity', 'shipToCity')

        // Consulta API de query (líneas y cabecera): Máximo 100000 registros
        let ids = []
        if ($filterLines != '') {
          const linesData = await apiNavisionNew.navisionControllerGetendPoint('querySalesShipment', $filterLines, 100000, 0)
          ids = [...new Set(linesData.data.value?.map(line => line.no))]
        } 
        // Obtener array único de Documentos
        if (ids.length > 0) {
          // Variables de Paginacion cuando busca lineas
          const start = ((page - 1) * perPage)
          const end = start + perPage

          // Calcula total de registros para paginación
          total = ids.length

          // Obtener la parte del array de DocumentNo para la pagina solicitada
          const idsPaginated = total > perPage ? ids.slice(start, end) : ids

          // Agrega filtros por los documentos de la pagina
          const list = idsPaginated.map(ids => `documentNo eq '${ids}'`).join(' or ')
  
          if (list) {
            // Agrega filtros por los documentos de la pagina y modifica parametros de paginación
            filterList.push(`(${list})`)
            $top = perPage
            $skip = 0
          }
        } 
         // Filtros para Cabecera
      if (status || documentNo || postingDate || shipmentDate || shipToCity || shipToAddress || customerNoFilter || sellToCustomerName) {
        if (customerNo) {
          if (isParent) {
            const showFromHijos = getState().salesQuote.showFromHijos
            if (showFromHijos) {
              filterList.push(`billtoCustomerNo eq '*${customerNoFilter}*'`)
              // filterList.push(`sellToCustomerNo <> '*${customerNo}*'`)
            } // else filterList.push(`sellToCustomerNo eq '*${customerNoFilter}*'`)
          } // else filterList.push(`sellToCustomerNo eq '*${customerNoFilter}*'`)
        }
        if (documentNo) {
          filterList.push(`documentNo eq '*${documentNo}*'`)
        }
        if (postingDate && postingDate.length) {
          if (postingDate.length === 2) {
            filterList.push(`postingDate ge ${formatDateToDatabase(postingDate[0])}`)
            filterList.push(`postingDate le ${formatDateToDatabase(postingDate[1])}`)
          } else {
            filterList.push(`postingDate eq ${formatDateToDatabase(postingDate[0])}`)
          }
        }
        if (shipmentDate && shipmentDate.length) {
          if (shipmentDate.length === 2) {
            filterList.push(`shipmentDate ge ${formatDateToDatabase(shipmentDate[0])}`)
            filterList.push(`shipmentDate le ${formatDateToDatabase(shipmentDate[1])}`)
          } else {
            filterList.push(`shipmentDate eq ${formatDateToDatabase(shipmentDate[0])}`)
          }
        }
        if (shipToCity) {
          filterList.push(`shiptoCity eq '*${shipToCity}*'`)
        }
        if (shipToAddress) {
          filterList.push(`shipToAddress eq '*${shipToAddress}*'`)
        }
        if (sellToCustomerName) {
          filterList.push(`billtoName eq '*${sellToCustomerName}*'`)
        }
        if (filterList.length) {
          $filter = filterList.join(' and ')
        }
      }
       
        $filter = filterList.join(' and ')
      }
        await apiNavisionNew.navisionControllerGetendPoint('salesShipmentsHeader', $filter, $top, $skip, $orderby, $expand, $count).then((response) => {
          console.log(response.data)
          const {data} = response
          dispatch({
            type: GET_DELIVERY_NOTES,
            allData: data.value,
            data: data.value,
            totalPages: total || data['@odata.count'] || 0,
            params
          })
          odataLogs(response.config.url, response.status, response.config.method, GET_DELIVERY_NOTES, config)
        }).catch((err) => odataLogs(err.message, "ERROR", "", GET_DELIVERY_NOTES, config))
    } catch (err) {
      Swal.fire({
        title: intl.formatMessage({ id: "Error in Navision"}),
        icon: "error"
      })
      console.error(err.message)
    }
  }
}

// ** Get Delivery Note
export const getDeliveryNote = (params) => {
  return async (dispatch) => {
    const {
      page = 1,
      perPage = 1,
      documentNo = null
    } = params

    try {
      const $top = perPage
      const $skip = (page - 1) * perPage
      const $orderby = 'documentNo desc'
      const $expand = 'salesShipmentLines'
      const $count = undefined
      const $filter = `documentNo eq '*${documentNo}*'`
      await apiNavisionNew.navisionControllerGetendPoint('salesShipmentsHeader', $filter, $top, $skip, $orderby, $expand, $count).then((response) => {
        const { data } = response
        dispatch({
          type: GET_DELIVERY_NOTE,
          deliveryNote: data.value?.pop()
        })
        odataLogs(response.config.url, response.status, response.config.method, GET_DELIVERY_NOTE, config)
      }).catch((err) => odataLogs(err.message, "ERROR", "", GET_DELIVERY_NOTE, config))
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const consultarSiExisteAlbaran = (params) => {
  return async () => {
    const {
      page = 1,
      perPage = 1,
      documentNo = null
    } = params

    try {
      const $top = perPage
      const $skip = (page - 1) * perPage
      const $orderby = 'documentNo desc'
      const $expand = 'salesShipmentLines'
      const $count = undefined
      const $filter = `documentNo eq '*${documentNo}*'`
      return await apiNavisionNew.navisionControllerGetendPoint('salesShipmentsHeader', $filter, $top, $skip, $orderby, $expand, $count).then((response) => {
        return response
      }).catch((err) => odataLogs(err.message, "ERROR", "", GET_DELIVERY_NOTE, config))
    } catch (err) {
      console.error(err.message)
    }
  }
}

// ** INIT PDF Albaran
export const initDeliveryNotePdf = () => {
  return async (dispatch) => {
    dispatch({
      type: INIT_DELIVERY_NOTE_PDF
    })
  }
}

// ** Get Delivery Note PDF
export const getDeliveryNotePdf = (documentNo) => {
  return async (dispatch) => {
    try {
      const objNew = { DocNo: documentNo, DocType: 'ALBARANVENTA' };
      const objJSON = JSON.stringify(objNew); // <-- creamos el JSON a enviar
      await apiNavisionNew.navisionControllerPostEndpointBC("TW_Functions_PrintDocument", objJSON).then((response) => {
        const { data } = response
        dispatch({
          type: GET_DELIVERY_NOTE_PDF,
          deliveryNotePdf: data
        })
        odataLogs(response.config.url, response.status, response.config.method, GET_DELIVERY_NOTE_PDF, config)
      })
        .catch((err) => odataLogs(err.message, "ERROR", "", GET_DELIVERY_NOTE_PDF, config))
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const initDownloadDeliveryNotePdf = () => {
  return async (dispatch) => {
    dispatch({
      type: INIT_DOWNLOAD_DELIVERY_NOTE_PDF
    })
  }
}

// ** Descargar PDF Albaran
export const downloadDeliveryNotePdf = (documentNo, processing, intl) => {
  return async (dispatch) => {
    try {     
      if (processing && processing === true) dispatch(initRequest())
      //const objNew = { DocNo: documentNo, DocType: 'ALBARANVENTA' };
      const objNew = { DocNo: documentNo, DocType: 'ALBARANVENTA', idioma: seleccionarIdiomaYFormatoRegional() }; // Llama a la función que devuelve el idioma seleccionado por el usuario en el desplegable de la web
      const objJSON = JSON.stringify(objNew); // <-- creamos el JSON a enviar
      await apiNavisionNew.navisionControllerPostEndpointBC("TW_Functions_PrintDocument", objJSON).then((response) => {
        const { data } = response
        // Escapa con notificacion error si encuentra error en la respuesta de navision
        if (data.status !== 'OK') return displayErrorMsg(`Error al generar PDF en Navision:${data.mensaje.message}`)
        dispatch({
          type: DOWNLOAD_DELIVERY_NOTE_PDF,
          deliveryNotePdf: data.mensaje,
          documentNo
        })
        odataLogs(response.config.url, response.status, response.config.method, DOWNLOAD_DELIVERY_NOTE_PDF, config)
        automaticDownload(`${documentNo}.pdf`, `data:application/pdf;base64,${data.mensaje}`)
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: intl.formatMessage({ id: 'Downloaded PDF'})
        })
      }).catch((err) => odataLogs(err.message, "ERROR", "", DOWNLOAD_DELIVERY_NOTE_PDF, config))
    } catch (err) {
      console.error(err.message)
    }
  }
}

// ** Fetch pdf y lo envia por correo
export const sendDeliveryNotePdfEmail = (mailObject, documentNo, intl) => {
  return async (dispatch) => {
    try {
      Swal.fire({
        position: 'center',
        title: intl.formatMessage({ id: 'Process started on the server, a warning will be displayed on the screen at the end of the process.'}),
        icon: 'info'
      })
      await dispatch(getDeliveryNotePdf(documentNo))
      await apiMail.mailControllerSendMail(mailObject).then((response) => {
        dispatch({
          type: SEND_DELIVERY_NOTE
        })
        odataLogs(response.config.url, response.status, response.config.method, SEND_DELIVERY_NOTE, config)
      }).catch((err) => odataLogs(err.message, "ERROR", "", SEND_DELIVERY_NOTE, config))
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: intl.formatMessage({ id: 'E-mail sent'})
      })
    } catch (err) {
      console.error(err.message)
    }
  }
}

// ** Set albaran desde alldata
export const setDeliveryNoteFromAllData = (documentNo) => {
  return async (dispatch) => {
    dispatch({
      type: SET_DELIVERY_NOTE_FROM_ALLDATA,
      data: documentNo
    })
  }
}

export const switchBilltoSellto = () => {
  return async (dispatch) => {
    dispatch({
      type: SWITCH_BILLTO_SELLTO
    })
  }
}
