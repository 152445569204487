// ** React Imports
import { useState, createContext, useEffect } from 'react'

// ** Intl Provider Import
import { IntlProvider } from 'react-intl'

import { TranslationsControllerApi, settings } from '@api/backend'
const api = new TranslationsControllerApi(settings)

import Cookies from 'js-cookie';

// ** Create Context
const Context = createContext()

const IntlProviderWrapper = ({ children }) => {
  // ** States
  const idiomaCookies = Cookies.get('idioma');
  let idioma = 'es'
  if (idiomaCookies) {
    idioma = idiomaCookies
  }
  const [locale, setLocale] = useState(idioma)
  const [messages, setMessages] = useState(null)

  useEffect(() => {
    async function getAndSetTranslations () {
      api.translationsControllerFind(JSON.stringify({where: {language: locale}})).then(result => {
        const newLanguageObj = {} // {"Announcements": "Comunicados"}

        result.data?.map(translation => {
          if (translation?.value?.length) newLanguageObj[`${translation.keyName}`] = translation.value
        })
        setMessages(newLanguageObj)
      })
    }
    getAndSetTranslations()
  }, [locale])

  // ** Switches Language
  const switchLanguage = lang => {
    setLocale(lang)
  }

  return (
    <Context.Provider value={{ locale, switchLanguage }}>
      <IntlProvider key={locale} locale={locale} messages={messages} defaultLocale='es'>
        {children}
      </IntlProvider>
    </Context.Provider>
  )
}

export { IntlProviderWrapper, Context as IntlContext }
