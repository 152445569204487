// ** React Imports
import {Suspense, lazy} from 'react'
import ReactDOM from 'react-dom'
// ** Redux Imports
import {Provider} from 'react-redux'
import {persistor, store} from './redux/storeConfig/store'
// ** Toast & ThemeColors Context
import ability from '@configs/acl/ability'
import {ToastContainer} from 'react-toastify'
import {AbilityContext} from './context/Can'
import {ThemeContext} from './context/ThemeColors'
import {IntlProviderWrapper} from './context/Internationalization'
// ** Spinner (Splash Screen)
import Spinner from './@core/components/spinner/Fallback-spinner'
// ** Ripple Button
import './@core/components/ripple-button'
// ** PrismJS
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import 'prismjs/components/prism-jsx.min'
// ** React Perfect Scrollbar
import 'react-perfect-scrollbar/dist/css/styles.css'
// ** React Toastify
import '@styles/react/libs/toastify/toastify.scss'
// ** Core styles
import './@core/assets/fonts/feather/iconfont.css'
import './@core/scss/core.scss'
import './assets/scss/style.scss'
// ** Service Worker
import * as serviceWorker from './serviceWorker'
import {PersistGate} from 'redux-persist/integration/react'
import AutoLogout from './global'; // Importa el componente AutoLogout

// ** Lazy load app
const LazyApp = lazy(() => import('./App'))

ReactDOM.render(
  <Provider store={store}>
    <AutoLogout /> {/* Llama al componente AutoLogout al cargar la aplicación */}
    <PersistGate loading={null} persistor={persistor}>
      <Suspense fallback={<Spinner />}>
        <AbilityContext.Provider value={ability}>
          <ThemeContext>
            <IntlProviderWrapper>
              <LazyApp />
              <ToastContainer newestOnTop />
            </IntlProviderWrapper>
          </ThemeContext>
        </AbilityContext.Provider>
      </Suspense>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
)

serviceWorker.unregister()
