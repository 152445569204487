/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {  settings, PromotionsControllerApi, PromotionsPromotionMetaControllerApi } from '@api/backend'
import { ERROR_FEEDBACK } from '@store/actions'
import { odataLogs } from '@utils'

const prefix = 'admin/promociones'
const config = settings
export const INIT_PROMOTION = `${prefix}/INIT_PROMOTION`
export const GET_PROMOTION = `${prefix}/GET_PROMOTION`
export const GET_PROMOTIONS = `${prefix}/GET_DATA`
export const GET_PROMOTIONS_COUNT = `${prefix}/GET_DATA_COUNT`
export const ADD_PROMOTION = `${prefix}/ADD`
export const DELETE_PROMOTION = `${prefix}/DELETE`
export const UPDATE_PROMOTION = `${prefix}/UPDATE`
export const ADD_PROMOTION_META = `${prefix}/ADD_ANNONUCEMENT_META`
export const DELETE_PROMOTION_META = `${prefix}/DELETE_ANNONUCEMENT_META`
export const INIT_REQUEST = `${prefix}/INIT_REQUEST`

const api = new PromotionsControllerApi(settings)
const apiPromotionMeta = new PromotionsPromotionMetaControllerApi(settings)

const getParams = (state) => state.admin.promotions.params

// ** Add Promotion Metas
export const createPromotionMetas = (id, metas) => {
    return async (dispatch) => {
        await Promise.all(
            metas.map(async meta => {
                const newMeta = {
                    key: meta.key,
                    content: meta.content
                }
                const response = await apiPromotionMeta.promotionsPromotionMetaControllerCreate(id, newMeta)
                odataLogs(response.config.url, response.status, response.config.method, "admin/promociones/store/action/index/createPromotionMetas", config)
            })
        )
            .then(result => console.log(result))
            .catch(err => {
                console.error(err)
                dispatch({ message: err.message })
                odataLogs(err.message, "ERROR", "", ERROR_FEEDBACK, config)
            })
    }
}

// ** Delete Promotion Meta
export const deletePromotionMetas = (id) => {
    return async (dispatch) => {

        await apiPromotionMeta.promotionsPromotionMetaControllerDelete(id).then(response => {
            dispatch({
                type: DELETE_PROMOTION_META,
                metas: []
            })
            odataLogs(response.config.url, response.status, response.config.method, DELETE_PROMOTION_META, config)
        })
            .catch(err => {
                console.error(err)
                dispatch({ message: err.message })
                odataLogs(err.message, "ERROR", "", ERROR_FEEDBACK, config)
            })

    }
}

// ** Get data on page or row change
export const getData = (params) => {

    return async (dispatch) => {
        const { page = 1, perPage = 30, q = '', sortBy } = params

        const filter = {
            offset: (page - 1) * perPage,
            limit: perPage,
            skip: ((page - 1) * perPage),
            order: sortBy || "createdAt DESC",
            where: {
                ['title']: { like: `%${q}%` }
            },
            include: [
                {
                    relation: 'promotionMetas'
                },
                {
                    relation: 'author'
                }
            ]

        }

        await api.promotionsControllerFind(JSON.stringify(filter)).then(response => {
            dispatch({
                type: GET_PROMOTIONS,
                data: response.data,
                params
            })
            odataLogs(response.config.url, response.status, response.config.method, GET_PROMOTIONS, config)
        }).catch((err) => odataLogs(err.message, "ERROR", "", GET_PROMOTIONS, config))

        await api.promotionsControllerCount(JSON.stringify(filter.where)).then(response => {
            dispatch({
                type: GET_PROMOTIONS_COUNT,
                totalPages: response.data.count || 0
            })
            odataLogs(response.config.url, response.status, response.config.method, GET_PROMOTIONS_COUNT, config)
        }).catch((err) => odataLogs(err.message, "ERROR", "", GET_PROMOTIONS_COUNT, config))
    }
}

export const initPromotion = () => {
    return async (dispatch) => {
        dispatch({
            type: GET_PROMOTION
        })
    }
}
// ** Get Promotion
export const getPromotion = (id) => {
    return async (dispatch) => {
        const filter = {
            include: [
                {
                    relation: 'author'
                },
                {
                    relation: 'promotionMetas'
                }
            ]
        }
        await api.promotionsControllerFindById(id, JSON.stringify(filter)).then(response => {
            dispatch({
                type: GET_PROMOTION,
                promotion: response.data
            })
            odataLogs(response.config.url, response.status, response.config.method, GET_PROMOTION, config)
        })
            .catch((err) => odataLogs(err.message, "ERROR", "", GET_PROMOTION, config))
    }
}

// ** Get Promotion by slug
export const getPromotionBySlug = (slug) => {
    return async (dispatch) => {
        const filter = {
            where: {
                ['slug']: { eq: slug }
            },
            include: [
                {
                    relation: 'author'
                },
                {
                    relation: 'promotionMetas'
                }
            ]
        }
        await api.promotionsControllerFind(JSON.stringify(filter)).then(response => {
            dispatch({
                type: GET_PROMOTION,
                promotion: response.data
            })
            odataLogs(response.config.url, response.status, response.config.method, GET_PROMOTION, config)
        })
            .catch((err) => odataLogs(err.message, "ERROR", "", GET_PROMOTION, config))
    }
}

// ** Add new Promotion
export const addPromotion = (promotion, metas) => {
    return (dispatch, getState) => {
        api.promotionsControllerCreate(promotion).then(response => {
            dispatch({
                type: ADD_PROMOTION,
                promotion: response.data
            })
            odataLogs(response.config.url, response.status, response.config.method, ADD_PROMOTION, config)
            return response.data
        })
            .then((promotion) => {
                if (promotion.id && metas.length > 0) {
                    const metasList = metas.map(meta => {
                        return {
                            promotionId: promotion.id,
                            key: meta.id,
                            content: meta.meta.name
                        }
                    })
                    dispatch(createPromotionMetas(promotion.id, metasList))
                }
                dispatch(getData(getParams(getState()) || []))
            })
            .catch((err) => odataLogs(err.message, "ERROR", "", ADD_PROMOTION, config))

    }
}

// ** Update a Promotion
export const updatePromotion = (id, promotion, metas) => {
    return (dispatch, getState) => {
        api.promotionsControllerUpdateById(id, promotion).then(response => {
            dispatch({
                type: UPDATE_PROMOTION,
                promotion
            })
            odataLogs(response.config.url, response.status, response.config.method, UPDATE_PROMOTION, config)
        })
            .then(() => {
                if (metas.length > 0) {
                    const metasList = metas.map(meta => {
                        return {
                            promotionId: promotion.id,
                            key: meta.id,
                            content: meta.meta.name
                        }
                    })
                    // Borro los que haya, y creo el nuevo, ya que solo podria existir uno
                    dispatch(deletePromotionMetas(id))
                    dispatch(createPromotionMetas(id, metasList))
                }
            })
            .then(() => {
                dispatch(getData(getParams(getState())))
            })
            .catch((err) => odataLogs(err.message, "ERROR", "", UPDATE_PROMOTION, config))
    }
}

// ** Delete a Promotions
export const deletePromotion = (id) => {
    return (dispatch, getState) => {
        Promise.all([apiPromotionMeta.promotionsPromotionMetaControllerDelete(id)])
            .then(() => api.promotionsControllerDeleteById(id))
            .then(response => {
                dispatch({
                    type: DELETE_PROMOTION
                })
                odataLogs(response.config.url, response.status, response.config.method, DELETE_PROMOTION, config)
            })
            .then(() => {
                dispatch(getData(getParams(getState())))
            })
            .catch((err) => odataLogs(err.message, "ERROR", "", DELETE_PROMOTION, config))
    }
}
