/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { settings, NavisionControllerApi } from '@api/backend'
import { odataLogs } from '@utils'
const prefix = 'admin/productosdisponibles'
const config = settings
export const INIT_REQUEST = `${prefix}/INIT_REQUEST`
export const GET_PRODUCTO_DISPONIBLE = `${prefix}/GET_DATA`
export const GET_PRODUCTOS_DISPONIBLES = `${prefix}/GET_DATAS`
export const GET_PRODUCTOS_DISPONIBLES_COUNT = `${prefix}/GET_DATA_COUNT`
export const GET_ALL_PRODUCTOS_DISPONIBLES = `${prefix}/GET_ALL_DATAS`
export const GET_FILTROS_PRODUCTOS_DISPONIBLES = `${prefix}/GET_FILTROS_DATAS`

const apiNavisionNew = new NavisionControllerApi(settings)

// ** INIT REQUEST
export const initRequest = () => {
    return async (dispatch) => {
      dispatch({
        type: INIT_REQUEST
      })
    }
  }

// ** Get data on page or row change
export const getData = (params) => {

    return async (dispatch) => {
        dispatch(initRequest())
        const { page = 1, perPage = 10, filtrosBusqueda } = params

        const limit = perPage
        const skip = ((page - 1) * perPage)
        // const order = sortBy || "createdAt DESC"
        const order = ''
        const expand = '';
        const count = true;
        
        const { no, description, category, ean, inventory, brand } = filtrosBusqueda || {};

        let filtro = ''
        const listaDeFiltros = []

        if (no || description || category || ean || inventory || brand) {
            if (no) {
                listaDeFiltros.push(`no eq '${no}'`)
            }
            if (description) {
                listaDeFiltros.push(`description eq '*${description}*'`)
            }
            if (category) {
                listaDeFiltros.push(`itemCategoryCode eq '*${category}*'`)
            }
            if (ean) {
                listaDeFiltros.push(`ean eq '${ean}'`)
            }
            if (inventory) {
                listaDeFiltros.push(`inventory eq '${inventory}'`)
            }
            if (brand) {
                listaDeFiltros.push(`brand eq '${brand}'`)
            }
            filtro = listaDeFiltros.join(' and ')
        }

        await apiNavisionNew.navisionControllerGetendPoint('itemAvailability', filtro, limit, skip, order, expand, count).then(response => {
            
            const todosLosRegistros = response.data.value
            const registrosPaginados = todosLosRegistros.slice(0, limit); // -> Filtra solo primera pagina
            console.log('Productos disponibles: ', registrosPaginados)

            //Guardamos los datos que se van a mostrar en la pagina
            dispatch({
                type: GET_PRODUCTOS_DISPONIBLES,
                data: registrosPaginados,
                params
            })

            //Guardamos el numero de registros
            dispatch({
                type: GET_PRODUCTOS_DISPONIBLES_COUNT,
                totalPages: response.data['@odata.count'] || 0
                //totalPages: todosLosRegistros.length || 0
            })

            //Guardamos todos los productos disponibles//
            dispatch({
                type: GET_ALL_PRODUCTOS_DISPONIBLES,
                data: todosLosRegistros
            })

            //Guardamos los filtros de busqueda (Inputs)
            dispatch({
                type: GET_FILTROS_PRODUCTOS_DISPONIBLES,
                filtros: filtrosBusqueda
            })

            odataLogs(response.config.url, response.status, response.config.method, GET_PRODUCTOS_DISPONIBLES, config)
        }).catch((err) => odataLogs(err.message, "ERROR", "", GET_PRODUCTOS_DISPONIBLES, config))

    }
}