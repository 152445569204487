/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { GET_PROMOTION, GET_PROMOTIONS, GET_PROMOTIONS_COUNT, GET_PROMOTIONS_SPECIAL, INIT_PROMOTION } from '../action'

const initialState = {
    allData: [],
    data: [],
    total: 1,
    params: {},
    specialPromotions: [],
    promotion: undefined,
    processing: false
}

const promotions = (state = initialState, action) => {
    switch (action.type) {
        case GET_PROMOTIONS:
            return {
                ...state,
                data: action.data,
                params: action.params
            }
        case GET_PROMOTIONS_COUNT:
            return {
                ...state,
                total: action.totalPages
            }
        case GET_PROMOTIONS_SPECIAL:
            return {
                ...state,
                specialPromotions: action.data
            }
        case INIT_PROMOTION:
            return { ...state, promotion: {} }
        case GET_PROMOTION:
            return { ...state, promotion: action.promotion }

        default:
            return { ...state }
    }
}
export default promotions
