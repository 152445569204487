/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// ** APIS
import { settings, MailControllerApi, NavisionControllerApi } from '@api/backend'
// ** Utils
import { displaySuccessMsg, formatDateToDatabase, displayErrorMsg, odataLogs } from '@src/utility/utils'
import Swal from 'sweetalert2'
import { automaticDownload, csvmaker } from '@utils'
import { getUserCode, isParentUser } from '@src/auth/utils'
import { FormattedMessage, useIntl } from 'react-intl'

// ** Prefijo acciones
const prefix = 'invoice'
const config = settings
// ** Constantes Acciones
export const GET_INVOICE = `${prefix}/GET_INVOICE`
export const GET_INVOICE_PDF = `${prefix}/GET_INVOICE_PDF`
export const GET_INVOICES = `${prefix}/GET_DATA`
export const DELETE_INVOICE = `${prefix}/DELETE_INVOICE`
export const INIT_REQUEST = `${prefix}/INIT_REQUEST`
export const INIT_INVOICE_PDF = `${prefix}/INIT_INVOICE_PDF`
export const INIT_DOWNLOAD_INVOICE_PDF = `${prefix}/INIT_DOWNLOAD_INVOICE_PDF`
export const DOWNLOAD_INVOICE_PDF = `${prefix}/DOWNLOAD_INVOICE_PDF`
export const SEND_INVOICE = `${prefix}/SEND_INVOICE`
export const SET_INVOICE_FROM_ALLDATA = `${prefix}/SET_INVOICE_FROM_ALLDATA`
export const SWITCH_BILLTO_SELLTO = `${prefix}/SWITCH_BILLTO_SELLTO`

// API Backend instance
const apiMail = new MailControllerApi(settings)
const apiNavisionNew = new NavisionControllerApi(settings)

// ** INIT REQUEST
export const initRequest = () => {
  return async (dispatch) => {
    dispatch({
      type: INIT_REQUEST
    })
  }
}
/*  Código obsoleto. Se ha creado la función csvmaker en /utility/utils.js para englobar las funciones csvmaker que hay, en una sola
const csvmaker = function (data) {
  const csvRows = []
  const headers = Object.keys(data[0])
  
  const indiceAEliminar = headers.indexOf('salesInvoiceLines');
  if (indiceAEliminar !== -1) {
    headers.splice(indiceAEliminar, 1); // Eliminamos la cabecera (salesInvoiceLines)
  }
  headers.push('total') // Agregamos la cabecera (total)

  let values
  csvRows.push(headers.splice(1, headers.length - 1).join(';'))
  Object.values(data).forEach(value => {
    const sumaTotal = (value.salesInvoiceLines.reduce((p, c) => p + (c.lineAmount * (1 + (c.vatPercent / 100))), 0) || 0).toFixed(2).replace('.', ',') // Obtenemos la suma total de (salesInvoiceLines). Viene con los decimales separados por un punto, en vez de la notación española que sería una coma, se añade el código 'replace' para que reemplace los puntos que hay, por comas
    delete value.salesInvoiceLines; // Eliminamos la columna (salesInvoiceLines)
    values = Object.values(value)
    values.push(sumaTotal) // Agregamos la columna (total)
    csvRows.push(values.splice(1, values.length - 1).join(';'))
  })
  return csvRows.join('\n')
}
*/
const descargaFichero = function (data) {
  const blob = new Blob([data], { type: 'text/csv' })
  const url = window.URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.setAttribute('href', url)
  a.setAttribute('download', '/facturas.csv')
  a.click()
}

export const descargarCsv = (params, intl) => {
  return async (dispatch) => {
    dispatch(initRequest())
    const {
      page = 1,
      perPage = 10,
      documentNo = null,
      postingDate = null,
      shipmentDate = null,
      shipToCity = null,
      shipToAddress = null,
      sellToCustomerName = null,
      customerNo = null,
      vendedor = null,
      gerente = null
    } = params

    const filterList = []
    if (documentNo) {
      filterList.push(`documentNo eq '*${documentNo}*'`)
    }
    if (postingDate && postingDate.length) {
      if (postingDate.length === 2) {
        filterList.push(`postingDate ge ${formatDateToDatabase(postingDate[0])}`)
        filterList.push(`postingDate le ${formatDateToDatabase(postingDate[1])}`)
      } else {
        filterList.push(`postingDate eq ${formatDateToDatabase(postingDate[0])}`)
      }
    }
    if (shipmentDate && shipmentDate.length) {
      if (shipmentDate.length === 2) {
        filterList.push(`shipmentDate ge ${formatDateToDatabase(shipmentDate[0])}`)
        filterList.push(`shipmentDate le ${formatDateToDatabase(shipmentDate[1])}`)
      } else {
        filterList.push(`shipmentDate eq ${formatDateToDatabase(shipmentDate[0])}`)
      }
    }
    if (shipToCity) {
      filterList.push(`shiptoCity eq '*${shipToCity}*'`)
    }
    if (shipToAddress) {
      filterList.push(`shipToAddress eq '*${shipToAddress}*'`)
    }
    if (sellToCustomerName) {
      filterList.push(`sellToCustomerName eq '*${sellToCustomerName}*'`)
    }
    if (gerente === "off" || gerente === "" || gerente === null || gerente === undefined) {
      if (vendedor) {
        filterList.push(`salespersonCode eq '${vendedor}'`) //->Filtramos por el codigo exacto del vendedor, sin añadir *__*
      }
    }
    if (customerNo) {
      filterList.push(`sellToCustomerNo eq '*${customerNo}*'`)
    }
    
    const $filter = filterList.join(' and ')
    const $top = perPage
    const $skip = (page - 1) * perPage
    const $orderby = 'documentNo desc'
    const $expand = 'salesInvoiceLines'
    const $count = true
    Swal.fire({
      title: intl.formatMessage({ id: 'Generating csv' }),
      html: intl.formatMessage({ id:  'Please wait'}),
      timer: $top * 1.5,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading()
      }
    })
    await apiNavisionNew.navisionControllerGetendPoint('salesInvoiceHeader', $filter, $top, $skip, $orderby, $expand, $count).then((response) => {
      const { data } = response
      const csvdata = csvmaker(data.value)
      descargaFichero(csvdata)
      location.reload()
    })    
  }
}

// ** Get Invoices list
export const getData = (intl, params) => {
  return async (dispatch, getState) => {
    dispatch(initRequest())
    const {
      page = 1,
      perPage = 10,
      status = null,
      documentNo = null,
      postingDate = null,
      shipmentDate = null,
      shipToCity = null,
      shipToAddress = null,
      ean = null,
      description = null,
      productCode = null,
      sellToCustomerName = null,
      customerNo = null,
      vendedor = null,
      gerente = null
    } = params

    try {
      let $filter 
      let total
      let $top = perPage
      let $skip = (page - 1) * perPage
      const $orderby = 'documentNo desc'
      const $expand = 'salesInvoiceLines'
      const $count = true
      const filterList = []
      const customerNoFilter = getUserCode()
      const isParent = await isParentUser()

      // Filtros para Lineas
      if (ean || description || productCode || vendedor || customerNo) {
        const filterLines = [...filterList]

        if (ean) {
          filterLines.push(`no eq '${ean}'`)
        }
        if (description) {
          filterLines.push(`description eq '*${description}*'`)
        }
        if (productCode) {
          filterLines.push(`barCode eq '*${productCode}*'`)
        }
        //if (gerente === "off" || gerente === "" || gerente === null || gerente === undefined) {
          if (vendedor) {
            filterList.push(`salespersonCode eq '${vendedor}'`) //->Filtramos por el codigo exacto del vendedor, sin añadir *__*
          }
        //}
        //if (gerente === "off" || gerente === "" || gerente === null || gerente === undefined) {
          if (customerNo) {
            filterList.push(`sellToCustomerNo eq '*${customerNo}*'`)
          }
        //}
        // Join array de Filtros y reemplazo de campos 'shiptoCity' a 'shipToCity' por diferencia entre cabecera y lineas
        const $filterLines = filterLines.join(' and ')
          .replace('documentNo', 'no') //? CREO QUE NO VA
          .replace('shiptoCity', 'shipToCity')

        // Consulta API de query (líneas y cabecera): Máximo 100000 registros
        let ids = []
        if ($filterLines != '') {
          const linesData = await apiNavisionNew.navisionControllerGetendPoint('salesInvoiceLines', $filterLines, 100000, 0, undefined, undefined, undefined)
          ids = [...new Set(linesData.data.value?.map(line => line.no))]
        }

        // Obtener array único de Documentos
        if (ids.length > 0) {
          // Variables de Paginacion cuando busca lineas
          const start = ((page - 1) * perPage)
          const end = start + perPage

          // Calcula total de registros para paginación
          total = ids.length

          // Obtener la parte del array de DocumentNo para la pagina solicitada
          const idsPaginated = total > perPage ? ids.slice(start, end) : ids

          // Agrega filtros por los documentos de la pagina
          const list = idsPaginated.map(ids => `documentNo eq '${ids}'`).join(' or ')

          if (list) {
            // Agrega filtros por los documentos de la pagina y modifica parametros de paginación
            filterList.push(`(${list})`)
            $top = perPage
            $skip = 0
          }
        }
        // Filtros para Cabecera
        if (status || documentNo || postingDate || shipmentDate || shipToCity || shipToAddress || customerNoFilter || sellToCustomerName) {
          if (customerNo) {
            if (isParent) {
              const showFromHijos = getState().salesQuote.showFromHijos
              if (showFromHijos) {
                filterList.push(`billtoCustomerNo eq '*${customerNoFilter}*'`)
                // filterList.push(`sellToCustomerNo <> '*${customerNo}*'`)
              } // else filterList.push(`sellToCustomerNo eq '*${customerNoFilter}*'`)
            } // else filterList.push(`sellToCustomerNo eq '*${customerNoFilter}*'`)
          }
          if (documentNo) {
            filterList.push(`documentNo eq '*${documentNo}*'`)
          }
          if (postingDate && postingDate.length) {
            if (postingDate.length === 2) {
              filterList.push(`postingDate ge ${formatDateToDatabase(postingDate[0])}`)
              filterList.push(`postingDate le ${formatDateToDatabase(postingDate[1])}`)
            } else {
              filterList.push(`postingDate eq ${formatDateToDatabase(postingDate[0])}`)
            }
          }
          if (shipmentDate && shipmentDate.length) {
            if (shipmentDate.length === 2) {
              filterList.push(`shipmentDate ge ${formatDateToDatabase(shipmentDate[0])}`)
              filterList.push(`shipmentDate le ${formatDateToDatabase(shipmentDate[1])}`)
            } else {
              filterList.push(`shipmentDate eq ${formatDateToDatabase(shipmentDate[0])}`)
            }
          }
          if (shipToCity) {
            filterList.push(`shiptoCity eq '*${shipToCity}*'`)
          }
          if (shipToAddress) {
            filterList.push(`shipToAddress eq '*${shipToAddress}*'`)
          }
          if (sellToCustomerName) {
            filterList.push(`billtoName eq '*${sellToCustomerName}*'`)
          }
          if (filterList.length) {
            $filter = filterList.join(' and ')
          }
        }

        $filter = filterList.join(' and ')
      }
      await apiNavisionNew.navisionControllerGetendPoint('salesInvoiceHeader', $filter, $top, $skip, $orderby, $expand, $count).then((response) => {
        const { data } = response
        dispatch({
          type: GET_INVOICES,
          allData: data.value,
          data: data.value,
          totalPages: total || data['@odata.count'] || 0,
          params
        })
        odataLogs(response.config.url, response.status, response.config.method, GET_INVOICES, config)
      }).catch((err) => odataLogs(err.message, "ERROR", "", GET_INVOICES, config))
      
    } catch (err) {
      Swal.fire({
        title: intl.formatMessage({ id: "Error in Navision"}),
        icon: "error"
      })
      console.log(err.message)
    }
  }
}


// ** Get invoice
export const getInvoice = (params) => {
  return async (dispatch) => {
    const {
      page = 1,
      perPage = 1,
      documentNo = null
    } = params

    try {
      const $top = perPage
      const $skip = (page - 1) * perPage
      const $orderby = 'documentNo desc'
      const $expand = 'salesInvoiceLines'
      const $count = undefined

      const $filter = `documentNo eq '${documentNo}'`

      await apiNavisionNew.navisionControllerGetendPoint('salesInvoiceHeader', $filter, $top, $skip, $orderby, $expand, $count).then(response => {
        const { data } = response
        dispatch({
          type: GET_INVOICE,
          invoice: data.value?.pop()
        })
        odataLogs(response.config.url, response.status, response.config.method, GET_INVOICE, config)
      }).catch(err => odataLogs(err.message, "ERROR", "", GET_INVOICE, config))
    } catch (err) {
      console.log(err.message)
    }
  }
}

export const consultarSiExisteFactura = (params) => {
  return async () => {
    const {
      page = 1,
      perPage = 1,
      documentNo = null
    } = params

    try {
      const $top = perPage
      const $skip = (page - 1) * perPage
      const $orderby = 'documentNo desc'
      const $expand = 'salesInvoiceLines'
      const $count = undefined

      const $filter = `documentNo eq '${documentNo}'`
      return await apiNavisionNew.navisionControllerGetendPoint('salesInvoiceHeader', $filter, $top, $skip, $orderby, $expand, $count).then(response => {
        return response
      }).catch(err => odataLogs(err.message, "ERROR", "", GET_INVOICE, config))
    } catch (err) {
      console.error(err.message)
    }
  }
}

// ** INIT PDF Factura
export const initInvoicePdf = () => {
  return async (dispatch) => {
    dispatch({
      type: INIT_INVOICE_PDF
    })
  }
}

// ** Obtener PDF Factura
export const getInvoicePdf = (documentNo) => {
  return async (dispatch) => {
    try {
      const objNew = { DocNo: documentNo, DocType: 'FACTURAVENTA' };
      const objJSON = JSON.stringify(objNew); // <-- creamos el JSON a enviar
      await apiNavisionNew.navisionControllerPostEndpointBC("TW_Functions_PrintDocument", objJSON).then((response) => {
        const { data } = response
        dispatch({
          type: GET_INVOICE_PDF,
          invoicePdf: data,
          documentNo
        })
        odataLogs(response.config.url, response.status, response.config.method, GET_INVOICE_PDF, config)
      })
    } catch (err) {
      odataLogs(err.message, "ERROR", "", GET_INVOICE_PDF, config)

    }
  }
}

// Iniciar PDF Factura
export const initDownloadInvoicePdf = () => {
  return async (dispatch) => {
    dispatch({
      type: INIT_DOWNLOAD_INVOICE_PDF
    })
  }
}

// ** Fetchea pdf y lo descarga
export const downloadInvoicePdf = (documentNo, processing, intl) => {
  return async (dispatch) => {
    try {
      if (processing && processing === true) dispatch(initRequest())
      const objNew = { DocNo: documentNo, DocType: 'FACTURAVENTA' };
      const objJSON = JSON.stringify(objNew); // <-- creamos el JSON a enviar
      await apiNavisionNew.navisionControllerPostEndpointBC("TW_Functions_PrintDocument", objJSON).then((response) => {
        const { data } = response
        // Escapa con notificacion error si encuentra error en la respuesta de navision
        if (data.status !== 'OK') return displayErrorMsg(`${intl.formatMessage({ id: 'Error generating PDF in Navision'})}:${data.mensaje.message}`)
        dispatch({
          type: DOWNLOAD_INVOICE_PDF,
          invoicePdf: data.mensaje,
          documentNo
        })
        automaticDownload(`${documentNo}.pdf`, `data:application/pdf;base64,${data.mensaje}`)
        displaySuccessMsg(intl.formatMessage({ id: 'Downloaded PDF'}))
        odataLogs(response.config.url, response.status, response.config.method, DOWNLOAD_INVOICE_PDF, config)
      })
    } catch (err) {
      odataLogs(err.message, "ERROR", "", GET_INVOICE_PDF, config)
      console.log(err.message)
    }
  }
}

// ** Fetchea PDF y lo envia por email
export const sendInvoicePdfEmail = (mailObject, factura, intl) => {
  return async (dispatch) => {
    try {
      Swal.fire({
        position: 'center',
        title: intl.formatMessage({ id: 'Process started on the server, a warning will be displayed on the screen at the end of the process.'}),
        icon: 'info'
      })
      const objNew = { DocNo: factura.toString(), DocType: 'FACTURAVENTA' };
      const objJSON = JSON.stringify(objNew); // <-- creamos el JSON a enviar
      await apiNavisionNew.navisionControllerPostEndpointBC("TW_Functions_PrintDocument", objJSON).then((response) => {
        const { data } = response
        console.log(response)
        dispatch({
          type: DOWNLOAD_INVOICE_PDF,
          invoicePdf: data,
          documentNo: factura.toString()
        })

        mailObject.attachments = [
          {
          filename: 'file.pdf',
          text: 'factura b2b',
          contentType: 'application/pdf',
          encoding: 'base64',
          content: data
          }
        ]

        apiMail.mailControllerSendMail(mailObject).then((response) => {
          dispatch({
            type: SEND_INVOICE
          })
          odataLogs(response.config.url, response.status, response.config.method, SEND_INVOICE, config)
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: intl.formatMessage({ id: 'E-mail sent'})
          })
        })
      })
    } catch (err) {
      odataLogs(err.message, "ERROR", "", SEND_INVOICE, config)
      console.log(err.message)
    }
  }
}

// ** Precargar data del invoice desde alldata
export const setInvoiceFromAllData = (documentNo) => {
  return async (dispatch) => {
    dispatch({
      type: SET_INVOICE_FROM_ALLDATA,
      data: documentNo
    })
  }
}

export const switchBilltoSellto = () => {
  return async (dispatch) => {
    dispatch({
      type: SWITCH_BILLTO_SELLTO
    })
  }
}
