/* tslint:disable */
/* eslint-disable */
/**
 * Svan application
 * Svan API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dcampos@dynamizatic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AnnouncementCategory
 */
export interface AnnouncementCategory {
    /**
     * 
     * @type {number}
     * @memberof AnnouncementCategory
     */
    'announcementId': number;
    /**
     * 
     * @type {number}
     * @memberof AnnouncementCategory
     */
    'categoryId': number;
}
/**
 * (tsType: Partial<AnnouncementCategory>, schemaOptions: { partial: true })
 * @export
 * @interface AnnouncementCategoryPartial
 */
export interface AnnouncementCategoryPartial {
    /**
     * 
     * @type {number}
     * @memberof AnnouncementCategoryPartial
     */
    'announcementId'?: number;
    /**
     * 
     * @type {number}
     * @memberof AnnouncementCategoryPartial
     */
    'categoryId'?: number;
}
/**
 * (tsType: AnnouncementCategoryWithRelations, schemaOptions: { includeRelations: true })
 * @export
 * @interface AnnouncementCategoryWithRelations
 */
export interface AnnouncementCategoryWithRelations {
    /**
     * 
     * @type {number}
     * @memberof AnnouncementCategoryWithRelations
     */
    'announcementId': number;
    /**
     * 
     * @type {number}
     * @memberof AnnouncementCategoryWithRelations
     */
    'categoryId': number;
}
/**
 * 
 * @export
 * @interface AnnouncementMeta
 */
export interface AnnouncementMeta {
    /**
     * 
     * @type {number}
     * @memberof AnnouncementMeta
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof AnnouncementMeta
     */
    'announcementId': number;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementMeta
     */
    'key': string;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementMeta
     */
    'content'?: string;
}
/**
 * (tsType: Partial<AnnouncementMeta>, schemaOptions: { partial: true })
 * @export
 * @interface AnnouncementMetaPartial
 */
export interface AnnouncementMetaPartial {
    /**
     * 
     * @type {number}
     * @memberof AnnouncementMetaPartial
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof AnnouncementMetaPartial
     */
    'announcementId'?: number;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementMetaPartial
     */
    'key'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementMetaPartial
     */
    'content'?: string;
}
/**
 * (tsType: AnnouncementMetaWithRelations, schemaOptions: { includeRelations: true })
 * @export
 * @interface AnnouncementMetaWithRelations
 */
export interface AnnouncementMetaWithRelations {
    /**
     * 
     * @type {number}
     * @memberof AnnouncementMetaWithRelations
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof AnnouncementMetaWithRelations
     */
    'announcementId': number;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementMetaWithRelations
     */
    'key': string;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementMetaWithRelations
     */
    'content'?: string;
}
/**
 * 
 * @export
 * @interface Announcements
 */
export interface Announcements {
    /**
     * 
     * @type {number}
     * @memberof Announcements
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof Announcements
     */
    'parentId'?: number;
    /**
     * 
     * @type {string}
     * @memberof Announcements
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof Announcements
     */
    'metaTitle'?: string;
    /**
     * 
     * @type {string}
     * @memberof Announcements
     */
    'slug': string;
    /**
     * 
     * @type {string}
     * @memberof Announcements
     */
    'summary'?: string;
    /**
     * 
     * @type {string}
     * @memberof Announcements
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof Announcements
     */
    'vistoPor': string;
    /**
     * 
     * @type {number}
     * @memberof Announcements
     */
    'published': number;
    /**
     * 
     * @type {string}
     * @memberof Announcements
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Announcements
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Announcements
     */
    'publishedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Announcements
     */
    'content'?: string;
    /**
     * 
     * @type {number}
     * @memberof Announcements
     */
    'authorId'?: number;
}
/**
 * 
 * @export
 * @interface AnnouncementsFilter
 */
export interface AnnouncementsFilter {
    /**
     * 
     * @type {number}
     * @memberof AnnouncementsFilter
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof AnnouncementsFilter
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof AnnouncementsFilter
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof AnnouncementsFilter
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {object | Set<string>}
     * @memberof AnnouncementsFilter
     */
    'fields'?: object | Set<string>;
    /**
     * 
     * @type {Array<AnnouncementsIncludeFilterItems | string>}
     * @memberof AnnouncementsFilter
     */
    'include'?: Array<AnnouncementsIncludeFilterItems | string>;
}
/**
 * 
 * @export
 * @interface AnnouncementsFilter1
 */
export interface AnnouncementsFilter1 {
    /**
     * 
     * @type {number}
     * @memberof AnnouncementsFilter1
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof AnnouncementsFilter1
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof AnnouncementsFilter1
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof AnnouncementsFilter1
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof AnnouncementsFilter1
     */
    'where'?: { [key: string]: object; };
    /**
     * 
     * @type {object | Set<string>}
     * @memberof AnnouncementsFilter1
     */
    'fields'?: object | Set<string>;
    /**
     * 
     * @type {Array<AnnouncementsIncludeFilterItems | string>}
     * @memberof AnnouncementsFilter1
     */
    'include'?: Array<AnnouncementsIncludeFilterItems | string>;
}
/**
 * 
 * @export
 * @interface AnnouncementsIncludeFilterItems
 */
export interface AnnouncementsIncludeFilterItems {
    /**
     * 
     * @type {string}
     * @memberof AnnouncementsIncludeFilterItems
     */
    'relation'?: string;
    /**
     * 
     * @type {AnnouncementsScopeFilter}
     * @memberof AnnouncementsIncludeFilterItems
     */
    'scope'?: AnnouncementsScopeFilter;
}
/**
 * (tsType: Partial<Announcements>, schemaOptions: { partial: true })
 * @export
 * @interface AnnouncementsPartial
 */
export interface AnnouncementsPartial {
    /**
     * 
     * @type {number}
     * @memberof AnnouncementsPartial
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof AnnouncementsPartial
     */
    'parentId'?: number;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementsPartial
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementsPartial
     */
    'metaTitle'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementsPartial
     */
    'slug'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementsPartial
     */
    'summary'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementsPartial
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementsPartial
     */
    'vistoPor'?: string;
    /**
     * 
     * @type {number}
     * @memberof AnnouncementsPartial
     */
    'published'?: number;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementsPartial
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementsPartial
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementsPartial
     */
    'publishedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementsPartial
     */
    'content'?: string;
    /**
     * 
     * @type {number}
     * @memberof AnnouncementsPartial
     */
    'authorId'?: number;
}
/**
 * 
 * @export
 * @interface AnnouncementsScopeFilter
 */
export interface AnnouncementsScopeFilter {
    /**
     * 
     * @type {number}
     * @memberof AnnouncementsScopeFilter
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof AnnouncementsScopeFilter
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof AnnouncementsScopeFilter
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof AnnouncementsScopeFilter
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof AnnouncementsScopeFilter
     */
    'where'?: { [key: string]: object; };
    /**
     * 
     * @type {any | Set<string>}
     * @memberof AnnouncementsScopeFilter
     */
    'fields'?: any | Set<string>;
    /**
     * 
     * @type {Array<{ [key: string]: object; }>}
     * @memberof AnnouncementsScopeFilter
     */
    'include'?: Array<{ [key: string]: object; }>;
}
/**
 * (tsType: AnnouncementsWithRelations, schemaOptions: { includeRelations: true })
 * @export
 * @interface AnnouncementsWithRelations
 */
export interface AnnouncementsWithRelations {
    /**
     * 
     * @type {number}
     * @memberof AnnouncementsWithRelations
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof AnnouncementsWithRelations
     */
    'parentId'?: number;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementsWithRelations
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementsWithRelations
     */
    'metaTitle'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementsWithRelations
     */
    'slug': string;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementsWithRelations
     */
    'summary'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementsWithRelations
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementsWithRelations
     */
    'vistoPor': string;
    /**
     * 
     * @type {number}
     * @memberof AnnouncementsWithRelations
     */
    'published': number;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementsWithRelations
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementsWithRelations
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementsWithRelations
     */
    'publishedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementsWithRelations
     */
    'content'?: string;
    /**
     * 
     * @type {number}
     * @memberof AnnouncementsWithRelations
     */
    'authorId'?: number;
    /**
     * 
     * @type {UsersWithRelations}
     * @memberof AnnouncementsWithRelations
     */
    'author'?: UsersWithRelations;
    /**
     * 
     * @type {Array<CategoriesWithRelations>}
     * @memberof AnnouncementsWithRelations
     */
    'categories'?: Array<CategoriesWithRelations>;
    /**
     * 
     * @type {Array<AnnouncementMetaWithRelations>}
     * @memberof AnnouncementsWithRelations
     */
    'announcementMetas'?: Array<AnnouncementMetaWithRelations>;
    /**
     * 
     * @type {Array<AnnouncementCategoryWithRelations>}
     * @memberof AnnouncementsWithRelations
     */
    'announcementCategories'?: Array<AnnouncementCategoryWithRelations>;
}
/**
 * 
 * @export
 * @interface Categories
 */
export interface Categories {
    /**
     * 
     * @type {number}
     * @memberof Categories
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof Categories
     */
    'parentId'?: number;
    /**
     * 
     * @type {string}
     * @memberof Categories
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof Categories
     */
    'metaTitle'?: string;
    /**
     * 
     * @type {string}
     * @memberof Categories
     */
    'slug': string;
    /**
     * 
     * @type {string}
     * @memberof Categories
     */
    'content'?: string;
}
/**
 * 
 * @export
 * @interface CategoriesFilter
 */
export interface CategoriesFilter {
    /**
     * 
     * @type {number}
     * @memberof CategoriesFilter
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof CategoriesFilter
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof CategoriesFilter
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof CategoriesFilter
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {object | Set<string>}
     * @memberof CategoriesFilter
     */
    'fields'?: object | Set<string>;
}
/**
 * 
 * @export
 * @interface CategoriesFilter1
 */
export interface CategoriesFilter1 {
    /**
     * 
     * @type {number}
     * @memberof CategoriesFilter1
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof CategoriesFilter1
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof CategoriesFilter1
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof CategoriesFilter1
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof CategoriesFilter1
     */
    'where'?: { [key: string]: object; };
    /**
     * 
     * @type {object | Set<string>}
     * @memberof CategoriesFilter1
     */
    'fields'?: object | Set<string>;
}
/**
 * (tsType: Partial<Categories>, schemaOptions: { partial: true })
 * @export
 * @interface CategoriesPartial
 */
export interface CategoriesPartial {
    /**
     * 
     * @type {number}
     * @memberof CategoriesPartial
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof CategoriesPartial
     */
    'parentId'?: number;
    /**
     * 
     * @type {string}
     * @memberof CategoriesPartial
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoriesPartial
     */
    'metaTitle'?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoriesPartial
     */
    'slug'?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoriesPartial
     */
    'content'?: string;
}
/**
 * (tsType: CategoriesWithRelations, schemaOptions: { includeRelations: true })
 * @export
 * @interface CategoriesWithRelations
 */
export interface CategoriesWithRelations {
    /**
     * 
     * @type {number}
     * @memberof CategoriesWithRelations
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof CategoriesWithRelations
     */
    'parentId'?: number;
    /**
     * 
     * @type {string}
     * @memberof CategoriesWithRelations
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof CategoriesWithRelations
     */
    'metaTitle'?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoriesWithRelations
     */
    'slug': string;
    /**
     * 
     * @type {string}
     * @memberof CategoriesWithRelations
     */
    'content'?: string;
}
/**
 * 
 * @export
 * @interface Clientes
 */
export interface Clientes {
    /**
     * 
     * @type {number}
     * @memberof Clientes
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Clientes
     */
    'address'?: string;
    /**
     * 
     * @type {string}
     * @memberof Clientes
     */
    'address2'?: string;
    /**
     * 
     * @type {string}
     * @memberof Clientes
     */
    'allowStock'?: string;
    /**
     * 
     * @type {string}
     * @memberof Clientes
     */
    'auxiliaryIndex1'?: string;
    /**
     * 
     * @type {string}
     * @memberof Clientes
     */
    'billToCustomerNo'?: string;
    /**
     * 
     * @type {string}
     * @memberof Clientes
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof Clientes
     */
    'contact'?: string;
    /**
     * 
     * @type {string}
     * @memberof Clientes
     */
    'country'?: string;
    /**
     * 
     * @type {string}
     * @memberof Clientes
     */
    'county'?: string;
    /**
     * 
     * @type {string}
     * @memberof Clientes
     */
    'customerFilter_FilterOnly'?: string;
    /**
     * 
     * @type {string}
     * @memberof Clientes
     */
    'customerName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Clientes
     */
    'customerNo'?: string;
    /**
     * 
     * @type {string}
     * @memberof Clientes
     */
    'eMail'?: string;
    /**
     * 
     * @type {string}
     * @memberof Clientes
     */
    'filtercategories'?: string;
    /**
     * 
     * @type {string}
     * @memberof Clientes
     */
    'lastDateTimeCreated'?: string;
    /**
     * 
     * @type {string}
     * @memberof Clientes
     */
    'lastDateTimeModified'?: string;
    /**
     * 
     * @type {string}
     * @memberof Clientes
     */
    'markFilter'?: string;
    /**
     * 
     * @type {string}
     * @memberof Clientes
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Clientes
     */
    'name2'?: string;
    /**
     * 
     * @type {string}
     * @memberof Clientes
     */
    'phoneNo'?: string;
    /**
     * 
     * @type {string}
     * @memberof Clientes
     */
    'postCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof Clientes
     */
    'referenceStatus'?: string;
    /**
     * 
     * @type {string}
     * @memberof Clientes
     */
    'shippingIncluded'?: string;
    /**
     * 
     * @type {string}
     * @memberof Clientes
     */
    'shipToCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof Clientes
     */
    'shop'?: string;
    /**
     * 
     * @type {number}
     * @memberof Clientes
     */
    'companyId'?: number;
}
/**
 * 
 * @export
 * @interface ClientesFilter
 */
export interface ClientesFilter {
    /**
     * 
     * @type {number}
     * @memberof ClientesFilter
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof ClientesFilter
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof ClientesFilter
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof ClientesFilter
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {object | Set<string>}
     * @memberof ClientesFilter
     */
    'fields'?: object | Set<string>;
}
/**
 * 
 * @export
 * @interface ClientesFilter1
 */
export interface ClientesFilter1 {
    /**
     * 
     * @type {number}
     * @memberof ClientesFilter1
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof ClientesFilter1
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof ClientesFilter1
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof ClientesFilter1
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof ClientesFilter1
     */
    'where'?: { [key: string]: object; };
    /**
     * 
     * @type {object | Set<string>}
     * @memberof ClientesFilter1
     */
    'fields'?: object | Set<string>;
}
/**
 * (tsType: Partial<Clientes>, schemaOptions: { partial: true })
 * @export
 * @interface ClientesPartial
 */
export interface ClientesPartial {
    /**
     * 
     * @type {number}
     * @memberof ClientesPartial
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ClientesPartial
     */
    'address'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientesPartial
     */
    'address2'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientesPartial
     */
    'allowStock'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientesPartial
     */
    'auxiliaryIndex1'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientesPartial
     */
    'billToCustomerNo'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientesPartial
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientesPartial
     */
    'contact'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientesPartial
     */
    'country'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientesPartial
     */
    'county'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientesPartial
     */
    'customerFilter_FilterOnly'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientesPartial
     */
    'customerName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientesPartial
     */
    'customerNo'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientesPartial
     */
    'eMail'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientesPartial
     */
    'filtercategories'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientesPartial
     */
    'lastDateTimeCreated'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientesPartial
     */
    'lastDateTimeModified'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientesPartial
     */
    'markFilter'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientesPartial
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientesPartial
     */
    'name2'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientesPartial
     */
    'phoneNo'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientesPartial
     */
    'postCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientesPartial
     */
    'referenceStatus'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientesPartial
     */
    'shippingIncluded'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientesPartial
     */
    'shipToCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientesPartial
     */
    'shop'?: string;
    /**
     * 
     * @type {number}
     * @memberof ClientesPartial
     */
    'companyId'?: number;
}
/**
 * (tsType: ClientesWithRelations, schemaOptions: { includeRelations: true })
 * @export
 * @interface ClientesWithRelations
 */
export interface ClientesWithRelations {
    /**
     * 
     * @type {number}
     * @memberof ClientesWithRelations
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ClientesWithRelations
     */
    'address'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientesWithRelations
     */
    'address2'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientesWithRelations
     */
    'allowStock'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientesWithRelations
     */
    'auxiliaryIndex1'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientesWithRelations
     */
    'billToCustomerNo'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientesWithRelations
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientesWithRelations
     */
    'contact'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientesWithRelations
     */
    'country'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientesWithRelations
     */
    'county'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientesWithRelations
     */
    'customerFilter_FilterOnly'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientesWithRelations
     */
    'customerName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientesWithRelations
     */
    'customerNo'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientesWithRelations
     */
    'eMail'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientesWithRelations
     */
    'filtercategories'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientesWithRelations
     */
    'lastDateTimeCreated'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientesWithRelations
     */
    'lastDateTimeModified'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientesWithRelations
     */
    'markFilter'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientesWithRelations
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientesWithRelations
     */
    'name2'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientesWithRelations
     */
    'phoneNo'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientesWithRelations
     */
    'postCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientesWithRelations
     */
    'referenceStatus'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientesWithRelations
     */
    'shippingIncluded'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientesWithRelations
     */
    'shipToCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientesWithRelations
     */
    'shop'?: string;
    /**
     * 
     * @type {number}
     * @memberof ClientesWithRelations
     */
    'companyId'?: number;
}
/**
 * 
 * @export
 * @interface Companies
 */
export interface Companies {
    /**
     * 
     * @type {number}
     * @memberof Companies
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Companies
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Companies
     */
    'image'?: string;
    /**
     * 
     * @type {string}
     * @memberof Companies
     */
    'icon'?: string;
    /**
     * 
     * @type {string}
     * @memberof Companies
     */
    'correo'?: string;
    /**
     * 
     * @type {string}
     * @memberof Companies
     */
    'tituloWeb'?: string;
    /**
     * 
     * @type {string}
     * @memberof Companies
     */
    'colorFondoBoton'?: string;
    /**
     * 
     * @type {string}
     * @memberof Companies
     */
    'colorTextoBoton'?: string;
    /**
     * 
     * @type {string}
     * @memberof Companies
     */
    'colorHoverBoton'?: string;
    /**
     * 
     * @type {string}
     * @memberof Companies
     */
    'colorNavBar'?: string;
    /**
     * 
     * @type {string}
     * @memberof Companies
     */
    'colorBotonNavBar'?: string;
    /**
     * 
     * @type {string}
     * @memberof Companies
     */
    'colorTextoBotonNavBar'?: string;
    /**
     * 
     * @type {string}
     * @memberof Companies
     */
    'colorHoverNavBar'?: string;
    /**
     * 
     * @type {string}
     * @memberof Companies
     */
    'colorFondoWeb'?: string;
    /**
     * 
     * @type {string}
     * @memberof Companies
     */
    'idCompany'?: string;
}
/**
 * 
 * @export
 * @interface CompaniesFilter
 */
export interface CompaniesFilter {
    /**
     * 
     * @type {number}
     * @memberof CompaniesFilter
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof CompaniesFilter
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof CompaniesFilter
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof CompaniesFilter
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {object | Set<string>}
     * @memberof CompaniesFilter
     */
    'fields'?: object | Set<string>;
}
/**
 * 
 * @export
 * @interface CompaniesFilter1
 */
export interface CompaniesFilter1 {
    /**
     * 
     * @type {number}
     * @memberof CompaniesFilter1
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof CompaniesFilter1
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof CompaniesFilter1
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof CompaniesFilter1
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof CompaniesFilter1
     */
    'where'?: { [key: string]: object; };
    /**
     * 
     * @type {object | Set<string>}
     * @memberof CompaniesFilter1
     */
    'fields'?: object | Set<string>;
}
/**
 * (tsType: Partial<Companies>, schemaOptions: { partial: true })
 * @export
 * @interface CompaniesPartial
 */
export interface CompaniesPartial {
    /**
     * 
     * @type {number}
     * @memberof CompaniesPartial
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof CompaniesPartial
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompaniesPartial
     */
    'image'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompaniesPartial
     */
    'icon'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompaniesPartial
     */
    'correo'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompaniesPartial
     */
    'tituloWeb'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompaniesPartial
     */
    'colorFondoBoton'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompaniesPartial
     */
    'colorTextoBoton'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompaniesPartial
     */
    'colorHoverBoton'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompaniesPartial
     */
    'colorNavBar'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompaniesPartial
     */
    'colorBotonNavBar'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompaniesPartial
     */
    'colorTextoBotonNavBar'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompaniesPartial
     */
    'colorHoverNavBar'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompaniesPartial
     */
    'colorFondoWeb'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompaniesPartial
     */
    'idCompany'?: string;
}
/**
 * (tsType: CompaniesWithRelations, schemaOptions: { includeRelations: true })
 * @export
 * @interface CompaniesWithRelations
 */
export interface CompaniesWithRelations {
    /**
     * 
     * @type {number}
     * @memberof CompaniesWithRelations
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof CompaniesWithRelations
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompaniesWithRelations
     */
    'image'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompaniesWithRelations
     */
    'icon'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompaniesWithRelations
     */
    'correo'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompaniesWithRelations
     */
    'tituloWeb'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompaniesWithRelations
     */
    'colorFondoBoton'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompaniesWithRelations
     */
    'colorTextoBoton'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompaniesWithRelations
     */
    'colorHoverBoton'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompaniesWithRelations
     */
    'colorNavBar'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompaniesWithRelations
     */
    'colorBotonNavBar'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompaniesWithRelations
     */
    'colorTextoBotonNavBar'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompaniesWithRelations
     */
    'colorHoverNavBar'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompaniesWithRelations
     */
    'colorFondoWeb'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompaniesWithRelations
     */
    'idCompany'?: string;
}
/**
 * (tsType: Email, schemaOptions: { title: \'Email\' })
 * @export
 * @interface Email
 */
export interface Email {
    /**
     * 
     * @type {string}
     * @memberof Email
     */
    'from': string;
    /**
     * 
     * @type {string}
     * @memberof Email
     */
    'to': string;
    /**
     * 
     * @type {string}
     * @memberof Email
     */
    'subject': string;
    /**
     * 
     * @type {string}
     * @memberof Email
     */
    'text'?: string;
    /**
     * 
     * @type {string}
     * @memberof Email
     */
    'html'?: string;
    /**
     * 
     * @type {Array<object>}
     * @memberof Email
     */
    'attachments'?: Array<object>;
}
/**
 * 
 * @export
 * @interface FailedLogins
 */
export interface FailedLogins {
    /**
     * 
     * @type {number}
     * @memberof FailedLogins
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof FailedLogins
     */
    'usersId'?: number;
    /**
     * 
     * @type {string}
     * @memberof FailedLogins
     */
    'ipAddress'?: string;
    /**
     * 
     * @type {number}
     * @memberof FailedLogins
     */
    'attempted'?: number;
    /**
     * 
     * @type {string}
     * @memberof FailedLogins
     */
    'createdAt'?: string;
}
/**
 * 
 * @export
 * @interface FailedLoginsFilter
 */
export interface FailedLoginsFilter {
    /**
     * 
     * @type {number}
     * @memberof FailedLoginsFilter
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof FailedLoginsFilter
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof FailedLoginsFilter
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof FailedLoginsFilter
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {object | Set<string>}
     * @memberof FailedLoginsFilter
     */
    'fields'?: object | Set<string>;
}
/**
 * 
 * @export
 * @interface FailedLoginsFilter1
 */
export interface FailedLoginsFilter1 {
    /**
     * 
     * @type {number}
     * @memberof FailedLoginsFilter1
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof FailedLoginsFilter1
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof FailedLoginsFilter1
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof FailedLoginsFilter1
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof FailedLoginsFilter1
     */
    'where'?: { [key: string]: object; };
    /**
     * 
     * @type {object | Set<string>}
     * @memberof FailedLoginsFilter1
     */
    'fields'?: object | Set<string>;
}
/**
 * (tsType: Partial<FailedLogins>, schemaOptions: { partial: true })
 * @export
 * @interface FailedLoginsPartial
 */
export interface FailedLoginsPartial {
    /**
     * 
     * @type {number}
     * @memberof FailedLoginsPartial
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof FailedLoginsPartial
     */
    'usersId'?: number;
    /**
     * 
     * @type {string}
     * @memberof FailedLoginsPartial
     */
    'ipAddress'?: string;
    /**
     * 
     * @type {number}
     * @memberof FailedLoginsPartial
     */
    'attempted'?: number;
    /**
     * 
     * @type {string}
     * @memberof FailedLoginsPartial
     */
    'createdAt'?: string;
}
/**
 * (tsType: FailedLoginsWithRelations, schemaOptions: { includeRelations: true })
 * @export
 * @interface FailedLoginsWithRelations
 */
export interface FailedLoginsWithRelations {
    /**
     * 
     * @type {number}
     * @memberof FailedLoginsWithRelations
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof FailedLoginsWithRelations
     */
    'usersId'?: number;
    /**
     * 
     * @type {string}
     * @memberof FailedLoginsWithRelations
     */
    'ipAddress'?: string;
    /**
     * 
     * @type {number}
     * @memberof FailedLoginsWithRelations
     */
    'attempted'?: number;
    /**
     * 
     * @type {string}
     * @memberof FailedLoginsWithRelations
     */
    'createdAt'?: string;
}
/**
 * 
 * @export
 * @interface Files
 */
export interface Files {
    /**
     * 
     * @type {number}
     * @memberof Files
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Files
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof Files
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof Files
     */
    'extension'?: string;
    /**
     * 
     * @type {number}
     * @memberof Files
     */
    'size'?: number;
    /**
     * 
     * @type {string}
     * @memberof Files
     */
    'src'?: string;
    /**
     * 
     * @type {string}
     * @memberof Files
     */
    'hash'?: string;
    /**
     * 
     * @type {string}
     * @memberof Files
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Files
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Files
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof Files
     */
    'alt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Files
     */
    'modifiedAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof Files
     */
    'userCreator'?: number;
    /**
     * 
     * @type {number}
     * @memberof Files
     */
    'userModifier'?: number;
    /**
     * 
     * @type {string}
     * @memberof Files
     */
    'deletedAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof Files
     */
    'userDeleted'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Files
     */
    'deleted'?: boolean;
}
/**
 * 
 * @export
 * @interface FilesFilter
 */
export interface FilesFilter {
    /**
     * 
     * @type {number}
     * @memberof FilesFilter
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof FilesFilter
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof FilesFilter
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof FilesFilter
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {object | Set<string>}
     * @memberof FilesFilter
     */
    'fields'?: object | Set<string>;
}
/**
 * 
 * @export
 * @interface FilesFilter1
 */
export interface FilesFilter1 {
    /**
     * 
     * @type {number}
     * @memberof FilesFilter1
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof FilesFilter1
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof FilesFilter1
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof FilesFilter1
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof FilesFilter1
     */
    'where'?: { [key: string]: object; };
    /**
     * 
     * @type {object | Set<string>}
     * @memberof FilesFilter1
     */
    'fields'?: object | Set<string>;
}
/**
 * (tsType: Partial<Files>, schemaOptions: { partial: true })
 * @export
 * @interface FilesPartial
 */
export interface FilesPartial {
    /**
     * 
     * @type {number}
     * @memberof FilesPartial
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FilesPartial
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof FilesPartial
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof FilesPartial
     */
    'extension'?: string;
    /**
     * 
     * @type {number}
     * @memberof FilesPartial
     */
    'size'?: number;
    /**
     * 
     * @type {string}
     * @memberof FilesPartial
     */
    'src'?: string;
    /**
     * 
     * @type {string}
     * @memberof FilesPartial
     */
    'hash'?: string;
    /**
     * 
     * @type {string}
     * @memberof FilesPartial
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof FilesPartial
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof FilesPartial
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof FilesPartial
     */
    'alt'?: string;
    /**
     * 
     * @type {string}
     * @memberof FilesPartial
     */
    'modifiedAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof FilesPartial
     */
    'userCreator'?: number;
    /**
     * 
     * @type {number}
     * @memberof FilesPartial
     */
    'userModifier'?: number;
    /**
     * 
     * @type {string}
     * @memberof FilesPartial
     */
    'deletedAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof FilesPartial
     */
    'userDeleted'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof FilesPartial
     */
    'deleted'?: boolean;
}
/**
 * (tsType: FilesWithRelations, schemaOptions: { includeRelations: true })
 * @export
 * @interface FilesWithRelations
 */
export interface FilesWithRelations {
    /**
     * 
     * @type {number}
     * @memberof FilesWithRelations
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FilesWithRelations
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof FilesWithRelations
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof FilesWithRelations
     */
    'extension'?: string;
    /**
     * 
     * @type {number}
     * @memberof FilesWithRelations
     */
    'size'?: number;
    /**
     * 
     * @type {string}
     * @memberof FilesWithRelations
     */
    'src'?: string;
    /**
     * 
     * @type {string}
     * @memberof FilesWithRelations
     */
    'hash'?: string;
    /**
     * 
     * @type {string}
     * @memberof FilesWithRelations
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof FilesWithRelations
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof FilesWithRelations
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof FilesWithRelations
     */
    'alt'?: string;
    /**
     * 
     * @type {string}
     * @memberof FilesWithRelations
     */
    'modifiedAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof FilesWithRelations
     */
    'userCreator'?: number;
    /**
     * 
     * @type {number}
     * @memberof FilesWithRelations
     */
    'userModifier'?: number;
    /**
     * 
     * @type {string}
     * @memberof FilesWithRelations
     */
    'deletedAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof FilesWithRelations
     */
    'userDeleted'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof FilesWithRelations
     */
    'deleted'?: boolean;
}
/**
 * 
 * @export
 * @interface InlineObject
 */
export interface InlineObject {
    /**
     * 
     * @type {string}
     * @memberof InlineObject
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface InlineObject1
 */
export interface InlineObject1 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject1
     */
    'refreshToken': string;
}
/**
 * 
 * @export
 * @interface InlineObject4
 */
export interface InlineObject4 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject4
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject4
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface InlineResponse200
 */
export interface InlineResponse200 {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse200
     */
    'token'?: string;
}
/**
 * 
 * @export
 * @interface InlineResponse2001
 */
export interface InlineResponse2001 {
    /**
     * 
     * @type {object}
     * @memberof InlineResponse2001
     */
    'accessToken'?: object;
}
/**
 * 
 * @export
 * @interface InlineResponse2002
 */
export interface InlineResponse2002 {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2002
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface InlineResponse2003
 */
export interface InlineResponse2003 {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2003
     */
    'accessToken'?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2003
     */
    'refreshToken'?: string;
}
/**
 * 
 * @export
 * @interface Languages
 */
export interface Languages {
    /**
     * 
     * @type {number}
     * @memberof Languages
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Languages
     */
    'lang'?: string;
    /**
     * 
     * @type {string}
     * @memberof Languages
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof Languages
     */
    'locale'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Languages
     */
    'active'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Languages
     */
    'orden'?: number;
}
/**
 * 
 * @export
 * @interface LanguagesFilter
 */
export interface LanguagesFilter {
    /**
     * 
     * @type {number}
     * @memberof LanguagesFilter
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof LanguagesFilter
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof LanguagesFilter
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof LanguagesFilter
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {object | Set<string>}
     * @memberof LanguagesFilter
     */
    'fields'?: object | Set<string>;
}
/**
 * 
 * @export
 * @interface LanguagesFilter1
 */
export interface LanguagesFilter1 {
    /**
     * 
     * @type {number}
     * @memberof LanguagesFilter1
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof LanguagesFilter1
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof LanguagesFilter1
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof LanguagesFilter1
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof LanguagesFilter1
     */
    'where'?: { [key: string]: object; };
    /**
     * 
     * @type {object | Set<string>}
     * @memberof LanguagesFilter1
     */
    'fields'?: object | Set<string>;
}
/**
 * (tsType: Partial<Languages>, schemaOptions: { partial: true })
 * @export
 * @interface LanguagesPartial
 */
export interface LanguagesPartial {
    /**
     * 
     * @type {number}
     * @memberof LanguagesPartial
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof LanguagesPartial
     */
    'lang'?: string;
    /**
     * 
     * @type {string}
     * @memberof LanguagesPartial
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof LanguagesPartial
     */
    'locale'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof LanguagesPartial
     */
    'active'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof LanguagesPartial
     */
    'orden'?: number;
}
/**
 * (tsType: LanguagesWithRelations, schemaOptions: { includeRelations: true })
 * @export
 * @interface LanguagesWithRelations
 */
export interface LanguagesWithRelations {
    /**
     * 
     * @type {number}
     * @memberof LanguagesWithRelations
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof LanguagesWithRelations
     */
    'lang'?: string;
    /**
     * 
     * @type {string}
     * @memberof LanguagesWithRelations
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof LanguagesWithRelations
     */
    'locale'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof LanguagesWithRelations
     */
    'active'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof LanguagesWithRelations
     */
    'orden'?: number;
}
/**
 * 
 * @export
 * @interface Log
 */
export interface Log {
    /**
     * 
     * @type {number}
     * @memberof Log
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Log
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Log
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof Log
     */
    'process'?: string;
    /**
     * 
     * @type {string}
     * @memberof Log
     */
    'action'?: string;
}
/**
 * 
 * @export
 * @interface LogFilter
 */
export interface LogFilter {
    /**
     * 
     * @type {number}
     * @memberof LogFilter
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof LogFilter
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof LogFilter
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof LogFilter
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {object | Set<string>}
     * @memberof LogFilter
     */
    'fields'?: object | Set<string>;
}
/**
 * 
 * @export
 * @interface LogFilter1
 */
export interface LogFilter1 {
    /**
     * 
     * @type {number}
     * @memberof LogFilter1
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof LogFilter1
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof LogFilter1
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof LogFilter1
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof LogFilter1
     */
    'where'?: { [key: string]: object; };
    /**
     * 
     * @type {object | Set<string>}
     * @memberof LogFilter1
     */
    'fields'?: object | Set<string>;
}
/**
 * 
 * @export
 * @interface LogNotFound
 */
export interface LogNotFound {
    /**
     * 
     * @type {number}
     * @memberof LogNotFound
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof LogNotFound
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof LogNotFound
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof LogNotFound
     */
    'userName'?: string;
    /**
     * 
     * @type {string}
     * @memberof LogNotFound
     */
    'userRolesId'?: string;
    /**
     * 
     * @type {string}
     * @memberof LogNotFound
     */
    'empresaName'?: string;
    /**
     * 
     * @type {string}
     * @memberof LogNotFound
     */
    'url'?: string;
}
/**
 * 
 * @export
 * @interface LogNotFoundFilter
 */
export interface LogNotFoundFilter {
    /**
     * 
     * @type {number}
     * @memberof LogNotFoundFilter
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof LogNotFoundFilter
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof LogNotFoundFilter
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof LogNotFoundFilter
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {object | Set<string>}
     * @memberof LogNotFoundFilter
     */
    'fields'?: object | Set<string>;
}
/**
 * 
 * @export
 * @interface LogNotFoundFilter1
 */
export interface LogNotFoundFilter1 {
    /**
     * 
     * @type {number}
     * @memberof LogNotFoundFilter1
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof LogNotFoundFilter1
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof LogNotFoundFilter1
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof LogNotFoundFilter1
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof LogNotFoundFilter1
     */
    'where'?: { [key: string]: object; };
    /**
     * 
     * @type {object | Set<string>}
     * @memberof LogNotFoundFilter1
     */
    'fields'?: object | Set<string>;
}
/**
 * (tsType: Partial<LogNotFound>, schemaOptions: { partial: true })
 * @export
 * @interface LogNotFoundPartial
 */
export interface LogNotFoundPartial {
    /**
     * 
     * @type {number}
     * @memberof LogNotFoundPartial
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof LogNotFoundPartial
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof LogNotFoundPartial
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof LogNotFoundPartial
     */
    'userName'?: string;
    /**
     * 
     * @type {string}
     * @memberof LogNotFoundPartial
     */
    'userRolesId'?: string;
    /**
     * 
     * @type {string}
     * @memberof LogNotFoundPartial
     */
    'empresaName'?: string;
    /**
     * 
     * @type {string}
     * @memberof LogNotFoundPartial
     */
    'url'?: string;
}
/**
 * (tsType: LogNotFoundWithRelations, schemaOptions: { includeRelations: true })
 * @export
 * @interface LogNotFoundWithRelations
 */
export interface LogNotFoundWithRelations {
    /**
     * 
     * @type {number}
     * @memberof LogNotFoundWithRelations
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof LogNotFoundWithRelations
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof LogNotFoundWithRelations
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof LogNotFoundWithRelations
     */
    'userName'?: string;
    /**
     * 
     * @type {string}
     * @memberof LogNotFoundWithRelations
     */
    'userRolesId'?: string;
    /**
     * 
     * @type {string}
     * @memberof LogNotFoundWithRelations
     */
    'empresaName'?: string;
    /**
     * 
     * @type {string}
     * @memberof LogNotFoundWithRelations
     */
    'url'?: string;
}
/**
 * (tsType: Partial<Log>, schemaOptions: { partial: true })
 * @export
 * @interface LogPartial
 */
export interface LogPartial {
    /**
     * 
     * @type {number}
     * @memberof LogPartial
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof LogPartial
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof LogPartial
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof LogPartial
     */
    'process'?: string;
    /**
     * 
     * @type {string}
     * @memberof LogPartial
     */
    'action'?: string;
}
/**
 * (tsType: LogWithRelations, schemaOptions: { includeRelations: true })
 * @export
 * @interface LogWithRelations
 */
export interface LogWithRelations {
    /**
     * 
     * @type {number}
     * @memberof LogWithRelations
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof LogWithRelations
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof LogWithRelations
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof LogWithRelations
     */
    'process'?: string;
    /**
     * 
     * @type {string}
     * @memberof LogWithRelations
     */
    'action'?: string;
}
/**
 * 
 * @export
 * @interface LoopbackCount
 */
export interface LoopbackCount {
    /**
     * 
     * @type {number}
     * @memberof LoopbackCount
     */
    'count'?: number;
}
/**
 * (tsType: @loopback/repository-json-schema#Optional<Omit<AnnouncementCategory, \'announcementId\'>, \'announcementId\'>, schemaOptions: { title: \'NewAnnouncementCategoryInAnnouncements\', exclude: [ \'announcementId\' ], optional: [ \'announcementId\' ] })
 * @export
 * @interface NewAnnouncementCategoryInAnnouncements
 */
export interface NewAnnouncementCategoryInAnnouncements {
    /**
     * 
     * @type {number}
     * @memberof NewAnnouncementCategoryInAnnouncements
     */
    'categoryId': number;
}
/**
 * (tsType: @loopback/repository-json-schema#Optional<Omit<AnnouncementMeta, \'id\'>, \'announcementId\'>, schemaOptions: { title: \'NewAnnouncementMetaInAnnouncements\', exclude: [ \'id\' ], optional: [ \'announcementId\' ] })
 * @export
 * @interface NewAnnouncementMetaInAnnouncements
 */
export interface NewAnnouncementMetaInAnnouncements {
    /**
     * 
     * @type {number}
     * @memberof NewAnnouncementMetaInAnnouncements
     */
    'announcementId'?: number;
    /**
     * 
     * @type {string}
     * @memberof NewAnnouncementMetaInAnnouncements
     */
    'key': string;
    /**
     * 
     * @type {string}
     * @memberof NewAnnouncementMetaInAnnouncements
     */
    'content'?: string;
}
/**
 * (tsType: Omit<Announcements, \'id\'>, schemaOptions: { title: \'NewAnnouncements\', exclude: [ \'id\' ] })
 * @export
 * @interface NewAnnouncements
 */
export interface NewAnnouncements {
    /**
     * 
     * @type {number}
     * @memberof NewAnnouncements
     */
    'parentId'?: number;
    /**
     * 
     * @type {string}
     * @memberof NewAnnouncements
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof NewAnnouncements
     */
    'metaTitle'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewAnnouncements
     */
    'slug': string;
    /**
     * 
     * @type {string}
     * @memberof NewAnnouncements
     */
    'summary'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewAnnouncements
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof NewAnnouncements
     */
    'vistoPor': string;
    /**
     * 
     * @type {number}
     * @memberof NewAnnouncements
     */
    'published': number;
    /**
     * 
     * @type {string}
     * @memberof NewAnnouncements
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof NewAnnouncements
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewAnnouncements
     */
    'publishedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewAnnouncements
     */
    'content'?: string;
    /**
     * 
     * @type {number}
     * @memberof NewAnnouncements
     */
    'authorId'?: number;
}
/**
 * (tsType: Omit<Categories, \'id\'>, schemaOptions: { title: \'NewCategories\', exclude: [ \'id\' ] })
 * @export
 * @interface NewCategories
 */
export interface NewCategories {
    /**
     * 
     * @type {number}
     * @memberof NewCategories
     */
    'parentId'?: number;
    /**
     * 
     * @type {string}
     * @memberof NewCategories
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof NewCategories
     */
    'metaTitle'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewCategories
     */
    'slug': string;
    /**
     * 
     * @type {string}
     * @memberof NewCategories
     */
    'content'?: string;
}
/**
 * (tsType: Omit<Clientes, \'id\'>, schemaOptions: { title: \'NewClientes\', exclude: [ \'id\' ] })
 * @export
 * @interface NewClientes
 */
export interface NewClientes {
    /**
     * 
     * @type {string}
     * @memberof NewClientes
     */
    'address'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewClientes
     */
    'address2'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewClientes
     */
    'allowStock'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewClientes
     */
    'auxiliaryIndex1'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewClientes
     */
    'billToCustomerNo'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewClientes
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewClientes
     */
    'contact'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewClientes
     */
    'country'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewClientes
     */
    'county'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewClientes
     */
    'customerFilter_FilterOnly'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewClientes
     */
    'customerName'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewClientes
     */
    'customerNo'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewClientes
     */
    'eMail'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewClientes
     */
    'filtercategories'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewClientes
     */
    'lastDateTimeCreated'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewClientes
     */
    'lastDateTimeModified'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewClientes
     */
    'markFilter'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewClientes
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewClientes
     */
    'name2'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewClientes
     */
    'phoneNo'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewClientes
     */
    'postCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewClientes
     */
    'referenceStatus'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewClientes
     */
    'shippingIncluded'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewClientes
     */
    'shipToCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewClientes
     */
    'shop'?: string;
    /**
     * 
     * @type {number}
     * @memberof NewClientes
     */
    'companyId'?: number;
}
/**
 * (tsType: Omit<Companies, \'id\'>, schemaOptions: { title: \'NewCompanies\', exclude: [ \'id\' ] })
 * @export
 * @interface NewCompanies
 */
export interface NewCompanies {
    /**
     * 
     * @type {string}
     * @memberof NewCompanies
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewCompanies
     */
    'image'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewCompanies
     */
    'icon'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewCompanies
     */
    'correo'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewCompanies
     */
    'tituloWeb'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewCompanies
     */
    'colorFondoBoton'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewCompanies
     */
    'colorTextoBoton'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewCompanies
     */
    'colorHoverBoton'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewCompanies
     */
    'colorNavBar'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewCompanies
     */
    'colorBotonNavBar'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewCompanies
     */
    'colorTextoBotonNavBar'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewCompanies
     */
    'colorHoverNavBar'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewCompanies
     */
    'colorFondoWeb'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewCompanies
     */
    'idCompany'?: string;
}
/**
 * (tsType: Omit<FailedLogins, \'id\'>, schemaOptions: { title: \'NewFailedLogins\', exclude: [ \'id\' ] })
 * @export
 * @interface NewFailedLogins
 */
export interface NewFailedLogins {
    /**
     * 
     * @type {number}
     * @memberof NewFailedLogins
     */
    'usersId'?: number;
    /**
     * 
     * @type {string}
     * @memberof NewFailedLogins
     */
    'ipAddress'?: string;
    /**
     * 
     * @type {number}
     * @memberof NewFailedLogins
     */
    'attempted'?: number;
    /**
     * 
     * @type {string}
     * @memberof NewFailedLogins
     */
    'createdAt'?: string;
}
/**
 * (tsType: Omit<Files, \'id\'>, schemaOptions: { title: \'NewFiles\', exclude: [ \'id\' ] })
 * @export
 * @interface NewFiles
 */
export interface NewFiles {
    /**
     * 
     * @type {string}
     * @memberof NewFiles
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewFiles
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewFiles
     */
    'extension'?: string;
    /**
     * 
     * @type {number}
     * @memberof NewFiles
     */
    'size'?: number;
    /**
     * 
     * @type {string}
     * @memberof NewFiles
     */
    'src'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewFiles
     */
    'hash'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewFiles
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewFiles
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewFiles
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewFiles
     */
    'alt'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewFiles
     */
    'modifiedAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof NewFiles
     */
    'userCreator'?: number;
    /**
     * 
     * @type {number}
     * @memberof NewFiles
     */
    'userModifier'?: number;
    /**
     * 
     * @type {string}
     * @memberof NewFiles
     */
    'deletedAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof NewFiles
     */
    'userDeleted'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof NewFiles
     */
    'deleted'?: boolean;
}
/**
 * (tsType: Omit<Languages, \'id\'>, schemaOptions: { title: \'NewLanguages\', exclude: [ \'id\' ] })
 * @export
 * @interface NewLanguages
 */
export interface NewLanguages {
    /**
     * 
     * @type {string}
     * @memberof NewLanguages
     */
    'lang'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewLanguages
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewLanguages
     */
    'locale'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof NewLanguages
     */
    'active'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof NewLanguages
     */
    'orden'?: number;
}
/**
 * (tsType: Omit<Log, \'id\'>, schemaOptions: { title: \'NewLog\', exclude: [ \'id\' ] })
 * @export
 * @interface NewLog
 */
export interface NewLog {
    /**
     * 
     * @type {string}
     * @memberof NewLog
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewLog
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewLog
     */
    'process'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewLog
     */
    'action'?: string;
}
/**
 * (tsType: Omit<LogNotFound, \'id\'>, schemaOptions: { title: \'NewLogNotFound\', exclude: [ \'id\' ] })
 * @export
 * @interface NewLogNotFound
 */
export interface NewLogNotFound {
    /**
     * 
     * @type {string}
     * @memberof NewLogNotFound
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewLogNotFound
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewLogNotFound
     */
    'userName'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewLogNotFound
     */
    'userRolesId'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewLogNotFound
     */
    'empresaName'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewLogNotFound
     */
    'url'?: string;
}
/**
 * (tsType: Omit<Observaciones, \'id\'>, schemaOptions: { title: \'NewObservaciones\', exclude: [ \'id\' ] })
 * @export
 * @interface NewObservaciones
 */
export interface NewObservaciones {
    /**
     * 
     * @type {string}
     * @memberof NewObservaciones
     */
    'oferta'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewObservaciones
     */
    'contenido'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewObservaciones
     */
    'cliente'?: string;
}
/**
 * (tsType: Omit<OdataLogs, \'id\'>, schemaOptions: { title: \'NewOdataLogs\', exclude: [ \'id\' ] })
 * @export
 * @interface NewOdataLogs
 */
export interface NewOdataLogs {
    /**
     * 
     * @type {string}
     * @memberof NewOdataLogs
     */
    'url': string;
    /**
     * 
     * @type {string}
     * @memberof NewOdataLogs
     */
    'fecha': string;
    /**
     * 
     * @type {string}
     * @memberof NewOdataLogs
     */
    'result'?: string;
    /**
     * 
     * @type {number}
     * @memberof NewOdataLogs
     */
    'userId'?: number;
    /**
     * 
     * @type {string}
     * @memberof NewOdataLogs
     */
    'empresaName'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewOdataLogs
     */
    'endPoint'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewOdataLogs
     */
    'type'?: string;
}
/**
 * (tsType: Omit<PasswordChanges, \'id\'>, schemaOptions: { title: \'NewPasswordChanges\', exclude: [ \'id\' ] })
 * @export
 * @interface NewPasswordChanges
 */
export interface NewPasswordChanges {
    /**
     * 
     * @type {number}
     * @memberof NewPasswordChanges
     */
    'usersId': number;
    /**
     * 
     * @type {string}
     * @memberof NewPasswordChanges
     */
    'ipAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewPasswordChanges
     */
    'userAgent'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewPasswordChanges
     */
    'createdAt'?: string;
}
/**
 * (tsType: Omit<PermissionsLogs, \'id\'>, schemaOptions: { title: \'NewPermissionsLogs\', exclude: [ \'id\' ] })
 * @export
 * @interface NewPermissionsLogs
 */
export interface NewPermissionsLogs {
    /**
     * 
     * @type {string}
     * @memberof NewPermissionsLogs
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof NewPermissionsLogs
     */
    'userCreator'?: number;
    /**
     * 
     * @type {string}
     * @memberof NewPermissionsLogs
     */
    'dataBefore': string;
    /**
     * 
     * @type {string}
     * @memberof NewPermissionsLogs
     */
    'dataAfter': string;
}
/**
 * (tsType: Omit<Portes, \'id\'>, schemaOptions: { title: \'NewPortes\', exclude: [ \'id\' ] })
 * @export
 * @interface NewPortes
 */
export interface NewPortes {
    /**
     * 
     * @type {string}
     * @memberof NewPortes
     */
    'countryRegionCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewPortes
     */
    'countyCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewPortes
     */
    'categoryCode'?: string;
    /**
     * 
     * @type {number}
     * @memberof NewPortes
     */
    'unitPrice'?: number;
    /**
     * 
     * @type {number}
     * @memberof NewPortes
     */
    'companyId'?: number;
}
/**
 * (tsType: Omit<PromotionMeta, \'id\'>, schemaOptions: { title: \'NewPromotionMeta\', exclude: [ \'id\' ] })
 * @export
 * @interface NewPromotionMeta
 */
export interface NewPromotionMeta {
    /**
     * 
     * @type {number}
     * @memberof NewPromotionMeta
     */
    'promotionId': number;
    /**
     * 
     * @type {string}
     * @memberof NewPromotionMeta
     */
    'key': string;
    /**
     * 
     * @type {string}
     * @memberof NewPromotionMeta
     */
    'content'?: string;
}
/**
 * (tsType: @loopback/repository-json-schema#Optional<Omit<PromotionMeta, \'id\'>, \'promotionId\'>, schemaOptions: { title: \'NewPromotionMetaInPromotions\', exclude: [ \'id\' ], optional: [ \'promotionId\' ] })
 * @export
 * @interface NewPromotionMetaInPromotions
 */
export interface NewPromotionMetaInPromotions {
    /**
     * 
     * @type {number}
     * @memberof NewPromotionMetaInPromotions
     */
    'promotionId'?: number;
    /**
     * 
     * @type {string}
     * @memberof NewPromotionMetaInPromotions
     */
    'key': string;
    /**
     * 
     * @type {string}
     * @memberof NewPromotionMetaInPromotions
     */
    'content'?: string;
}
/**
 * (tsType: Omit<Promotions, \'id\'>, schemaOptions: { title: \'NewPromotions\', exclude: [ \'id\' ] })
 * @export
 * @interface NewPromotions
 */
export interface NewPromotions {
    /**
     * 
     * @type {number}
     * @memberof NewPromotions
     */
    'parentId'?: number;
    /**
     * 
     * @type {string}
     * @memberof NewPromotions
     */
    'vistoPor': string;
    /**
     * 
     * @type {string}
     * @memberof NewPromotions
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof NewPromotions
     */
    'metaTitle'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewPromotions
     */
    'slug': string;
    /**
     * 
     * @type {string}
     * @memberof NewPromotions
     */
    'summary'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof NewPromotions
     */
    'special'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof NewPromotions
     */
    'status': string;
    /**
     * 
     * @type {number}
     * @memberof NewPromotions
     */
    'published': number;
    /**
     * 
     * @type {string}
     * @memberof NewPromotions
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof NewPromotions
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewPromotions
     */
    'publishedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewPromotions
     */
    'content'?: string;
    /**
     * 
     * @type {number}
     * @memberof NewPromotions
     */
    'authorId'?: number;
}
/**
 * (tsType: Omit<RememberTokens, \'id\'>, schemaOptions: { title: \'NewRememberTokens\', exclude: [ \'id\' ] })
 * @export
 * @interface NewRememberTokens
 */
export interface NewRememberTokens {
    /**
     * 
     * @type {number}
     * @memberof NewRememberTokens
     */
    'usersId': number;
    /**
     * 
     * @type {string}
     * @memberof NewRememberTokens
     */
    'token'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewRememberTokens
     */
    'userAgent'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewRememberTokens
     */
    'createdAt': string;
}
/**
 * (tsType: Omit<ResetPasswords, \'id\'>, schemaOptions: { title: \'NewResetPasswords\', exclude: [ \'id\' ] })
 * @export
 * @interface NewResetPasswords
 */
export interface NewResetPasswords {
    /**
     * 
     * @type {number}
     * @memberof NewResetPasswords
     */
    'usersId': number;
    /**
     * 
     * @type {string}
     * @memberof NewResetPasswords
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewResetPasswords
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof NewResetPasswords
     */
    'modifiedAt'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof NewResetPasswords
     */
    'reset'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof NewResetPasswords
     */
    'ipAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewResetPasswords
     */
    'userAgent'?: string;
}
/**
 * (tsType: Omit<Roles, \'id\'>, schemaOptions: { title: \'NewRoles\', exclude: [ \'id\' ] })
 * @export
 * @interface NewRoles
 */
export interface NewRoles {
    /**
     * 
     * @type {string}
     * @memberof NewRoles
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewRoles
     */
    'tipoUsuario'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof NewRoles
     */
    'active'?: boolean;
}
/**
 * (tsType: Omit<RolesPermissions, \'id\'>, schemaOptions: { title: \'NewRolesPermissions\', exclude: [ \'id\' ] })
 * @export
 * @interface NewRolesPermissions
 */
export interface NewRolesPermissions {
    /**
     * 
     * @type {string}
     * @memberof NewRolesPermissions
     */
    'moduleName'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewRolesPermissions
     */
    'controllerName'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewRolesPermissions
     */
    'actionName'?: string;
    /**
     * 
     * @type {number}
     * @memberof NewRolesPermissions
     */
    'rolesId'?: number;
}
/**
 * (tsType: @loopback/repository-json-schema#Optional<Omit<RolesPermissions, \'id\'>, \'rolesId\'>, schemaOptions: { title: \'NewRolesPermissionsInRoles\', exclude: [ \'id\' ], optional: [ \'rolesId\' ] })
 * @export
 * @interface NewRolesPermissionsInRoles
 */
export interface NewRolesPermissionsInRoles {
    /**
     * 
     * @type {string}
     * @memberof NewRolesPermissionsInRoles
     */
    'moduleName'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewRolesPermissionsInRoles
     */
    'controllerName'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewRolesPermissionsInRoles
     */
    'actionName'?: string;
    /**
     * 
     * @type {number}
     * @memberof NewRolesPermissionsInRoles
     */
    'rolesId'?: number;
}
/**
 * (tsType: Omit<SoapLogs, \'id\'>, schemaOptions: { title: \'NewSoapLogs\', exclude: [ \'id\' ] })
 * @export
 * @interface NewSoapLogs
 */
export interface NewSoapLogs {
    /**
     * 
     * @type {string}
     * @memberof NewSoapLogs
     */
    'url'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewSoapLogs
     */
    'fecha': string;
    /**
     * 
     * @type {string}
     * @memberof NewSoapLogs
     */
    'dateend': string;
    /**
     * 
     * @type {number}
     * @memberof NewSoapLogs
     */
    'seconds': number;
    /**
     * 
     * @type {string}
     * @memberof NewSoapLogs
     */
    'result'?: string;
    /**
     * 
     * @type {number}
     * @memberof NewSoapLogs
     */
    'userId'?: number;
    /**
     * 
     * @type {string}
     * @memberof NewSoapLogs
     */
    'empresaName'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewSoapLogs
     */
    'endPoint'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewSoapLogs
     */
    'params'?: string;
}
/**
 * (tsType: Omit<SuccessLogins, \'id\'>, schemaOptions: { title: \'NewSuccessLogins\', exclude: [ \'id\' ] })
 * @export
 * @interface NewSuccessLogins
 */
export interface NewSuccessLogins {
    /**
     * 
     * @type {number}
     * @memberof NewSuccessLogins
     */
    'usersId': number;
    /**
     * 
     * @type {string}
     * @memberof NewSuccessLogins
     */
    'ipAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewSuccessLogins
     */
    'userAgent'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewSuccessLogins
     */
    'createdAt': string;
}
/**
 * (tsType: Omit<Translations, \'id\'>, schemaOptions: { title: \'NewTranslations\', exclude: [ \'id\' ] })
 * @export
 * @interface NewTranslations
 */
export interface NewTranslations {
    /**
     * 
     * @type {string}
     * @memberof NewTranslations
     */
    'language'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewTranslations
     */
    'keyName'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewTranslations
     */
    'value'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewTranslations
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewTranslations
     */
    'modifiedAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof NewTranslations
     */
    'userDeleted'?: number;
    /**
     * 
     * @type {number}
     * @memberof NewTranslations
     */
    'userCreator'?: number;
    /**
     * 
     * @type {number}
     * @memberof NewTranslations
     */
    'userModifier'?: number;
    /**
     * 
     * @type {string}
     * @memberof NewTranslations
     */
    'deletedAt'?: string;
}
/**
 * (tsType: Omit<Users, \'id\'>, schemaOptions: { title: \'NewUsers\', exclude: [ \'id\' ] })
 * @export
 * @interface NewUsers
 */
export interface NewUsers {
    /**
     * 
     * @type {string}
     * @memberof NewUsers
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewUsers
     */
    'password'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof NewUsers
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof NewUsers
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewUsers
     */
    'modifiedAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof NewUsers
     */
    'userDeleted'?: number;
    /**
     * 
     * @type {number}
     * @memberof NewUsers
     */
    'userCreator'?: number;
    /**
     * 
     * @type {number}
     * @memberof NewUsers
     */
    'userModifier'?: number;
    /**
     * 
     * @type {string}
     * @memberof NewUsers
     */
    'deletedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewUsers
     */
    'userName'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof NewUsers
     */
    'notifMail'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NewUsers
     */
    'sendMail'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof NewUsers
     */
    'code'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof NewUsers
     */
    'used'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof NewUsers
     */
    'companyId'?: number;
    /**
     * 
     * @type {number}
     * @memberof NewUsers
     */
    'maximoDescuento'?: number;
    /**
     * 
     * @type {string}
     * @memberof NewUsers
     */
    'avatar'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewUsers
     */
    'codVendedorCliente'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewUsers
     */
    'tipoUsuario'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewUsers
     */
    'clienteDefecto'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewUsers
     */
    'tarifaDefecto'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewUsers
     */
    'notificaciones'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewUsers
     */
    'mailExtra1'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewUsers
     */
    'mailExtra2'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewUsers
     */
    'mailExtra3'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewUsers
     */
    'sat'?: string;
    /**
     * 
     * @type {number}
     * @memberof NewUsers
     */
    'rolesId'?: number;
    /**
     * 
     * @type {number}
     * @memberof NewUsers
     */
    'languagesId'?: number;
}
/**
 * (tsType: Omit<Vendedores, \'id\'>, schemaOptions: { title: \'NewVendedores\', exclude: [ \'id\' ] })
 * @export
 * @interface NewVendedores
 */
export interface NewVendedores {
    /**
     * 
     * @type {string}
     * @memberof NewVendedores
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewVendedores
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewVendedores
     */
    'lastDateTimeCreated'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewVendedores
     */
    'lastDateTimeModified'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewVendedores
     */
    'relatedCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewVendedores
     */
    'type'?: string;
    /**
     * 
     * @type {number}
     * @memberof NewVendedores
     */
    'companyId'?: number;
}
/**
 * (tsType: Omit<Version, \'id\'>, schemaOptions: { title: \'NewVersion\', exclude: [ \'id\' ] })
 * @export
 * @interface NewVersion
 */
export interface NewVersion {
    /**
     * 
     * @type {string}
     * @memberof NewVersion
     */
    'hash'?: string;
    /**
     * 
     * @type {number}
     * @memberof NewVersion
     */
    'version'?: number;
    /**
     * 
     * @type {string}
     * @memberof NewVersion
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewVersion
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewVersion
     */
    'modifiedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewVersion
     */
    'deletedAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof NewVersion
     */
    'userCreator'?: number;
    /**
     * 
     * @type {number}
     * @memberof NewVersion
     */
    'userModifier'?: number;
    /**
     * 
     * @type {number}
     * @memberof NewVersion
     */
    'userDeleted'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof NewVersion
     */
    'deleted'?: boolean;
}
/**
 * 
 * @export
 * @interface Observaciones
 */
export interface Observaciones {
    /**
     * 
     * @type {number}
     * @memberof Observaciones
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Observaciones
     */
    'oferta'?: string;
    /**
     * 
     * @type {string}
     * @memberof Observaciones
     */
    'contenido'?: string;
    /**
     * 
     * @type {string}
     * @memberof Observaciones
     */
    'cliente'?: string;
}
/**
 * 
 * @export
 * @interface ObservacionesFilter
 */
export interface ObservacionesFilter {
    /**
     * 
     * @type {number}
     * @memberof ObservacionesFilter
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof ObservacionesFilter
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof ObservacionesFilter
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof ObservacionesFilter
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {object | Set<string>}
     * @memberof ObservacionesFilter
     */
    'fields'?: object | Set<string>;
}
/**
 * 
 * @export
 * @interface ObservacionesFilter1
 */
export interface ObservacionesFilter1 {
    /**
     * 
     * @type {number}
     * @memberof ObservacionesFilter1
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof ObservacionesFilter1
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof ObservacionesFilter1
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof ObservacionesFilter1
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof ObservacionesFilter1
     */
    'where'?: { [key: string]: object; };
    /**
     * 
     * @type {object | Set<string>}
     * @memberof ObservacionesFilter1
     */
    'fields'?: object | Set<string>;
}
/**
 * (tsType: Partial<Observaciones>, schemaOptions: { partial: true })
 * @export
 * @interface ObservacionesPartial
 */
export interface ObservacionesPartial {
    /**
     * 
     * @type {number}
     * @memberof ObservacionesPartial
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ObservacionesPartial
     */
    'oferta'?: string;
    /**
     * 
     * @type {string}
     * @memberof ObservacionesPartial
     */
    'contenido'?: string;
    /**
     * 
     * @type {string}
     * @memberof ObservacionesPartial
     */
    'cliente'?: string;
}
/**
 * (tsType: ObservacionesWithRelations, schemaOptions: { includeRelations: true })
 * @export
 * @interface ObservacionesWithRelations
 */
export interface ObservacionesWithRelations {
    /**
     * 
     * @type {number}
     * @memberof ObservacionesWithRelations
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ObservacionesWithRelations
     */
    'oferta'?: string;
    /**
     * 
     * @type {string}
     * @memberof ObservacionesWithRelations
     */
    'contenido'?: string;
    /**
     * 
     * @type {string}
     * @memberof ObservacionesWithRelations
     */
    'cliente'?: string;
}
/**
 * 
 * @export
 * @interface OdataLogs
 */
export interface OdataLogs {
    /**
     * 
     * @type {number}
     * @memberof OdataLogs
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof OdataLogs
     */
    'url': string;
    /**
     * 
     * @type {string}
     * @memberof OdataLogs
     */
    'fecha': string;
    /**
     * 
     * @type {string}
     * @memberof OdataLogs
     */
    'result'?: string;
    /**
     * 
     * @type {number}
     * @memberof OdataLogs
     */
    'userId'?: number;
    /**
     * 
     * @type {string}
     * @memberof OdataLogs
     */
    'empresaName'?: string;
    /**
     * 
     * @type {string}
     * @memberof OdataLogs
     */
    'endPoint'?: string;
    /**
     * 
     * @type {string}
     * @memberof OdataLogs
     */
    'type'?: string;
}
/**
 * 
 * @export
 * @interface OdataLogsFilter
 */
export interface OdataLogsFilter {
    /**
     * 
     * @type {number}
     * @memberof OdataLogsFilter
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof OdataLogsFilter
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof OdataLogsFilter
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof OdataLogsFilter
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {object | Set<string>}
     * @memberof OdataLogsFilter
     */
    'fields'?: object | Set<string>;
}
/**
 * 
 * @export
 * @interface OdataLogsFilter1
 */
export interface OdataLogsFilter1 {
    /**
     * 
     * @type {number}
     * @memberof OdataLogsFilter1
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof OdataLogsFilter1
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof OdataLogsFilter1
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof OdataLogsFilter1
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof OdataLogsFilter1
     */
    'where'?: { [key: string]: object; };
    /**
     * 
     * @type {object | Set<string>}
     * @memberof OdataLogsFilter1
     */
    'fields'?: object | Set<string>;
}
/**
 * (tsType: Partial<OdataLogs>, schemaOptions: { partial: true })
 * @export
 * @interface OdataLogsPartial
 */
export interface OdataLogsPartial {
    /**
     * 
     * @type {number}
     * @memberof OdataLogsPartial
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof OdataLogsPartial
     */
    'url'?: string;
    /**
     * 
     * @type {string}
     * @memberof OdataLogsPartial
     */
    'fecha'?: string;
    /**
     * 
     * @type {string}
     * @memberof OdataLogsPartial
     */
    'result'?: string;
    /**
     * 
     * @type {number}
     * @memberof OdataLogsPartial
     */
    'userId'?: number;
    /**
     * 
     * @type {string}
     * @memberof OdataLogsPartial
     */
    'empresaName'?: string;
    /**
     * 
     * @type {string}
     * @memberof OdataLogsPartial
     */
    'endPoint'?: string;
    /**
     * 
     * @type {string}
     * @memberof OdataLogsPartial
     */
    'type'?: string;
}
/**
 * (tsType: OdataLogsWithRelations, schemaOptions: { includeRelations: true })
 * @export
 * @interface OdataLogsWithRelations
 */
export interface OdataLogsWithRelations {
    /**
     * 
     * @type {number}
     * @memberof OdataLogsWithRelations
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof OdataLogsWithRelations
     */
    'url': string;
    /**
     * 
     * @type {string}
     * @memberof OdataLogsWithRelations
     */
    'fecha': string;
    /**
     * 
     * @type {string}
     * @memberof OdataLogsWithRelations
     */
    'result'?: string;
    /**
     * 
     * @type {number}
     * @memberof OdataLogsWithRelations
     */
    'userId'?: number;
    /**
     * 
     * @type {string}
     * @memberof OdataLogsWithRelations
     */
    'empresaName'?: string;
    /**
     * 
     * @type {string}
     * @memberof OdataLogsWithRelations
     */
    'endPoint'?: string;
    /**
     * 
     * @type {string}
     * @memberof OdataLogsWithRelations
     */
    'type'?: string;
}
/**
 * 
 * @export
 * @interface PasswordChanges
 */
export interface PasswordChanges {
    /**
     * 
     * @type {number}
     * @memberof PasswordChanges
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof PasswordChanges
     */
    'usersId': number;
    /**
     * 
     * @type {string}
     * @memberof PasswordChanges
     */
    'ipAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof PasswordChanges
     */
    'userAgent'?: string;
    /**
     * 
     * @type {string}
     * @memberof PasswordChanges
     */
    'createdAt'?: string;
}
/**
 * 
 * @export
 * @interface PasswordChangesFilter
 */
export interface PasswordChangesFilter {
    /**
     * 
     * @type {number}
     * @memberof PasswordChangesFilter
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof PasswordChangesFilter
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof PasswordChangesFilter
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof PasswordChangesFilter
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {object | Set<string>}
     * @memberof PasswordChangesFilter
     */
    'fields'?: object | Set<string>;
}
/**
 * 
 * @export
 * @interface PasswordChangesFilter1
 */
export interface PasswordChangesFilter1 {
    /**
     * 
     * @type {number}
     * @memberof PasswordChangesFilter1
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof PasswordChangesFilter1
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof PasswordChangesFilter1
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof PasswordChangesFilter1
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof PasswordChangesFilter1
     */
    'where'?: { [key: string]: object; };
    /**
     * 
     * @type {object | Set<string>}
     * @memberof PasswordChangesFilter1
     */
    'fields'?: object | Set<string>;
}
/**
 * (tsType: Partial<PasswordChanges>, schemaOptions: { partial: true })
 * @export
 * @interface PasswordChangesPartial
 */
export interface PasswordChangesPartial {
    /**
     * 
     * @type {number}
     * @memberof PasswordChangesPartial
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof PasswordChangesPartial
     */
    'usersId'?: number;
    /**
     * 
     * @type {string}
     * @memberof PasswordChangesPartial
     */
    'ipAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof PasswordChangesPartial
     */
    'userAgent'?: string;
    /**
     * 
     * @type {string}
     * @memberof PasswordChangesPartial
     */
    'createdAt'?: string;
}
/**
 * (tsType: PasswordChangesWithRelations, schemaOptions: { includeRelations: true })
 * @export
 * @interface PasswordChangesWithRelations
 */
export interface PasswordChangesWithRelations {
    /**
     * 
     * @type {number}
     * @memberof PasswordChangesWithRelations
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof PasswordChangesWithRelations
     */
    'usersId': number;
    /**
     * 
     * @type {string}
     * @memberof PasswordChangesWithRelations
     */
    'ipAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof PasswordChangesWithRelations
     */
    'userAgent'?: string;
    /**
     * 
     * @type {string}
     * @memberof PasswordChangesWithRelations
     */
    'createdAt'?: string;
}
/**
 * 
 * @export
 * @interface PermissionsLogs
 */
export interface PermissionsLogs {
    /**
     * 
     * @type {number}
     * @memberof PermissionsLogs
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PermissionsLogs
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof PermissionsLogs
     */
    'userCreator'?: number;
    /**
     * 
     * @type {string}
     * @memberof PermissionsLogs
     */
    'dataBefore': string;
    /**
     * 
     * @type {string}
     * @memberof PermissionsLogs
     */
    'dataAfter': string;
}
/**
 * 
 * @export
 * @interface PermissionsLogsFilter
 */
export interface PermissionsLogsFilter {
    /**
     * 
     * @type {number}
     * @memberof PermissionsLogsFilter
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof PermissionsLogsFilter
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof PermissionsLogsFilter
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof PermissionsLogsFilter
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {object | Set<string>}
     * @memberof PermissionsLogsFilter
     */
    'fields'?: object | Set<string>;
}
/**
 * 
 * @export
 * @interface PermissionsLogsFilter1
 */
export interface PermissionsLogsFilter1 {
    /**
     * 
     * @type {number}
     * @memberof PermissionsLogsFilter1
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof PermissionsLogsFilter1
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof PermissionsLogsFilter1
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof PermissionsLogsFilter1
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof PermissionsLogsFilter1
     */
    'where'?: { [key: string]: object; };
    /**
     * 
     * @type {object | Set<string>}
     * @memberof PermissionsLogsFilter1
     */
    'fields'?: object | Set<string>;
}
/**
 * (tsType: Partial<PermissionsLogs>, schemaOptions: { partial: true })
 * @export
 * @interface PermissionsLogsPartial
 */
export interface PermissionsLogsPartial {
    /**
     * 
     * @type {number}
     * @memberof PermissionsLogsPartial
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PermissionsLogsPartial
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof PermissionsLogsPartial
     */
    'userCreator'?: number;
    /**
     * 
     * @type {string}
     * @memberof PermissionsLogsPartial
     */
    'dataBefore'?: string;
    /**
     * 
     * @type {string}
     * @memberof PermissionsLogsPartial
     */
    'dataAfter'?: string;
}
/**
 * (tsType: PermissionsLogsWithRelations, schemaOptions: { includeRelations: true })
 * @export
 * @interface PermissionsLogsWithRelations
 */
export interface PermissionsLogsWithRelations {
    /**
     * 
     * @type {number}
     * @memberof PermissionsLogsWithRelations
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PermissionsLogsWithRelations
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof PermissionsLogsWithRelations
     */
    'userCreator'?: number;
    /**
     * 
     * @type {string}
     * @memberof PermissionsLogsWithRelations
     */
    'dataBefore': string;
    /**
     * 
     * @type {string}
     * @memberof PermissionsLogsWithRelations
     */
    'dataAfter': string;
}
/**
 * 
 * @export
 * @interface PingResponse
 */
export interface PingResponse {
    /**
     * 
     * @type {string}
     * @memberof PingResponse
     */
    'greeting'?: string;
    /**
     * 
     * @type {string}
     * @memberof PingResponse
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof PingResponse
     */
    'url'?: string;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof PingResponse
     */
    'headers'?: { [key: string]: object; };
}
/**
 * 
 * @export
 * @interface Portes
 */
export interface Portes {
    /**
     * 
     * @type {number}
     * @memberof Portes
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Portes
     */
    'countryRegionCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof Portes
     */
    'countyCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof Portes
     */
    'categoryCode'?: string;
    /**
     * 
     * @type {number}
     * @memberof Portes
     */
    'unitPrice'?: number;
    /**
     * 
     * @type {number}
     * @memberof Portes
     */
    'companyId'?: number;
}
/**
 * 
 * @export
 * @interface PortesFilter
 */
export interface PortesFilter {
    /**
     * 
     * @type {number}
     * @memberof PortesFilter
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof PortesFilter
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof PortesFilter
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof PortesFilter
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {object | Set<string>}
     * @memberof PortesFilter
     */
    'fields'?: object | Set<string>;
}
/**
 * 
 * @export
 * @interface PortesFilter1
 */
export interface PortesFilter1 {
    /**
     * 
     * @type {number}
     * @memberof PortesFilter1
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof PortesFilter1
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof PortesFilter1
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof PortesFilter1
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof PortesFilter1
     */
    'where'?: { [key: string]: object; };
    /**
     * 
     * @type {object | Set<string>}
     * @memberof PortesFilter1
     */
    'fields'?: object | Set<string>;
}
/**
 * (tsType: Partial<Portes>, schemaOptions: { partial: true })
 * @export
 * @interface PortesPartial
 */
export interface PortesPartial {
    /**
     * 
     * @type {number}
     * @memberof PortesPartial
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PortesPartial
     */
    'countryRegionCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof PortesPartial
     */
    'countyCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof PortesPartial
     */
    'categoryCode'?: string;
    /**
     * 
     * @type {number}
     * @memberof PortesPartial
     */
    'unitPrice'?: number;
    /**
     * 
     * @type {number}
     * @memberof PortesPartial
     */
    'companyId'?: number;
}
/**
 * (tsType: PortesWithRelations, schemaOptions: { includeRelations: true })
 * @export
 * @interface PortesWithRelations
 */
export interface PortesWithRelations {
    /**
     * 
     * @type {number}
     * @memberof PortesWithRelations
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PortesWithRelations
     */
    'countryRegionCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof PortesWithRelations
     */
    'countyCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof PortesWithRelations
     */
    'categoryCode'?: string;
    /**
     * 
     * @type {number}
     * @memberof PortesWithRelations
     */
    'unitPrice'?: number;
    /**
     * 
     * @type {number}
     * @memberof PortesWithRelations
     */
    'companyId'?: number;
}
/**
 * 
 * @export
 * @interface PromotionMeta
 */
export interface PromotionMeta {
    /**
     * 
     * @type {number}
     * @memberof PromotionMeta
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof PromotionMeta
     */
    'promotionId': number;
    /**
     * 
     * @type {string}
     * @memberof PromotionMeta
     */
    'key': string;
    /**
     * 
     * @type {string}
     * @memberof PromotionMeta
     */
    'content'?: string;
}
/**
 * 
 * @export
 * @interface PromotionMetaFilter
 */
export interface PromotionMetaFilter {
    /**
     * 
     * @type {number}
     * @memberof PromotionMetaFilter
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof PromotionMetaFilter
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof PromotionMetaFilter
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof PromotionMetaFilter
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {object | Set<string>}
     * @memberof PromotionMetaFilter
     */
    'fields'?: object | Set<string>;
}
/**
 * 
 * @export
 * @interface PromotionMetaFilter1
 */
export interface PromotionMetaFilter1 {
    /**
     * 
     * @type {number}
     * @memberof PromotionMetaFilter1
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof PromotionMetaFilter1
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof PromotionMetaFilter1
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof PromotionMetaFilter1
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof PromotionMetaFilter1
     */
    'where'?: { [key: string]: object; };
    /**
     * 
     * @type {object | Set<string>}
     * @memberof PromotionMetaFilter1
     */
    'fields'?: object | Set<string>;
}
/**
 * (tsType: Partial<PromotionMeta>, schemaOptions: { partial: true })
 * @export
 * @interface PromotionMetaPartial
 */
export interface PromotionMetaPartial {
    /**
     * 
     * @type {number}
     * @memberof PromotionMetaPartial
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof PromotionMetaPartial
     */
    'promotionId'?: number;
    /**
     * 
     * @type {string}
     * @memberof PromotionMetaPartial
     */
    'key'?: string;
    /**
     * 
     * @type {string}
     * @memberof PromotionMetaPartial
     */
    'content'?: string;
}
/**
 * (tsType: PromotionMetaWithRelations, schemaOptions: { includeRelations: true })
 * @export
 * @interface PromotionMetaWithRelations
 */
export interface PromotionMetaWithRelations {
    /**
     * 
     * @type {number}
     * @memberof PromotionMetaWithRelations
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof PromotionMetaWithRelations
     */
    'promotionId': number;
    /**
     * 
     * @type {string}
     * @memberof PromotionMetaWithRelations
     */
    'key': string;
    /**
     * 
     * @type {string}
     * @memberof PromotionMetaWithRelations
     */
    'content'?: string;
}
/**
 * 
 * @export
 * @interface Promotions
 */
export interface Promotions {
    /**
     * 
     * @type {number}
     * @memberof Promotions
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof Promotions
     */
    'parentId'?: number;
    /**
     * 
     * @type {string}
     * @memberof Promotions
     */
    'vistoPor': string;
    /**
     * 
     * @type {string}
     * @memberof Promotions
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof Promotions
     */
    'metaTitle'?: string;
    /**
     * 
     * @type {string}
     * @memberof Promotions
     */
    'slug': string;
    /**
     * 
     * @type {string}
     * @memberof Promotions
     */
    'summary'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Promotions
     */
    'special'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Promotions
     */
    'status': string;
    /**
     * 
     * @type {number}
     * @memberof Promotions
     */
    'published': number;
    /**
     * 
     * @type {string}
     * @memberof Promotions
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Promotions
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Promotions
     */
    'publishedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Promotions
     */
    'content'?: string;
    /**
     * 
     * @type {number}
     * @memberof Promotions
     */
    'authorId'?: number;
}
/**
 * 
 * @export
 * @interface PromotionsFilter
 */
export interface PromotionsFilter {
    /**
     * 
     * @type {number}
     * @memberof PromotionsFilter
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof PromotionsFilter
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof PromotionsFilter
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof PromotionsFilter
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {object | Set<string>}
     * @memberof PromotionsFilter
     */
    'fields'?: object | Set<string>;
    /**
     * 
     * @type {Array<PromotionsIncludeFilterItems | string>}
     * @memberof PromotionsFilter
     */
    'include'?: Array<PromotionsIncludeFilterItems | string>;
}
/**
 * 
 * @export
 * @interface PromotionsFilter1
 */
export interface PromotionsFilter1 {
    /**
     * 
     * @type {number}
     * @memberof PromotionsFilter1
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof PromotionsFilter1
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof PromotionsFilter1
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof PromotionsFilter1
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof PromotionsFilter1
     */
    'where'?: { [key: string]: object; };
    /**
     * 
     * @type {object | Set<string>}
     * @memberof PromotionsFilter1
     */
    'fields'?: object | Set<string>;
    /**
     * 
     * @type {Array<PromotionsIncludeFilterItems | string>}
     * @memberof PromotionsFilter1
     */
    'include'?: Array<PromotionsIncludeFilterItems | string>;
}
/**
 * 
 * @export
 * @interface PromotionsIncludeFilterItems
 */
export interface PromotionsIncludeFilterItems {
    /**
     * 
     * @type {string}
     * @memberof PromotionsIncludeFilterItems
     */
    'relation'?: string;
    /**
     * 
     * @type {PromotionsScopeFilter}
     * @memberof PromotionsIncludeFilterItems
     */
    'scope'?: PromotionsScopeFilter;
}
/**
 * (tsType: Partial<Promotions>, schemaOptions: { partial: true })
 * @export
 * @interface PromotionsPartial
 */
export interface PromotionsPartial {
    /**
     * 
     * @type {number}
     * @memberof PromotionsPartial
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof PromotionsPartial
     */
    'parentId'?: number;
    /**
     * 
     * @type {string}
     * @memberof PromotionsPartial
     */
    'vistoPor'?: string;
    /**
     * 
     * @type {string}
     * @memberof PromotionsPartial
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof PromotionsPartial
     */
    'metaTitle'?: string;
    /**
     * 
     * @type {string}
     * @memberof PromotionsPartial
     */
    'slug'?: string;
    /**
     * 
     * @type {string}
     * @memberof PromotionsPartial
     */
    'summary'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PromotionsPartial
     */
    'special'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PromotionsPartial
     */
    'status'?: string;
    /**
     * 
     * @type {number}
     * @memberof PromotionsPartial
     */
    'published'?: number;
    /**
     * 
     * @type {string}
     * @memberof PromotionsPartial
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof PromotionsPartial
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof PromotionsPartial
     */
    'publishedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof PromotionsPartial
     */
    'content'?: string;
    /**
     * 
     * @type {number}
     * @memberof PromotionsPartial
     */
    'authorId'?: number;
}
/**
 * 
 * @export
 * @interface PromotionsScopeFilter
 */
export interface PromotionsScopeFilter {
    /**
     * 
     * @type {number}
     * @memberof PromotionsScopeFilter
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof PromotionsScopeFilter
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof PromotionsScopeFilter
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof PromotionsScopeFilter
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof PromotionsScopeFilter
     */
    'where'?: { [key: string]: object; };
    /**
     * 
     * @type {any | Set<string>}
     * @memberof PromotionsScopeFilter
     */
    'fields'?: any | Set<string>;
    /**
     * 
     * @type {Array<{ [key: string]: object; }>}
     * @memberof PromotionsScopeFilter
     */
    'include'?: Array<{ [key: string]: object; }>;
}
/**
 * (tsType: PromotionsWithRelations, schemaOptions: { includeRelations: true })
 * @export
 * @interface PromotionsWithRelations
 */
export interface PromotionsWithRelations {
    /**
     * 
     * @type {number}
     * @memberof PromotionsWithRelations
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof PromotionsWithRelations
     */
    'parentId'?: number;
    /**
     * 
     * @type {string}
     * @memberof PromotionsWithRelations
     */
    'vistoPor': string;
    /**
     * 
     * @type {string}
     * @memberof PromotionsWithRelations
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof PromotionsWithRelations
     */
    'metaTitle'?: string;
    /**
     * 
     * @type {string}
     * @memberof PromotionsWithRelations
     */
    'slug': string;
    /**
     * 
     * @type {string}
     * @memberof PromotionsWithRelations
     */
    'summary'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PromotionsWithRelations
     */
    'special'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PromotionsWithRelations
     */
    'status': string;
    /**
     * 
     * @type {number}
     * @memberof PromotionsWithRelations
     */
    'published': number;
    /**
     * 
     * @type {string}
     * @memberof PromotionsWithRelations
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof PromotionsWithRelations
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof PromotionsWithRelations
     */
    'publishedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof PromotionsWithRelations
     */
    'content'?: string;
    /**
     * 
     * @type {number}
     * @memberof PromotionsWithRelations
     */
    'authorId'?: number;
    /**
     * 
     * @type {Array<PromotionMetaWithRelations>}
     * @memberof PromotionsWithRelations
     */
    'promotionMetas'?: Array<PromotionMetaWithRelations>;
    /**
     * 
     * @type {UsersWithRelations}
     * @memberof PromotionsWithRelations
     */
    'author'?: UsersWithRelations;
}
/**
 * 
 * @export
 * @interface RememberTokens
 */
export interface RememberTokens {
    /**
     * 
     * @type {number}
     * @memberof RememberTokens
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof RememberTokens
     */
    'usersId': number;
    /**
     * 
     * @type {string}
     * @memberof RememberTokens
     */
    'token'?: string;
    /**
     * 
     * @type {string}
     * @memberof RememberTokens
     */
    'userAgent'?: string;
    /**
     * 
     * @type {string}
     * @memberof RememberTokens
     */
    'createdAt': string;
}
/**
 * 
 * @export
 * @interface RememberTokensFilter
 */
export interface RememberTokensFilter {
    /**
     * 
     * @type {number}
     * @memberof RememberTokensFilter
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof RememberTokensFilter
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof RememberTokensFilter
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof RememberTokensFilter
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {object | Set<string>}
     * @memberof RememberTokensFilter
     */
    'fields'?: object | Set<string>;
}
/**
 * 
 * @export
 * @interface RememberTokensFilter1
 */
export interface RememberTokensFilter1 {
    /**
     * 
     * @type {number}
     * @memberof RememberTokensFilter1
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof RememberTokensFilter1
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof RememberTokensFilter1
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof RememberTokensFilter1
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof RememberTokensFilter1
     */
    'where'?: { [key: string]: object; };
    /**
     * 
     * @type {object | Set<string>}
     * @memberof RememberTokensFilter1
     */
    'fields'?: object | Set<string>;
}
/**
 * (tsType: Partial<RememberTokens>, schemaOptions: { partial: true })
 * @export
 * @interface RememberTokensPartial
 */
export interface RememberTokensPartial {
    /**
     * 
     * @type {number}
     * @memberof RememberTokensPartial
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof RememberTokensPartial
     */
    'usersId'?: number;
    /**
     * 
     * @type {string}
     * @memberof RememberTokensPartial
     */
    'token'?: string;
    /**
     * 
     * @type {string}
     * @memberof RememberTokensPartial
     */
    'userAgent'?: string;
    /**
     * 
     * @type {string}
     * @memberof RememberTokensPartial
     */
    'createdAt'?: string;
}
/**
 * (tsType: RememberTokensWithRelations, schemaOptions: { includeRelations: true })
 * @export
 * @interface RememberTokensWithRelations
 */
export interface RememberTokensWithRelations {
    /**
     * 
     * @type {number}
     * @memberof RememberTokensWithRelations
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof RememberTokensWithRelations
     */
    'usersId': number;
    /**
     * 
     * @type {string}
     * @memberof RememberTokensWithRelations
     */
    'token'?: string;
    /**
     * 
     * @type {string}
     * @memberof RememberTokensWithRelations
     */
    'userAgent'?: string;
    /**
     * 
     * @type {string}
     * @memberof RememberTokensWithRelations
     */
    'createdAt': string;
}
/**
 * 
 * @export
 * @interface ResetPasswords
 */
export interface ResetPasswords {
    /**
     * 
     * @type {number}
     * @memberof ResetPasswords
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResetPasswords
     */
    'usersId': number;
    /**
     * 
     * @type {string}
     * @memberof ResetPasswords
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResetPasswords
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ResetPasswords
     */
    'modifiedAt'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ResetPasswords
     */
    'reset'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ResetPasswords
     */
    'ipAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResetPasswords
     */
    'userAgent'?: string;
}
/**
 * 
 * @export
 * @interface ResetPasswordsFilter
 */
export interface ResetPasswordsFilter {
    /**
     * 
     * @type {number}
     * @memberof ResetPasswordsFilter
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResetPasswordsFilter
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResetPasswordsFilter
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof ResetPasswordsFilter
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {object | Set<string>}
     * @memberof ResetPasswordsFilter
     */
    'fields'?: object | Set<string>;
}
/**
 * 
 * @export
 * @interface ResetPasswordsFilter1
 */
export interface ResetPasswordsFilter1 {
    /**
     * 
     * @type {number}
     * @memberof ResetPasswordsFilter1
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResetPasswordsFilter1
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResetPasswordsFilter1
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof ResetPasswordsFilter1
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof ResetPasswordsFilter1
     */
    'where'?: { [key: string]: object; };
    /**
     * 
     * @type {object | Set<string>}
     * @memberof ResetPasswordsFilter1
     */
    'fields'?: object | Set<string>;
}
/**
 * (tsType: Partial<ResetPasswords>, schemaOptions: { partial: true })
 * @export
 * @interface ResetPasswordsPartial
 */
export interface ResetPasswordsPartial {
    /**
     * 
     * @type {number}
     * @memberof ResetPasswordsPartial
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResetPasswordsPartial
     */
    'usersId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordsPartial
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordsPartial
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordsPartial
     */
    'modifiedAt'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ResetPasswordsPartial
     */
    'reset'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordsPartial
     */
    'ipAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordsPartial
     */
    'userAgent'?: string;
}
/**
 * (tsType: ResetPasswordsWithRelations, schemaOptions: { includeRelations: true })
 * @export
 * @interface ResetPasswordsWithRelations
 */
export interface ResetPasswordsWithRelations {
    /**
     * 
     * @type {number}
     * @memberof ResetPasswordsWithRelations
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResetPasswordsWithRelations
     */
    'usersId': number;
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordsWithRelations
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordsWithRelations
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordsWithRelations
     */
    'modifiedAt'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ResetPasswordsWithRelations
     */
    'reset'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordsWithRelations
     */
    'ipAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordsWithRelations
     */
    'userAgent'?: string;
}
/**
 * 
 * @export
 * @interface Roles
 */
export interface Roles {
    /**
     * 
     * @type {number}
     * @memberof Roles
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Roles
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Roles
     */
    'tipoUsuario'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Roles
     */
    'active'?: boolean;
}
/**
 * 
 * @export
 * @interface RolesFilter
 */
export interface RolesFilter {
    /**
     * 
     * @type {number}
     * @memberof RolesFilter
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof RolesFilter
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof RolesFilter
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof RolesFilter
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {object | Set<string>}
     * @memberof RolesFilter
     */
    'fields'?: object | Set<string>;
    /**
     * 
     * @type {Array<RolesIncludeFilterItems | string>}
     * @memberof RolesFilter
     */
    'include'?: Array<RolesIncludeFilterItems | string>;
}
/**
 * 
 * @export
 * @interface RolesFilter1
 */
export interface RolesFilter1 {
    /**
     * 
     * @type {number}
     * @memberof RolesFilter1
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof RolesFilter1
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof RolesFilter1
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof RolesFilter1
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof RolesFilter1
     */
    'where'?: { [key: string]: object; };
    /**
     * 
     * @type {object | Set<string>}
     * @memberof RolesFilter1
     */
    'fields'?: object | Set<string>;
    /**
     * 
     * @type {Array<RolesIncludeFilterItems | string>}
     * @memberof RolesFilter1
     */
    'include'?: Array<RolesIncludeFilterItems | string>;
}
/**
 * 
 * @export
 * @interface RolesIncludeFilterItems
 */
export interface RolesIncludeFilterItems {
    /**
     * 
     * @type {string}
     * @memberof RolesIncludeFilterItems
     */
    'relation'?: string;
    /**
     * 
     * @type {RolesScopeFilter}
     * @memberof RolesIncludeFilterItems
     */
    'scope'?: RolesScopeFilter;
}
/**
 * (tsType: Partial<Roles>, schemaOptions: { partial: true })
 * @export
 * @interface RolesPartial
 */
export interface RolesPartial {
    /**
     * 
     * @type {number}
     * @memberof RolesPartial
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof RolesPartial
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof RolesPartial
     */
    'tipoUsuario'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RolesPartial
     */
    'active'?: boolean;
}
/**
 * 
 * @export
 * @interface RolesPermissions
 */
export interface RolesPermissions {
    /**
     * 
     * @type {number}
     * @memberof RolesPermissions
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof RolesPermissions
     */
    'moduleName'?: string;
    /**
     * 
     * @type {string}
     * @memberof RolesPermissions
     */
    'controllerName'?: string;
    /**
     * 
     * @type {string}
     * @memberof RolesPermissions
     */
    'actionName'?: string;
    /**
     * 
     * @type {number}
     * @memberof RolesPermissions
     */
    'rolesId'?: number;
}
/**
 * 
 * @export
 * @interface RolesPermissionsFilter
 */
export interface RolesPermissionsFilter {
    /**
     * 
     * @type {number}
     * @memberof RolesPermissionsFilter
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof RolesPermissionsFilter
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof RolesPermissionsFilter
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof RolesPermissionsFilter
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof RolesPermissionsFilter
     */
    'where'?: { [key: string]: object; };
    /**
     * 
     * @type {object | Set<string>}
     * @memberof RolesPermissionsFilter
     */
    'fields'?: object | Set<string>;
}
/**
 * 
 * @export
 * @interface RolesPermissionsFilter1
 */
export interface RolesPermissionsFilter1 {
    /**
     * 
     * @type {number}
     * @memberof RolesPermissionsFilter1
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof RolesPermissionsFilter1
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof RolesPermissionsFilter1
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof RolesPermissionsFilter1
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {object | Set<string>}
     * @memberof RolesPermissionsFilter1
     */
    'fields'?: object | Set<string>;
}
/**
 * (tsType: Partial<RolesPermissions>, schemaOptions: { partial: true })
 * @export
 * @interface RolesPermissionsPartial
 */
export interface RolesPermissionsPartial {
    /**
     * 
     * @type {number}
     * @memberof RolesPermissionsPartial
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof RolesPermissionsPartial
     */
    'moduleName'?: string;
    /**
     * 
     * @type {string}
     * @memberof RolesPermissionsPartial
     */
    'controllerName'?: string;
    /**
     * 
     * @type {string}
     * @memberof RolesPermissionsPartial
     */
    'actionName'?: string;
    /**
     * 
     * @type {number}
     * @memberof RolesPermissionsPartial
     */
    'rolesId'?: number;
}
/**
 * (tsType: RolesPermissionsWithRelations, schemaOptions: { includeRelations: true })
 * @export
 * @interface RolesPermissionsWithRelations
 */
export interface RolesPermissionsWithRelations {
    /**
     * 
     * @type {number}
     * @memberof RolesPermissionsWithRelations
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof RolesPermissionsWithRelations
     */
    'moduleName'?: string;
    /**
     * 
     * @type {string}
     * @memberof RolesPermissionsWithRelations
     */
    'controllerName'?: string;
    /**
     * 
     * @type {string}
     * @memberof RolesPermissionsWithRelations
     */
    'actionName'?: string;
    /**
     * 
     * @type {number}
     * @memberof RolesPermissionsWithRelations
     */
    'rolesId'?: number;
}
/**
 * 
 * @export
 * @interface RolesScopeFilter
 */
export interface RolesScopeFilter {
    /**
     * 
     * @type {number}
     * @memberof RolesScopeFilter
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof RolesScopeFilter
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof RolesScopeFilter
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof RolesScopeFilter
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof RolesScopeFilter
     */
    'where'?: { [key: string]: object; };
    /**
     * 
     * @type {any | Set<string>}
     * @memberof RolesScopeFilter
     */
    'fields'?: any | Set<string>;
    /**
     * 
     * @type {Array<{ [key: string]: object; }>}
     * @memberof RolesScopeFilter
     */
    'include'?: Array<{ [key: string]: object; }>;
}
/**
 * (tsType: RolesWithRelations, schemaOptions: { includeRelations: true })
 * @export
 * @interface RolesWithRelations
 */
export interface RolesWithRelations {
    /**
     * 
     * @type {number}
     * @memberof RolesWithRelations
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof RolesWithRelations
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof RolesWithRelations
     */
    'tipoUsuario'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RolesWithRelations
     */
    'active'?: boolean;
    /**
     * 
     * @type {Array<RolesPermissionsWithRelations>}
     * @memberof RolesWithRelations
     */
    'rolesPermissions'?: Array<RolesPermissionsWithRelations>;
}
/**
 * 
 * @export
 * @interface SoapLogs
 */
export interface SoapLogs {
    /**
     * 
     * @type {number}
     * @memberof SoapLogs
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof SoapLogs
     */
    'url'?: string;
    /**
     * 
     * @type {string}
     * @memberof SoapLogs
     */
    'fecha': string;
    /**
     * 
     * @type {string}
     * @memberof SoapLogs
     */
    'dateend': string;
    /**
     * 
     * @type {number}
     * @memberof SoapLogs
     */
    'seconds': number;
    /**
     * 
     * @type {string}
     * @memberof SoapLogs
     */
    'result'?: string;
    /**
     * 
     * @type {number}
     * @memberof SoapLogs
     */
    'userId'?: number;
    /**
     * 
     * @type {string}
     * @memberof SoapLogs
     */
    'empresaName'?: string;
    /**
     * 
     * @type {string}
     * @memberof SoapLogs
     */
    'endPoint'?: string;
    /**
     * 
     * @type {string}
     * @memberof SoapLogs
     */
    'params'?: string;
}
/**
 * 
 * @export
 * @interface SoapLogsFilter
 */
export interface SoapLogsFilter {
    /**
     * 
     * @type {number}
     * @memberof SoapLogsFilter
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof SoapLogsFilter
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof SoapLogsFilter
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof SoapLogsFilter
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {object | Set<string>}
     * @memberof SoapLogsFilter
     */
    'fields'?: object | Set<string>;
}
/**
 * 
 * @export
 * @interface SoapLogsFilter1
 */
export interface SoapLogsFilter1 {
    /**
     * 
     * @type {number}
     * @memberof SoapLogsFilter1
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof SoapLogsFilter1
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof SoapLogsFilter1
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof SoapLogsFilter1
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof SoapLogsFilter1
     */
    'where'?: { [key: string]: object; };
    /**
     * 
     * @type {object | Set<string>}
     * @memberof SoapLogsFilter1
     */
    'fields'?: object | Set<string>;
}
/**
 * (tsType: Partial<SoapLogs>, schemaOptions: { partial: true })
 * @export
 * @interface SoapLogsPartial
 */
export interface SoapLogsPartial {
    /**
     * 
     * @type {number}
     * @memberof SoapLogsPartial
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof SoapLogsPartial
     */
    'url'?: string;
    /**
     * 
     * @type {string}
     * @memberof SoapLogsPartial
     */
    'fecha'?: string;
    /**
     * 
     * @type {string}
     * @memberof SoapLogsPartial
     */
    'dateend'?: string;
    /**
     * 
     * @type {number}
     * @memberof SoapLogsPartial
     */
    'seconds'?: number;
    /**
     * 
     * @type {string}
     * @memberof SoapLogsPartial
     */
    'result'?: string;
    /**
     * 
     * @type {number}
     * @memberof SoapLogsPartial
     */
    'userId'?: number;
    /**
     * 
     * @type {string}
     * @memberof SoapLogsPartial
     */
    'empresaName'?: string;
    /**
     * 
     * @type {string}
     * @memberof SoapLogsPartial
     */
    'endPoint'?: string;
    /**
     * 
     * @type {string}
     * @memberof SoapLogsPartial
     */
    'params'?: string;
}
/**
 * (tsType: SoapLogsWithRelations, schemaOptions: { includeRelations: true })
 * @export
 * @interface SoapLogsWithRelations
 */
export interface SoapLogsWithRelations {
    /**
     * 
     * @type {number}
     * @memberof SoapLogsWithRelations
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof SoapLogsWithRelations
     */
    'url'?: string;
    /**
     * 
     * @type {string}
     * @memberof SoapLogsWithRelations
     */
    'fecha': string;
    /**
     * 
     * @type {string}
     * @memberof SoapLogsWithRelations
     */
    'dateend': string;
    /**
     * 
     * @type {number}
     * @memberof SoapLogsWithRelations
     */
    'seconds': number;
    /**
     * 
     * @type {string}
     * @memberof SoapLogsWithRelations
     */
    'result'?: string;
    /**
     * 
     * @type {number}
     * @memberof SoapLogsWithRelations
     */
    'userId'?: number;
    /**
     * 
     * @type {string}
     * @memberof SoapLogsWithRelations
     */
    'empresaName'?: string;
    /**
     * 
     * @type {string}
     * @memberof SoapLogsWithRelations
     */
    'endPoint'?: string;
    /**
     * 
     * @type {string}
     * @memberof SoapLogsWithRelations
     */
    'params'?: string;
}
/**
 * 
 * @export
 * @interface SuccessLogins
 */
export interface SuccessLogins {
    /**
     * 
     * @type {number}
     * @memberof SuccessLogins
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof SuccessLogins
     */
    'usersId': number;
    /**
     * 
     * @type {string}
     * @memberof SuccessLogins
     */
    'ipAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof SuccessLogins
     */
    'userAgent'?: string;
    /**
     * 
     * @type {string}
     * @memberof SuccessLogins
     */
    'createdAt': string;
}
/**
 * 
 * @export
 * @interface SuccessLoginsFilter
 */
export interface SuccessLoginsFilter {
    /**
     * 
     * @type {number}
     * @memberof SuccessLoginsFilter
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof SuccessLoginsFilter
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof SuccessLoginsFilter
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof SuccessLoginsFilter
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {object | Set<string>}
     * @memberof SuccessLoginsFilter
     */
    'fields'?: object | Set<string>;
}
/**
 * 
 * @export
 * @interface SuccessLoginsFilter1
 */
export interface SuccessLoginsFilter1 {
    /**
     * 
     * @type {number}
     * @memberof SuccessLoginsFilter1
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof SuccessLoginsFilter1
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof SuccessLoginsFilter1
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof SuccessLoginsFilter1
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof SuccessLoginsFilter1
     */
    'where'?: { [key: string]: object; };
    /**
     * 
     * @type {object | Set<string>}
     * @memberof SuccessLoginsFilter1
     */
    'fields'?: object | Set<string>;
}
/**
 * (tsType: Partial<SuccessLogins>, schemaOptions: { partial: true })
 * @export
 * @interface SuccessLoginsPartial
 */
export interface SuccessLoginsPartial {
    /**
     * 
     * @type {number}
     * @memberof SuccessLoginsPartial
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof SuccessLoginsPartial
     */
    'usersId'?: number;
    /**
     * 
     * @type {string}
     * @memberof SuccessLoginsPartial
     */
    'ipAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof SuccessLoginsPartial
     */
    'userAgent'?: string;
    /**
     * 
     * @type {string}
     * @memberof SuccessLoginsPartial
     */
    'createdAt'?: string;
}
/**
 * (tsType: SuccessLoginsWithRelations, schemaOptions: { includeRelations: true })
 * @export
 * @interface SuccessLoginsWithRelations
 */
export interface SuccessLoginsWithRelations {
    /**
     * 
     * @type {number}
     * @memberof SuccessLoginsWithRelations
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof SuccessLoginsWithRelations
     */
    'usersId': number;
    /**
     * 
     * @type {string}
     * @memberof SuccessLoginsWithRelations
     */
    'ipAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof SuccessLoginsWithRelations
     */
    'userAgent'?: string;
    /**
     * 
     * @type {string}
     * @memberof SuccessLoginsWithRelations
     */
    'createdAt': string;
}
/**
 * 
 * @export
 * @interface Translations
 */
export interface Translations {
    /**
     * 
     * @type {number}
     * @memberof Translations
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Translations
     */
    'language'?: string;
    /**
     * 
     * @type {string}
     * @memberof Translations
     */
    'keyName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Translations
     */
    'value'?: string;
    /**
     * 
     * @type {string}
     * @memberof Translations
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Translations
     */
    'modifiedAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof Translations
     */
    'userDeleted'?: number;
    /**
     * 
     * @type {number}
     * @memberof Translations
     */
    'userCreator'?: number;
    /**
     * 
     * @type {number}
     * @memberof Translations
     */
    'userModifier'?: number;
    /**
     * 
     * @type {string}
     * @memberof Translations
     */
    'deletedAt'?: string;
}
/**
 * 
 * @export
 * @interface TranslationsFilter
 */
export interface TranslationsFilter {
    /**
     * 
     * @type {number}
     * @memberof TranslationsFilter
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof TranslationsFilter
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof TranslationsFilter
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof TranslationsFilter
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {object | Set<string>}
     * @memberof TranslationsFilter
     */
    'fields'?: object | Set<string>;
}
/**
 * 
 * @export
 * @interface TranslationsFilter1
 */
export interface TranslationsFilter1 {
    /**
     * 
     * @type {number}
     * @memberof TranslationsFilter1
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof TranslationsFilter1
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof TranslationsFilter1
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof TranslationsFilter1
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof TranslationsFilter1
     */
    'where'?: { [key: string]: object; };
    /**
     * 
     * @type {object | Set<string>}
     * @memberof TranslationsFilter1
     */
    'fields'?: object | Set<string>;
}
/**
 * (tsType: Partial<Translations>, schemaOptions: { partial: true })
 * @export
 * @interface TranslationsPartial
 */
export interface TranslationsPartial {
    /**
     * 
     * @type {number}
     * @memberof TranslationsPartial
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TranslationsPartial
     */
    'language'?: string;
    /**
     * 
     * @type {string}
     * @memberof TranslationsPartial
     */
    'keyName'?: string;
    /**
     * 
     * @type {string}
     * @memberof TranslationsPartial
     */
    'value'?: string;
    /**
     * 
     * @type {string}
     * @memberof TranslationsPartial
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof TranslationsPartial
     */
    'modifiedAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof TranslationsPartial
     */
    'userDeleted'?: number;
    /**
     * 
     * @type {number}
     * @memberof TranslationsPartial
     */
    'userCreator'?: number;
    /**
     * 
     * @type {number}
     * @memberof TranslationsPartial
     */
    'userModifier'?: number;
    /**
     * 
     * @type {string}
     * @memberof TranslationsPartial
     */
    'deletedAt'?: string;
}
/**
 * (tsType: TranslationsWithRelations, schemaOptions: { includeRelations: true })
 * @export
 * @interface TranslationsWithRelations
 */
export interface TranslationsWithRelations {
    /**
     * 
     * @type {number}
     * @memberof TranslationsWithRelations
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TranslationsWithRelations
     */
    'language'?: string;
    /**
     * 
     * @type {string}
     * @memberof TranslationsWithRelations
     */
    'keyName'?: string;
    /**
     * 
     * @type {string}
     * @memberof TranslationsWithRelations
     */
    'value'?: string;
    /**
     * 
     * @type {string}
     * @memberof TranslationsWithRelations
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof TranslationsWithRelations
     */
    'modifiedAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof TranslationsWithRelations
     */
    'userDeleted'?: number;
    /**
     * 
     * @type {number}
     * @memberof TranslationsWithRelations
     */
    'userCreator'?: number;
    /**
     * 
     * @type {number}
     * @memberof TranslationsWithRelations
     */
    'userModifier'?: number;
    /**
     * 
     * @type {string}
     * @memberof TranslationsWithRelations
     */
    'deletedAt'?: string;
}
/**
 * 
 * @export
 * @interface Users
 */
export interface Users {
    /**
     * 
     * @type {number}
     * @memberof Users
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Users
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof Users
     */
    'password'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Users
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Users
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Users
     */
    'modifiedAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof Users
     */
    'userDeleted'?: number;
    /**
     * 
     * @type {number}
     * @memberof Users
     */
    'userCreator'?: number;
    /**
     * 
     * @type {number}
     * @memberof Users
     */
    'userModifier'?: number;
    /**
     * 
     * @type {string}
     * @memberof Users
     */
    'deletedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Users
     */
    'userName'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Users
     */
    'notifMail'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Users
     */
    'sendMail'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Users
     */
    'code'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Users
     */
    'used'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Users
     */
    'companyId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Users
     */
    'maximoDescuento'?: number;
    /**
     * 
     * @type {string}
     * @memberof Users
     */
    'avatar'?: string;
    /**
     * 
     * @type {string}
     * @memberof Users
     */
    'codVendedorCliente'?: string;
    /**
     * 
     * @type {string}
     * @memberof Users
     */
    'tipoUsuario'?: string;
    /**
     * 
     * @type {string}
     * @memberof Users
     */
    'clienteDefecto'?: string;
    /**
     * 
     * @type {string}
     * @memberof Users
     */
    'tarifaDefecto'?: string;
    /**
     * 
     * @type {string}
     * @memberof Users
     */
    'notificaciones'?: string;
    /**
     * 
     * @type {string}
     * @memberof Users
     */
    'mailExtra1'?: string;
    /**
     * 
     * @type {string}
     * @memberof Users
     */
    'mailExtra2'?: string;
    /**
     * 
     * @type {string}
     * @memberof Users
     */
    'mailExtra3'?: string;
    /**
     * 
     * @type {string}
     * @memberof Users
     */
    'sat'?: string;
    /**
     * 
     * @type {number}
     * @memberof Users
     */
    'rolesId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Users
     */
    'languagesId'?: number;
}
/**
 * 
 * @export
 * @interface UsersFilter
 */
export interface UsersFilter {
    /**
     * 
     * @type {number}
     * @memberof UsersFilter
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof UsersFilter
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof UsersFilter
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof UsersFilter
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {object | Set<string>}
     * @memberof UsersFilter
     */
    'fields'?: object | Set<string>;
    /**
     * 
     * @type {Array<UsersIncludeFilterItems | string>}
     * @memberof UsersFilter
     */
    'include'?: Array<UsersIncludeFilterItems | string>;
}
/**
 * 
 * @export
 * @interface UsersFilter1
 */
export interface UsersFilter1 {
    /**
     * 
     * @type {number}
     * @memberof UsersFilter1
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof UsersFilter1
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof UsersFilter1
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof UsersFilter1
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof UsersFilter1
     */
    'where'?: { [key: string]: object; };
    /**
     * 
     * @type {object | Set<string>}
     * @memberof UsersFilter1
     */
    'fields'?: object | Set<string>;
    /**
     * 
     * @type {Array<UsersIncludeFilterItems | string>}
     * @memberof UsersFilter1
     */
    'include'?: Array<UsersIncludeFilterItems | string>;
}
/**
 * 
 * @export
 * @interface UsersIncludeFilterItems
 */
export interface UsersIncludeFilterItems {
    /**
     * 
     * @type {string}
     * @memberof UsersIncludeFilterItems
     */
    'relation'?: string;
    /**
     * 
     * @type {UsersScopeFilter}
     * @memberof UsersIncludeFilterItems
     */
    'scope'?: UsersScopeFilter;
}
/**
 * (tsType: Partial<Users>, schemaOptions: { partial: true })
 * @export
 * @interface UsersPartial
 */
export interface UsersPartial {
    /**
     * 
     * @type {number}
     * @memberof UsersPartial
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof UsersPartial
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof UsersPartial
     */
    'password'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UsersPartial
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UsersPartial
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof UsersPartial
     */
    'modifiedAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof UsersPartial
     */
    'userDeleted'?: number;
    /**
     * 
     * @type {number}
     * @memberof UsersPartial
     */
    'userCreator'?: number;
    /**
     * 
     * @type {number}
     * @memberof UsersPartial
     */
    'userModifier'?: number;
    /**
     * 
     * @type {string}
     * @memberof UsersPartial
     */
    'deletedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof UsersPartial
     */
    'userName'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UsersPartial
     */
    'notifMail'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UsersPartial
     */
    'sendMail'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UsersPartial
     */
    'code'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UsersPartial
     */
    'used'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UsersPartial
     */
    'companyId'?: number;
    /**
     * 
     * @type {number}
     * @memberof UsersPartial
     */
    'maximoDescuento'?: number;
    /**
     * 
     * @type {string}
     * @memberof UsersPartial
     */
    'avatar'?: string;
    /**
     * 
     * @type {string}
     * @memberof UsersPartial
     */
    'codVendedorCliente'?: string;
    /**
     * 
     * @type {string}
     * @memberof UsersPartial
     */
    'tipoUsuario'?: string;
    /**
     * 
     * @type {string}
     * @memberof UsersPartial
     */
    'clienteDefecto'?: string;
    /**
     * 
     * @type {string}
     * @memberof UsersPartial
     */
    'tarifaDefecto'?: string;
    /**
     * 
     * @type {string}
     * @memberof UsersPartial
     */
    'notificaciones'?: string;
    /**
     * 
     * @type {string}
     * @memberof UsersPartial
     */
    'mailExtra1'?: string;
    /**
     * 
     * @type {string}
     * @memberof UsersPartial
     */
    'mailExtra2'?: string;
    /**
     * 
     * @type {string}
     * @memberof UsersPartial
     */
    'mailExtra3'?: string;
    /**
     * 
     * @type {string}
     * @memberof UsersPartial
     */
    'sat'?: string;
    /**
     * 
     * @type {number}
     * @memberof UsersPartial
     */
    'rolesId'?: number;
    /**
     * 
     * @type {number}
     * @memberof UsersPartial
     */
    'languagesId'?: number;
}
/**
 * 
 * @export
 * @interface UsersScopeFilter
 */
export interface UsersScopeFilter {
    /**
     * 
     * @type {number}
     * @memberof UsersScopeFilter
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof UsersScopeFilter
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof UsersScopeFilter
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof UsersScopeFilter
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof UsersScopeFilter
     */
    'where'?: { [key: string]: object; };
    /**
     * 
     * @type {any | Set<string>}
     * @memberof UsersScopeFilter
     */
    'fields'?: any | Set<string>;
    /**
     * 
     * @type {Array<{ [key: string]: object; }>}
     * @memberof UsersScopeFilter
     */
    'include'?: Array<{ [key: string]: object; }>;
}
/**
 * (tsType: UsersWithRelations, schemaOptions: { includeRelations: true })
 * @export
 * @interface UsersWithRelations
 */
export interface UsersWithRelations {
    /**
     * 
     * @type {number}
     * @memberof UsersWithRelations
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof UsersWithRelations
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof UsersWithRelations
     */
    'password'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UsersWithRelations
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UsersWithRelations
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof UsersWithRelations
     */
    'modifiedAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof UsersWithRelations
     */
    'userDeleted'?: number;
    /**
     * 
     * @type {number}
     * @memberof UsersWithRelations
     */
    'userCreator'?: number;
    /**
     * 
     * @type {number}
     * @memberof UsersWithRelations
     */
    'userModifier'?: number;
    /**
     * 
     * @type {string}
     * @memberof UsersWithRelations
     */
    'deletedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof UsersWithRelations
     */
    'userName'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UsersWithRelations
     */
    'notifMail'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UsersWithRelations
     */
    'sendMail'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UsersWithRelations
     */
    'code'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UsersWithRelations
     */
    'used'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UsersWithRelations
     */
    'companyId'?: number;
    /**
     * 
     * @type {number}
     * @memberof UsersWithRelations
     */
    'maximoDescuento'?: number;
    /**
     * 
     * @type {string}
     * @memberof UsersWithRelations
     */
    'avatar'?: string;
    /**
     * 
     * @type {string}
     * @memberof UsersWithRelations
     */
    'codVendedorCliente'?: string;
    /**
     * 
     * @type {string}
     * @memberof UsersWithRelations
     */
    'tipoUsuario'?: string;
    /**
     * 
     * @type {string}
     * @memberof UsersWithRelations
     */
    'clienteDefecto'?: string;
    /**
     * 
     * @type {string}
     * @memberof UsersWithRelations
     */
    'tarifaDefecto'?: string;
    /**
     * 
     * @type {string}
     * @memberof UsersWithRelations
     */
    'notificaciones'?: string;
    /**
     * 
     * @type {string}
     * @memberof UsersWithRelations
     */
    'mailExtra1'?: string;
    /**
     * 
     * @type {string}
     * @memberof UsersWithRelations
     */
    'mailExtra2'?: string;
    /**
     * 
     * @type {string}
     * @memberof UsersWithRelations
     */
    'mailExtra3'?: string;
    /**
     * 
     * @type {string}
     * @memberof UsersWithRelations
     */
    'sat'?: string;
    /**
     * 
     * @type {number}
     * @memberof UsersWithRelations
     */
    'rolesId'?: number;
    /**
     * 
     * @type {number}
     * @memberof UsersWithRelations
     */
    'languagesId'?: number;
    /**
     * 
     * @type {RolesWithRelations}
     * @memberof UsersWithRelations
     */
    'roles'?: RolesWithRelations;
    /**
     * 
     * @type {LanguagesWithRelations}
     * @memberof UsersWithRelations
     */
    'languages'?: LanguagesWithRelations;
}
/**
 * 
 * @export
 * @interface Vendedores
 */
export interface Vendedores {
    /**
     * 
     * @type {number}
     * @memberof Vendedores
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Vendedores
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof Vendedores
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof Vendedores
     */
    'lastDateTimeCreated'?: string;
    /**
     * 
     * @type {string}
     * @memberof Vendedores
     */
    'lastDateTimeModified'?: string;
    /**
     * 
     * @type {string}
     * @memberof Vendedores
     */
    'relatedCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof Vendedores
     */
    'type'?: string;
    /**
     * 
     * @type {number}
     * @memberof Vendedores
     */
    'companyId'?: number;
}
/**
 * 
 * @export
 * @interface VendedoresFilter
 */
export interface VendedoresFilter {
    /**
     * 
     * @type {number}
     * @memberof VendedoresFilter
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof VendedoresFilter
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof VendedoresFilter
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof VendedoresFilter
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {object | Set<string>}
     * @memberof VendedoresFilter
     */
    'fields'?: object | Set<string>;
}
/**
 * 
 * @export
 * @interface VendedoresFilter1
 */
export interface VendedoresFilter1 {
    /**
     * 
     * @type {number}
     * @memberof VendedoresFilter1
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof VendedoresFilter1
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof VendedoresFilter1
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof VendedoresFilter1
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof VendedoresFilter1
     */
    'where'?: { [key: string]: object; };
    /**
     * 
     * @type {object | Set<string>}
     * @memberof VendedoresFilter1
     */
    'fields'?: object | Set<string>;
}
/**
 * (tsType: Partial<Vendedores>, schemaOptions: { partial: true })
 * @export
 * @interface VendedoresPartial
 */
export interface VendedoresPartial {
    /**
     * 
     * @type {number}
     * @memberof VendedoresPartial
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof VendedoresPartial
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof VendedoresPartial
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof VendedoresPartial
     */
    'lastDateTimeCreated'?: string;
    /**
     * 
     * @type {string}
     * @memberof VendedoresPartial
     */
    'lastDateTimeModified'?: string;
    /**
     * 
     * @type {string}
     * @memberof VendedoresPartial
     */
    'relatedCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof VendedoresPartial
     */
    'type'?: string;
    /**
     * 
     * @type {number}
     * @memberof VendedoresPartial
     */
    'companyId'?: number;
}
/**
 * (tsType: VendedoresWithRelations, schemaOptions: { includeRelations: true })
 * @export
 * @interface VendedoresWithRelations
 */
export interface VendedoresWithRelations {
    /**
     * 
     * @type {number}
     * @memberof VendedoresWithRelations
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof VendedoresWithRelations
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof VendedoresWithRelations
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof VendedoresWithRelations
     */
    'lastDateTimeCreated'?: string;
    /**
     * 
     * @type {string}
     * @memberof VendedoresWithRelations
     */
    'lastDateTimeModified'?: string;
    /**
     * 
     * @type {string}
     * @memberof VendedoresWithRelations
     */
    'relatedCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof VendedoresWithRelations
     */
    'type'?: string;
    /**
     * 
     * @type {number}
     * @memberof VendedoresWithRelations
     */
    'companyId'?: number;
}
/**
 * 
 * @export
 * @interface Version
 */
export interface Version {
    /**
     * 
     * @type {number}
     * @memberof Version
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Version
     */
    'hash'?: string;
    /**
     * 
     * @type {number}
     * @memberof Version
     */
    'version'?: number;
    /**
     * 
     * @type {string}
     * @memberof Version
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof Version
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Version
     */
    'modifiedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Version
     */
    'deletedAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof Version
     */
    'userCreator'?: number;
    /**
     * 
     * @type {number}
     * @memberof Version
     */
    'userModifier'?: number;
    /**
     * 
     * @type {number}
     * @memberof Version
     */
    'userDeleted'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Version
     */
    'deleted'?: boolean;
}
/**
 * 
 * @export
 * @interface VersionFilter
 */
export interface VersionFilter {
    /**
     * 
     * @type {number}
     * @memberof VersionFilter
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof VersionFilter
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof VersionFilter
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof VersionFilter
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {object | Set<string>}
     * @memberof VersionFilter
     */
    'fields'?: object | Set<string>;
}
/**
 * 
 * @export
 * @interface VersionFilter1
 */
export interface VersionFilter1 {
    /**
     * 
     * @type {number}
     * @memberof VersionFilter1
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof VersionFilter1
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof VersionFilter1
     */
    'skip'?: number;
    /**
     * 
     * @type {string | Array<string>}
     * @memberof VersionFilter1
     */
    'order'?: string | Array<string>;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof VersionFilter1
     */
    'where'?: { [key: string]: object; };
    /**
     * 
     * @type {object | Set<string>}
     * @memberof VersionFilter1
     */
    'fields'?: object | Set<string>;
}
/**
 * (tsType: Partial<Version>, schemaOptions: { partial: true })
 * @export
 * @interface VersionPartial
 */
export interface VersionPartial {
    /**
     * 
     * @type {number}
     * @memberof VersionPartial
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof VersionPartial
     */
    'hash'?: string;
    /**
     * 
     * @type {number}
     * @memberof VersionPartial
     */
    'version'?: number;
    /**
     * 
     * @type {string}
     * @memberof VersionPartial
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof VersionPartial
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof VersionPartial
     */
    'modifiedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof VersionPartial
     */
    'deletedAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof VersionPartial
     */
    'userCreator'?: number;
    /**
     * 
     * @type {number}
     * @memberof VersionPartial
     */
    'userModifier'?: number;
    /**
     * 
     * @type {number}
     * @memberof VersionPartial
     */
    'userDeleted'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof VersionPartial
     */
    'deleted'?: boolean;
}
/**
 * (tsType: VersionWithRelations, schemaOptions: { includeRelations: true })
 * @export
 * @interface VersionWithRelations
 */
export interface VersionWithRelations {
    /**
     * 
     * @type {number}
     * @memberof VersionWithRelations
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof VersionWithRelations
     */
    'hash'?: string;
    /**
     * 
     * @type {number}
     * @memberof VersionWithRelations
     */
    'version'?: number;
    /**
     * 
     * @type {string}
     * @memberof VersionWithRelations
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof VersionWithRelations
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof VersionWithRelations
     */
    'modifiedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof VersionWithRelations
     */
    'deletedAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof VersionWithRelations
     */
    'userCreator'?: number;
    /**
     * 
     * @type {number}
     * @memberof VersionWithRelations
     */
    'userModifier'?: number;
    /**
     * 
     * @type {number}
     * @memberof VersionWithRelations
     */
    'userDeleted'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof VersionWithRelations
     */
    'deleted'?: boolean;
}

/**
 * AnnouncementsAnnouncementCategoryControllerApi - axios parameter creator
 * @export
 */
export const AnnouncementsAnnouncementCategoryControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {NewAnnouncementCategoryInAnnouncements} [newAnnouncementCategoryInAnnouncements] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        announcementsAnnouncementCategoryControllerCreate: async (id: number, newAnnouncementCategoryInAnnouncements?: NewAnnouncementCategoryInAnnouncements, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('announcementsAnnouncementCategoryControllerCreate', 'id', id)
            const localVarPath = `/announcements/{id}/announcement-categories`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newAnnouncementCategoryInAnnouncements, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        announcementsAnnouncementCategoryControllerDelete: async (id: number, where?: { [key: string]: object; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('announcementsAnnouncementCategoryControllerDelete', 'id', id)
            const localVarPath = `/announcements/{id}/announcement-categories`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {{ [key: string]: object; }} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        announcementsAnnouncementCategoryControllerFind: async (id: number, filter?: { [key: string]: object; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('announcementsAnnouncementCategoryControllerFind', 'id', id)
            const localVarPath = `/announcements/{id}/announcement-categories`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {{ [key: string]: object; }} [where] 
         * @param {AnnouncementCategoryPartial} [announcementCategoryPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        announcementsAnnouncementCategoryControllerPatch: async (id: number, where?: { [key: string]: object; }, announcementCategoryPartial?: AnnouncementCategoryPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('announcementsAnnouncementCategoryControllerPatch', 'id', id)
            const localVarPath = `/announcements/{id}/announcement-categories`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(announcementCategoryPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AnnouncementsAnnouncementCategoryControllerApi - functional programming interface
 * @export
 */
export const AnnouncementsAnnouncementCategoryControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AnnouncementsAnnouncementCategoryControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {NewAnnouncementCategoryInAnnouncements} [newAnnouncementCategoryInAnnouncements] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async announcementsAnnouncementCategoryControllerCreate(id: number, newAnnouncementCategoryInAnnouncements?: NewAnnouncementCategoryInAnnouncements, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnnouncementCategory>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.announcementsAnnouncementCategoryControllerCreate(id, newAnnouncementCategoryInAnnouncements, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async announcementsAnnouncementCategoryControllerDelete(id: number, where?: { [key: string]: object; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.announcementsAnnouncementCategoryControllerDelete(id, where, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {{ [key: string]: object; }} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async announcementsAnnouncementCategoryControllerFind(id: number, filter?: { [key: string]: object; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AnnouncementCategory>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.announcementsAnnouncementCategoryControllerFind(id, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {{ [key: string]: object; }} [where] 
         * @param {AnnouncementCategoryPartial} [announcementCategoryPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async announcementsAnnouncementCategoryControllerPatch(id: number, where?: { [key: string]: object; }, announcementCategoryPartial?: AnnouncementCategoryPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.announcementsAnnouncementCategoryControllerPatch(id, where, announcementCategoryPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AnnouncementsAnnouncementCategoryControllerApi - factory interface
 * @export
 */
export const AnnouncementsAnnouncementCategoryControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AnnouncementsAnnouncementCategoryControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {NewAnnouncementCategoryInAnnouncements} [newAnnouncementCategoryInAnnouncements] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        announcementsAnnouncementCategoryControllerCreate(id: number, newAnnouncementCategoryInAnnouncements?: NewAnnouncementCategoryInAnnouncements, options?: any): AxiosPromise<AnnouncementCategory> {
            return localVarFp.announcementsAnnouncementCategoryControllerCreate(id, newAnnouncementCategoryInAnnouncements, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        announcementsAnnouncementCategoryControllerDelete(id: number, where?: { [key: string]: object; }, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.announcementsAnnouncementCategoryControllerDelete(id, where, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {{ [key: string]: object; }} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        announcementsAnnouncementCategoryControllerFind(id: number, filter?: { [key: string]: object; }, options?: any): AxiosPromise<Array<AnnouncementCategory>> {
            return localVarFp.announcementsAnnouncementCategoryControllerFind(id, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {{ [key: string]: object; }} [where] 
         * @param {AnnouncementCategoryPartial} [announcementCategoryPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        announcementsAnnouncementCategoryControllerPatch(id: number, where?: { [key: string]: object; }, announcementCategoryPartial?: AnnouncementCategoryPartial, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.announcementsAnnouncementCategoryControllerPatch(id, where, announcementCategoryPartial, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AnnouncementsAnnouncementCategoryControllerApi - object-oriented interface
 * @export
 * @class AnnouncementsAnnouncementCategoryControllerApi
 * @extends {BaseAPI}
 */
export class AnnouncementsAnnouncementCategoryControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {NewAnnouncementCategoryInAnnouncements} [newAnnouncementCategoryInAnnouncements] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnouncementsAnnouncementCategoryControllerApi
     */
    public announcementsAnnouncementCategoryControllerCreate(id: number, newAnnouncementCategoryInAnnouncements?: NewAnnouncementCategoryInAnnouncements, options?: AxiosRequestConfig) {
        return AnnouncementsAnnouncementCategoryControllerApiFp(this.configuration).announcementsAnnouncementCategoryControllerCreate(id, newAnnouncementCategoryInAnnouncements, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {{ [key: string]: object; }} [where] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnouncementsAnnouncementCategoryControllerApi
     */
    public announcementsAnnouncementCategoryControllerDelete(id: number, where?: { [key: string]: object; }, options?: AxiosRequestConfig) {
        return AnnouncementsAnnouncementCategoryControllerApiFp(this.configuration).announcementsAnnouncementCategoryControllerDelete(id, where, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {{ [key: string]: object; }} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnouncementsAnnouncementCategoryControllerApi
     */
    public announcementsAnnouncementCategoryControllerFind(id: number, filter?: { [key: string]: object; }, options?: AxiosRequestConfig) {
        return AnnouncementsAnnouncementCategoryControllerApiFp(this.configuration).announcementsAnnouncementCategoryControllerFind(id, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {{ [key: string]: object; }} [where] 
     * @param {AnnouncementCategoryPartial} [announcementCategoryPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnouncementsAnnouncementCategoryControllerApi
     */
    public announcementsAnnouncementCategoryControllerPatch(id: number, where?: { [key: string]: object; }, announcementCategoryPartial?: AnnouncementCategoryPartial, options?: AxiosRequestConfig) {
        return AnnouncementsAnnouncementCategoryControllerApiFp(this.configuration).announcementsAnnouncementCategoryControllerPatch(id, where, announcementCategoryPartial, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AnnouncementsAnnouncementMetaControllerApi - axios parameter creator
 * @export
 */
export const AnnouncementsAnnouncementMetaControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {NewAnnouncementMetaInAnnouncements} [newAnnouncementMetaInAnnouncements] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        announcementsAnnouncementMetaControllerCreate: async (id: number, newAnnouncementMetaInAnnouncements?: NewAnnouncementMetaInAnnouncements, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('announcementsAnnouncementMetaControllerCreate', 'id', id)
            const localVarPath = `/announcements/{id}/announcement-metas`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newAnnouncementMetaInAnnouncements, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        announcementsAnnouncementMetaControllerDelete: async (id: number, where?: { [key: string]: object; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('announcementsAnnouncementMetaControllerDelete', 'id', id)
            const localVarPath = `/announcements/{id}/announcement-metas`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {{ [key: string]: object; }} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        announcementsAnnouncementMetaControllerFind: async (id: number, filter?: { [key: string]: object; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('announcementsAnnouncementMetaControllerFind', 'id', id)
            const localVarPath = `/announcements/{id}/announcement-metas`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {{ [key: string]: object; }} [where] 
         * @param {AnnouncementMetaPartial} [announcementMetaPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        announcementsAnnouncementMetaControllerPatch: async (id: number, where?: { [key: string]: object; }, announcementMetaPartial?: AnnouncementMetaPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('announcementsAnnouncementMetaControllerPatch', 'id', id)
            const localVarPath = `/announcements/{id}/announcement-metas`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(announcementMetaPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AnnouncementsAnnouncementMetaControllerApi - functional programming interface
 * @export
 */
export const AnnouncementsAnnouncementMetaControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AnnouncementsAnnouncementMetaControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {NewAnnouncementMetaInAnnouncements} [newAnnouncementMetaInAnnouncements] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async announcementsAnnouncementMetaControllerCreate(id: number, newAnnouncementMetaInAnnouncements?: NewAnnouncementMetaInAnnouncements, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnnouncementMeta>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.announcementsAnnouncementMetaControllerCreate(id, newAnnouncementMetaInAnnouncements, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async announcementsAnnouncementMetaControllerDelete(id: number, where?: { [key: string]: object; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.announcementsAnnouncementMetaControllerDelete(id, where, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {{ [key: string]: object; }} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async announcementsAnnouncementMetaControllerFind(id: number, filter?: { [key: string]: object; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AnnouncementMeta>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.announcementsAnnouncementMetaControllerFind(id, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {{ [key: string]: object; }} [where] 
         * @param {AnnouncementMetaPartial} [announcementMetaPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async announcementsAnnouncementMetaControllerPatch(id: number, where?: { [key: string]: object; }, announcementMetaPartial?: AnnouncementMetaPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.announcementsAnnouncementMetaControllerPatch(id, where, announcementMetaPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AnnouncementsAnnouncementMetaControllerApi - factory interface
 * @export
 */
export const AnnouncementsAnnouncementMetaControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AnnouncementsAnnouncementMetaControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {NewAnnouncementMetaInAnnouncements} [newAnnouncementMetaInAnnouncements] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        announcementsAnnouncementMetaControllerCreate(id: number, newAnnouncementMetaInAnnouncements?: NewAnnouncementMetaInAnnouncements, options?: any): AxiosPromise<AnnouncementMeta> {
            return localVarFp.announcementsAnnouncementMetaControllerCreate(id, newAnnouncementMetaInAnnouncements, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        announcementsAnnouncementMetaControllerDelete(id: number, where?: { [key: string]: object; }, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.announcementsAnnouncementMetaControllerDelete(id, where, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {{ [key: string]: object; }} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        announcementsAnnouncementMetaControllerFind(id: number, filter?: { [key: string]: object; }, options?: any): AxiosPromise<Array<AnnouncementMeta>> {
            return localVarFp.announcementsAnnouncementMetaControllerFind(id, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {{ [key: string]: object; }} [where] 
         * @param {AnnouncementMetaPartial} [announcementMetaPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        announcementsAnnouncementMetaControllerPatch(id: number, where?: { [key: string]: object; }, announcementMetaPartial?: AnnouncementMetaPartial, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.announcementsAnnouncementMetaControllerPatch(id, where, announcementMetaPartial, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AnnouncementsAnnouncementMetaControllerApi - object-oriented interface
 * @export
 * @class AnnouncementsAnnouncementMetaControllerApi
 * @extends {BaseAPI}
 */
export class AnnouncementsAnnouncementMetaControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {NewAnnouncementMetaInAnnouncements} [newAnnouncementMetaInAnnouncements] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnouncementsAnnouncementMetaControllerApi
     */
    public announcementsAnnouncementMetaControllerCreate(id: number, newAnnouncementMetaInAnnouncements?: NewAnnouncementMetaInAnnouncements, options?: AxiosRequestConfig) {
        return AnnouncementsAnnouncementMetaControllerApiFp(this.configuration).announcementsAnnouncementMetaControllerCreate(id, newAnnouncementMetaInAnnouncements, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {{ [key: string]: object; }} [where] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnouncementsAnnouncementMetaControllerApi
     */
    public announcementsAnnouncementMetaControllerDelete(id: number, where?: { [key: string]: object; }, options?: AxiosRequestConfig) {
        return AnnouncementsAnnouncementMetaControllerApiFp(this.configuration).announcementsAnnouncementMetaControllerDelete(id, where, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {{ [key: string]: object; }} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnouncementsAnnouncementMetaControllerApi
     */
    public announcementsAnnouncementMetaControllerFind(id: number, filter?: { [key: string]: object; }, options?: AxiosRequestConfig) {
        return AnnouncementsAnnouncementMetaControllerApiFp(this.configuration).announcementsAnnouncementMetaControllerFind(id, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {{ [key: string]: object; }} [where] 
     * @param {AnnouncementMetaPartial} [announcementMetaPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnouncementsAnnouncementMetaControllerApi
     */
    public announcementsAnnouncementMetaControllerPatch(id: number, where?: { [key: string]: object; }, announcementMetaPartial?: AnnouncementMetaPartial, options?: AxiosRequestConfig) {
        return AnnouncementsAnnouncementMetaControllerApiFp(this.configuration).announcementsAnnouncementMetaControllerPatch(id, where, announcementMetaPartial, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AnnouncementsCategoriesControllerApi - axios parameter creator
 * @export
 */
export const AnnouncementsCategoriesControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {{ [key: string]: object; }} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        announcementsCategoriesControllerFind: async (id: number, filter?: { [key: string]: object; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('announcementsCategoriesControllerFind', 'id', id)
            const localVarPath = `/announcements/{id}/categories`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AnnouncementsCategoriesControllerApi - functional programming interface
 * @export
 */
export const AnnouncementsCategoriesControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AnnouncementsCategoriesControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {{ [key: string]: object; }} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async announcementsCategoriesControllerFind(id: number, filter?: { [key: string]: object; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Categories>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.announcementsCategoriesControllerFind(id, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AnnouncementsCategoriesControllerApi - factory interface
 * @export
 */
export const AnnouncementsCategoriesControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AnnouncementsCategoriesControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {{ [key: string]: object; }} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        announcementsCategoriesControllerFind(id: number, filter?: { [key: string]: object; }, options?: any): AxiosPromise<Array<Categories>> {
            return localVarFp.announcementsCategoriesControllerFind(id, filter, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AnnouncementsCategoriesControllerApi - object-oriented interface
 * @export
 * @class AnnouncementsCategoriesControllerApi
 * @extends {BaseAPI}
 */
export class AnnouncementsCategoriesControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {{ [key: string]: object; }} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnouncementsCategoriesControllerApi
     */
    public announcementsCategoriesControllerFind(id: number, filter?: { [key: string]: object; }, options?: AxiosRequestConfig) {
        return AnnouncementsCategoriesControllerApiFp(this.configuration).announcementsCategoriesControllerFind(id, filter, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AnnouncementsControllerApi - axios parameter creator
 * @export
 */
export const AnnouncementsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        announcementsControllerCount: async (where?: { [key: string]: object; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/announcements/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {NewAnnouncements} [newAnnouncements] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        announcementsControllerCreate: async (newAnnouncements?: NewAnnouncements, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/announcements`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newAnnouncements, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        announcementsControllerDeleteById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('announcementsControllerDeleteById', 'id', id)
            const localVarPath = `/announcements/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AnnouncementsFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        announcementsControllerFind: async (filter?: AnnouncementsFilter1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/announcements`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {AnnouncementsFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        announcementsControllerFindById: async (id: number, filter?: AnnouncementsFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('announcementsControllerFindById', 'id', id)
            const localVarPath = `/announcements/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {Announcements} [announcements] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        announcementsControllerReplaceById: async (id: number, announcements?: Announcements, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('announcementsControllerReplaceById', 'id', id)
            const localVarPath = `/announcements/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(announcements, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {AnnouncementsPartial} [announcementsPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        announcementsControllerUpdateAll: async (where?: { [key: string]: object; }, announcementsPartial?: AnnouncementsPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/announcements`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(announcementsPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {AnnouncementsPartial} [announcementsPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        announcementsControllerUpdateById: async (id: number, announcementsPartial?: AnnouncementsPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('announcementsControllerUpdateById', 'id', id)
            const localVarPath = `/announcements/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(announcementsPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AnnouncementsControllerApi - functional programming interface
 * @export
 */
export const AnnouncementsControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AnnouncementsControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async announcementsControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.announcementsControllerCount(where, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {NewAnnouncements} [newAnnouncements] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async announcementsControllerCreate(newAnnouncements?: NewAnnouncements, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Announcements>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.announcementsControllerCreate(newAnnouncements, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async announcementsControllerDeleteById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.announcementsControllerDeleteById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AnnouncementsFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async announcementsControllerFind(filter?: AnnouncementsFilter1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AnnouncementsWithRelations>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.announcementsControllerFind(filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {AnnouncementsFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async announcementsControllerFindById(id: number, filter?: AnnouncementsFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnnouncementsWithRelations>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.announcementsControllerFindById(id, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {Announcements} [announcements] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async announcementsControllerReplaceById(id: number, announcements?: Announcements, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.announcementsControllerReplaceById(id, announcements, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {AnnouncementsPartial} [announcementsPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async announcementsControllerUpdateAll(where?: { [key: string]: object; }, announcementsPartial?: AnnouncementsPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.announcementsControllerUpdateAll(where, announcementsPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {AnnouncementsPartial} [announcementsPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async announcementsControllerUpdateById(id: number, announcementsPartial?: AnnouncementsPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.announcementsControllerUpdateById(id, announcementsPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AnnouncementsControllerApi - factory interface
 * @export
 */
export const AnnouncementsControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AnnouncementsControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        announcementsControllerCount(where?: { [key: string]: object; }, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.announcementsControllerCount(where, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NewAnnouncements} [newAnnouncements] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        announcementsControllerCreate(newAnnouncements?: NewAnnouncements, options?: any): AxiosPromise<Announcements> {
            return localVarFp.announcementsControllerCreate(newAnnouncements, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        announcementsControllerDeleteById(id: number, options?: any): AxiosPromise<any> {
            return localVarFp.announcementsControllerDeleteById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AnnouncementsFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        announcementsControllerFind(filter?: AnnouncementsFilter1, options?: any): AxiosPromise<Array<AnnouncementsWithRelations>> {
            return localVarFp.announcementsControllerFind(filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {AnnouncementsFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        announcementsControllerFindById(id: number, filter?: AnnouncementsFilter, options?: any): AxiosPromise<AnnouncementsWithRelations> {
            return localVarFp.announcementsControllerFindById(id, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {Announcements} [announcements] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        announcementsControllerReplaceById(id: number, announcements?: Announcements, options?: any): AxiosPromise<any> {
            return localVarFp.announcementsControllerReplaceById(id, announcements, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {AnnouncementsPartial} [announcementsPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        announcementsControllerUpdateAll(where?: { [key: string]: object; }, announcementsPartial?: AnnouncementsPartial, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.announcementsControllerUpdateAll(where, announcementsPartial, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {AnnouncementsPartial} [announcementsPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        announcementsControllerUpdateById(id: number, announcementsPartial?: AnnouncementsPartial, options?: any): AxiosPromise<any> {
            return localVarFp.announcementsControllerUpdateById(id, announcementsPartial, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AnnouncementsControllerApi - object-oriented interface
 * @export
 * @class AnnouncementsControllerApi
 * @extends {BaseAPI}
 */
export class AnnouncementsControllerApi extends BaseAPI {
    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnouncementsControllerApi
     */
    public announcementsControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig) {
        return AnnouncementsControllerApiFp(this.configuration).announcementsControllerCount(where, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NewAnnouncements} [newAnnouncements] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnouncementsControllerApi
     */
    public announcementsControllerCreate(newAnnouncements?: NewAnnouncements, options?: AxiosRequestConfig) {
        return AnnouncementsControllerApiFp(this.configuration).announcementsControllerCreate(newAnnouncements, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnouncementsControllerApi
     */
    public announcementsControllerDeleteById(id: number, options?: AxiosRequestConfig) {
        return AnnouncementsControllerApiFp(this.configuration).announcementsControllerDeleteById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AnnouncementsFilter1} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnouncementsControllerApi
     */
    public announcementsControllerFind(filter?: AnnouncementsFilter1, options?: AxiosRequestConfig) {
        return AnnouncementsControllerApiFp(this.configuration).announcementsControllerFind(filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {AnnouncementsFilter} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnouncementsControllerApi
     */
    public announcementsControllerFindById(id: number, filter?: AnnouncementsFilter, options?: AxiosRequestConfig) {
        return AnnouncementsControllerApiFp(this.configuration).announcementsControllerFindById(id, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {Announcements} [announcements] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnouncementsControllerApi
     */
    public announcementsControllerReplaceById(id: number, announcements?: Announcements, options?: AxiosRequestConfig) {
        return AnnouncementsControllerApiFp(this.configuration).announcementsControllerReplaceById(id, announcements, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {AnnouncementsPartial} [announcementsPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnouncementsControllerApi
     */
    public announcementsControllerUpdateAll(where?: { [key: string]: object; }, announcementsPartial?: AnnouncementsPartial, options?: AxiosRequestConfig) {
        return AnnouncementsControllerApiFp(this.configuration).announcementsControllerUpdateAll(where, announcementsPartial, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {AnnouncementsPartial} [announcementsPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnouncementsControllerApi
     */
    public announcementsControllerUpdateById(id: number, announcementsPartial?: AnnouncementsPartial, options?: AxiosRequestConfig) {
        return AnnouncementsControllerApiFp(this.configuration).announcementsControllerUpdateById(id, announcementsPartial, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AnnouncementsUsersControllerApi - axios parameter creator
 * @export
 */
export const AnnouncementsUsersControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        announcementsUsersControllerGetUsers: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('announcementsUsersControllerGetUsers', 'id', id)
            const localVarPath = `/announcements/{id}/users`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AnnouncementsUsersControllerApi - functional programming interface
 * @export
 */
export const AnnouncementsUsersControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AnnouncementsUsersControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async announcementsUsersControllerGetUsers(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Users>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.announcementsUsersControllerGetUsers(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AnnouncementsUsersControllerApi - factory interface
 * @export
 */
export const AnnouncementsUsersControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AnnouncementsUsersControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        announcementsUsersControllerGetUsers(id: number, options?: any): AxiosPromise<Array<Users>> {
            return localVarFp.announcementsUsersControllerGetUsers(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AnnouncementsUsersControllerApi - object-oriented interface
 * @export
 * @class AnnouncementsUsersControllerApi
 * @extends {BaseAPI}
 */
export class AnnouncementsUsersControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnouncementsUsersControllerApi
     */
    public announcementsUsersControllerGetUsers(id: number, options?: AxiosRequestConfig) {
        return AnnouncementsUsersControllerApiFp(this.configuration).announcementsUsersControllerGetUsers(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AuthControllerApi - axios parameter creator
 * @export
 */
export const AuthControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {InlineObject} inlineObject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerLogin: async (inlineObject: InlineObject, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'inlineObject' is not null or undefined
            assertParamExists('authControllerLogin', 'inlineObject', inlineObject)
            const localVarPath = `/auth/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerMe: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InlineObject1} inlineObject1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerRefresh: async (inlineObject1: InlineObject1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'inlineObject1' is not null or undefined
            assertParamExists('authControllerRefresh', 'inlineObject1', inlineObject1)
            const localVarPath = `/auth/refresh-token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InlineObject4} inlineObject4 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerRefreshLogin: async (inlineObject4: InlineObject4, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'inlineObject4' is not null or undefined
            assertParamExists('authControllerRefreshLogin', 'inlineObject4', inlineObject4)
            const localVarPath = `/users/refresh-login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject4, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Users} [users] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerSignup: async (users?: Users, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/signup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(users, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthControllerApi - functional programming interface
 * @export
 */
export const AuthControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {InlineObject} inlineObject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerLogin(inlineObject: InlineObject, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerLogin(inlineObject, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerMe(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UsersWithRelations>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerMe(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {InlineObject1} inlineObject1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerRefresh(inlineObject1: InlineObject1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2001>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerRefresh(inlineObject1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {InlineObject4} inlineObject4 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerRefreshLogin(inlineObject4: InlineObject4, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2003>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerRefreshLogin(inlineObject4, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Users} [users] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerSignup(users?: Users, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Users>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerSignup(users, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthControllerApi - factory interface
 * @export
 */
export const AuthControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {InlineObject} inlineObject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerLogin(inlineObject: InlineObject, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.authControllerLogin(inlineObject, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerMe(options?: any): AxiosPromise<UsersWithRelations> {
            return localVarFp.authControllerMe(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InlineObject1} inlineObject1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerRefresh(inlineObject1: InlineObject1, options?: any): AxiosPromise<InlineResponse2001> {
            return localVarFp.authControllerRefresh(inlineObject1, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InlineObject4} inlineObject4 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerRefreshLogin(inlineObject4: InlineObject4, options?: any): AxiosPromise<InlineResponse2003> {
            return localVarFp.authControllerRefreshLogin(inlineObject4, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Users} [users] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerSignup(users?: Users, options?: any): AxiosPromise<Users> {
            return localVarFp.authControllerSignup(users, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthControllerApi - object-oriented interface
 * @export
 * @class AuthControllerApi
 * @extends {BaseAPI}
 */
export class AuthControllerApi extends BaseAPI {
    /**
     * 
     * @param {InlineObject} inlineObject 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthControllerApi
     */
    public authControllerLogin(inlineObject: InlineObject, options?: AxiosRequestConfig) {
        return AuthControllerApiFp(this.configuration).authControllerLogin(inlineObject, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthControllerApi
     */
    public authControllerMe(options?: AxiosRequestConfig) {
        return AuthControllerApiFp(this.configuration).authControllerMe(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InlineObject1} inlineObject1 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthControllerApi
     */
    public authControllerRefresh(inlineObject1: InlineObject1, options?: AxiosRequestConfig) {
        return AuthControllerApiFp(this.configuration).authControllerRefresh(inlineObject1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InlineObject4} inlineObject4 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthControllerApi
     */
    public authControllerRefreshLogin(inlineObject4: InlineObject4, options?: AxiosRequestConfig) {
        return AuthControllerApiFp(this.configuration).authControllerRefreshLogin(inlineObject4, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Users} [users] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthControllerApi
     */
    public authControllerSignup(users?: Users, options?: AxiosRequestConfig) {
        return AuthControllerApiFp(this.configuration).authControllerSignup(users, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CargaTablasMaestrasControllerApi - axios parameter creator
 * @export
 */
export const CargaTablasMaestrasControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} email 
         * @param {string} password 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cargaTablasMaestrasControllerCargaClientes: async (email: string, password: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('cargaTablasMaestrasControllerCargaClientes', 'email', email)
            // verify required parameter 'password' is not null or undefined
            assertParamExists('cargaTablasMaestrasControllerCargaClientes', 'password', password)
            const localVarPath = `/cargaClientes/{email}/{password}`
                .replace(`{${"email"}}`, encodeURIComponent(String(email)))
                .replace(`{${"password"}}`, encodeURIComponent(String(password)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} email 
         * @param {string} password 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cargaTablasMaestrasControllerCargaPortes: async (email: string, password: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('cargaTablasMaestrasControllerCargaPortes', 'email', email)
            // verify required parameter 'password' is not null or undefined
            assertParamExists('cargaTablasMaestrasControllerCargaPortes', 'password', password)
            const localVarPath = `/cargaPortes/{email}/{password}`
                .replace(`{${"email"}}`, encodeURIComponent(String(email)))
                .replace(`{${"password"}}`, encodeURIComponent(String(password)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cargaTablasMaestrasControllerCargaTodos: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/cargaTodos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} email 
         * @param {string} password 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cargaTablasMaestrasControllerCargaVendedores: async (email: string, password: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('cargaTablasMaestrasControllerCargaVendedores', 'email', email)
            // verify required parameter 'password' is not null or undefined
            assertParamExists('cargaTablasMaestrasControllerCargaVendedores', 'password', password)
            const localVarPath = `/cargaVendedores/{email}/{password}`
                .replace(`{${"email"}}`, encodeURIComponent(String(email)))
                .replace(`{${"password"}}`, encodeURIComponent(String(password)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CargaTablasMaestrasControllerApi - functional programming interface
 * @export
 */
export const CargaTablasMaestrasControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CargaTablasMaestrasControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} email 
         * @param {string} password 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cargaTablasMaestrasControllerCargaClientes(email: string, password: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cargaTablasMaestrasControllerCargaClientes(email, password, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} email 
         * @param {string} password 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cargaTablasMaestrasControllerCargaPortes(email: string, password: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cargaTablasMaestrasControllerCargaPortes(email, password, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cargaTablasMaestrasControllerCargaTodos(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cargaTablasMaestrasControllerCargaTodos(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} email 
         * @param {string} password 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cargaTablasMaestrasControllerCargaVendedores(email: string, password: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cargaTablasMaestrasControllerCargaVendedores(email, password, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CargaTablasMaestrasControllerApi - factory interface
 * @export
 */
export const CargaTablasMaestrasControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CargaTablasMaestrasControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} email 
         * @param {string} password 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cargaTablasMaestrasControllerCargaClientes(email: string, password: string, options?: any): AxiosPromise<object> {
            return localVarFp.cargaTablasMaestrasControllerCargaClientes(email, password, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} email 
         * @param {string} password 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cargaTablasMaestrasControllerCargaPortes(email: string, password: string, options?: any): AxiosPromise<object> {
            return localVarFp.cargaTablasMaestrasControllerCargaPortes(email, password, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cargaTablasMaestrasControllerCargaTodos(options?: any): AxiosPromise<object> {
            return localVarFp.cargaTablasMaestrasControllerCargaTodos(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} email 
         * @param {string} password 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cargaTablasMaestrasControllerCargaVendedores(email: string, password: string, options?: any): AxiosPromise<object> {
            return localVarFp.cargaTablasMaestrasControllerCargaVendedores(email, password, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CargaTablasMaestrasControllerApi - object-oriented interface
 * @export
 * @class CargaTablasMaestrasControllerApi
 * @extends {BaseAPI}
 */
export class CargaTablasMaestrasControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} email 
     * @param {string} password 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CargaTablasMaestrasControllerApi
     */
    public cargaTablasMaestrasControllerCargaClientes(email: string, password: string, options?: AxiosRequestConfig) {
        return CargaTablasMaestrasControllerApiFp(this.configuration).cargaTablasMaestrasControllerCargaClientes(email, password, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} email 
     * @param {string} password 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CargaTablasMaestrasControllerApi
     */
    public cargaTablasMaestrasControllerCargaPortes(email: string, password: string, options?: AxiosRequestConfig) {
        return CargaTablasMaestrasControllerApiFp(this.configuration).cargaTablasMaestrasControllerCargaPortes(email, password, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CargaTablasMaestrasControllerApi
     */
    public cargaTablasMaestrasControllerCargaTodos(options?: AxiosRequestConfig) {
        return CargaTablasMaestrasControllerApiFp(this.configuration).cargaTablasMaestrasControllerCargaTodos(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} email 
     * @param {string} password 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CargaTablasMaestrasControllerApi
     */
    public cargaTablasMaestrasControllerCargaVendedores(email: string, password: string, options?: AxiosRequestConfig) {
        return CargaTablasMaestrasControllerApiFp(this.configuration).cargaTablasMaestrasControllerCargaVendedores(email, password, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CategoriesControllerApi - axios parameter creator
 * @export
 */
export const CategoriesControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesControllerCount: async (where?: { [key: string]: object; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/categories/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {NewCategories} [newCategories] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesControllerCreate: async (newCategories?: NewCategories, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newCategories, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesControllerDeleteById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('categoriesControllerDeleteById', 'id', id)
            const localVarPath = `/categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CategoriesFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesControllerFind: async (filter?: CategoriesFilter1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {CategoriesFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesControllerFindById: async (id: number, filter?: CategoriesFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('categoriesControllerFindById', 'id', id)
            const localVarPath = `/categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {Categories} [categories] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesControllerReplaceById: async (id: number, categories?: Categories, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('categoriesControllerReplaceById', 'id', id)
            const localVarPath = `/categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(categories, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {CategoriesPartial} [categoriesPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesControllerUpdateAll: async (where?: { [key: string]: object; }, categoriesPartial?: CategoriesPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(categoriesPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {CategoriesPartial} [categoriesPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesControllerUpdateById: async (id: number, categoriesPartial?: CategoriesPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('categoriesControllerUpdateById', 'id', id)
            const localVarPath = `/categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(categoriesPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CategoriesControllerApi - functional programming interface
 * @export
 */
export const CategoriesControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CategoriesControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoriesControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.categoriesControllerCount(where, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {NewCategories} [newCategories] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoriesControllerCreate(newCategories?: NewCategories, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Categories>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.categoriesControllerCreate(newCategories, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoriesControllerDeleteById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.categoriesControllerDeleteById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CategoriesFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoriesControllerFind(filter?: CategoriesFilter1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CategoriesWithRelations>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.categoriesControllerFind(filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {CategoriesFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoriesControllerFindById(id: number, filter?: CategoriesFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CategoriesWithRelations>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.categoriesControllerFindById(id, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {Categories} [categories] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoriesControllerReplaceById(id: number, categories?: Categories, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.categoriesControllerReplaceById(id, categories, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {CategoriesPartial} [categoriesPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoriesControllerUpdateAll(where?: { [key: string]: object; }, categoriesPartial?: CategoriesPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.categoriesControllerUpdateAll(where, categoriesPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {CategoriesPartial} [categoriesPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoriesControllerUpdateById(id: number, categoriesPartial?: CategoriesPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.categoriesControllerUpdateById(id, categoriesPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CategoriesControllerApi - factory interface
 * @export
 */
export const CategoriesControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CategoriesControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesControllerCount(where?: { [key: string]: object; }, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.categoriesControllerCount(where, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NewCategories} [newCategories] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesControllerCreate(newCategories?: NewCategories, options?: any): AxiosPromise<Categories> {
            return localVarFp.categoriesControllerCreate(newCategories, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesControllerDeleteById(id: number, options?: any): AxiosPromise<any> {
            return localVarFp.categoriesControllerDeleteById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CategoriesFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesControllerFind(filter?: CategoriesFilter1, options?: any): AxiosPromise<Array<CategoriesWithRelations>> {
            return localVarFp.categoriesControllerFind(filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {CategoriesFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesControllerFindById(id: number, filter?: CategoriesFilter, options?: any): AxiosPromise<CategoriesWithRelations> {
            return localVarFp.categoriesControllerFindById(id, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {Categories} [categories] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesControllerReplaceById(id: number, categories?: Categories, options?: any): AxiosPromise<any> {
            return localVarFp.categoriesControllerReplaceById(id, categories, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {CategoriesPartial} [categoriesPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesControllerUpdateAll(where?: { [key: string]: object; }, categoriesPartial?: CategoriesPartial, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.categoriesControllerUpdateAll(where, categoriesPartial, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {CategoriesPartial} [categoriesPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesControllerUpdateById(id: number, categoriesPartial?: CategoriesPartial, options?: any): AxiosPromise<any> {
            return localVarFp.categoriesControllerUpdateById(id, categoriesPartial, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CategoriesControllerApi - object-oriented interface
 * @export
 * @class CategoriesControllerApi
 * @extends {BaseAPI}
 */
export class CategoriesControllerApi extends BaseAPI {
    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesControllerApi
     */
    public categoriesControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig) {
        return CategoriesControllerApiFp(this.configuration).categoriesControllerCount(where, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NewCategories} [newCategories] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesControllerApi
     */
    public categoriesControllerCreate(newCategories?: NewCategories, options?: AxiosRequestConfig) {
        return CategoriesControllerApiFp(this.configuration).categoriesControllerCreate(newCategories, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesControllerApi
     */
    public categoriesControllerDeleteById(id: number, options?: AxiosRequestConfig) {
        return CategoriesControllerApiFp(this.configuration).categoriesControllerDeleteById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CategoriesFilter1} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesControllerApi
     */
    public categoriesControllerFind(filter?: CategoriesFilter1, options?: AxiosRequestConfig) {
        return CategoriesControllerApiFp(this.configuration).categoriesControllerFind(filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {CategoriesFilter} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesControllerApi
     */
    public categoriesControllerFindById(id: number, filter?: CategoriesFilter, options?: AxiosRequestConfig) {
        return CategoriesControllerApiFp(this.configuration).categoriesControllerFindById(id, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {Categories} [categories] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesControllerApi
     */
    public categoriesControllerReplaceById(id: number, categories?: Categories, options?: AxiosRequestConfig) {
        return CategoriesControllerApiFp(this.configuration).categoriesControllerReplaceById(id, categories, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {CategoriesPartial} [categoriesPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesControllerApi
     */
    public categoriesControllerUpdateAll(where?: { [key: string]: object; }, categoriesPartial?: CategoriesPartial, options?: AxiosRequestConfig) {
        return CategoriesControllerApiFp(this.configuration).categoriesControllerUpdateAll(where, categoriesPartial, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {CategoriesPartial} [categoriesPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesControllerApi
     */
    public categoriesControllerUpdateById(id: number, categoriesPartial?: CategoriesPartial, options?: AxiosRequestConfig) {
        return CategoriesControllerApiFp(this.configuration).categoriesControllerUpdateById(id, categoriesPartial, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ClientesControllerApi - axios parameter creator
 * @export
 */
export const ClientesControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientesControllerCount: async (where?: { [key: string]: object; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/clientes/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {NewClientes} [newClientes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientesControllerCreate: async (newClientes?: NewClientes, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/clientes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newClientes, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientesControllerDeleteById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('clientesControllerDeleteById', 'id', id)
            const localVarPath = `/clientes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ClientesFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientesControllerFind: async (filter?: ClientesFilter1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/clientes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ClientesFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientesControllerFindById: async (id: number, filter?: ClientesFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('clientesControllerFindById', 'id', id)
            const localVarPath = `/clientes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientesControllerObtenerClientesFiltrados: async (companyId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('clientesControllerObtenerClientesFiltrados', 'companyId', companyId)
            const localVarPath = `/obtenerClientesFiltrados/{companyId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientesControllerObtenerClientesFiltradosHijos: async (companyId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('clientesControllerObtenerClientesFiltradosHijos', 'companyId', companyId)
            const localVarPath = `/obtenerClientesFiltradosHijos/{companyId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {Clientes} [clientes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientesControllerReplaceById: async (id: number, clientes?: Clientes, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('clientesControllerReplaceById', 'id', id)
            const localVarPath = `/clientes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clientes, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {ClientesPartial} [clientesPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientesControllerUpdateAll: async (where?: { [key: string]: object; }, clientesPartial?: ClientesPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/clientes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clientesPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ClientesPartial} [clientesPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientesControllerUpdateById: async (id: number, clientesPartial?: ClientesPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('clientesControllerUpdateById', 'id', id)
            const localVarPath = `/clientes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clientesPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ClientesControllerApi - functional programming interface
 * @export
 */
export const ClientesControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ClientesControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientesControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientesControllerCount(where, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {NewClientes} [newClientes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientesControllerCreate(newClientes?: NewClientes, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Clientes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientesControllerCreate(newClientes, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientesControllerDeleteById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientesControllerDeleteById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ClientesFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientesControllerFind(filter?: ClientesFilter1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ClientesWithRelations>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientesControllerFind(filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ClientesFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientesControllerFindById(id: number, filter?: ClientesFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientesWithRelations>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientesControllerFindById(id, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientesControllerObtenerClientesFiltrados(companyId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientesControllerObtenerClientesFiltrados(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientesControllerObtenerClientesFiltradosHijos(companyId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientesControllerObtenerClientesFiltradosHijos(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {Clientes} [clientes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientesControllerReplaceById(id: number, clientes?: Clientes, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientesControllerReplaceById(id, clientes, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {ClientesPartial} [clientesPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientesControllerUpdateAll(where?: { [key: string]: object; }, clientesPartial?: ClientesPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientesControllerUpdateAll(where, clientesPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ClientesPartial} [clientesPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientesControllerUpdateById(id: number, clientesPartial?: ClientesPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientesControllerUpdateById(id, clientesPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ClientesControllerApi - factory interface
 * @export
 */
export const ClientesControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ClientesControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientesControllerCount(where?: { [key: string]: object; }, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.clientesControllerCount(where, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NewClientes} [newClientes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientesControllerCreate(newClientes?: NewClientes, options?: any): AxiosPromise<Clientes> {
            return localVarFp.clientesControllerCreate(newClientes, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientesControllerDeleteById(id: number, options?: any): AxiosPromise<any> {
            return localVarFp.clientesControllerDeleteById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ClientesFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientesControllerFind(filter?: ClientesFilter1, options?: any): AxiosPromise<Array<ClientesWithRelations>> {
            return localVarFp.clientesControllerFind(filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ClientesFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientesControllerFindById(id: number, filter?: ClientesFilter, options?: any): AxiosPromise<ClientesWithRelations> {
            return localVarFp.clientesControllerFindById(id, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientesControllerObtenerClientesFiltrados(companyId: number, options?: any): AxiosPromise<object> {
            return localVarFp.clientesControllerObtenerClientesFiltrados(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientesControllerObtenerClientesFiltradosHijos(companyId: number, options?: any): AxiosPromise<object> {
            return localVarFp.clientesControllerObtenerClientesFiltradosHijos(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {Clientes} [clientes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientesControllerReplaceById(id: number, clientes?: Clientes, options?: any): AxiosPromise<any> {
            return localVarFp.clientesControllerReplaceById(id, clientes, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {ClientesPartial} [clientesPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientesControllerUpdateAll(where?: { [key: string]: object; }, clientesPartial?: ClientesPartial, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.clientesControllerUpdateAll(where, clientesPartial, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ClientesPartial} [clientesPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientesControllerUpdateById(id: number, clientesPartial?: ClientesPartial, options?: any): AxiosPromise<any> {
            return localVarFp.clientesControllerUpdateById(id, clientesPartial, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ClientesControllerApi - object-oriented interface
 * @export
 * @class ClientesControllerApi
 * @extends {BaseAPI}
 */
export class ClientesControllerApi extends BaseAPI {
    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientesControllerApi
     */
    public clientesControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig) {
        return ClientesControllerApiFp(this.configuration).clientesControllerCount(where, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NewClientes} [newClientes] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientesControllerApi
     */
    public clientesControllerCreate(newClientes?: NewClientes, options?: AxiosRequestConfig) {
        return ClientesControllerApiFp(this.configuration).clientesControllerCreate(newClientes, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientesControllerApi
     */
    public clientesControllerDeleteById(id: number, options?: AxiosRequestConfig) {
        return ClientesControllerApiFp(this.configuration).clientesControllerDeleteById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ClientesFilter1} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientesControllerApi
     */
    public clientesControllerFind(filter?: ClientesFilter1, options?: AxiosRequestConfig) {
        return ClientesControllerApiFp(this.configuration).clientesControllerFind(filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ClientesFilter} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientesControllerApi
     */
    public clientesControllerFindById(id: number, filter?: ClientesFilter, options?: AxiosRequestConfig) {
        return ClientesControllerApiFp(this.configuration).clientesControllerFindById(id, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientesControllerApi
     */
    public clientesControllerObtenerClientesFiltrados(companyId: number, options?: AxiosRequestConfig) {
        return ClientesControllerApiFp(this.configuration).clientesControllerObtenerClientesFiltrados(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientesControllerApi
     */
    public clientesControllerObtenerClientesFiltradosHijos(companyId: number, options?: AxiosRequestConfig) {
        return ClientesControllerApiFp(this.configuration).clientesControllerObtenerClientesFiltradosHijos(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {Clientes} [clientes] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientesControllerApi
     */
    public clientesControllerReplaceById(id: number, clientes?: Clientes, options?: AxiosRequestConfig) {
        return ClientesControllerApiFp(this.configuration).clientesControllerReplaceById(id, clientes, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {ClientesPartial} [clientesPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientesControllerApi
     */
    public clientesControllerUpdateAll(where?: { [key: string]: object; }, clientesPartial?: ClientesPartial, options?: AxiosRequestConfig) {
        return ClientesControllerApiFp(this.configuration).clientesControllerUpdateAll(where, clientesPartial, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ClientesPartial} [clientesPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientesControllerApi
     */
    public clientesControllerUpdateById(id: number, clientesPartial?: ClientesPartial, options?: AxiosRequestConfig) {
        return ClientesControllerApiFp(this.configuration).clientesControllerUpdateById(id, clientesPartial, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CompaniesControllerApi - axios parameter creator
 * @export
 */
export const CompaniesControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companiesControllerCount: async (where?: { [key: string]: object; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/companies/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {NewCompanies} [newCompanies] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companiesControllerCreate: async (newCompanies?: NewCompanies, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/companies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newCompanies, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companiesControllerDeleteById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('companiesControllerDeleteById', 'id', id)
            const localVarPath = `/companies/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CompaniesFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companiesControllerFind: async (filter?: CompaniesFilter1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/companies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {CompaniesFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companiesControllerFindById: async (id: number, filter?: CompaniesFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('companiesControllerFindById', 'id', id)
            const localVarPath = `/companies/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {Companies} [companies] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companiesControllerReplaceById: async (id: number, companies?: Companies, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('companiesControllerReplaceById', 'id', id)
            const localVarPath = `/companies/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(companies, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {CompaniesPartial} [companiesPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companiesControllerUpdateAll: async (where?: { [key: string]: object; }, companiesPartial?: CompaniesPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/companies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(companiesPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {CompaniesPartial} [companiesPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companiesControllerUpdateById: async (id: number, companiesPartial?: CompaniesPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('companiesControllerUpdateById', 'id', id)
            const localVarPath = `/companies/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(companiesPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CompaniesControllerApi - functional programming interface
 * @export
 */
export const CompaniesControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CompaniesControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companiesControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companiesControllerCount(where, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {NewCompanies} [newCompanies] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companiesControllerCreate(newCompanies?: NewCompanies, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Companies>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companiesControllerCreate(newCompanies, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companiesControllerDeleteById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companiesControllerDeleteById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CompaniesFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companiesControllerFind(filter?: CompaniesFilter1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CompaniesWithRelations>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companiesControllerFind(filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {CompaniesFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companiesControllerFindById(id: number, filter?: CompaniesFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompaniesWithRelations>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companiesControllerFindById(id, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {Companies} [companies] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companiesControllerReplaceById(id: number, companies?: Companies, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companiesControllerReplaceById(id, companies, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {CompaniesPartial} [companiesPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companiesControllerUpdateAll(where?: { [key: string]: object; }, companiesPartial?: CompaniesPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companiesControllerUpdateAll(where, companiesPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {CompaniesPartial} [companiesPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companiesControllerUpdateById(id: number, companiesPartial?: CompaniesPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companiesControllerUpdateById(id, companiesPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CompaniesControllerApi - factory interface
 * @export
 */
export const CompaniesControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CompaniesControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companiesControllerCount(where?: { [key: string]: object; }, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.companiesControllerCount(where, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NewCompanies} [newCompanies] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companiesControllerCreate(newCompanies?: NewCompanies, options?: any): AxiosPromise<Companies> {
            return localVarFp.companiesControllerCreate(newCompanies, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companiesControllerDeleteById(id: number, options?: any): AxiosPromise<any> {
            return localVarFp.companiesControllerDeleteById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CompaniesFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companiesControllerFind(filter?: CompaniesFilter1, options?: any): AxiosPromise<Array<CompaniesWithRelations>> {
            return localVarFp.companiesControllerFind(filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {CompaniesFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companiesControllerFindById(id: number, filter?: CompaniesFilter, options?: any): AxiosPromise<CompaniesWithRelations> {
            return localVarFp.companiesControllerFindById(id, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {Companies} [companies] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companiesControllerReplaceById(id: number, companies?: Companies, options?: any): AxiosPromise<any> {
            return localVarFp.companiesControllerReplaceById(id, companies, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {CompaniesPartial} [companiesPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companiesControllerUpdateAll(where?: { [key: string]: object; }, companiesPartial?: CompaniesPartial, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.companiesControllerUpdateAll(where, companiesPartial, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {CompaniesPartial} [companiesPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companiesControllerUpdateById(id: number, companiesPartial?: CompaniesPartial, options?: any): AxiosPromise<any> {
            return localVarFp.companiesControllerUpdateById(id, companiesPartial, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CompaniesControllerApi - object-oriented interface
 * @export
 * @class CompaniesControllerApi
 * @extends {BaseAPI}
 */
export class CompaniesControllerApi extends BaseAPI {
    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesControllerApi
     */
    public companiesControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig) {
        return CompaniesControllerApiFp(this.configuration).companiesControllerCount(where, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NewCompanies} [newCompanies] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesControllerApi
     */
    public companiesControllerCreate(newCompanies?: NewCompanies, options?: AxiosRequestConfig) {
        return CompaniesControllerApiFp(this.configuration).companiesControllerCreate(newCompanies, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesControllerApi
     */
    public companiesControllerDeleteById(id: number, options?: AxiosRequestConfig) {
        return CompaniesControllerApiFp(this.configuration).companiesControllerDeleteById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CompaniesFilter1} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesControllerApi
     */
    public companiesControllerFind(filter?: CompaniesFilter1, options?: AxiosRequestConfig) {
        return CompaniesControllerApiFp(this.configuration).companiesControllerFind(filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {CompaniesFilter} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesControllerApi
     */
    public companiesControllerFindById(id: number, filter?: CompaniesFilter, options?: AxiosRequestConfig) {
        return CompaniesControllerApiFp(this.configuration).companiesControllerFindById(id, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {Companies} [companies] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesControllerApi
     */
    public companiesControllerReplaceById(id: number, companies?: Companies, options?: AxiosRequestConfig) {
        return CompaniesControllerApiFp(this.configuration).companiesControllerReplaceById(id, companies, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {CompaniesPartial} [companiesPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesControllerApi
     */
    public companiesControllerUpdateAll(where?: { [key: string]: object; }, companiesPartial?: CompaniesPartial, options?: AxiosRequestConfig) {
        return CompaniesControllerApiFp(this.configuration).companiesControllerUpdateAll(where, companiesPartial, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {CompaniesPartial} [companiesPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesControllerApi
     */
    public companiesControllerUpdateById(id: number, companiesPartial?: CompaniesPartial, options?: AxiosRequestConfig) {
        return CompaniesControllerApiFp(this.configuration).companiesControllerUpdateById(id, companiesPartial, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ConexionSatControllerApi - axios parameter creator
 * @export
 */
export const ConexionSatControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} idCaso 
         * @param {string} nombreArchivo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conexionSatControllerDeleteArchivo: async (idCaso: string, nombreArchivo: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idCaso' is not null or undefined
            assertParamExists('conexionSatControllerDeleteArchivo', 'idCaso', idCaso)
            // verify required parameter 'nombreArchivo' is not null or undefined
            assertParamExists('conexionSatControllerDeleteArchivo', 'nombreArchivo', nombreArchivo)
            const localVarPath = `/deleteArchivo/{idCaso}/{nombreArchivo}`
                .replace(`{${"idCaso"}}`, encodeURIComponent(String(idCaso)))
                .replace(`{${"nombreArchivo"}}`, encodeURIComponent(String(nombreArchivo)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} workorderServiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conexionSatControllerDeleteServicio: async (workorderServiceId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workorderServiceId' is not null or undefined
            assertParamExists('conexionSatControllerDeleteServicio', 'workorderServiceId', workorderServiceId)
            const localVarPath = `/deleteServicio/{workorderServiceId}`
                .replace(`{${"workorderServiceId"}}`, encodeURIComponent(String(workorderServiceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} idCaso 
         * @param {string} nombreArchivo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conexionSatControllerDownloadArchivo: async (idCaso: string, nombreArchivo: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idCaso' is not null or undefined
            assertParamExists('conexionSatControllerDownloadArchivo', 'idCaso', idCaso)
            // verify required parameter 'nombreArchivo' is not null or undefined
            assertParamExists('conexionSatControllerDownloadArchivo', 'nombreArchivo', nombreArchivo)
            const localVarPath = `/downloadArchivo/{idCaso}/{nombreArchivo}`
                .replace(`{${"idCaso"}}`, encodeURIComponent(String(idCaso)))
                .replace(`{${"nombreArchivo"}}`, encodeURIComponent(String(nombreArchivo)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [$idCaso] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conexionSatControllerGetArchivos: async ($idCaso?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/archivos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if ($idCaso !== undefined) {
                localVarQueryParameter['$idCaso'] = $idCaso;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [$endpoint] 
         * @param {string} [$select] 
         * @param {string} [$filter] 
         * @param {string} [$orderby] 
         * @param {string} [$top] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conexionSatControllerGetEndpointReservas: async ($endpoint?: string, $select?: string, $filter?: string, $orderby?: string, $top?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/endpointGenericoReservas`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if ($endpoint !== undefined) {
                localVarQueryParameter['$endpoint'] = $endpoint;
            }

            if ($select !== undefined) {
                localVarQueryParameter['$select'] = $select;
            }

            if ($filter !== undefined) {
                localVarQueryParameter['$filter'] = $filter;
            }

            if ($orderby !== undefined) {
                localVarQueryParameter['$orderby'] = $orderby;
            }

            if ($top !== undefined) {
                localVarQueryParameter['$top'] = $top;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [$endpoint] 
         * @param {string} [$select] 
         * @param {string} [$filter] 
         * @param {string} [$orderby] 
         * @param {string} [$top] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conexionSatControllerGetRepuestosBC: async ($endpoint?: string, $select?: string, $filter?: string, $orderby?: string, $top?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/getRepuestosBC`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if ($endpoint !== undefined) {
                localVarQueryParameter['$endpoint'] = $endpoint;
            }

            if ($select !== undefined) {
                localVarQueryParameter['$select'] = $select;
            }

            if ($filter !== undefined) {
                localVarQueryParameter['$filter'] = $filter;
            }

            if ($orderby !== undefined) {
                localVarQueryParameter['$orderby'] = $orderby;
            }

            if ($top !== undefined) {
                localVarQueryParameter['$top'] = $top;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conexionSatControllerGetTokenSharePoint: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/tokenSharePoint`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [$endpoint] 
         * @param {string} [$select] 
         * @param {string} [$filter] 
         * @param {number} [$top] 
         * @param {number} [$skip] 
         * @param {string} [$orderby] 
         * @param {string} [$expand] 
         * @param {boolean} [$count] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conexionSatControllerGetendPoint: async ($endpoint?: string, $select?: string, $filter?: string, $top?: number, $skip?: number, $orderby?: string, $expand?: string, $count?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/endpointGenerico`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if ($endpoint !== undefined) {
                localVarQueryParameter['$endpoint'] = $endpoint;
            }

            if ($select !== undefined) {
                localVarQueryParameter['$select'] = $select;
            }

            if ($filter !== undefined) {
                localVarQueryParameter['$filter'] = $filter;
            }

            if ($top !== undefined) {
                localVarQueryParameter['$top'] = $top;
            }

            if ($skip !== undefined) {
                localVarQueryParameter['$skip'] = $skip;
            }

            if ($orderby !== undefined) {
                localVarQueryParameter['$orderby'] = $orderby;
            }

            if ($expand !== undefined) {
                localVarQueryParameter['$expand'] = $expand;
            }

            if ($count !== undefined) {
                localVarQueryParameter['$count'] = $count;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [guidRepuesto] 
         * @param {string} [ordenDeTrabajo] 
         * @param {string} [guidListaPrecios] 
         * @param {string} [cantAlmacenPropio] 
         * @param {string} [cantAlmacenSvan] 
         * @param {string} [sumaCantidades] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conexionSatControllerGuardarRepuestoCRM: async (guidRepuesto?: string, ordenDeTrabajo?: string, guidListaPrecios?: string, cantAlmacenPropio?: string, cantAlmacenSvan?: string, sumaCantidades?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/guardarRepuestoCRM`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (guidRepuesto !== undefined) {
                localVarQueryParameter['guidRepuesto'] = guidRepuesto;
            }

            if (ordenDeTrabajo !== undefined) {
                localVarQueryParameter['ordenDeTrabajo'] = ordenDeTrabajo;
            }

            if (guidListaPrecios !== undefined) {
                localVarQueryParameter['guidListaPrecios'] = guidListaPrecios;
            }

            if (cantAlmacenPropio !== undefined) {
                localVarQueryParameter['cantAlmacenPropio'] = cantAlmacenPropio;
            }

            if (cantAlmacenSvan !== undefined) {
                localVarQueryParameter['cantAlmacenSvan'] = cantAlmacenSvan;
            }

            if (sumaCantidades !== undefined) {
                localVarQueryParameter['sumaCantidades'] = sumaCantidades;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [$endpoint] 
         * @param {string} [$filter] 
         * @param {string} [$data] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conexionSatControllerPatch: async ($endpoint?: string, $filter?: string, $data?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/actualiza`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if ($endpoint !== undefined) {
                localVarQueryParameter['$endpoint'] = $endpoint;
            }

            if ($filter !== undefined) {
                localVarQueryParameter['$filter'] = $filter;
            }

            if ($data !== undefined) {
                localVarQueryParameter['$data'] = $data;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [idOrden] 
         * @param {string} [idTipoAveria] 
         * @param {string} [idResolucion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conexionSatControllerPostResolucionOrdenTrabajo: async (idOrden?: string, idTipoAveria?: string, idResolucion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/postResolucionOrdenTrabajo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (idOrden !== undefined) {
                localVarQueryParameter['idOrden'] = idOrden;
            }

            if (idTipoAveria !== undefined) {
                localVarQueryParameter['idTipoAveria'] = idTipoAveria;
            }

            if (idResolucion !== undefined) {
                localVarQueryParameter['idResolucion'] = idResolucion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [idOrden] 
         * @param {string} [idSubEstado] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conexionSatControllerPostSubEstado: async (idOrden?: string, idSubEstado?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/postSubEstado`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (idOrden !== undefined) {
                localVarQueryParameter['idOrden'] = idOrden;
            }

            if (idSubEstado !== undefined) {
                localVarQueryParameter['idSubEstado'] = idSubEstado;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [fechaInicio] 
         * @param {string} [fechaFin] 
         * @param {string} [recurso] 
         * @param {string} [ordenDeTrabajo] 
         * @param {string} [notas] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conexionSatControllerReserva: async (fechaInicio?: string, fechaFin?: string, recurso?: string, ordenDeTrabajo?: string, notas?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/reserva`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fechaInicio !== undefined) {
                localVarQueryParameter['fechaInicio'] = fechaInicio;
            }

            if (fechaFin !== undefined) {
                localVarQueryParameter['fechaFin'] = fechaFin;
            }

            if (recurso !== undefined) {
                localVarQueryParameter['recurso'] = recurso;
            }

            if (ordenDeTrabajo !== undefined) {
                localVarQueryParameter['ordenDeTrabajo'] = ordenDeTrabajo;
            }

            if (notas !== undefined) {
                localVarQueryParameter['notas'] = notas;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [servicio] 
         * @param {string} [idOrdenTrabajo] 
         * @param {string} [idListaPreciosOrden] 
         * @param {boolean} [servicioExtra] 
         * @param {string} [descripcionExtra] 
         * @param {string} [costoExtra] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conexionSatControllerServicio: async (servicio?: string, idOrdenTrabajo?: string, idListaPreciosOrden?: string, servicioExtra?: boolean, descripcionExtra?: string, costoExtra?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/servicio`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (servicio !== undefined) {
                localVarQueryParameter['servicio'] = servicio;
            }

            if (idOrdenTrabajo !== undefined) {
                localVarQueryParameter['idOrdenTrabajo'] = idOrdenTrabajo;
            }

            if (idListaPreciosOrden !== undefined) {
                localVarQueryParameter['idListaPreciosOrden'] = idListaPreciosOrden;
            }

            if (servicioExtra !== undefined) {
                localVarQueryParameter['servicioExtra'] = servicioExtra;
            }

            if (descripcionExtra !== undefined) {
                localVarQueryParameter['descripcionExtra'] = descripcionExtra;
            }

            if (costoExtra !== undefined) {
                localVarQueryParameter['costoExtra'] = costoExtra;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [$idCaso] 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conexionSatControllerSubirArchivo: async ($idCaso?: string, file?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/uploadArchivo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if ($idCaso !== undefined) {
                localVarQueryParameter['$idCaso'] = $idCaso;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConexionSatControllerApi - functional programming interface
 * @export
 */
export const ConexionSatControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConexionSatControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} idCaso 
         * @param {string} nombreArchivo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async conexionSatControllerDeleteArchivo(idCaso: string, nombreArchivo: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.conexionSatControllerDeleteArchivo(idCaso, nombreArchivo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} workorderServiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async conexionSatControllerDeleteServicio(workorderServiceId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.conexionSatControllerDeleteServicio(workorderServiceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} idCaso 
         * @param {string} nombreArchivo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async conexionSatControllerDownloadArchivo(idCaso: string, nombreArchivo: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.conexionSatControllerDownloadArchivo(idCaso, nombreArchivo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [$idCaso] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async conexionSatControllerGetArchivos($idCaso?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.conexionSatControllerGetArchivos($idCaso, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [$endpoint] 
         * @param {string} [$select] 
         * @param {string} [$filter] 
         * @param {string} [$orderby] 
         * @param {string} [$top] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async conexionSatControllerGetEndpointReservas($endpoint?: string, $select?: string, $filter?: string, $orderby?: string, $top?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.conexionSatControllerGetEndpointReservas($endpoint, $select, $filter, $orderby, $top, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [$endpoint] 
         * @param {string} [$select] 
         * @param {string} [$filter] 
         * @param {string} [$orderby] 
         * @param {string} [$top] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async conexionSatControllerGetRepuestosBC($endpoint?: string, $select?: string, $filter?: string, $orderby?: string, $top?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.conexionSatControllerGetRepuestosBC($endpoint, $select, $filter, $orderby, $top, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async conexionSatControllerGetTokenSharePoint(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.conexionSatControllerGetTokenSharePoint(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [$endpoint] 
         * @param {string} [$select] 
         * @param {string} [$filter] 
         * @param {number} [$top] 
         * @param {number} [$skip] 
         * @param {string} [$orderby] 
         * @param {string} [$expand] 
         * @param {boolean} [$count] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async conexionSatControllerGetendPoint($endpoint?: string, $select?: string, $filter?: string, $top?: number, $skip?: number, $orderby?: string, $expand?: string, $count?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.conexionSatControllerGetendPoint($endpoint, $select, $filter, $top, $skip, $orderby, $expand, $count, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [guidRepuesto] 
         * @param {string} [ordenDeTrabajo] 
         * @param {string} [guidListaPrecios] 
         * @param {string} [cantAlmacenPropio] 
         * @param {string} [cantAlmacenSvan] 
         * @param {string} [sumaCantidades] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async conexionSatControllerGuardarRepuestoCRM(guidRepuesto?: string, ordenDeTrabajo?: string, guidListaPrecios?: string, cantAlmacenPropio?: string, cantAlmacenSvan?: string, sumaCantidades?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.conexionSatControllerGuardarRepuestoCRM(guidRepuesto, ordenDeTrabajo, guidListaPrecios, cantAlmacenPropio, cantAlmacenSvan, sumaCantidades, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [$endpoint] 
         * @param {string} [$filter] 
         * @param {string} [$data] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async conexionSatControllerPatch($endpoint?: string, $filter?: string, $data?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.conexionSatControllerPatch($endpoint, $filter, $data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [idOrden] 
         * @param {string} [idTipoAveria] 
         * @param {string} [idResolucion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async conexionSatControllerPostResolucionOrdenTrabajo(idOrden?: string, idTipoAveria?: string, idResolucion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.conexionSatControllerPostResolucionOrdenTrabajo(idOrden, idTipoAveria, idResolucion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [idOrden] 
         * @param {string} [idSubEstado] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async conexionSatControllerPostSubEstado(idOrden?: string, idSubEstado?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.conexionSatControllerPostSubEstado(idOrden, idSubEstado, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [fechaInicio] 
         * @param {string} [fechaFin] 
         * @param {string} [recurso] 
         * @param {string} [ordenDeTrabajo] 
         * @param {string} [notas] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async conexionSatControllerReserva(fechaInicio?: string, fechaFin?: string, recurso?: string, ordenDeTrabajo?: string, notas?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.conexionSatControllerReserva(fechaInicio, fechaFin, recurso, ordenDeTrabajo, notas, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [servicio] 
         * @param {string} [idOrdenTrabajo] 
         * @param {string} [idListaPreciosOrden] 
         * @param {boolean} [servicioExtra] 
         * @param {string} [descripcionExtra] 
         * @param {string} [costoExtra] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async conexionSatControllerServicio(servicio?: string, idOrdenTrabajo?: string, idListaPreciosOrden?: string, servicioExtra?: boolean, descripcionExtra?: string, costoExtra?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.conexionSatControllerServicio(servicio, idOrdenTrabajo, idListaPreciosOrden, servicioExtra, descripcionExtra, costoExtra, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [$idCaso] 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async conexionSatControllerSubirArchivo($idCaso?: string, file?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.conexionSatControllerSubirArchivo($idCaso, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ConexionSatControllerApi - factory interface
 * @export
 */
export const ConexionSatControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConexionSatControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} idCaso 
         * @param {string} nombreArchivo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conexionSatControllerDeleteArchivo(idCaso: string, nombreArchivo: string, options?: any): AxiosPromise<any> {
            return localVarFp.conexionSatControllerDeleteArchivo(idCaso, nombreArchivo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} workorderServiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conexionSatControllerDeleteServicio(workorderServiceId: string, options?: any): AxiosPromise<any> {
            return localVarFp.conexionSatControllerDeleteServicio(workorderServiceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} idCaso 
         * @param {string} nombreArchivo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conexionSatControllerDownloadArchivo(idCaso: string, nombreArchivo: string, options?: any): AxiosPromise<any> {
            return localVarFp.conexionSatControllerDownloadArchivo(idCaso, nombreArchivo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [$idCaso] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conexionSatControllerGetArchivos($idCaso?: string, options?: any): AxiosPromise<object> {
            return localVarFp.conexionSatControllerGetArchivos($idCaso, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [$endpoint] 
         * @param {string} [$select] 
         * @param {string} [$filter] 
         * @param {string} [$orderby] 
         * @param {string} [$top] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conexionSatControllerGetEndpointReservas($endpoint?: string, $select?: string, $filter?: string, $orderby?: string, $top?: string, options?: any): AxiosPromise<object> {
            return localVarFp.conexionSatControllerGetEndpointReservas($endpoint, $select, $filter, $orderby, $top, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [$endpoint] 
         * @param {string} [$select] 
         * @param {string} [$filter] 
         * @param {string} [$orderby] 
         * @param {string} [$top] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conexionSatControllerGetRepuestosBC($endpoint?: string, $select?: string, $filter?: string, $orderby?: string, $top?: string, options?: any): AxiosPromise<object> {
            return localVarFp.conexionSatControllerGetRepuestosBC($endpoint, $select, $filter, $orderby, $top, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conexionSatControllerGetTokenSharePoint(options?: any): AxiosPromise<void> {
            return localVarFp.conexionSatControllerGetTokenSharePoint(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [$endpoint] 
         * @param {string} [$select] 
         * @param {string} [$filter] 
         * @param {number} [$top] 
         * @param {number} [$skip] 
         * @param {string} [$orderby] 
         * @param {string} [$expand] 
         * @param {boolean} [$count] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conexionSatControllerGetendPoint($endpoint?: string, $select?: string, $filter?: string, $top?: number, $skip?: number, $orderby?: string, $expand?: string, $count?: boolean, options?: any): AxiosPromise<object> {
            return localVarFp.conexionSatControllerGetendPoint($endpoint, $select, $filter, $top, $skip, $orderby, $expand, $count, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [guidRepuesto] 
         * @param {string} [ordenDeTrabajo] 
         * @param {string} [guidListaPrecios] 
         * @param {string} [cantAlmacenPropio] 
         * @param {string} [cantAlmacenSvan] 
         * @param {string} [sumaCantidades] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conexionSatControllerGuardarRepuestoCRM(guidRepuesto?: string, ordenDeTrabajo?: string, guidListaPrecios?: string, cantAlmacenPropio?: string, cantAlmacenSvan?: string, sumaCantidades?: string, options?: any): AxiosPromise<void> {
            return localVarFp.conexionSatControllerGuardarRepuestoCRM(guidRepuesto, ordenDeTrabajo, guidListaPrecios, cantAlmacenPropio, cantAlmacenSvan, sumaCantidades, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [$endpoint] 
         * @param {string} [$filter] 
         * @param {string} [$data] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conexionSatControllerPatch($endpoint?: string, $filter?: string, $data?: string, options?: any): AxiosPromise<object> {
            return localVarFp.conexionSatControllerPatch($endpoint, $filter, $data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [idOrden] 
         * @param {string} [idTipoAveria] 
         * @param {string} [idResolucion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conexionSatControllerPostResolucionOrdenTrabajo(idOrden?: string, idTipoAveria?: string, idResolucion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.conexionSatControllerPostResolucionOrdenTrabajo(idOrden, idTipoAveria, idResolucion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [idOrden] 
         * @param {string} [idSubEstado] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conexionSatControllerPostSubEstado(idOrden?: string, idSubEstado?: string, options?: any): AxiosPromise<void> {
            return localVarFp.conexionSatControllerPostSubEstado(idOrden, idSubEstado, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [fechaInicio] 
         * @param {string} [fechaFin] 
         * @param {string} [recurso] 
         * @param {string} [ordenDeTrabajo] 
         * @param {string} [notas] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conexionSatControllerReserva(fechaInicio?: string, fechaFin?: string, recurso?: string, ordenDeTrabajo?: string, notas?: string, options?: any): AxiosPromise<void> {
            return localVarFp.conexionSatControllerReserva(fechaInicio, fechaFin, recurso, ordenDeTrabajo, notas, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [servicio] 
         * @param {string} [idOrdenTrabajo] 
         * @param {string} [idListaPreciosOrden] 
         * @param {boolean} [servicioExtra] 
         * @param {string} [descripcionExtra] 
         * @param {string} [costoExtra] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conexionSatControllerServicio(servicio?: string, idOrdenTrabajo?: string, idListaPreciosOrden?: string, servicioExtra?: boolean, descripcionExtra?: string, costoExtra?: string, options?: any): AxiosPromise<void> {
            return localVarFp.conexionSatControllerServicio(servicio, idOrdenTrabajo, idListaPreciosOrden, servicioExtra, descripcionExtra, costoExtra, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [$idCaso] 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conexionSatControllerSubirArchivo($idCaso?: string, file?: any, options?: any): AxiosPromise<object> {
            return localVarFp.conexionSatControllerSubirArchivo($idCaso, file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ConexionSatControllerApi - object-oriented interface
 * @export
 * @class ConexionSatControllerApi
 * @extends {BaseAPI}
 */
export class ConexionSatControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} idCaso 
     * @param {string} nombreArchivo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConexionSatControllerApi
     */
    public conexionSatControllerDeleteArchivo(idCaso: string, nombreArchivo: string, options?: AxiosRequestConfig) {
        return ConexionSatControllerApiFp(this.configuration).conexionSatControllerDeleteArchivo(idCaso, nombreArchivo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} workorderServiceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConexionSatControllerApi
     */
    public conexionSatControllerDeleteServicio(workorderServiceId: string, options?: AxiosRequestConfig) {
        return ConexionSatControllerApiFp(this.configuration).conexionSatControllerDeleteServicio(workorderServiceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} idCaso 
     * @param {string} nombreArchivo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConexionSatControllerApi
     */
    public conexionSatControllerDownloadArchivo(idCaso: string, nombreArchivo: string, options?: AxiosRequestConfig) {
        return ConexionSatControllerApiFp(this.configuration).conexionSatControllerDownloadArchivo(idCaso, nombreArchivo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [$idCaso] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConexionSatControllerApi
     */
    public conexionSatControllerGetArchivos($idCaso?: string, options?: AxiosRequestConfig) {
        return ConexionSatControllerApiFp(this.configuration).conexionSatControllerGetArchivos($idCaso, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [$endpoint] 
     * @param {string} [$select] 
     * @param {string} [$filter] 
     * @param {string} [$orderby] 
     * @param {string} [$top] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConexionSatControllerApi
     */
    public conexionSatControllerGetEndpointReservas($endpoint?: string, $select?: string, $filter?: string, $orderby?: string, $top?: string, options?: AxiosRequestConfig) {
        return ConexionSatControllerApiFp(this.configuration).conexionSatControllerGetEndpointReservas($endpoint, $select, $filter, $orderby, $top, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [$endpoint] 
     * @param {string} [$select] 
     * @param {string} [$filter] 
     * @param {string} [$orderby] 
     * @param {string} [$top] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConexionSatControllerApi
     */
    public conexionSatControllerGetRepuestosBC($endpoint?: string, $select?: string, $filter?: string, $orderby?: string, $top?: string, options?: AxiosRequestConfig) {
        return ConexionSatControllerApiFp(this.configuration).conexionSatControllerGetRepuestosBC($endpoint, $select, $filter, $orderby, $top, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConexionSatControllerApi
     */
    public conexionSatControllerGetTokenSharePoint(options?: AxiosRequestConfig) {
        return ConexionSatControllerApiFp(this.configuration).conexionSatControllerGetTokenSharePoint(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [$endpoint] 
     * @param {string} [$select] 
     * @param {string} [$filter] 
     * @param {number} [$top] 
     * @param {number} [$skip] 
     * @param {string} [$orderby] 
     * @param {string} [$expand] 
     * @param {boolean} [$count] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConexionSatControllerApi
     */
    public conexionSatControllerGetendPoint($endpoint?: string, $select?: string, $filter?: string, $top?: number, $skip?: number, $orderby?: string, $expand?: string, $count?: boolean, options?: AxiosRequestConfig) {
        return ConexionSatControllerApiFp(this.configuration).conexionSatControllerGetendPoint($endpoint, $select, $filter, $top, $skip, $orderby, $expand, $count, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [guidRepuesto] 
     * @param {string} [ordenDeTrabajo] 
     * @param {string} [guidListaPrecios] 
     * @param {string} [cantAlmacenPropio] 
     * @param {string} [cantAlmacenSvan] 
     * @param {string} [sumaCantidades] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConexionSatControllerApi
     */
    public conexionSatControllerGuardarRepuestoCRM(guidRepuesto?: string, ordenDeTrabajo?: string, guidListaPrecios?: string, cantAlmacenPropio?: string, cantAlmacenSvan?: string, sumaCantidades?: string, options?: AxiosRequestConfig) {
        return ConexionSatControllerApiFp(this.configuration).conexionSatControllerGuardarRepuestoCRM(guidRepuesto, ordenDeTrabajo, guidListaPrecios, cantAlmacenPropio, cantAlmacenSvan, sumaCantidades, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [$endpoint] 
     * @param {string} [$filter] 
     * @param {string} [$data] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConexionSatControllerApi
     */
    public conexionSatControllerPatch($endpoint?: string, $filter?: string, $data?: string, options?: AxiosRequestConfig) {
        return ConexionSatControllerApiFp(this.configuration).conexionSatControllerPatch($endpoint, $filter, $data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [idOrden] 
     * @param {string} [idTipoAveria] 
     * @param {string} [idResolucion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConexionSatControllerApi
     */
    public conexionSatControllerPostResolucionOrdenTrabajo(idOrden?: string, idTipoAveria?: string, idResolucion?: string, options?: AxiosRequestConfig) {
        return ConexionSatControllerApiFp(this.configuration).conexionSatControllerPostResolucionOrdenTrabajo(idOrden, idTipoAveria, idResolucion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [idOrden] 
     * @param {string} [idSubEstado] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConexionSatControllerApi
     */
    public conexionSatControllerPostSubEstado(idOrden?: string, idSubEstado?: string, options?: AxiosRequestConfig) {
        return ConexionSatControllerApiFp(this.configuration).conexionSatControllerPostSubEstado(idOrden, idSubEstado, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [fechaInicio] 
     * @param {string} [fechaFin] 
     * @param {string} [recurso] 
     * @param {string} [ordenDeTrabajo] 
     * @param {string} [notas] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConexionSatControllerApi
     */
    public conexionSatControllerReserva(fechaInicio?: string, fechaFin?: string, recurso?: string, ordenDeTrabajo?: string, notas?: string, options?: AxiosRequestConfig) {
        return ConexionSatControllerApiFp(this.configuration).conexionSatControllerReserva(fechaInicio, fechaFin, recurso, ordenDeTrabajo, notas, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [servicio] 
     * @param {string} [idOrdenTrabajo] 
     * @param {string} [idListaPreciosOrden] 
     * @param {boolean} [servicioExtra] 
     * @param {string} [descripcionExtra] 
     * @param {string} [costoExtra] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConexionSatControllerApi
     */
    public conexionSatControllerServicio(servicio?: string, idOrdenTrabajo?: string, idListaPreciosOrden?: string, servicioExtra?: boolean, descripcionExtra?: string, costoExtra?: string, options?: AxiosRequestConfig) {
        return ConexionSatControllerApiFp(this.configuration).conexionSatControllerServicio(servicio, idOrdenTrabajo, idListaPreciosOrden, servicioExtra, descripcionExtra, costoExtra, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [$idCaso] 
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConexionSatControllerApi
     */
    public conexionSatControllerSubirArchivo($idCaso?: string, file?: any, options?: AxiosRequestConfig) {
        return ConexionSatControllerApiFp(this.configuration).conexionSatControllerSubirArchivo($idCaso, file, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FailedLoginsControllerApi - axios parameter creator
 * @export
 */
export const FailedLoginsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        failedLoginsControllerCount: async (where?: { [key: string]: object; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/failed-logins/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {NewFailedLogins} [newFailedLogins] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        failedLoginsControllerCreate: async (newFailedLogins?: NewFailedLogins, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/failed-logins`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newFailedLogins, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        failedLoginsControllerDeleteById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('failedLoginsControllerDeleteById', 'id', id)
            const localVarPath = `/failed-logins/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FailedLoginsFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        failedLoginsControllerFind: async (filter?: FailedLoginsFilter1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/failed-logins`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {FailedLoginsFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        failedLoginsControllerFindById: async (id: number, filter?: FailedLoginsFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('failedLoginsControllerFindById', 'id', id)
            const localVarPath = `/failed-logins/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {FailedLogins} [failedLogins] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        failedLoginsControllerReplaceById: async (id: number, failedLogins?: FailedLogins, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('failedLoginsControllerReplaceById', 'id', id)
            const localVarPath = `/failed-logins/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(failedLogins, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {FailedLoginsPartial} [failedLoginsPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        failedLoginsControllerUpdateAll: async (where?: { [key: string]: object; }, failedLoginsPartial?: FailedLoginsPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/failed-logins`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(failedLoginsPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {FailedLoginsPartial} [failedLoginsPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        failedLoginsControllerUpdateById: async (id: number, failedLoginsPartial?: FailedLoginsPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('failedLoginsControllerUpdateById', 'id', id)
            const localVarPath = `/failed-logins/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(failedLoginsPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FailedLoginsControllerApi - functional programming interface
 * @export
 */
export const FailedLoginsControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FailedLoginsControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async failedLoginsControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.failedLoginsControllerCount(where, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {NewFailedLogins} [newFailedLogins] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async failedLoginsControllerCreate(newFailedLogins?: NewFailedLogins, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FailedLogins>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.failedLoginsControllerCreate(newFailedLogins, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async failedLoginsControllerDeleteById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.failedLoginsControllerDeleteById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {FailedLoginsFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async failedLoginsControllerFind(filter?: FailedLoginsFilter1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FailedLoginsWithRelations>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.failedLoginsControllerFind(filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {FailedLoginsFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async failedLoginsControllerFindById(id: number, filter?: FailedLoginsFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FailedLoginsWithRelations>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.failedLoginsControllerFindById(id, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {FailedLogins} [failedLogins] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async failedLoginsControllerReplaceById(id: number, failedLogins?: FailedLogins, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.failedLoginsControllerReplaceById(id, failedLogins, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {FailedLoginsPartial} [failedLoginsPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async failedLoginsControllerUpdateAll(where?: { [key: string]: object; }, failedLoginsPartial?: FailedLoginsPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.failedLoginsControllerUpdateAll(where, failedLoginsPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {FailedLoginsPartial} [failedLoginsPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async failedLoginsControllerUpdateById(id: number, failedLoginsPartial?: FailedLoginsPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.failedLoginsControllerUpdateById(id, failedLoginsPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FailedLoginsControllerApi - factory interface
 * @export
 */
export const FailedLoginsControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FailedLoginsControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        failedLoginsControllerCount(where?: { [key: string]: object; }, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.failedLoginsControllerCount(where, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NewFailedLogins} [newFailedLogins] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        failedLoginsControllerCreate(newFailedLogins?: NewFailedLogins, options?: any): AxiosPromise<FailedLogins> {
            return localVarFp.failedLoginsControllerCreate(newFailedLogins, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        failedLoginsControllerDeleteById(id: number, options?: any): AxiosPromise<any> {
            return localVarFp.failedLoginsControllerDeleteById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FailedLoginsFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        failedLoginsControllerFind(filter?: FailedLoginsFilter1, options?: any): AxiosPromise<Array<FailedLoginsWithRelations>> {
            return localVarFp.failedLoginsControllerFind(filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {FailedLoginsFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        failedLoginsControllerFindById(id: number, filter?: FailedLoginsFilter, options?: any): AxiosPromise<FailedLoginsWithRelations> {
            return localVarFp.failedLoginsControllerFindById(id, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {FailedLogins} [failedLogins] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        failedLoginsControllerReplaceById(id: number, failedLogins?: FailedLogins, options?: any): AxiosPromise<any> {
            return localVarFp.failedLoginsControllerReplaceById(id, failedLogins, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {FailedLoginsPartial} [failedLoginsPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        failedLoginsControllerUpdateAll(where?: { [key: string]: object; }, failedLoginsPartial?: FailedLoginsPartial, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.failedLoginsControllerUpdateAll(where, failedLoginsPartial, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {FailedLoginsPartial} [failedLoginsPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        failedLoginsControllerUpdateById(id: number, failedLoginsPartial?: FailedLoginsPartial, options?: any): AxiosPromise<any> {
            return localVarFp.failedLoginsControllerUpdateById(id, failedLoginsPartial, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FailedLoginsControllerApi - object-oriented interface
 * @export
 * @class FailedLoginsControllerApi
 * @extends {BaseAPI}
 */
export class FailedLoginsControllerApi extends BaseAPI {
    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FailedLoginsControllerApi
     */
    public failedLoginsControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig) {
        return FailedLoginsControllerApiFp(this.configuration).failedLoginsControllerCount(where, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NewFailedLogins} [newFailedLogins] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FailedLoginsControllerApi
     */
    public failedLoginsControllerCreate(newFailedLogins?: NewFailedLogins, options?: AxiosRequestConfig) {
        return FailedLoginsControllerApiFp(this.configuration).failedLoginsControllerCreate(newFailedLogins, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FailedLoginsControllerApi
     */
    public failedLoginsControllerDeleteById(id: number, options?: AxiosRequestConfig) {
        return FailedLoginsControllerApiFp(this.configuration).failedLoginsControllerDeleteById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FailedLoginsFilter1} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FailedLoginsControllerApi
     */
    public failedLoginsControllerFind(filter?: FailedLoginsFilter1, options?: AxiosRequestConfig) {
        return FailedLoginsControllerApiFp(this.configuration).failedLoginsControllerFind(filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {FailedLoginsFilter} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FailedLoginsControllerApi
     */
    public failedLoginsControllerFindById(id: number, filter?: FailedLoginsFilter, options?: AxiosRequestConfig) {
        return FailedLoginsControllerApiFp(this.configuration).failedLoginsControllerFindById(id, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {FailedLogins} [failedLogins] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FailedLoginsControllerApi
     */
    public failedLoginsControllerReplaceById(id: number, failedLogins?: FailedLogins, options?: AxiosRequestConfig) {
        return FailedLoginsControllerApiFp(this.configuration).failedLoginsControllerReplaceById(id, failedLogins, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {FailedLoginsPartial} [failedLoginsPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FailedLoginsControllerApi
     */
    public failedLoginsControllerUpdateAll(where?: { [key: string]: object; }, failedLoginsPartial?: FailedLoginsPartial, options?: AxiosRequestConfig) {
        return FailedLoginsControllerApiFp(this.configuration).failedLoginsControllerUpdateAll(where, failedLoginsPartial, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {FailedLoginsPartial} [failedLoginsPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FailedLoginsControllerApi
     */
    public failedLoginsControllerUpdateById(id: number, failedLoginsPartial?: FailedLoginsPartial, options?: AxiosRequestConfig) {
        return FailedLoginsControllerApiFp(this.configuration).failedLoginsControllerUpdateById(id, failedLoginsPartial, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FileDownloadControllerApi - axios parameter creator
 * @export
 */
export const FileDownloadControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} filename 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileDownloadControllerDownloadFile: async (filename: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'filename' is not null or undefined
            assertParamExists('fileDownloadControllerDownloadFile', 'filename', filename)
            const localVarPath = `/files-download/{filename}`
                .replace(`{${"filename"}}`, encodeURIComponent(String(filename)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileDownloadControllerListFiles: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/files-download`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FileDownloadControllerApi - functional programming interface
 * @export
 */
export const FileDownloadControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FileDownloadControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} filename 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileDownloadControllerDownloadFile(filename: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileDownloadControllerDownloadFile(filename, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileDownloadControllerListFiles(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileDownloadControllerListFiles(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FileDownloadControllerApi - factory interface
 * @export
 */
export const FileDownloadControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FileDownloadControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} filename 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileDownloadControllerDownloadFile(filename: string, options?: any): AxiosPromise<any> {
            return localVarFp.fileDownloadControllerDownloadFile(filename, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileDownloadControllerListFiles(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.fileDownloadControllerListFiles(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FileDownloadControllerApi - object-oriented interface
 * @export
 * @class FileDownloadControllerApi
 * @extends {BaseAPI}
 */
export class FileDownloadControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} filename 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileDownloadControllerApi
     */
    public fileDownloadControllerDownloadFile(filename: string, options?: AxiosRequestConfig) {
        return FileDownloadControllerApiFp(this.configuration).fileDownloadControllerDownloadFile(filename, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileDownloadControllerApi
     */
    public fileDownloadControllerListFiles(options?: AxiosRequestConfig) {
        return FileDownloadControllerApiFp(this.configuration).fileDownloadControllerListFiles(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FileUploadControllerApi - axios parameter creator
 * @export
 */
export const FileUploadControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUploadControllerFileUpload: async (file?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/files-upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FileUploadControllerApi - functional programming interface
 * @export
 */
export const FileUploadControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FileUploadControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileUploadControllerFileUpload(file?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileUploadControllerFileUpload(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FileUploadControllerApi - factory interface
 * @export
 */
export const FileUploadControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FileUploadControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUploadControllerFileUpload(file?: any, options?: any): AxiosPromise<object> {
            return localVarFp.fileUploadControllerFileUpload(file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FileUploadControllerApi - object-oriented interface
 * @export
 * @class FileUploadControllerApi
 * @extends {BaseAPI}
 */
export class FileUploadControllerApi extends BaseAPI {
    /**
     * 
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileUploadControllerApi
     */
    public fileUploadControllerFileUpload(file?: any, options?: AxiosRequestConfig) {
        return FileUploadControllerApiFp(this.configuration).fileUploadControllerFileUpload(file, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FilesControllerApi - axios parameter creator
 * @export
 */
export const FilesControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filesControllerCount: async (where?: { [key: string]: object; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/files/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {NewFiles} [newFiles] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filesControllerCreate: async (newFiles?: NewFiles, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/files`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newFiles, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filesControllerDeleteById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('filesControllerDeleteById', 'id', id)
            const localVarPath = `/files/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FilesFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filesControllerFind: async (filter?: FilesFilter1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/files`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {FilesFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filesControllerFindById: async (id: number, filter?: FilesFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('filesControllerFindById', 'id', id)
            const localVarPath = `/files/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {Files} [files] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filesControllerReplaceById: async (id: number, files?: Files, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('filesControllerReplaceById', 'id', id)
            const localVarPath = `/files/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(files, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {FilesPartial} [filesPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filesControllerUpdateAll: async (where?: { [key: string]: object; }, filesPartial?: FilesPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/files`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(filesPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {FilesPartial} [filesPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filesControllerUpdateById: async (id: number, filesPartial?: FilesPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('filesControllerUpdateById', 'id', id)
            const localVarPath = `/files/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(filesPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FilesControllerApi - functional programming interface
 * @export
 */
export const FilesControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FilesControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async filesControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.filesControllerCount(where, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {NewFiles} [newFiles] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async filesControllerCreate(newFiles?: NewFiles, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Files>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.filesControllerCreate(newFiles, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async filesControllerDeleteById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.filesControllerDeleteById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {FilesFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async filesControllerFind(filter?: FilesFilter1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FilesWithRelations>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.filesControllerFind(filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {FilesFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async filesControllerFindById(id: number, filter?: FilesFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FilesWithRelations>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.filesControllerFindById(id, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {Files} [files] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async filesControllerReplaceById(id: number, files?: Files, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.filesControllerReplaceById(id, files, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {FilesPartial} [filesPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async filesControllerUpdateAll(where?: { [key: string]: object; }, filesPartial?: FilesPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.filesControllerUpdateAll(where, filesPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {FilesPartial} [filesPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async filesControllerUpdateById(id: number, filesPartial?: FilesPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.filesControllerUpdateById(id, filesPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FilesControllerApi - factory interface
 * @export
 */
export const FilesControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FilesControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filesControllerCount(where?: { [key: string]: object; }, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.filesControllerCount(where, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NewFiles} [newFiles] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filesControllerCreate(newFiles?: NewFiles, options?: any): AxiosPromise<Files> {
            return localVarFp.filesControllerCreate(newFiles, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filesControllerDeleteById(id: number, options?: any): AxiosPromise<any> {
            return localVarFp.filesControllerDeleteById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FilesFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filesControllerFind(filter?: FilesFilter1, options?: any): AxiosPromise<Array<FilesWithRelations>> {
            return localVarFp.filesControllerFind(filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {FilesFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filesControllerFindById(id: number, filter?: FilesFilter, options?: any): AxiosPromise<FilesWithRelations> {
            return localVarFp.filesControllerFindById(id, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {Files} [files] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filesControllerReplaceById(id: number, files?: Files, options?: any): AxiosPromise<any> {
            return localVarFp.filesControllerReplaceById(id, files, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {FilesPartial} [filesPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filesControllerUpdateAll(where?: { [key: string]: object; }, filesPartial?: FilesPartial, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.filesControllerUpdateAll(where, filesPartial, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {FilesPartial} [filesPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filesControllerUpdateById(id: number, filesPartial?: FilesPartial, options?: any): AxiosPromise<any> {
            return localVarFp.filesControllerUpdateById(id, filesPartial, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FilesControllerApi - object-oriented interface
 * @export
 * @class FilesControllerApi
 * @extends {BaseAPI}
 */
export class FilesControllerApi extends BaseAPI {
    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesControllerApi
     */
    public filesControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig) {
        return FilesControllerApiFp(this.configuration).filesControllerCount(where, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NewFiles} [newFiles] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesControllerApi
     */
    public filesControllerCreate(newFiles?: NewFiles, options?: AxiosRequestConfig) {
        return FilesControllerApiFp(this.configuration).filesControllerCreate(newFiles, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesControllerApi
     */
    public filesControllerDeleteById(id: number, options?: AxiosRequestConfig) {
        return FilesControllerApiFp(this.configuration).filesControllerDeleteById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FilesFilter1} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesControllerApi
     */
    public filesControllerFind(filter?: FilesFilter1, options?: AxiosRequestConfig) {
        return FilesControllerApiFp(this.configuration).filesControllerFind(filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {FilesFilter} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesControllerApi
     */
    public filesControllerFindById(id: number, filter?: FilesFilter, options?: AxiosRequestConfig) {
        return FilesControllerApiFp(this.configuration).filesControllerFindById(id, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {Files} [files] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesControllerApi
     */
    public filesControllerReplaceById(id: number, files?: Files, options?: AxiosRequestConfig) {
        return FilesControllerApiFp(this.configuration).filesControllerReplaceById(id, files, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {FilesPartial} [filesPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesControllerApi
     */
    public filesControllerUpdateAll(where?: { [key: string]: object; }, filesPartial?: FilesPartial, options?: AxiosRequestConfig) {
        return FilesControllerApiFp(this.configuration).filesControllerUpdateAll(where, filesPartial, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {FilesPartial} [filesPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesControllerApi
     */
    public filesControllerUpdateById(id: number, filesPartial?: FilesPartial, options?: AxiosRequestConfig) {
        return FilesControllerApiFp(this.configuration).filesControllerUpdateById(id, filesPartial, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LanguagesControllerApi - axios parameter creator
 * @export
 */
export const LanguagesControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        languagesControllerCount: async (where?: { [key: string]: object; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/languages/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {NewLanguages} [newLanguages] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        languagesControllerCreate: async (newLanguages?: NewLanguages, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/languages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newLanguages, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        languagesControllerDeleteById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('languagesControllerDeleteById', 'id', id)
            const localVarPath = `/languages/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LanguagesFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        languagesControllerFind: async (filter?: LanguagesFilter1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/languages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {LanguagesFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        languagesControllerFindById: async (id: number, filter?: LanguagesFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('languagesControllerFindById', 'id', id)
            const localVarPath = `/languages/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {Languages} [languages] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        languagesControllerReplaceById: async (id: number, languages?: Languages, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('languagesControllerReplaceById', 'id', id)
            const localVarPath = `/languages/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(languages, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {LanguagesPartial} [languagesPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        languagesControllerUpdateAll: async (where?: { [key: string]: object; }, languagesPartial?: LanguagesPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/languages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(languagesPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {LanguagesPartial} [languagesPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        languagesControllerUpdateById: async (id: number, languagesPartial?: LanguagesPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('languagesControllerUpdateById', 'id', id)
            const localVarPath = `/languages/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(languagesPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LanguagesControllerApi - functional programming interface
 * @export
 */
export const LanguagesControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LanguagesControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async languagesControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.languagesControllerCount(where, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {NewLanguages} [newLanguages] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async languagesControllerCreate(newLanguages?: NewLanguages, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Languages>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.languagesControllerCreate(newLanguages, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async languagesControllerDeleteById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.languagesControllerDeleteById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {LanguagesFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async languagesControllerFind(filter?: LanguagesFilter1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LanguagesWithRelations>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.languagesControllerFind(filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {LanguagesFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async languagesControllerFindById(id: number, filter?: LanguagesFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LanguagesWithRelations>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.languagesControllerFindById(id, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {Languages} [languages] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async languagesControllerReplaceById(id: number, languages?: Languages, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.languagesControllerReplaceById(id, languages, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {LanguagesPartial} [languagesPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async languagesControllerUpdateAll(where?: { [key: string]: object; }, languagesPartial?: LanguagesPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.languagesControllerUpdateAll(where, languagesPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {LanguagesPartial} [languagesPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async languagesControllerUpdateById(id: number, languagesPartial?: LanguagesPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.languagesControllerUpdateById(id, languagesPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LanguagesControllerApi - factory interface
 * @export
 */
export const LanguagesControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LanguagesControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        languagesControllerCount(where?: { [key: string]: object; }, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.languagesControllerCount(where, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NewLanguages} [newLanguages] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        languagesControllerCreate(newLanguages?: NewLanguages, options?: any): AxiosPromise<Languages> {
            return localVarFp.languagesControllerCreate(newLanguages, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        languagesControllerDeleteById(id: number, options?: any): AxiosPromise<any> {
            return localVarFp.languagesControllerDeleteById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LanguagesFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        languagesControllerFind(filter?: LanguagesFilter1, options?: any): AxiosPromise<Array<LanguagesWithRelations>> {
            return localVarFp.languagesControllerFind(filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {LanguagesFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        languagesControllerFindById(id: number, filter?: LanguagesFilter, options?: any): AxiosPromise<LanguagesWithRelations> {
            return localVarFp.languagesControllerFindById(id, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {Languages} [languages] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        languagesControllerReplaceById(id: number, languages?: Languages, options?: any): AxiosPromise<any> {
            return localVarFp.languagesControllerReplaceById(id, languages, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {LanguagesPartial} [languagesPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        languagesControllerUpdateAll(where?: { [key: string]: object; }, languagesPartial?: LanguagesPartial, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.languagesControllerUpdateAll(where, languagesPartial, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {LanguagesPartial} [languagesPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        languagesControllerUpdateById(id: number, languagesPartial?: LanguagesPartial, options?: any): AxiosPromise<any> {
            return localVarFp.languagesControllerUpdateById(id, languagesPartial, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LanguagesControllerApi - object-oriented interface
 * @export
 * @class LanguagesControllerApi
 * @extends {BaseAPI}
 */
export class LanguagesControllerApi extends BaseAPI {
    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LanguagesControllerApi
     */
    public languagesControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig) {
        return LanguagesControllerApiFp(this.configuration).languagesControllerCount(where, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NewLanguages} [newLanguages] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LanguagesControllerApi
     */
    public languagesControllerCreate(newLanguages?: NewLanguages, options?: AxiosRequestConfig) {
        return LanguagesControllerApiFp(this.configuration).languagesControllerCreate(newLanguages, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LanguagesControllerApi
     */
    public languagesControllerDeleteById(id: number, options?: AxiosRequestConfig) {
        return LanguagesControllerApiFp(this.configuration).languagesControllerDeleteById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LanguagesFilter1} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LanguagesControllerApi
     */
    public languagesControllerFind(filter?: LanguagesFilter1, options?: AxiosRequestConfig) {
        return LanguagesControllerApiFp(this.configuration).languagesControllerFind(filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {LanguagesFilter} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LanguagesControllerApi
     */
    public languagesControllerFindById(id: number, filter?: LanguagesFilter, options?: AxiosRequestConfig) {
        return LanguagesControllerApiFp(this.configuration).languagesControllerFindById(id, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {Languages} [languages] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LanguagesControllerApi
     */
    public languagesControllerReplaceById(id: number, languages?: Languages, options?: AxiosRequestConfig) {
        return LanguagesControllerApiFp(this.configuration).languagesControllerReplaceById(id, languages, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {LanguagesPartial} [languagesPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LanguagesControllerApi
     */
    public languagesControllerUpdateAll(where?: { [key: string]: object; }, languagesPartial?: LanguagesPartial, options?: AxiosRequestConfig) {
        return LanguagesControllerApiFp(this.configuration).languagesControllerUpdateAll(where, languagesPartial, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {LanguagesPartial} [languagesPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LanguagesControllerApi
     */
    public languagesControllerUpdateById(id: number, languagesPartial?: LanguagesPartial, options?: AxiosRequestConfig) {
        return LanguagesControllerApiFp(this.configuration).languagesControllerUpdateById(id, languagesPartial, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LogControllerApi - axios parameter creator
 * @export
 */
export const LogControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logControllerCount: async (where?: { [key: string]: object; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/logs/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {NewLog} [newLog] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logControllerCreate: async (newLog?: NewLog, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/logs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newLog, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logControllerDeleteById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('logControllerDeleteById', 'id', id)
            const localVarPath = `/logs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LogFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logControllerFind: async (filter?: LogFilter1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/logs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {LogFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logControllerFindById: async (id: number, filter?: LogFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('logControllerFindById', 'id', id)
            const localVarPath = `/logs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {Log} [log] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logControllerReplaceById: async (id: number, log?: Log, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('logControllerReplaceById', 'id', id)
            const localVarPath = `/logs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(log, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {LogPartial} [logPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logControllerUpdateAll: async (where?: { [key: string]: object; }, logPartial?: LogPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/logs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(logPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {LogPartial} [logPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logControllerUpdateById: async (id: number, logPartial?: LogPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('logControllerUpdateById', 'id', id)
            const localVarPath = `/logs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(logPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LogControllerApi - functional programming interface
 * @export
 */
export const LogControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LogControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logControllerCount(where, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {NewLog} [newLog] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logControllerCreate(newLog?: NewLog, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Log>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logControllerCreate(newLog, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logControllerDeleteById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logControllerDeleteById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {LogFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logControllerFind(filter?: LogFilter1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LogWithRelations>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logControllerFind(filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {LogFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logControllerFindById(id: number, filter?: LogFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LogWithRelations>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logControllerFindById(id, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {Log} [log] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logControllerReplaceById(id: number, log?: Log, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logControllerReplaceById(id, log, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {LogPartial} [logPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logControllerUpdateAll(where?: { [key: string]: object; }, logPartial?: LogPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logControllerUpdateAll(where, logPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {LogPartial} [logPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logControllerUpdateById(id: number, logPartial?: LogPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logControllerUpdateById(id, logPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LogControllerApi - factory interface
 * @export
 */
export const LogControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LogControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logControllerCount(where?: { [key: string]: object; }, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.logControllerCount(where, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NewLog} [newLog] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logControllerCreate(newLog?: NewLog, options?: any): AxiosPromise<Log> {
            return localVarFp.logControllerCreate(newLog, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logControllerDeleteById(id: number, options?: any): AxiosPromise<any> {
            return localVarFp.logControllerDeleteById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LogFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logControllerFind(filter?: LogFilter1, options?: any): AxiosPromise<Array<LogWithRelations>> {
            return localVarFp.logControllerFind(filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {LogFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logControllerFindById(id: number, filter?: LogFilter, options?: any): AxiosPromise<LogWithRelations> {
            return localVarFp.logControllerFindById(id, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {Log} [log] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logControllerReplaceById(id: number, log?: Log, options?: any): AxiosPromise<any> {
            return localVarFp.logControllerReplaceById(id, log, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {LogPartial} [logPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logControllerUpdateAll(where?: { [key: string]: object; }, logPartial?: LogPartial, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.logControllerUpdateAll(where, logPartial, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {LogPartial} [logPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logControllerUpdateById(id: number, logPartial?: LogPartial, options?: any): AxiosPromise<any> {
            return localVarFp.logControllerUpdateById(id, logPartial, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LogControllerApi - object-oriented interface
 * @export
 * @class LogControllerApi
 * @extends {BaseAPI}
 */
export class LogControllerApi extends BaseAPI {
    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogControllerApi
     */
    public logControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig) {
        return LogControllerApiFp(this.configuration).logControllerCount(where, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NewLog} [newLog] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogControllerApi
     */
    public logControllerCreate(newLog?: NewLog, options?: AxiosRequestConfig) {
        return LogControllerApiFp(this.configuration).logControllerCreate(newLog, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogControllerApi
     */
    public logControllerDeleteById(id: number, options?: AxiosRequestConfig) {
        return LogControllerApiFp(this.configuration).logControllerDeleteById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LogFilter1} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogControllerApi
     */
    public logControllerFind(filter?: LogFilter1, options?: AxiosRequestConfig) {
        return LogControllerApiFp(this.configuration).logControllerFind(filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {LogFilter} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogControllerApi
     */
    public logControllerFindById(id: number, filter?: LogFilter, options?: AxiosRequestConfig) {
        return LogControllerApiFp(this.configuration).logControllerFindById(id, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {Log} [log] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogControllerApi
     */
    public logControllerReplaceById(id: number, log?: Log, options?: AxiosRequestConfig) {
        return LogControllerApiFp(this.configuration).logControllerReplaceById(id, log, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {LogPartial} [logPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogControllerApi
     */
    public logControllerUpdateAll(where?: { [key: string]: object; }, logPartial?: LogPartial, options?: AxiosRequestConfig) {
        return LogControllerApiFp(this.configuration).logControllerUpdateAll(where, logPartial, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {LogPartial} [logPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogControllerApi
     */
    public logControllerUpdateById(id: number, logPartial?: LogPartial, options?: AxiosRequestConfig) {
        return LogControllerApiFp(this.configuration).logControllerUpdateById(id, logPartial, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LogNotFoundControllerApi - axios parameter creator
 * @export
 */
export const LogNotFoundControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logNotFoundControllerCount: async (where?: { [key: string]: object; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/log-not-founds/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {NewLogNotFound} [newLogNotFound] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logNotFoundControllerCreate: async (newLogNotFound?: NewLogNotFound, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/log-not-founds`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newLogNotFound, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logNotFoundControllerDeleteById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('logNotFoundControllerDeleteById', 'id', id)
            const localVarPath = `/log-not-founds/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LogNotFoundFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logNotFoundControllerFind: async (filter?: LogNotFoundFilter1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/log-not-founds`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {LogNotFoundFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logNotFoundControllerFindById: async (id: number, filter?: LogNotFoundFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('logNotFoundControllerFindById', 'id', id)
            const localVarPath = `/log-not-founds/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {LogNotFound} [logNotFound] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logNotFoundControllerReplaceById: async (id: number, logNotFound?: LogNotFound, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('logNotFoundControllerReplaceById', 'id', id)
            const localVarPath = `/log-not-founds/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(logNotFound, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {LogNotFoundPartial} [logNotFoundPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logNotFoundControllerUpdateAll: async (where?: { [key: string]: object; }, logNotFoundPartial?: LogNotFoundPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/log-not-founds`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(logNotFoundPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {LogNotFoundPartial} [logNotFoundPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logNotFoundControllerUpdateById: async (id: number, logNotFoundPartial?: LogNotFoundPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('logNotFoundControllerUpdateById', 'id', id)
            const localVarPath = `/log-not-founds/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(logNotFoundPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LogNotFoundControllerApi - functional programming interface
 * @export
 */
export const LogNotFoundControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LogNotFoundControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logNotFoundControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logNotFoundControllerCount(where, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {NewLogNotFound} [newLogNotFound] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logNotFoundControllerCreate(newLogNotFound?: NewLogNotFound, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LogNotFound>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logNotFoundControllerCreate(newLogNotFound, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logNotFoundControllerDeleteById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logNotFoundControllerDeleteById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {LogNotFoundFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logNotFoundControllerFind(filter?: LogNotFoundFilter1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LogNotFoundWithRelations>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logNotFoundControllerFind(filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {LogNotFoundFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logNotFoundControllerFindById(id: number, filter?: LogNotFoundFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LogNotFoundWithRelations>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logNotFoundControllerFindById(id, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {LogNotFound} [logNotFound] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logNotFoundControllerReplaceById(id: number, logNotFound?: LogNotFound, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logNotFoundControllerReplaceById(id, logNotFound, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {LogNotFoundPartial} [logNotFoundPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logNotFoundControllerUpdateAll(where?: { [key: string]: object; }, logNotFoundPartial?: LogNotFoundPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logNotFoundControllerUpdateAll(where, logNotFoundPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {LogNotFoundPartial} [logNotFoundPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logNotFoundControllerUpdateById(id: number, logNotFoundPartial?: LogNotFoundPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logNotFoundControllerUpdateById(id, logNotFoundPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LogNotFoundControllerApi - factory interface
 * @export
 */
export const LogNotFoundControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LogNotFoundControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logNotFoundControllerCount(where?: { [key: string]: object; }, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.logNotFoundControllerCount(where, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NewLogNotFound} [newLogNotFound] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logNotFoundControllerCreate(newLogNotFound?: NewLogNotFound, options?: any): AxiosPromise<LogNotFound> {
            return localVarFp.logNotFoundControllerCreate(newLogNotFound, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logNotFoundControllerDeleteById(id: number, options?: any): AxiosPromise<any> {
            return localVarFp.logNotFoundControllerDeleteById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LogNotFoundFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logNotFoundControllerFind(filter?: LogNotFoundFilter1, options?: any): AxiosPromise<Array<LogNotFoundWithRelations>> {
            return localVarFp.logNotFoundControllerFind(filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {LogNotFoundFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logNotFoundControllerFindById(id: number, filter?: LogNotFoundFilter, options?: any): AxiosPromise<LogNotFoundWithRelations> {
            return localVarFp.logNotFoundControllerFindById(id, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {LogNotFound} [logNotFound] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logNotFoundControllerReplaceById(id: number, logNotFound?: LogNotFound, options?: any): AxiosPromise<any> {
            return localVarFp.logNotFoundControllerReplaceById(id, logNotFound, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {LogNotFoundPartial} [logNotFoundPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logNotFoundControllerUpdateAll(where?: { [key: string]: object; }, logNotFoundPartial?: LogNotFoundPartial, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.logNotFoundControllerUpdateAll(where, logNotFoundPartial, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {LogNotFoundPartial} [logNotFoundPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logNotFoundControllerUpdateById(id: number, logNotFoundPartial?: LogNotFoundPartial, options?: any): AxiosPromise<any> {
            return localVarFp.logNotFoundControllerUpdateById(id, logNotFoundPartial, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LogNotFoundControllerApi - object-oriented interface
 * @export
 * @class LogNotFoundControllerApi
 * @extends {BaseAPI}
 */
export class LogNotFoundControllerApi extends BaseAPI {
    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogNotFoundControllerApi
     */
    public logNotFoundControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig) {
        return LogNotFoundControllerApiFp(this.configuration).logNotFoundControllerCount(where, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NewLogNotFound} [newLogNotFound] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogNotFoundControllerApi
     */
    public logNotFoundControllerCreate(newLogNotFound?: NewLogNotFound, options?: AxiosRequestConfig) {
        return LogNotFoundControllerApiFp(this.configuration).logNotFoundControllerCreate(newLogNotFound, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogNotFoundControllerApi
     */
    public logNotFoundControllerDeleteById(id: number, options?: AxiosRequestConfig) {
        return LogNotFoundControllerApiFp(this.configuration).logNotFoundControllerDeleteById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LogNotFoundFilter1} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogNotFoundControllerApi
     */
    public logNotFoundControllerFind(filter?: LogNotFoundFilter1, options?: AxiosRequestConfig) {
        return LogNotFoundControllerApiFp(this.configuration).logNotFoundControllerFind(filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {LogNotFoundFilter} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogNotFoundControllerApi
     */
    public logNotFoundControllerFindById(id: number, filter?: LogNotFoundFilter, options?: AxiosRequestConfig) {
        return LogNotFoundControllerApiFp(this.configuration).logNotFoundControllerFindById(id, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {LogNotFound} [logNotFound] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogNotFoundControllerApi
     */
    public logNotFoundControllerReplaceById(id: number, logNotFound?: LogNotFound, options?: AxiosRequestConfig) {
        return LogNotFoundControllerApiFp(this.configuration).logNotFoundControllerReplaceById(id, logNotFound, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {LogNotFoundPartial} [logNotFoundPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogNotFoundControllerApi
     */
    public logNotFoundControllerUpdateAll(where?: { [key: string]: object; }, logNotFoundPartial?: LogNotFoundPartial, options?: AxiosRequestConfig) {
        return LogNotFoundControllerApiFp(this.configuration).logNotFoundControllerUpdateAll(where, logNotFoundPartial, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {LogNotFoundPartial} [logNotFoundPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogNotFoundControllerApi
     */
    public logNotFoundControllerUpdateById(id: number, logNotFoundPartial?: LogNotFoundPartial, options?: AxiosRequestConfig) {
        return LogNotFoundControllerApiFp(this.configuration).logNotFoundControllerUpdateById(id, logNotFoundPartial, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MailControllerApi - axios parameter creator
 * @export
 */
export const MailControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Email} [email] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mailControllerSendMail: async (email?: Email, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/mail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(email, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MailControllerApi - functional programming interface
 * @export
 */
export const MailControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MailControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Email} [email] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mailControllerSendMail(email?: Email, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2002>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mailControllerSendMail(email, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MailControllerApi - factory interface
 * @export
 */
export const MailControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MailControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {Email} [email] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mailControllerSendMail(email?: Email, options?: any): AxiosPromise<InlineResponse2002> {
            return localVarFp.mailControllerSendMail(email, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MailControllerApi - object-oriented interface
 * @export
 * @class MailControllerApi
 * @extends {BaseAPI}
 */
export class MailControllerApi extends BaseAPI {
    /**
     * 
     * @param {Email} [email] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailControllerApi
     */
    public mailControllerSendMail(email?: Email, options?: AxiosRequestConfig) {
        return MailControllerApiFp(this.configuration).mailControllerSendMail(email, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NavisionControllerApi - axios parameter creator
 * @export
 */
export const NavisionControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        navisionControllerGetToken: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [$endpoint] 
         * @param {number} [$idEmpresa] 
         * @param {string} [$filter] 
         * @param {number} [$top] 
         * @param {number} [$skip] 
         * @param {string} [$orderby] 
         * @param {string} [$expand] 
         * @param {boolean} [$count] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        navisionControllerGetVendedoresPorIdEmpresa: async ($endpoint?: string, $idEmpresa?: number, $filter?: string, $top?: number, $skip?: number, $orderby?: string, $expand?: string, $count?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/vendedoresPorIdEmpresa`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if ($endpoint !== undefined) {
                localVarQueryParameter['$endpoint'] = $endpoint;
            }

            if ($idEmpresa !== undefined) {
                localVarQueryParameter['$idEmpresa'] = $idEmpresa;
            }

            if ($filter !== undefined) {
                localVarQueryParameter['$filter'] = $filter;
            }

            if ($top !== undefined) {
                localVarQueryParameter['$top'] = $top;
            }

            if ($skip !== undefined) {
                localVarQueryParameter['$skip'] = $skip;
            }

            if ($orderby !== undefined) {
                localVarQueryParameter['$orderby'] = $orderby;
            }

            if ($expand !== undefined) {
                localVarQueryParameter['$expand'] = $expand;
            }

            if ($count !== undefined) {
                localVarQueryParameter['$count'] = $count;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [$endpoint] 
         * @param {string} [$filter] 
         * @param {number} [$top] 
         * @param {number} [$skip] 
         * @param {string} [$orderby] 
         * @param {string} [$expand] 
         * @param {boolean} [$count] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        navisionControllerGetendPoint: async ($endpoint?: string, $filter?: string, $top?: number, $skip?: number, $orderby?: string, $expand?: string, $count?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/endpoint`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if ($endpoint !== undefined) {
                localVarQueryParameter['$endpoint'] = $endpoint;
            }

            if ($filter !== undefined) {
                localVarQueryParameter['$filter'] = $filter;
            }

            if ($top !== undefined) {
                localVarQueryParameter['$top'] = $top;
            }

            if ($skip !== undefined) {
                localVarQueryParameter['$skip'] = $skip;
            }

            if ($orderby !== undefined) {
                localVarQueryParameter['$orderby'] = $orderby;
            }

            if ($expand !== undefined) {
                localVarQueryParameter['$expand'] = $expand;
            }

            if ($count !== undefined) {
                localVarQueryParameter['$count'] = $count;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [$endpoint] 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        navisionControllerPostEndpointBC: async ($endpoint?: string, body?: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/postEndpointBC`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if ($endpoint !== undefined) {
                localVarQueryParameter['$endpoint'] = $endpoint;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NavisionControllerApi - functional programming interface
 * @export
 */
export const NavisionControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NavisionControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async navisionControllerGetToken(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.navisionControllerGetToken(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [$endpoint] 
         * @param {number} [$idEmpresa] 
         * @param {string} [$filter] 
         * @param {number} [$top] 
         * @param {number} [$skip] 
         * @param {string} [$orderby] 
         * @param {string} [$expand] 
         * @param {boolean} [$count] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async navisionControllerGetVendedoresPorIdEmpresa($endpoint?: string, $idEmpresa?: number, $filter?: string, $top?: number, $skip?: number, $orderby?: string, $expand?: string, $count?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.navisionControllerGetVendedoresPorIdEmpresa($endpoint, $idEmpresa, $filter, $top, $skip, $orderby, $expand, $count, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [$endpoint] 
         * @param {string} [$filter] 
         * @param {number} [$top] 
         * @param {number} [$skip] 
         * @param {string} [$orderby] 
         * @param {string} [$expand] 
         * @param {boolean} [$count] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async navisionControllerGetendPoint($endpoint?: string, $filter?: string, $top?: number, $skip?: number, $orderby?: string, $expand?: string, $count?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.navisionControllerGetendPoint($endpoint, $filter, $top, $skip, $orderby, $expand, $count, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [$endpoint] 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async navisionControllerPostEndpointBC($endpoint?: string, body?: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.navisionControllerPostEndpointBC($endpoint, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NavisionControllerApi - factory interface
 * @export
 */
export const NavisionControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NavisionControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        navisionControllerGetToken(options?: any): AxiosPromise<void> {
            return localVarFp.navisionControllerGetToken(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [$endpoint] 
         * @param {number} [$idEmpresa] 
         * @param {string} [$filter] 
         * @param {number} [$top] 
         * @param {number} [$skip] 
         * @param {string} [$orderby] 
         * @param {string} [$expand] 
         * @param {boolean} [$count] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        navisionControllerGetVendedoresPorIdEmpresa($endpoint?: string, $idEmpresa?: number, $filter?: string, $top?: number, $skip?: number, $orderby?: string, $expand?: string, $count?: boolean, options?: any): AxiosPromise<object> {
            return localVarFp.navisionControllerGetVendedoresPorIdEmpresa($endpoint, $idEmpresa, $filter, $top, $skip, $orderby, $expand, $count, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [$endpoint] 
         * @param {string} [$filter] 
         * @param {number} [$top] 
         * @param {number} [$skip] 
         * @param {string} [$orderby] 
         * @param {string} [$expand] 
         * @param {boolean} [$count] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        navisionControllerGetendPoint($endpoint?: string, $filter?: string, $top?: number, $skip?: number, $orderby?: string, $expand?: string, $count?: boolean, options?: any): AxiosPromise<object> {
            return localVarFp.navisionControllerGetendPoint($endpoint, $filter, $top, $skip, $orderby, $expand, $count, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [$endpoint] 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        navisionControllerPostEndpointBC($endpoint?: string, body?: object, options?: any): AxiosPromise<void> {
            return localVarFp.navisionControllerPostEndpointBC($endpoint, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NavisionControllerApi - object-oriented interface
 * @export
 * @class NavisionControllerApi
 * @extends {BaseAPI}
 */
export class NavisionControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NavisionControllerApi
     */
    public navisionControllerGetToken(options?: AxiosRequestConfig) {
        return NavisionControllerApiFp(this.configuration).navisionControllerGetToken(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [$endpoint] 
     * @param {number} [$idEmpresa] 
     * @param {string} [$filter] 
     * @param {number} [$top] 
     * @param {number} [$skip] 
     * @param {string} [$orderby] 
     * @param {string} [$expand] 
     * @param {boolean} [$count] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NavisionControllerApi
     */
    public navisionControllerGetVendedoresPorIdEmpresa($endpoint?: string, $idEmpresa?: number, $filter?: string, $top?: number, $skip?: number, $orderby?: string, $expand?: string, $count?: boolean, options?: AxiosRequestConfig) {
        return NavisionControllerApiFp(this.configuration).navisionControllerGetVendedoresPorIdEmpresa($endpoint, $idEmpresa, $filter, $top, $skip, $orderby, $expand, $count, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [$endpoint] 
     * @param {string} [$filter] 
     * @param {number} [$top] 
     * @param {number} [$skip] 
     * @param {string} [$orderby] 
     * @param {string} [$expand] 
     * @param {boolean} [$count] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NavisionControllerApi
     */
    public navisionControllerGetendPoint($endpoint?: string, $filter?: string, $top?: number, $skip?: number, $orderby?: string, $expand?: string, $count?: boolean, options?: AxiosRequestConfig) {
        return NavisionControllerApiFp(this.configuration).navisionControllerGetendPoint($endpoint, $filter, $top, $skip, $orderby, $expand, $count, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [$endpoint] 
     * @param {object} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NavisionControllerApi
     */
    public navisionControllerPostEndpointBC($endpoint?: string, body?: object, options?: AxiosRequestConfig) {
        return NavisionControllerApiFp(this.configuration).navisionControllerPostEndpointBC($endpoint, body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NotificacionesControllerApi - axios parameter creator
 * @export
 */
export const NotificacionesControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [codigoNotificacion] 
         * @param {string} [noDocumento] 
         * @param {string} [noDocumentoAuxilar] 
         * @param {string} [codigoCliente] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificacionesControllerNotificaciones: async (codigoNotificacion?: string, noDocumento?: string, noDocumentoAuxilar?: string, codigoCliente?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/notificaciones`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (codigoNotificacion !== undefined) {
                localVarQueryParameter['codigoNotificacion'] = codigoNotificacion;
            }

            if (noDocumento !== undefined) {
                localVarQueryParameter['noDocumento'] = noDocumento;
            }

            if (noDocumentoAuxilar !== undefined) {
                localVarQueryParameter['noDocumentoAuxilar'] = noDocumentoAuxilar;
            }

            if (codigoCliente !== undefined) {
                localVarQueryParameter['codigoCliente'] = codigoCliente;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotificacionesControllerApi - functional programming interface
 * @export
 */
export const NotificacionesControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NotificacionesControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [codigoNotificacion] 
         * @param {string} [noDocumento] 
         * @param {string} [noDocumentoAuxilar] 
         * @param {string} [codigoCliente] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notificacionesControllerNotificaciones(codigoNotificacion?: string, noDocumento?: string, noDocumentoAuxilar?: string, codigoCliente?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.notificacionesControllerNotificaciones(codigoNotificacion, noDocumento, noDocumentoAuxilar, codigoCliente, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NotificacionesControllerApi - factory interface
 * @export
 */
export const NotificacionesControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NotificacionesControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [codigoNotificacion] 
         * @param {string} [noDocumento] 
         * @param {string} [noDocumentoAuxilar] 
         * @param {string} [codigoCliente] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificacionesControllerNotificaciones(codigoNotificacion?: string, noDocumento?: string, noDocumentoAuxilar?: string, codigoCliente?: string, options?: any): AxiosPromise<object> {
            return localVarFp.notificacionesControllerNotificaciones(codigoNotificacion, noDocumento, noDocumentoAuxilar, codigoCliente, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NotificacionesControllerApi - object-oriented interface
 * @export
 * @class NotificacionesControllerApi
 * @extends {BaseAPI}
 */
export class NotificacionesControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} [codigoNotificacion] 
     * @param {string} [noDocumento] 
     * @param {string} [noDocumentoAuxilar] 
     * @param {string} [codigoCliente] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificacionesControllerApi
     */
    public notificacionesControllerNotificaciones(codigoNotificacion?: string, noDocumento?: string, noDocumentoAuxilar?: string, codigoCliente?: string, options?: AxiosRequestConfig) {
        return NotificacionesControllerApiFp(this.configuration).notificacionesControllerNotificaciones(codigoNotificacion, noDocumento, noDocumentoAuxilar, codigoCliente, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ObservacionesControllerApi - axios parameter creator
 * @export
 */
export const ObservacionesControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        observacionesControllerCount: async (where?: { [key: string]: object; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/observaciones/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {NewObservaciones} [newObservaciones] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        observacionesControllerCreate: async (newObservaciones?: NewObservaciones, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/observaciones`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newObservaciones, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        observacionesControllerDeleteById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('observacionesControllerDeleteById', 'id', id)
            const localVarPath = `/observaciones/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ObservacionesFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        observacionesControllerFind: async (filter?: ObservacionesFilter1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/observaciones`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ObservacionesFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        observacionesControllerFindById: async (id: number, filter?: ObservacionesFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('observacionesControllerFindById', 'id', id)
            const localVarPath = `/observaciones/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {Observaciones} [observaciones] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        observacionesControllerReplaceById: async (id: number, observaciones?: Observaciones, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('observacionesControllerReplaceById', 'id', id)
            const localVarPath = `/observaciones/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(observaciones, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {ObservacionesPartial} [observacionesPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        observacionesControllerUpdateAll: async (where?: { [key: string]: object; }, observacionesPartial?: ObservacionesPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/observaciones`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(observacionesPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ObservacionesPartial} [observacionesPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        observacionesControllerUpdateById: async (id: number, observacionesPartial?: ObservacionesPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('observacionesControllerUpdateById', 'id', id)
            const localVarPath = `/observaciones/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(observacionesPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ObservacionesControllerApi - functional programming interface
 * @export
 */
export const ObservacionesControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ObservacionesControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async observacionesControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.observacionesControllerCount(where, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {NewObservaciones} [newObservaciones] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async observacionesControllerCreate(newObservaciones?: NewObservaciones, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Observaciones>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.observacionesControllerCreate(newObservaciones, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async observacionesControllerDeleteById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.observacionesControllerDeleteById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ObservacionesFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async observacionesControllerFind(filter?: ObservacionesFilter1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ObservacionesWithRelations>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.observacionesControllerFind(filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ObservacionesFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async observacionesControllerFindById(id: number, filter?: ObservacionesFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ObservacionesWithRelations>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.observacionesControllerFindById(id, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {Observaciones} [observaciones] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async observacionesControllerReplaceById(id: number, observaciones?: Observaciones, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.observacionesControllerReplaceById(id, observaciones, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {ObservacionesPartial} [observacionesPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async observacionesControllerUpdateAll(where?: { [key: string]: object; }, observacionesPartial?: ObservacionesPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.observacionesControllerUpdateAll(where, observacionesPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ObservacionesPartial} [observacionesPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async observacionesControllerUpdateById(id: number, observacionesPartial?: ObservacionesPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.observacionesControllerUpdateById(id, observacionesPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ObservacionesControllerApi - factory interface
 * @export
 */
export const ObservacionesControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ObservacionesControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        observacionesControllerCount(where?: { [key: string]: object; }, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.observacionesControllerCount(where, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NewObservaciones} [newObservaciones] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        observacionesControllerCreate(newObservaciones?: NewObservaciones, options?: any): AxiosPromise<Observaciones> {
            return localVarFp.observacionesControllerCreate(newObservaciones, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        observacionesControllerDeleteById(id: number, options?: any): AxiosPromise<any> {
            return localVarFp.observacionesControllerDeleteById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ObservacionesFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        observacionesControllerFind(filter?: ObservacionesFilter1, options?: any): AxiosPromise<Array<ObservacionesWithRelations>> {
            return localVarFp.observacionesControllerFind(filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ObservacionesFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        observacionesControllerFindById(id: number, filter?: ObservacionesFilter, options?: any): AxiosPromise<ObservacionesWithRelations> {
            return localVarFp.observacionesControllerFindById(id, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {Observaciones} [observaciones] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        observacionesControllerReplaceById(id: number, observaciones?: Observaciones, options?: any): AxiosPromise<any> {
            return localVarFp.observacionesControllerReplaceById(id, observaciones, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {ObservacionesPartial} [observacionesPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        observacionesControllerUpdateAll(where?: { [key: string]: object; }, observacionesPartial?: ObservacionesPartial, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.observacionesControllerUpdateAll(where, observacionesPartial, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ObservacionesPartial} [observacionesPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        observacionesControllerUpdateById(id: number, observacionesPartial?: ObservacionesPartial, options?: any): AxiosPromise<any> {
            return localVarFp.observacionesControllerUpdateById(id, observacionesPartial, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ObservacionesControllerApi - object-oriented interface
 * @export
 * @class ObservacionesControllerApi
 * @extends {BaseAPI}
 */
export class ObservacionesControllerApi extends BaseAPI {
    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObservacionesControllerApi
     */
    public observacionesControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig) {
        return ObservacionesControllerApiFp(this.configuration).observacionesControllerCount(where, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NewObservaciones} [newObservaciones] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObservacionesControllerApi
     */
    public observacionesControllerCreate(newObservaciones?: NewObservaciones, options?: AxiosRequestConfig) {
        return ObservacionesControllerApiFp(this.configuration).observacionesControllerCreate(newObservaciones, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObservacionesControllerApi
     */
    public observacionesControllerDeleteById(id: number, options?: AxiosRequestConfig) {
        return ObservacionesControllerApiFp(this.configuration).observacionesControllerDeleteById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ObservacionesFilter1} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObservacionesControllerApi
     */
    public observacionesControllerFind(filter?: ObservacionesFilter1, options?: AxiosRequestConfig) {
        return ObservacionesControllerApiFp(this.configuration).observacionesControllerFind(filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ObservacionesFilter} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObservacionesControllerApi
     */
    public observacionesControllerFindById(id: number, filter?: ObservacionesFilter, options?: AxiosRequestConfig) {
        return ObservacionesControllerApiFp(this.configuration).observacionesControllerFindById(id, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {Observaciones} [observaciones] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObservacionesControllerApi
     */
    public observacionesControllerReplaceById(id: number, observaciones?: Observaciones, options?: AxiosRequestConfig) {
        return ObservacionesControllerApiFp(this.configuration).observacionesControllerReplaceById(id, observaciones, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {ObservacionesPartial} [observacionesPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObservacionesControllerApi
     */
    public observacionesControllerUpdateAll(where?: { [key: string]: object; }, observacionesPartial?: ObservacionesPartial, options?: AxiosRequestConfig) {
        return ObservacionesControllerApiFp(this.configuration).observacionesControllerUpdateAll(where, observacionesPartial, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ObservacionesPartial} [observacionesPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObservacionesControllerApi
     */
    public observacionesControllerUpdateById(id: number, observacionesPartial?: ObservacionesPartial, options?: AxiosRequestConfig) {
        return ObservacionesControllerApiFp(this.configuration).observacionesControllerUpdateById(id, observacionesPartial, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OdataLogsControllerApi - axios parameter creator
 * @export
 */
export const OdataLogsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        odataLogsControllerCount: async (where?: { [key: string]: object; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/odata-logs/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {NewOdataLogs} [newOdataLogs] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        odataLogsControllerCreate: async (newOdataLogs?: NewOdataLogs, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/odata-logs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newOdataLogs, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        odataLogsControllerDeleteById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('odataLogsControllerDeleteById', 'id', id)
            const localVarPath = `/odata-logs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {OdataLogsFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        odataLogsControllerFind: async (filter?: OdataLogsFilter1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/odata-logs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {OdataLogsFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        odataLogsControllerFindById: async (id: number, filter?: OdataLogsFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('odataLogsControllerFindById', 'id', id)
            const localVarPath = `/odata-logs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {OdataLogs} [odataLogs] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        odataLogsControllerReplaceById: async (id: number, odataLogs?: OdataLogs, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('odataLogsControllerReplaceById', 'id', id)
            const localVarPath = `/odata-logs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(odataLogs, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {OdataLogsPartial} [odataLogsPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        odataLogsControllerUpdateAll: async (where?: { [key: string]: object; }, odataLogsPartial?: OdataLogsPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/odata-logs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(odataLogsPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {OdataLogsPartial} [odataLogsPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        odataLogsControllerUpdateById: async (id: number, odataLogsPartial?: OdataLogsPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('odataLogsControllerUpdateById', 'id', id)
            const localVarPath = `/odata-logs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(odataLogsPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OdataLogsControllerApi - functional programming interface
 * @export
 */
export const OdataLogsControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OdataLogsControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async odataLogsControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.odataLogsControllerCount(where, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {NewOdataLogs} [newOdataLogs] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async odataLogsControllerCreate(newOdataLogs?: NewOdataLogs, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OdataLogs>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.odataLogsControllerCreate(newOdataLogs, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async odataLogsControllerDeleteById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.odataLogsControllerDeleteById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {OdataLogsFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async odataLogsControllerFind(filter?: OdataLogsFilter1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OdataLogsWithRelations>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.odataLogsControllerFind(filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {OdataLogsFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async odataLogsControllerFindById(id: number, filter?: OdataLogsFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OdataLogsWithRelations>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.odataLogsControllerFindById(id, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {OdataLogs} [odataLogs] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async odataLogsControllerReplaceById(id: number, odataLogs?: OdataLogs, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.odataLogsControllerReplaceById(id, odataLogs, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {OdataLogsPartial} [odataLogsPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async odataLogsControllerUpdateAll(where?: { [key: string]: object; }, odataLogsPartial?: OdataLogsPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.odataLogsControllerUpdateAll(where, odataLogsPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {OdataLogsPartial} [odataLogsPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async odataLogsControllerUpdateById(id: number, odataLogsPartial?: OdataLogsPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.odataLogsControllerUpdateById(id, odataLogsPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OdataLogsControllerApi - factory interface
 * @export
 */
export const OdataLogsControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OdataLogsControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        odataLogsControllerCount(where?: { [key: string]: object; }, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.odataLogsControllerCount(where, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NewOdataLogs} [newOdataLogs] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        odataLogsControllerCreate(newOdataLogs?: NewOdataLogs, options?: any): AxiosPromise<OdataLogs> {
            return localVarFp.odataLogsControllerCreate(newOdataLogs, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        odataLogsControllerDeleteById(id: number, options?: any): AxiosPromise<any> {
            return localVarFp.odataLogsControllerDeleteById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OdataLogsFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        odataLogsControllerFind(filter?: OdataLogsFilter1, options?: any): AxiosPromise<Array<OdataLogsWithRelations>> {
            return localVarFp.odataLogsControllerFind(filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {OdataLogsFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        odataLogsControllerFindById(id: number, filter?: OdataLogsFilter, options?: any): AxiosPromise<OdataLogsWithRelations> {
            return localVarFp.odataLogsControllerFindById(id, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {OdataLogs} [odataLogs] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        odataLogsControllerReplaceById(id: number, odataLogs?: OdataLogs, options?: any): AxiosPromise<any> {
            return localVarFp.odataLogsControllerReplaceById(id, odataLogs, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {OdataLogsPartial} [odataLogsPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        odataLogsControllerUpdateAll(where?: { [key: string]: object; }, odataLogsPartial?: OdataLogsPartial, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.odataLogsControllerUpdateAll(where, odataLogsPartial, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {OdataLogsPartial} [odataLogsPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        odataLogsControllerUpdateById(id: number, odataLogsPartial?: OdataLogsPartial, options?: any): AxiosPromise<any> {
            return localVarFp.odataLogsControllerUpdateById(id, odataLogsPartial, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OdataLogsControllerApi - object-oriented interface
 * @export
 * @class OdataLogsControllerApi
 * @extends {BaseAPI}
 */
export class OdataLogsControllerApi extends BaseAPI {
    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OdataLogsControllerApi
     */
    public odataLogsControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig) {
        return OdataLogsControllerApiFp(this.configuration).odataLogsControllerCount(where, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NewOdataLogs} [newOdataLogs] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OdataLogsControllerApi
     */
    public odataLogsControllerCreate(newOdataLogs?: NewOdataLogs, options?: AxiosRequestConfig) {
        return OdataLogsControllerApiFp(this.configuration).odataLogsControllerCreate(newOdataLogs, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OdataLogsControllerApi
     */
    public odataLogsControllerDeleteById(id: number, options?: AxiosRequestConfig) {
        return OdataLogsControllerApiFp(this.configuration).odataLogsControllerDeleteById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OdataLogsFilter1} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OdataLogsControllerApi
     */
    public odataLogsControllerFind(filter?: OdataLogsFilter1, options?: AxiosRequestConfig) {
        return OdataLogsControllerApiFp(this.configuration).odataLogsControllerFind(filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {OdataLogsFilter} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OdataLogsControllerApi
     */
    public odataLogsControllerFindById(id: number, filter?: OdataLogsFilter, options?: AxiosRequestConfig) {
        return OdataLogsControllerApiFp(this.configuration).odataLogsControllerFindById(id, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {OdataLogs} [odataLogs] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OdataLogsControllerApi
     */
    public odataLogsControllerReplaceById(id: number, odataLogs?: OdataLogs, options?: AxiosRequestConfig) {
        return OdataLogsControllerApiFp(this.configuration).odataLogsControllerReplaceById(id, odataLogs, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {OdataLogsPartial} [odataLogsPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OdataLogsControllerApi
     */
    public odataLogsControllerUpdateAll(where?: { [key: string]: object; }, odataLogsPartial?: OdataLogsPartial, options?: AxiosRequestConfig) {
        return OdataLogsControllerApiFp(this.configuration).odataLogsControllerUpdateAll(where, odataLogsPartial, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {OdataLogsPartial} [odataLogsPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OdataLogsControllerApi
     */
    public odataLogsControllerUpdateById(id: number, odataLogsPartial?: OdataLogsPartial, options?: AxiosRequestConfig) {
        return OdataLogsControllerApiFp(this.configuration).odataLogsControllerUpdateById(id, odataLogsPartial, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PasswordChangesControllerApi - axios parameter creator
 * @export
 */
export const PasswordChangesControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passwordChangesControllerCount: async (where?: { [key: string]: object; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/password-changes/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {NewPasswordChanges} [newPasswordChanges] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passwordChangesControllerCreate: async (newPasswordChanges?: NewPasswordChanges, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/password-changes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newPasswordChanges, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passwordChangesControllerDeleteById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('passwordChangesControllerDeleteById', 'id', id)
            const localVarPath = `/password-changes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PasswordChangesFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passwordChangesControllerFind: async (filter?: PasswordChangesFilter1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/password-changes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {PasswordChangesFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passwordChangesControllerFindById: async (id: number, filter?: PasswordChangesFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('passwordChangesControllerFindById', 'id', id)
            const localVarPath = `/password-changes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {PasswordChanges} [passwordChanges] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passwordChangesControllerReplaceById: async (id: number, passwordChanges?: PasswordChanges, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('passwordChangesControllerReplaceById', 'id', id)
            const localVarPath = `/password-changes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(passwordChanges, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {PasswordChangesPartial} [passwordChangesPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passwordChangesControllerUpdateAll: async (where?: { [key: string]: object; }, passwordChangesPartial?: PasswordChangesPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/password-changes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(passwordChangesPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {PasswordChangesPartial} [passwordChangesPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passwordChangesControllerUpdateById: async (id: number, passwordChangesPartial?: PasswordChangesPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('passwordChangesControllerUpdateById', 'id', id)
            const localVarPath = `/password-changes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(passwordChangesPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PasswordChangesControllerApi - functional programming interface
 * @export
 */
export const PasswordChangesControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PasswordChangesControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async passwordChangesControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.passwordChangesControllerCount(where, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {NewPasswordChanges} [newPasswordChanges] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async passwordChangesControllerCreate(newPasswordChanges?: NewPasswordChanges, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PasswordChanges>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.passwordChangesControllerCreate(newPasswordChanges, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async passwordChangesControllerDeleteById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.passwordChangesControllerDeleteById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PasswordChangesFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async passwordChangesControllerFind(filter?: PasswordChangesFilter1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PasswordChangesWithRelations>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.passwordChangesControllerFind(filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {PasswordChangesFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async passwordChangesControllerFindById(id: number, filter?: PasswordChangesFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PasswordChangesWithRelations>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.passwordChangesControllerFindById(id, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {PasswordChanges} [passwordChanges] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async passwordChangesControllerReplaceById(id: number, passwordChanges?: PasswordChanges, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.passwordChangesControllerReplaceById(id, passwordChanges, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {PasswordChangesPartial} [passwordChangesPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async passwordChangesControllerUpdateAll(where?: { [key: string]: object; }, passwordChangesPartial?: PasswordChangesPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.passwordChangesControllerUpdateAll(where, passwordChangesPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {PasswordChangesPartial} [passwordChangesPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async passwordChangesControllerUpdateById(id: number, passwordChangesPartial?: PasswordChangesPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.passwordChangesControllerUpdateById(id, passwordChangesPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PasswordChangesControllerApi - factory interface
 * @export
 */
export const PasswordChangesControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PasswordChangesControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passwordChangesControllerCount(where?: { [key: string]: object; }, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.passwordChangesControllerCount(where, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NewPasswordChanges} [newPasswordChanges] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passwordChangesControllerCreate(newPasswordChanges?: NewPasswordChanges, options?: any): AxiosPromise<PasswordChanges> {
            return localVarFp.passwordChangesControllerCreate(newPasswordChanges, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passwordChangesControllerDeleteById(id: number, options?: any): AxiosPromise<any> {
            return localVarFp.passwordChangesControllerDeleteById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PasswordChangesFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passwordChangesControllerFind(filter?: PasswordChangesFilter1, options?: any): AxiosPromise<Array<PasswordChangesWithRelations>> {
            return localVarFp.passwordChangesControllerFind(filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {PasswordChangesFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passwordChangesControllerFindById(id: number, filter?: PasswordChangesFilter, options?: any): AxiosPromise<PasswordChangesWithRelations> {
            return localVarFp.passwordChangesControllerFindById(id, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {PasswordChanges} [passwordChanges] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passwordChangesControllerReplaceById(id: number, passwordChanges?: PasswordChanges, options?: any): AxiosPromise<any> {
            return localVarFp.passwordChangesControllerReplaceById(id, passwordChanges, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {PasswordChangesPartial} [passwordChangesPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passwordChangesControllerUpdateAll(where?: { [key: string]: object; }, passwordChangesPartial?: PasswordChangesPartial, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.passwordChangesControllerUpdateAll(where, passwordChangesPartial, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {PasswordChangesPartial} [passwordChangesPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passwordChangesControllerUpdateById(id: number, passwordChangesPartial?: PasswordChangesPartial, options?: any): AxiosPromise<any> {
            return localVarFp.passwordChangesControllerUpdateById(id, passwordChangesPartial, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PasswordChangesControllerApi - object-oriented interface
 * @export
 * @class PasswordChangesControllerApi
 * @extends {BaseAPI}
 */
export class PasswordChangesControllerApi extends BaseAPI {
    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PasswordChangesControllerApi
     */
    public passwordChangesControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig) {
        return PasswordChangesControllerApiFp(this.configuration).passwordChangesControllerCount(where, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NewPasswordChanges} [newPasswordChanges] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PasswordChangesControllerApi
     */
    public passwordChangesControllerCreate(newPasswordChanges?: NewPasswordChanges, options?: AxiosRequestConfig) {
        return PasswordChangesControllerApiFp(this.configuration).passwordChangesControllerCreate(newPasswordChanges, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PasswordChangesControllerApi
     */
    public passwordChangesControllerDeleteById(id: number, options?: AxiosRequestConfig) {
        return PasswordChangesControllerApiFp(this.configuration).passwordChangesControllerDeleteById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PasswordChangesFilter1} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PasswordChangesControllerApi
     */
    public passwordChangesControllerFind(filter?: PasswordChangesFilter1, options?: AxiosRequestConfig) {
        return PasswordChangesControllerApiFp(this.configuration).passwordChangesControllerFind(filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {PasswordChangesFilter} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PasswordChangesControllerApi
     */
    public passwordChangesControllerFindById(id: number, filter?: PasswordChangesFilter, options?: AxiosRequestConfig) {
        return PasswordChangesControllerApiFp(this.configuration).passwordChangesControllerFindById(id, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {PasswordChanges} [passwordChanges] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PasswordChangesControllerApi
     */
    public passwordChangesControllerReplaceById(id: number, passwordChanges?: PasswordChanges, options?: AxiosRequestConfig) {
        return PasswordChangesControllerApiFp(this.configuration).passwordChangesControllerReplaceById(id, passwordChanges, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {PasswordChangesPartial} [passwordChangesPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PasswordChangesControllerApi
     */
    public passwordChangesControllerUpdateAll(where?: { [key: string]: object; }, passwordChangesPartial?: PasswordChangesPartial, options?: AxiosRequestConfig) {
        return PasswordChangesControllerApiFp(this.configuration).passwordChangesControllerUpdateAll(where, passwordChangesPartial, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {PasswordChangesPartial} [passwordChangesPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PasswordChangesControllerApi
     */
    public passwordChangesControllerUpdateById(id: number, passwordChangesPartial?: PasswordChangesPartial, options?: AxiosRequestConfig) {
        return PasswordChangesControllerApiFp(this.configuration).passwordChangesControllerUpdateById(id, passwordChangesPartial, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PermissionsLogsControllerApi - axios parameter creator
 * @export
 */
export const PermissionsLogsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        permissionsLogsControllerCount: async (where?: { [key: string]: object; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/permissions-logs/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {NewPermissionsLogs} [newPermissionsLogs] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        permissionsLogsControllerCreate: async (newPermissionsLogs?: NewPermissionsLogs, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/permissions-logs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newPermissionsLogs, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        permissionsLogsControllerDeleteById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('permissionsLogsControllerDeleteById', 'id', id)
            const localVarPath = `/permissions-logs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PermissionsLogsFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        permissionsLogsControllerFind: async (filter?: PermissionsLogsFilter1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/permissions-logs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {PermissionsLogsFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        permissionsLogsControllerFindById: async (id: number, filter?: PermissionsLogsFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('permissionsLogsControllerFindById', 'id', id)
            const localVarPath = `/permissions-logs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {PermissionsLogs} [permissionsLogs] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        permissionsLogsControllerReplaceById: async (id: number, permissionsLogs?: PermissionsLogs, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('permissionsLogsControllerReplaceById', 'id', id)
            const localVarPath = `/permissions-logs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(permissionsLogs, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {PermissionsLogsPartial} [permissionsLogsPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        permissionsLogsControllerUpdateAll: async (where?: { [key: string]: object; }, permissionsLogsPartial?: PermissionsLogsPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/permissions-logs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(permissionsLogsPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {PermissionsLogsPartial} [permissionsLogsPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        permissionsLogsControllerUpdateById: async (id: number, permissionsLogsPartial?: PermissionsLogsPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('permissionsLogsControllerUpdateById', 'id', id)
            const localVarPath = `/permissions-logs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(permissionsLogsPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PermissionsLogsControllerApi - functional programming interface
 * @export
 */
export const PermissionsLogsControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PermissionsLogsControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async permissionsLogsControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.permissionsLogsControllerCount(where, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {NewPermissionsLogs} [newPermissionsLogs] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async permissionsLogsControllerCreate(newPermissionsLogs?: NewPermissionsLogs, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PermissionsLogs>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.permissionsLogsControllerCreate(newPermissionsLogs, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async permissionsLogsControllerDeleteById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.permissionsLogsControllerDeleteById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PermissionsLogsFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async permissionsLogsControllerFind(filter?: PermissionsLogsFilter1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PermissionsLogsWithRelations>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.permissionsLogsControllerFind(filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {PermissionsLogsFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async permissionsLogsControllerFindById(id: number, filter?: PermissionsLogsFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PermissionsLogsWithRelations>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.permissionsLogsControllerFindById(id, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {PermissionsLogs} [permissionsLogs] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async permissionsLogsControllerReplaceById(id: number, permissionsLogs?: PermissionsLogs, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.permissionsLogsControllerReplaceById(id, permissionsLogs, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {PermissionsLogsPartial} [permissionsLogsPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async permissionsLogsControllerUpdateAll(where?: { [key: string]: object; }, permissionsLogsPartial?: PermissionsLogsPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.permissionsLogsControllerUpdateAll(where, permissionsLogsPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {PermissionsLogsPartial} [permissionsLogsPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async permissionsLogsControllerUpdateById(id: number, permissionsLogsPartial?: PermissionsLogsPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.permissionsLogsControllerUpdateById(id, permissionsLogsPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PermissionsLogsControllerApi - factory interface
 * @export
 */
export const PermissionsLogsControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PermissionsLogsControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        permissionsLogsControllerCount(where?: { [key: string]: object; }, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.permissionsLogsControllerCount(where, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NewPermissionsLogs} [newPermissionsLogs] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        permissionsLogsControllerCreate(newPermissionsLogs?: NewPermissionsLogs, options?: any): AxiosPromise<PermissionsLogs> {
            return localVarFp.permissionsLogsControllerCreate(newPermissionsLogs, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        permissionsLogsControllerDeleteById(id: number, options?: any): AxiosPromise<any> {
            return localVarFp.permissionsLogsControllerDeleteById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PermissionsLogsFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        permissionsLogsControllerFind(filter?: PermissionsLogsFilter1, options?: any): AxiosPromise<Array<PermissionsLogsWithRelations>> {
            return localVarFp.permissionsLogsControllerFind(filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {PermissionsLogsFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        permissionsLogsControllerFindById(id: number, filter?: PermissionsLogsFilter, options?: any): AxiosPromise<PermissionsLogsWithRelations> {
            return localVarFp.permissionsLogsControllerFindById(id, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {PermissionsLogs} [permissionsLogs] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        permissionsLogsControllerReplaceById(id: number, permissionsLogs?: PermissionsLogs, options?: any): AxiosPromise<any> {
            return localVarFp.permissionsLogsControllerReplaceById(id, permissionsLogs, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {PermissionsLogsPartial} [permissionsLogsPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        permissionsLogsControllerUpdateAll(where?: { [key: string]: object; }, permissionsLogsPartial?: PermissionsLogsPartial, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.permissionsLogsControllerUpdateAll(where, permissionsLogsPartial, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {PermissionsLogsPartial} [permissionsLogsPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        permissionsLogsControllerUpdateById(id: number, permissionsLogsPartial?: PermissionsLogsPartial, options?: any): AxiosPromise<any> {
            return localVarFp.permissionsLogsControllerUpdateById(id, permissionsLogsPartial, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PermissionsLogsControllerApi - object-oriented interface
 * @export
 * @class PermissionsLogsControllerApi
 * @extends {BaseAPI}
 */
export class PermissionsLogsControllerApi extends BaseAPI {
    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PermissionsLogsControllerApi
     */
    public permissionsLogsControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig) {
        return PermissionsLogsControllerApiFp(this.configuration).permissionsLogsControllerCount(where, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NewPermissionsLogs} [newPermissionsLogs] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PermissionsLogsControllerApi
     */
    public permissionsLogsControllerCreate(newPermissionsLogs?: NewPermissionsLogs, options?: AxiosRequestConfig) {
        return PermissionsLogsControllerApiFp(this.configuration).permissionsLogsControllerCreate(newPermissionsLogs, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PermissionsLogsControllerApi
     */
    public permissionsLogsControllerDeleteById(id: number, options?: AxiosRequestConfig) {
        return PermissionsLogsControllerApiFp(this.configuration).permissionsLogsControllerDeleteById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PermissionsLogsFilter1} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PermissionsLogsControllerApi
     */
    public permissionsLogsControllerFind(filter?: PermissionsLogsFilter1, options?: AxiosRequestConfig) {
        return PermissionsLogsControllerApiFp(this.configuration).permissionsLogsControllerFind(filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {PermissionsLogsFilter} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PermissionsLogsControllerApi
     */
    public permissionsLogsControllerFindById(id: number, filter?: PermissionsLogsFilter, options?: AxiosRequestConfig) {
        return PermissionsLogsControllerApiFp(this.configuration).permissionsLogsControllerFindById(id, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {PermissionsLogs} [permissionsLogs] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PermissionsLogsControllerApi
     */
    public permissionsLogsControllerReplaceById(id: number, permissionsLogs?: PermissionsLogs, options?: AxiosRequestConfig) {
        return PermissionsLogsControllerApiFp(this.configuration).permissionsLogsControllerReplaceById(id, permissionsLogs, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {PermissionsLogsPartial} [permissionsLogsPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PermissionsLogsControllerApi
     */
    public permissionsLogsControllerUpdateAll(where?: { [key: string]: object; }, permissionsLogsPartial?: PermissionsLogsPartial, options?: AxiosRequestConfig) {
        return PermissionsLogsControllerApiFp(this.configuration).permissionsLogsControllerUpdateAll(where, permissionsLogsPartial, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {PermissionsLogsPartial} [permissionsLogsPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PermissionsLogsControllerApi
     */
    public permissionsLogsControllerUpdateById(id: number, permissionsLogsPartial?: PermissionsLogsPartial, options?: AxiosRequestConfig) {
        return PermissionsLogsControllerApiFp(this.configuration).permissionsLogsControllerUpdateById(id, permissionsLogsPartial, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PingControllerApi - axios parameter creator
 * @export
 */
export const PingControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pingControllerPing: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ping`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PingControllerApi - functional programming interface
 * @export
 */
export const PingControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PingControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pingControllerPing(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pingControllerPing(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PingControllerApi - factory interface
 * @export
 */
export const PingControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PingControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pingControllerPing(options?: any): AxiosPromise<PingResponse> {
            return localVarFp.pingControllerPing(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PingControllerApi - object-oriented interface
 * @export
 * @class PingControllerApi
 * @extends {BaseAPI}
 */
export class PingControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PingControllerApi
     */
    public pingControllerPing(options?: AxiosRequestConfig) {
        return PingControllerApiFp(this.configuration).pingControllerPing(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PortesControllerApi - axios parameter creator
 * @export
 */
export const PortesControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        portesControllerCount: async (where?: { [key: string]: object; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/portes/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {NewPortes} [newPortes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        portesControllerCreate: async (newPortes?: NewPortes, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/portes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newPortes, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        portesControllerDeleteById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('portesControllerDeleteById', 'id', id)
            const localVarPath = `/portes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PortesFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        portesControllerFind: async (filter?: PortesFilter1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/portes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {PortesFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        portesControllerFindById: async (id: number, filter?: PortesFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('portesControllerFindById', 'id', id)
            const localVarPath = `/portes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {Portes} [portes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        portesControllerReplaceById: async (id: number, portes?: Portes, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('portesControllerReplaceById', 'id', id)
            const localVarPath = `/portes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(portes, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {PortesPartial} [portesPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        portesControllerUpdateAll: async (where?: { [key: string]: object; }, portesPartial?: PortesPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/portes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(portesPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {PortesPartial} [portesPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        portesControllerUpdateById: async (id: number, portesPartial?: PortesPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('portesControllerUpdateById', 'id', id)
            const localVarPath = `/portes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(portesPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PortesControllerApi - functional programming interface
 * @export
 */
export const PortesControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PortesControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async portesControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.portesControllerCount(where, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {NewPortes} [newPortes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async portesControllerCreate(newPortes?: NewPortes, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Portes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.portesControllerCreate(newPortes, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async portesControllerDeleteById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.portesControllerDeleteById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PortesFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async portesControllerFind(filter?: PortesFilter1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PortesWithRelations>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.portesControllerFind(filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {PortesFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async portesControllerFindById(id: number, filter?: PortesFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PortesWithRelations>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.portesControllerFindById(id, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {Portes} [portes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async portesControllerReplaceById(id: number, portes?: Portes, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.portesControllerReplaceById(id, portes, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {PortesPartial} [portesPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async portesControllerUpdateAll(where?: { [key: string]: object; }, portesPartial?: PortesPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.portesControllerUpdateAll(where, portesPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {PortesPartial} [portesPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async portesControllerUpdateById(id: number, portesPartial?: PortesPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.portesControllerUpdateById(id, portesPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PortesControllerApi - factory interface
 * @export
 */
export const PortesControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PortesControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        portesControllerCount(where?: { [key: string]: object; }, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.portesControllerCount(where, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NewPortes} [newPortes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        portesControllerCreate(newPortes?: NewPortes, options?: any): AxiosPromise<Portes> {
            return localVarFp.portesControllerCreate(newPortes, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        portesControllerDeleteById(id: number, options?: any): AxiosPromise<any> {
            return localVarFp.portesControllerDeleteById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PortesFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        portesControllerFind(filter?: PortesFilter1, options?: any): AxiosPromise<Array<PortesWithRelations>> {
            return localVarFp.portesControllerFind(filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {PortesFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        portesControllerFindById(id: number, filter?: PortesFilter, options?: any): AxiosPromise<PortesWithRelations> {
            return localVarFp.portesControllerFindById(id, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {Portes} [portes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        portesControllerReplaceById(id: number, portes?: Portes, options?: any): AxiosPromise<any> {
            return localVarFp.portesControllerReplaceById(id, portes, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {PortesPartial} [portesPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        portesControllerUpdateAll(where?: { [key: string]: object; }, portesPartial?: PortesPartial, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.portesControllerUpdateAll(where, portesPartial, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {PortesPartial} [portesPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        portesControllerUpdateById(id: number, portesPartial?: PortesPartial, options?: any): AxiosPromise<any> {
            return localVarFp.portesControllerUpdateById(id, portesPartial, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PortesControllerApi - object-oriented interface
 * @export
 * @class PortesControllerApi
 * @extends {BaseAPI}
 */
export class PortesControllerApi extends BaseAPI {
    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortesControllerApi
     */
    public portesControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig) {
        return PortesControllerApiFp(this.configuration).portesControllerCount(where, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NewPortes} [newPortes] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortesControllerApi
     */
    public portesControllerCreate(newPortes?: NewPortes, options?: AxiosRequestConfig) {
        return PortesControllerApiFp(this.configuration).portesControllerCreate(newPortes, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortesControllerApi
     */
    public portesControllerDeleteById(id: number, options?: AxiosRequestConfig) {
        return PortesControllerApiFp(this.configuration).portesControllerDeleteById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PortesFilter1} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortesControllerApi
     */
    public portesControllerFind(filter?: PortesFilter1, options?: AxiosRequestConfig) {
        return PortesControllerApiFp(this.configuration).portesControllerFind(filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {PortesFilter} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortesControllerApi
     */
    public portesControllerFindById(id: number, filter?: PortesFilter, options?: AxiosRequestConfig) {
        return PortesControllerApiFp(this.configuration).portesControllerFindById(id, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {Portes} [portes] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortesControllerApi
     */
    public portesControllerReplaceById(id: number, portes?: Portes, options?: AxiosRequestConfig) {
        return PortesControllerApiFp(this.configuration).portesControllerReplaceById(id, portes, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {PortesPartial} [portesPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortesControllerApi
     */
    public portesControllerUpdateAll(where?: { [key: string]: object; }, portesPartial?: PortesPartial, options?: AxiosRequestConfig) {
        return PortesControllerApiFp(this.configuration).portesControllerUpdateAll(where, portesPartial, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {PortesPartial} [portesPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortesControllerApi
     */
    public portesControllerUpdateById(id: number, portesPartial?: PortesPartial, options?: AxiosRequestConfig) {
        return PortesControllerApiFp(this.configuration).portesControllerUpdateById(id, portesPartial, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProductsControllerApi - axios parameter creator
 * @export
 */
export const ProductsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} customer 
         * @param {string} items 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsControllerQueryNav: async (customer: string, items: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customer' is not null or undefined
            assertParamExists('productsControllerQueryNav', 'customer', customer)
            // verify required parameter 'items' is not null or undefined
            assertParamExists('productsControllerQueryNav', 'items', items)
            const localVarPath = `/query-nav/{customer}/{items}`
                .replace(`{${"customer"}}`, encodeURIComponent(String(customer)))
                .replace(`{${"items"}}`, encodeURIComponent(String(items)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} customer 
         * @param {string} items 
         * @param {string} [customerPriceGroup] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsControllerQueryPageProducts: async (customer: string, items: string, customerPriceGroup?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customer' is not null or undefined
            assertParamExists('productsControllerQueryPageProducts', 'customer', customer)
            // verify required parameter 'items' is not null or undefined
            assertParamExists('productsControllerQueryPageProducts', 'items', items)
            const localVarPath = `/query-page-products/{customer}/{items}`
                .replace(`{${"customer"}}`, encodeURIComponent(String(customer)))
                .replace(`{${"items"}}`, encodeURIComponent(String(items)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (customerPriceGroup !== undefined) {
                localVarQueryParameter['customerPriceGroup'] = customerPriceGroup;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductsControllerApi - functional programming interface
 * @export
 */
export const ProductsControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductsControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} customer 
         * @param {string} items 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productsControllerQueryNav(customer: string, items: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productsControllerQueryNav(customer, items, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} customer 
         * @param {string} items 
         * @param {string} [customerPriceGroup] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productsControllerQueryPageProducts(customer: string, items: string, customerPriceGroup?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productsControllerQueryPageProducts(customer, items, customerPriceGroup, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProductsControllerApi - factory interface
 * @export
 */
export const ProductsControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductsControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} customer 
         * @param {string} items 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsControllerQueryNav(customer: string, items: string, options?: any): AxiosPromise<any> {
            return localVarFp.productsControllerQueryNav(customer, items, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} customer 
         * @param {string} items 
         * @param {string} [customerPriceGroup] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsControllerQueryPageProducts(customer: string, items: string, customerPriceGroup?: string, options?: any): AxiosPromise<any> {
            return localVarFp.productsControllerQueryPageProducts(customer, items, customerPriceGroup, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProductsControllerApi - object-oriented interface
 * @export
 * @class ProductsControllerApi
 * @extends {BaseAPI}
 */
export class ProductsControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} customer 
     * @param {string} items 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsControllerApi
     */
    public productsControllerQueryNav(customer: string, items: string, options?: AxiosRequestConfig) {
        return ProductsControllerApiFp(this.configuration).productsControllerQueryNav(customer, items, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} customer 
     * @param {string} items 
     * @param {string} [customerPriceGroup] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsControllerApi
     */
    public productsControllerQueryPageProducts(customer: string, items: string, customerPriceGroup?: string, options?: AxiosRequestConfig) {
        return ProductsControllerApiFp(this.configuration).productsControllerQueryPageProducts(customer, items, customerPriceGroup, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PromotionMetaControllerApi - axios parameter creator
 * @export
 */
export const PromotionMetaControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionMetaControllerCount: async (where?: { [key: string]: object; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/promotion-metas/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {NewPromotionMeta} [newPromotionMeta] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionMetaControllerCreate: async (newPromotionMeta?: NewPromotionMeta, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/promotion-metas`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newPromotionMeta, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionMetaControllerDeleteById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('promotionMetaControllerDeleteById', 'id', id)
            const localVarPath = `/promotion-metas/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PromotionMetaFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionMetaControllerFind: async (filter?: PromotionMetaFilter1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/promotion-metas`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {PromotionMetaFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionMetaControllerFindById: async (id: number, filter?: PromotionMetaFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('promotionMetaControllerFindById', 'id', id)
            const localVarPath = `/promotion-metas/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {PromotionMeta} [promotionMeta] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionMetaControllerReplaceById: async (id: number, promotionMeta?: PromotionMeta, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('promotionMetaControllerReplaceById', 'id', id)
            const localVarPath = `/promotion-metas/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(promotionMeta, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {PromotionMetaPartial} [promotionMetaPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionMetaControllerUpdateAll: async (where?: { [key: string]: object; }, promotionMetaPartial?: PromotionMetaPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/promotion-metas`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(promotionMetaPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {PromotionMetaPartial} [promotionMetaPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionMetaControllerUpdateById: async (id: number, promotionMetaPartial?: PromotionMetaPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('promotionMetaControllerUpdateById', 'id', id)
            const localVarPath = `/promotion-metas/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(promotionMetaPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PromotionMetaControllerApi - functional programming interface
 * @export
 */
export const PromotionMetaControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PromotionMetaControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async promotionMetaControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.promotionMetaControllerCount(where, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {NewPromotionMeta} [newPromotionMeta] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async promotionMetaControllerCreate(newPromotionMeta?: NewPromotionMeta, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PromotionMeta>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.promotionMetaControllerCreate(newPromotionMeta, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async promotionMetaControllerDeleteById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.promotionMetaControllerDeleteById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PromotionMetaFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async promotionMetaControllerFind(filter?: PromotionMetaFilter1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PromotionMetaWithRelations>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.promotionMetaControllerFind(filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {PromotionMetaFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async promotionMetaControllerFindById(id: number, filter?: PromotionMetaFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PromotionMetaWithRelations>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.promotionMetaControllerFindById(id, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {PromotionMeta} [promotionMeta] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async promotionMetaControllerReplaceById(id: number, promotionMeta?: PromotionMeta, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.promotionMetaControllerReplaceById(id, promotionMeta, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {PromotionMetaPartial} [promotionMetaPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async promotionMetaControllerUpdateAll(where?: { [key: string]: object; }, promotionMetaPartial?: PromotionMetaPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.promotionMetaControllerUpdateAll(where, promotionMetaPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {PromotionMetaPartial} [promotionMetaPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async promotionMetaControllerUpdateById(id: number, promotionMetaPartial?: PromotionMetaPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.promotionMetaControllerUpdateById(id, promotionMetaPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PromotionMetaControllerApi - factory interface
 * @export
 */
export const PromotionMetaControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PromotionMetaControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionMetaControllerCount(where?: { [key: string]: object; }, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.promotionMetaControllerCount(where, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NewPromotionMeta} [newPromotionMeta] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionMetaControllerCreate(newPromotionMeta?: NewPromotionMeta, options?: any): AxiosPromise<PromotionMeta> {
            return localVarFp.promotionMetaControllerCreate(newPromotionMeta, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionMetaControllerDeleteById(id: number, options?: any): AxiosPromise<any> {
            return localVarFp.promotionMetaControllerDeleteById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PromotionMetaFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionMetaControllerFind(filter?: PromotionMetaFilter1, options?: any): AxiosPromise<Array<PromotionMetaWithRelations>> {
            return localVarFp.promotionMetaControllerFind(filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {PromotionMetaFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionMetaControllerFindById(id: number, filter?: PromotionMetaFilter, options?: any): AxiosPromise<PromotionMetaWithRelations> {
            return localVarFp.promotionMetaControllerFindById(id, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {PromotionMeta} [promotionMeta] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionMetaControllerReplaceById(id: number, promotionMeta?: PromotionMeta, options?: any): AxiosPromise<any> {
            return localVarFp.promotionMetaControllerReplaceById(id, promotionMeta, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {PromotionMetaPartial} [promotionMetaPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionMetaControllerUpdateAll(where?: { [key: string]: object; }, promotionMetaPartial?: PromotionMetaPartial, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.promotionMetaControllerUpdateAll(where, promotionMetaPartial, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {PromotionMetaPartial} [promotionMetaPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionMetaControllerUpdateById(id: number, promotionMetaPartial?: PromotionMetaPartial, options?: any): AxiosPromise<any> {
            return localVarFp.promotionMetaControllerUpdateById(id, promotionMetaPartial, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PromotionMetaControllerApi - object-oriented interface
 * @export
 * @class PromotionMetaControllerApi
 * @extends {BaseAPI}
 */
export class PromotionMetaControllerApi extends BaseAPI {
    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionMetaControllerApi
     */
    public promotionMetaControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig) {
        return PromotionMetaControllerApiFp(this.configuration).promotionMetaControllerCount(where, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NewPromotionMeta} [newPromotionMeta] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionMetaControllerApi
     */
    public promotionMetaControllerCreate(newPromotionMeta?: NewPromotionMeta, options?: AxiosRequestConfig) {
        return PromotionMetaControllerApiFp(this.configuration).promotionMetaControllerCreate(newPromotionMeta, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionMetaControllerApi
     */
    public promotionMetaControllerDeleteById(id: number, options?: AxiosRequestConfig) {
        return PromotionMetaControllerApiFp(this.configuration).promotionMetaControllerDeleteById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PromotionMetaFilter1} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionMetaControllerApi
     */
    public promotionMetaControllerFind(filter?: PromotionMetaFilter1, options?: AxiosRequestConfig) {
        return PromotionMetaControllerApiFp(this.configuration).promotionMetaControllerFind(filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {PromotionMetaFilter} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionMetaControllerApi
     */
    public promotionMetaControllerFindById(id: number, filter?: PromotionMetaFilter, options?: AxiosRequestConfig) {
        return PromotionMetaControllerApiFp(this.configuration).promotionMetaControllerFindById(id, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {PromotionMeta} [promotionMeta] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionMetaControllerApi
     */
    public promotionMetaControllerReplaceById(id: number, promotionMeta?: PromotionMeta, options?: AxiosRequestConfig) {
        return PromotionMetaControllerApiFp(this.configuration).promotionMetaControllerReplaceById(id, promotionMeta, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {PromotionMetaPartial} [promotionMetaPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionMetaControllerApi
     */
    public promotionMetaControllerUpdateAll(where?: { [key: string]: object; }, promotionMetaPartial?: PromotionMetaPartial, options?: AxiosRequestConfig) {
        return PromotionMetaControllerApiFp(this.configuration).promotionMetaControllerUpdateAll(where, promotionMetaPartial, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {PromotionMetaPartial} [promotionMetaPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionMetaControllerApi
     */
    public promotionMetaControllerUpdateById(id: number, promotionMetaPartial?: PromotionMetaPartial, options?: AxiosRequestConfig) {
        return PromotionMetaControllerApiFp(this.configuration).promotionMetaControllerUpdateById(id, promotionMetaPartial, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PromotionsControllerApi - axios parameter creator
 * @export
 */
export const PromotionsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionsControllerCount: async (where?: { [key: string]: object; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/promotions/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {NewPromotions} [newPromotions] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionsControllerCreate: async (newPromotions?: NewPromotions, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/promotions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newPromotions, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionsControllerDeleteById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('promotionsControllerDeleteById', 'id', id)
            const localVarPath = `/promotions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PromotionsFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionsControllerFind: async (filter?: PromotionsFilter1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/promotions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {PromotionsFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionsControllerFindById: async (id: number, filter?: PromotionsFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('promotionsControllerFindById', 'id', id)
            const localVarPath = `/promotions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {Promotions} [promotions] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionsControllerReplaceById: async (id: number, promotions?: Promotions, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('promotionsControllerReplaceById', 'id', id)
            const localVarPath = `/promotions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(promotions, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {PromotionsPartial} [promotionsPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionsControllerUpdateAll: async (where?: { [key: string]: object; }, promotionsPartial?: PromotionsPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/promotions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(promotionsPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {PromotionsPartial} [promotionsPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionsControllerUpdateById: async (id: number, promotionsPartial?: PromotionsPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('promotionsControllerUpdateById', 'id', id)
            const localVarPath = `/promotions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(promotionsPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PromotionsControllerApi - functional programming interface
 * @export
 */
export const PromotionsControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PromotionsControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async promotionsControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.promotionsControllerCount(where, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {NewPromotions} [newPromotions] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async promotionsControllerCreate(newPromotions?: NewPromotions, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Promotions>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.promotionsControllerCreate(newPromotions, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async promotionsControllerDeleteById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.promotionsControllerDeleteById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PromotionsFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async promotionsControllerFind(filter?: PromotionsFilter1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PromotionsWithRelations>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.promotionsControllerFind(filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {PromotionsFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async promotionsControllerFindById(id: number, filter?: PromotionsFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PromotionsWithRelations>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.promotionsControllerFindById(id, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {Promotions} [promotions] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async promotionsControllerReplaceById(id: number, promotions?: Promotions, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.promotionsControllerReplaceById(id, promotions, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {PromotionsPartial} [promotionsPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async promotionsControllerUpdateAll(where?: { [key: string]: object; }, promotionsPartial?: PromotionsPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.promotionsControllerUpdateAll(where, promotionsPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {PromotionsPartial} [promotionsPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async promotionsControllerUpdateById(id: number, promotionsPartial?: PromotionsPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.promotionsControllerUpdateById(id, promotionsPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PromotionsControllerApi - factory interface
 * @export
 */
export const PromotionsControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PromotionsControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionsControllerCount(where?: { [key: string]: object; }, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.promotionsControllerCount(where, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NewPromotions} [newPromotions] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionsControllerCreate(newPromotions?: NewPromotions, options?: any): AxiosPromise<Promotions> {
            return localVarFp.promotionsControllerCreate(newPromotions, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionsControllerDeleteById(id: number, options?: any): AxiosPromise<any> {
            return localVarFp.promotionsControllerDeleteById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PromotionsFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionsControllerFind(filter?: PromotionsFilter1, options?: any): AxiosPromise<Array<PromotionsWithRelations>> {
            return localVarFp.promotionsControllerFind(filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {PromotionsFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionsControllerFindById(id: number, filter?: PromotionsFilter, options?: any): AxiosPromise<PromotionsWithRelations> {
            return localVarFp.promotionsControllerFindById(id, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {Promotions} [promotions] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionsControllerReplaceById(id: number, promotions?: Promotions, options?: any): AxiosPromise<any> {
            return localVarFp.promotionsControllerReplaceById(id, promotions, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {PromotionsPartial} [promotionsPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionsControllerUpdateAll(where?: { [key: string]: object; }, promotionsPartial?: PromotionsPartial, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.promotionsControllerUpdateAll(where, promotionsPartial, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {PromotionsPartial} [promotionsPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionsControllerUpdateById(id: number, promotionsPartial?: PromotionsPartial, options?: any): AxiosPromise<any> {
            return localVarFp.promotionsControllerUpdateById(id, promotionsPartial, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PromotionsControllerApi - object-oriented interface
 * @export
 * @class PromotionsControllerApi
 * @extends {BaseAPI}
 */
export class PromotionsControllerApi extends BaseAPI {
    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionsControllerApi
     */
    public promotionsControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig) {
        return PromotionsControllerApiFp(this.configuration).promotionsControllerCount(where, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NewPromotions} [newPromotions] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionsControllerApi
     */
    public promotionsControllerCreate(newPromotions?: NewPromotions, options?: AxiosRequestConfig) {
        return PromotionsControllerApiFp(this.configuration).promotionsControllerCreate(newPromotions, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionsControllerApi
     */
    public promotionsControllerDeleteById(id: number, options?: AxiosRequestConfig) {
        return PromotionsControllerApiFp(this.configuration).promotionsControllerDeleteById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PromotionsFilter1} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionsControllerApi
     */
    public promotionsControllerFind(filter?: PromotionsFilter1, options?: AxiosRequestConfig) {
        return PromotionsControllerApiFp(this.configuration).promotionsControllerFind(filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {PromotionsFilter} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionsControllerApi
     */
    public promotionsControllerFindById(id: number, filter?: PromotionsFilter, options?: AxiosRequestConfig) {
        return PromotionsControllerApiFp(this.configuration).promotionsControllerFindById(id, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {Promotions} [promotions] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionsControllerApi
     */
    public promotionsControllerReplaceById(id: number, promotions?: Promotions, options?: AxiosRequestConfig) {
        return PromotionsControllerApiFp(this.configuration).promotionsControllerReplaceById(id, promotions, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {PromotionsPartial} [promotionsPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionsControllerApi
     */
    public promotionsControllerUpdateAll(where?: { [key: string]: object; }, promotionsPartial?: PromotionsPartial, options?: AxiosRequestConfig) {
        return PromotionsControllerApiFp(this.configuration).promotionsControllerUpdateAll(where, promotionsPartial, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {PromotionsPartial} [promotionsPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionsControllerApi
     */
    public promotionsControllerUpdateById(id: number, promotionsPartial?: PromotionsPartial, options?: AxiosRequestConfig) {
        return PromotionsControllerApiFp(this.configuration).promotionsControllerUpdateById(id, promotionsPartial, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PromotionsPromotionMetaControllerApi - axios parameter creator
 * @export
 */
export const PromotionsPromotionMetaControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {NewPromotionMetaInPromotions} [newPromotionMetaInPromotions] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionsPromotionMetaControllerCreate: async (id: number, newPromotionMetaInPromotions?: NewPromotionMetaInPromotions, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('promotionsPromotionMetaControllerCreate', 'id', id)
            const localVarPath = `/promotions/{id}/promotion-metas`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newPromotionMetaInPromotions, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionsPromotionMetaControllerDelete: async (id: number, where?: { [key: string]: object; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('promotionsPromotionMetaControllerDelete', 'id', id)
            const localVarPath = `/promotions/{id}/promotion-metas`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {{ [key: string]: object; }} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionsPromotionMetaControllerFind: async (id: number, filter?: { [key: string]: object; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('promotionsPromotionMetaControllerFind', 'id', id)
            const localVarPath = `/promotions/{id}/promotion-metas`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {{ [key: string]: object; }} [where] 
         * @param {PromotionMetaPartial} [promotionMetaPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionsPromotionMetaControllerPatch: async (id: number, where?: { [key: string]: object; }, promotionMetaPartial?: PromotionMetaPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('promotionsPromotionMetaControllerPatch', 'id', id)
            const localVarPath = `/promotions/{id}/promotion-metas`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(promotionMetaPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PromotionsPromotionMetaControllerApi - functional programming interface
 * @export
 */
export const PromotionsPromotionMetaControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PromotionsPromotionMetaControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {NewPromotionMetaInPromotions} [newPromotionMetaInPromotions] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async promotionsPromotionMetaControllerCreate(id: number, newPromotionMetaInPromotions?: NewPromotionMetaInPromotions, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PromotionMeta>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.promotionsPromotionMetaControllerCreate(id, newPromotionMetaInPromotions, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async promotionsPromotionMetaControllerDelete(id: number, where?: { [key: string]: object; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.promotionsPromotionMetaControllerDelete(id, where, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {{ [key: string]: object; }} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async promotionsPromotionMetaControllerFind(id: number, filter?: { [key: string]: object; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PromotionMeta>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.promotionsPromotionMetaControllerFind(id, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {{ [key: string]: object; }} [where] 
         * @param {PromotionMetaPartial} [promotionMetaPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async promotionsPromotionMetaControllerPatch(id: number, where?: { [key: string]: object; }, promotionMetaPartial?: PromotionMetaPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.promotionsPromotionMetaControllerPatch(id, where, promotionMetaPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PromotionsPromotionMetaControllerApi - factory interface
 * @export
 */
export const PromotionsPromotionMetaControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PromotionsPromotionMetaControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {NewPromotionMetaInPromotions} [newPromotionMetaInPromotions] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionsPromotionMetaControllerCreate(id: number, newPromotionMetaInPromotions?: NewPromotionMetaInPromotions, options?: any): AxiosPromise<PromotionMeta> {
            return localVarFp.promotionsPromotionMetaControllerCreate(id, newPromotionMetaInPromotions, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionsPromotionMetaControllerDelete(id: number, where?: { [key: string]: object; }, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.promotionsPromotionMetaControllerDelete(id, where, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {{ [key: string]: object; }} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionsPromotionMetaControllerFind(id: number, filter?: { [key: string]: object; }, options?: any): AxiosPromise<Array<PromotionMeta>> {
            return localVarFp.promotionsPromotionMetaControllerFind(id, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {{ [key: string]: object; }} [where] 
         * @param {PromotionMetaPartial} [promotionMetaPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionsPromotionMetaControllerPatch(id: number, where?: { [key: string]: object; }, promotionMetaPartial?: PromotionMetaPartial, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.promotionsPromotionMetaControllerPatch(id, where, promotionMetaPartial, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PromotionsPromotionMetaControllerApi - object-oriented interface
 * @export
 * @class PromotionsPromotionMetaControllerApi
 * @extends {BaseAPI}
 */
export class PromotionsPromotionMetaControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {NewPromotionMetaInPromotions} [newPromotionMetaInPromotions] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionsPromotionMetaControllerApi
     */
    public promotionsPromotionMetaControllerCreate(id: number, newPromotionMetaInPromotions?: NewPromotionMetaInPromotions, options?: AxiosRequestConfig) {
        return PromotionsPromotionMetaControllerApiFp(this.configuration).promotionsPromotionMetaControllerCreate(id, newPromotionMetaInPromotions, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {{ [key: string]: object; }} [where] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionsPromotionMetaControllerApi
     */
    public promotionsPromotionMetaControllerDelete(id: number, where?: { [key: string]: object; }, options?: AxiosRequestConfig) {
        return PromotionsPromotionMetaControllerApiFp(this.configuration).promotionsPromotionMetaControllerDelete(id, where, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {{ [key: string]: object; }} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionsPromotionMetaControllerApi
     */
    public promotionsPromotionMetaControllerFind(id: number, filter?: { [key: string]: object; }, options?: AxiosRequestConfig) {
        return PromotionsPromotionMetaControllerApiFp(this.configuration).promotionsPromotionMetaControllerFind(id, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {{ [key: string]: object; }} [where] 
     * @param {PromotionMetaPartial} [promotionMetaPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionsPromotionMetaControllerApi
     */
    public promotionsPromotionMetaControllerPatch(id: number, where?: { [key: string]: object; }, promotionMetaPartial?: PromotionMetaPartial, options?: AxiosRequestConfig) {
        return PromotionsPromotionMetaControllerApiFp(this.configuration).promotionsPromotionMetaControllerPatch(id, where, promotionMetaPartial, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PromotionsUsersControllerApi - axios parameter creator
 * @export
 */
export const PromotionsUsersControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionsUsersControllerGetUsers: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('promotionsUsersControllerGetUsers', 'id', id)
            const localVarPath = `/promotions/{id}/users`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PromotionsUsersControllerApi - functional programming interface
 * @export
 */
export const PromotionsUsersControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PromotionsUsersControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async promotionsUsersControllerGetUsers(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Users>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.promotionsUsersControllerGetUsers(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PromotionsUsersControllerApi - factory interface
 * @export
 */
export const PromotionsUsersControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PromotionsUsersControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionsUsersControllerGetUsers(id: number, options?: any): AxiosPromise<Array<Users>> {
            return localVarFp.promotionsUsersControllerGetUsers(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PromotionsUsersControllerApi - object-oriented interface
 * @export
 * @class PromotionsUsersControllerApi
 * @extends {BaseAPI}
 */
export class PromotionsUsersControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionsUsersControllerApi
     */
    public promotionsUsersControllerGetUsers(id: number, options?: AxiosRequestConfig) {
        return PromotionsUsersControllerApiFp(this.configuration).promotionsUsersControllerGetUsers(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RememberTokensControllerApi - axios parameter creator
 * @export
 */
export const RememberTokensControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rememberTokensControllerCount: async (where?: { [key: string]: object; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/remember-tokens/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {NewRememberTokens} [newRememberTokens] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rememberTokensControllerCreate: async (newRememberTokens?: NewRememberTokens, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/remember-tokens`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newRememberTokens, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rememberTokensControllerDeleteById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('rememberTokensControllerDeleteById', 'id', id)
            const localVarPath = `/remember-tokens/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RememberTokensFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rememberTokensControllerFind: async (filter?: RememberTokensFilter1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/remember-tokens`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {RememberTokensFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rememberTokensControllerFindById: async (id: number, filter?: RememberTokensFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('rememberTokensControllerFindById', 'id', id)
            const localVarPath = `/remember-tokens/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {RememberTokens} [rememberTokens] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rememberTokensControllerReplaceById: async (id: number, rememberTokens?: RememberTokens, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('rememberTokensControllerReplaceById', 'id', id)
            const localVarPath = `/remember-tokens/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rememberTokens, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {RememberTokensPartial} [rememberTokensPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rememberTokensControllerUpdateAll: async (where?: { [key: string]: object; }, rememberTokensPartial?: RememberTokensPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/remember-tokens`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rememberTokensPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {RememberTokensPartial} [rememberTokensPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rememberTokensControllerUpdateById: async (id: number, rememberTokensPartial?: RememberTokensPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('rememberTokensControllerUpdateById', 'id', id)
            const localVarPath = `/remember-tokens/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rememberTokensPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RememberTokensControllerApi - functional programming interface
 * @export
 */
export const RememberTokensControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RememberTokensControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rememberTokensControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rememberTokensControllerCount(where, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {NewRememberTokens} [newRememberTokens] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rememberTokensControllerCreate(newRememberTokens?: NewRememberTokens, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RememberTokens>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rememberTokensControllerCreate(newRememberTokens, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rememberTokensControllerDeleteById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rememberTokensControllerDeleteById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RememberTokensFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rememberTokensControllerFind(filter?: RememberTokensFilter1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RememberTokensWithRelations>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rememberTokensControllerFind(filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {RememberTokensFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rememberTokensControllerFindById(id: number, filter?: RememberTokensFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RememberTokensWithRelations>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rememberTokensControllerFindById(id, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {RememberTokens} [rememberTokens] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rememberTokensControllerReplaceById(id: number, rememberTokens?: RememberTokens, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rememberTokensControllerReplaceById(id, rememberTokens, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {RememberTokensPartial} [rememberTokensPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rememberTokensControllerUpdateAll(where?: { [key: string]: object; }, rememberTokensPartial?: RememberTokensPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rememberTokensControllerUpdateAll(where, rememberTokensPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {RememberTokensPartial} [rememberTokensPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rememberTokensControllerUpdateById(id: number, rememberTokensPartial?: RememberTokensPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rememberTokensControllerUpdateById(id, rememberTokensPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RememberTokensControllerApi - factory interface
 * @export
 */
export const RememberTokensControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RememberTokensControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rememberTokensControllerCount(where?: { [key: string]: object; }, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.rememberTokensControllerCount(where, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NewRememberTokens} [newRememberTokens] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rememberTokensControllerCreate(newRememberTokens?: NewRememberTokens, options?: any): AxiosPromise<RememberTokens> {
            return localVarFp.rememberTokensControllerCreate(newRememberTokens, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rememberTokensControllerDeleteById(id: number, options?: any): AxiosPromise<any> {
            return localVarFp.rememberTokensControllerDeleteById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RememberTokensFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rememberTokensControllerFind(filter?: RememberTokensFilter1, options?: any): AxiosPromise<Array<RememberTokensWithRelations>> {
            return localVarFp.rememberTokensControllerFind(filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {RememberTokensFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rememberTokensControllerFindById(id: number, filter?: RememberTokensFilter, options?: any): AxiosPromise<RememberTokensWithRelations> {
            return localVarFp.rememberTokensControllerFindById(id, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {RememberTokens} [rememberTokens] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rememberTokensControllerReplaceById(id: number, rememberTokens?: RememberTokens, options?: any): AxiosPromise<any> {
            return localVarFp.rememberTokensControllerReplaceById(id, rememberTokens, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {RememberTokensPartial} [rememberTokensPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rememberTokensControllerUpdateAll(where?: { [key: string]: object; }, rememberTokensPartial?: RememberTokensPartial, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.rememberTokensControllerUpdateAll(where, rememberTokensPartial, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {RememberTokensPartial} [rememberTokensPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rememberTokensControllerUpdateById(id: number, rememberTokensPartial?: RememberTokensPartial, options?: any): AxiosPromise<any> {
            return localVarFp.rememberTokensControllerUpdateById(id, rememberTokensPartial, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RememberTokensControllerApi - object-oriented interface
 * @export
 * @class RememberTokensControllerApi
 * @extends {BaseAPI}
 */
export class RememberTokensControllerApi extends BaseAPI {
    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RememberTokensControllerApi
     */
    public rememberTokensControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig) {
        return RememberTokensControllerApiFp(this.configuration).rememberTokensControllerCount(where, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NewRememberTokens} [newRememberTokens] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RememberTokensControllerApi
     */
    public rememberTokensControllerCreate(newRememberTokens?: NewRememberTokens, options?: AxiosRequestConfig) {
        return RememberTokensControllerApiFp(this.configuration).rememberTokensControllerCreate(newRememberTokens, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RememberTokensControllerApi
     */
    public rememberTokensControllerDeleteById(id: number, options?: AxiosRequestConfig) {
        return RememberTokensControllerApiFp(this.configuration).rememberTokensControllerDeleteById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RememberTokensFilter1} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RememberTokensControllerApi
     */
    public rememberTokensControllerFind(filter?: RememberTokensFilter1, options?: AxiosRequestConfig) {
        return RememberTokensControllerApiFp(this.configuration).rememberTokensControllerFind(filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {RememberTokensFilter} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RememberTokensControllerApi
     */
    public rememberTokensControllerFindById(id: number, filter?: RememberTokensFilter, options?: AxiosRequestConfig) {
        return RememberTokensControllerApiFp(this.configuration).rememberTokensControllerFindById(id, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {RememberTokens} [rememberTokens] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RememberTokensControllerApi
     */
    public rememberTokensControllerReplaceById(id: number, rememberTokens?: RememberTokens, options?: AxiosRequestConfig) {
        return RememberTokensControllerApiFp(this.configuration).rememberTokensControllerReplaceById(id, rememberTokens, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {RememberTokensPartial} [rememberTokensPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RememberTokensControllerApi
     */
    public rememberTokensControllerUpdateAll(where?: { [key: string]: object; }, rememberTokensPartial?: RememberTokensPartial, options?: AxiosRequestConfig) {
        return RememberTokensControllerApiFp(this.configuration).rememberTokensControllerUpdateAll(where, rememberTokensPartial, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {RememberTokensPartial} [rememberTokensPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RememberTokensControllerApi
     */
    public rememberTokensControllerUpdateById(id: number, rememberTokensPartial?: RememberTokensPartial, options?: AxiosRequestConfig) {
        return RememberTokensControllerApiFp(this.configuration).rememberTokensControllerUpdateById(id, rememberTokensPartial, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ResetPasswordsControllerApi - axios parameter creator
 * @export
 */
export const ResetPasswordsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPasswordsControllerCount: async (where?: { [key: string]: object; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/reset-passwords/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {NewResetPasswords} [newResetPasswords] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPasswordsControllerCreate: async (newResetPasswords?: NewResetPasswords, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/reset-passwords`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newResetPasswords, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPasswordsControllerDeleteById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('resetPasswordsControllerDeleteById', 'id', id)
            const localVarPath = `/reset-passwords/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ResetPasswordsFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPasswordsControllerFind: async (filter?: ResetPasswordsFilter1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/reset-passwords`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ResetPasswordsFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPasswordsControllerFindById: async (id: number, filter?: ResetPasswordsFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('resetPasswordsControllerFindById', 'id', id)
            const localVarPath = `/reset-passwords/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ResetPasswords} [resetPasswords] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPasswordsControllerReplaceById: async (id: number, resetPasswords?: ResetPasswords, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('resetPasswordsControllerReplaceById', 'id', id)
            const localVarPath = `/reset-passwords/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resetPasswords, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {ResetPasswordsPartial} [resetPasswordsPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPasswordsControllerUpdateAll: async (where?: { [key: string]: object; }, resetPasswordsPartial?: ResetPasswordsPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/reset-passwords`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resetPasswordsPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ResetPasswordsPartial} [resetPasswordsPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPasswordsControllerUpdateById: async (id: number, resetPasswordsPartial?: ResetPasswordsPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('resetPasswordsControllerUpdateById', 'id', id)
            const localVarPath = `/reset-passwords/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resetPasswordsPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ResetPasswordsControllerApi - functional programming interface
 * @export
 */
export const ResetPasswordsControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ResetPasswordsControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetPasswordsControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetPasswordsControllerCount(where, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {NewResetPasswords} [newResetPasswords] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetPasswordsControllerCreate(newResetPasswords?: NewResetPasswords, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResetPasswords>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetPasswordsControllerCreate(newResetPasswords, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetPasswordsControllerDeleteById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetPasswordsControllerDeleteById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ResetPasswordsFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetPasswordsControllerFind(filter?: ResetPasswordsFilter1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ResetPasswordsWithRelations>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetPasswordsControllerFind(filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ResetPasswordsFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetPasswordsControllerFindById(id: number, filter?: ResetPasswordsFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResetPasswordsWithRelations>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetPasswordsControllerFindById(id, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ResetPasswords} [resetPasswords] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetPasswordsControllerReplaceById(id: number, resetPasswords?: ResetPasswords, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetPasswordsControllerReplaceById(id, resetPasswords, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {ResetPasswordsPartial} [resetPasswordsPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetPasswordsControllerUpdateAll(where?: { [key: string]: object; }, resetPasswordsPartial?: ResetPasswordsPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetPasswordsControllerUpdateAll(where, resetPasswordsPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ResetPasswordsPartial} [resetPasswordsPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetPasswordsControllerUpdateById(id: number, resetPasswordsPartial?: ResetPasswordsPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetPasswordsControllerUpdateById(id, resetPasswordsPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ResetPasswordsControllerApi - factory interface
 * @export
 */
export const ResetPasswordsControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ResetPasswordsControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPasswordsControllerCount(where?: { [key: string]: object; }, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.resetPasswordsControllerCount(where, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NewResetPasswords} [newResetPasswords] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPasswordsControllerCreate(newResetPasswords?: NewResetPasswords, options?: any): AxiosPromise<ResetPasswords> {
            return localVarFp.resetPasswordsControllerCreate(newResetPasswords, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPasswordsControllerDeleteById(id: number, options?: any): AxiosPromise<any> {
            return localVarFp.resetPasswordsControllerDeleteById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ResetPasswordsFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPasswordsControllerFind(filter?: ResetPasswordsFilter1, options?: any): AxiosPromise<Array<ResetPasswordsWithRelations>> {
            return localVarFp.resetPasswordsControllerFind(filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ResetPasswordsFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPasswordsControllerFindById(id: number, filter?: ResetPasswordsFilter, options?: any): AxiosPromise<ResetPasswordsWithRelations> {
            return localVarFp.resetPasswordsControllerFindById(id, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ResetPasswords} [resetPasswords] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPasswordsControllerReplaceById(id: number, resetPasswords?: ResetPasswords, options?: any): AxiosPromise<any> {
            return localVarFp.resetPasswordsControllerReplaceById(id, resetPasswords, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {ResetPasswordsPartial} [resetPasswordsPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPasswordsControllerUpdateAll(where?: { [key: string]: object; }, resetPasswordsPartial?: ResetPasswordsPartial, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.resetPasswordsControllerUpdateAll(where, resetPasswordsPartial, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ResetPasswordsPartial} [resetPasswordsPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPasswordsControllerUpdateById(id: number, resetPasswordsPartial?: ResetPasswordsPartial, options?: any): AxiosPromise<any> {
            return localVarFp.resetPasswordsControllerUpdateById(id, resetPasswordsPartial, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ResetPasswordsControllerApi - object-oriented interface
 * @export
 * @class ResetPasswordsControllerApi
 * @extends {BaseAPI}
 */
export class ResetPasswordsControllerApi extends BaseAPI {
    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResetPasswordsControllerApi
     */
    public resetPasswordsControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig) {
        return ResetPasswordsControllerApiFp(this.configuration).resetPasswordsControllerCount(where, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NewResetPasswords} [newResetPasswords] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResetPasswordsControllerApi
     */
    public resetPasswordsControllerCreate(newResetPasswords?: NewResetPasswords, options?: AxiosRequestConfig) {
        return ResetPasswordsControllerApiFp(this.configuration).resetPasswordsControllerCreate(newResetPasswords, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResetPasswordsControllerApi
     */
    public resetPasswordsControllerDeleteById(id: number, options?: AxiosRequestConfig) {
        return ResetPasswordsControllerApiFp(this.configuration).resetPasswordsControllerDeleteById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ResetPasswordsFilter1} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResetPasswordsControllerApi
     */
    public resetPasswordsControllerFind(filter?: ResetPasswordsFilter1, options?: AxiosRequestConfig) {
        return ResetPasswordsControllerApiFp(this.configuration).resetPasswordsControllerFind(filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ResetPasswordsFilter} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResetPasswordsControllerApi
     */
    public resetPasswordsControllerFindById(id: number, filter?: ResetPasswordsFilter, options?: AxiosRequestConfig) {
        return ResetPasswordsControllerApiFp(this.configuration).resetPasswordsControllerFindById(id, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ResetPasswords} [resetPasswords] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResetPasswordsControllerApi
     */
    public resetPasswordsControllerReplaceById(id: number, resetPasswords?: ResetPasswords, options?: AxiosRequestConfig) {
        return ResetPasswordsControllerApiFp(this.configuration).resetPasswordsControllerReplaceById(id, resetPasswords, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {ResetPasswordsPartial} [resetPasswordsPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResetPasswordsControllerApi
     */
    public resetPasswordsControllerUpdateAll(where?: { [key: string]: object; }, resetPasswordsPartial?: ResetPasswordsPartial, options?: AxiosRequestConfig) {
        return ResetPasswordsControllerApiFp(this.configuration).resetPasswordsControllerUpdateAll(where, resetPasswordsPartial, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ResetPasswordsPartial} [resetPasswordsPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResetPasswordsControllerApi
     */
    public resetPasswordsControllerUpdateById(id: number, resetPasswordsPartial?: ResetPasswordsPartial, options?: AxiosRequestConfig) {
        return ResetPasswordsControllerApiFp(this.configuration).resetPasswordsControllerUpdateById(id, resetPasswordsPartial, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RolesControllerApi - axios parameter creator
 * @export
 */
export const RolesControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rolesControllerCount: async (where?: { [key: string]: object; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/roles/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {NewRoles} [newRoles] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rolesControllerCreate: async (newRoles?: NewRoles, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newRoles, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rolesControllerDeleteById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('rolesControllerDeleteById', 'id', id)
            const localVarPath = `/roles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RolesFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rolesControllerFind: async (filter?: RolesFilter1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {RolesFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rolesControllerFindById: async (id: number, filter?: RolesFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('rolesControllerFindById', 'id', id)
            const localVarPath = `/roles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {Roles} [roles] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rolesControllerReplaceById: async (id: number, roles?: Roles, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('rolesControllerReplaceById', 'id', id)
            const localVarPath = `/roles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(roles, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {RolesPartial} [rolesPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rolesControllerUpdateAll: async (where?: { [key: string]: object; }, rolesPartial?: RolesPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rolesPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {RolesPartial} [rolesPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rolesControllerUpdateById: async (id: number, rolesPartial?: RolesPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('rolesControllerUpdateById', 'id', id)
            const localVarPath = `/roles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rolesPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RolesControllerApi - functional programming interface
 * @export
 */
export const RolesControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RolesControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rolesControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rolesControllerCount(where, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {NewRoles} [newRoles] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rolesControllerCreate(newRoles?: NewRoles, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Roles>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rolesControllerCreate(newRoles, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rolesControllerDeleteById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rolesControllerDeleteById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RolesFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rolesControllerFind(filter?: RolesFilter1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RolesWithRelations>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rolesControllerFind(filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {RolesFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rolesControllerFindById(id: number, filter?: RolesFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RolesWithRelations>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rolesControllerFindById(id, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {Roles} [roles] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rolesControllerReplaceById(id: number, roles?: Roles, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rolesControllerReplaceById(id, roles, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {RolesPartial} [rolesPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rolesControllerUpdateAll(where?: { [key: string]: object; }, rolesPartial?: RolesPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rolesControllerUpdateAll(where, rolesPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {RolesPartial} [rolesPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rolesControllerUpdateById(id: number, rolesPartial?: RolesPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rolesControllerUpdateById(id, rolesPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RolesControllerApi - factory interface
 * @export
 */
export const RolesControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RolesControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rolesControllerCount(where?: { [key: string]: object; }, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.rolesControllerCount(where, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NewRoles} [newRoles] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rolesControllerCreate(newRoles?: NewRoles, options?: any): AxiosPromise<Roles> {
            return localVarFp.rolesControllerCreate(newRoles, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rolesControllerDeleteById(id: number, options?: any): AxiosPromise<any> {
            return localVarFp.rolesControllerDeleteById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RolesFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rolesControllerFind(filter?: RolesFilter1, options?: any): AxiosPromise<Array<RolesWithRelations>> {
            return localVarFp.rolesControllerFind(filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {RolesFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rolesControllerFindById(id: number, filter?: RolesFilter, options?: any): AxiosPromise<RolesWithRelations> {
            return localVarFp.rolesControllerFindById(id, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {Roles} [roles] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rolesControllerReplaceById(id: number, roles?: Roles, options?: any): AxiosPromise<any> {
            return localVarFp.rolesControllerReplaceById(id, roles, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {RolesPartial} [rolesPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rolesControllerUpdateAll(where?: { [key: string]: object; }, rolesPartial?: RolesPartial, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.rolesControllerUpdateAll(where, rolesPartial, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {RolesPartial} [rolesPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rolesControllerUpdateById(id: number, rolesPartial?: RolesPartial, options?: any): AxiosPromise<any> {
            return localVarFp.rolesControllerUpdateById(id, rolesPartial, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RolesControllerApi - object-oriented interface
 * @export
 * @class RolesControllerApi
 * @extends {BaseAPI}
 */
export class RolesControllerApi extends BaseAPI {
    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesControllerApi
     */
    public rolesControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig) {
        return RolesControllerApiFp(this.configuration).rolesControllerCount(where, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NewRoles} [newRoles] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesControllerApi
     */
    public rolesControllerCreate(newRoles?: NewRoles, options?: AxiosRequestConfig) {
        return RolesControllerApiFp(this.configuration).rolesControllerCreate(newRoles, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesControllerApi
     */
    public rolesControllerDeleteById(id: number, options?: AxiosRequestConfig) {
        return RolesControllerApiFp(this.configuration).rolesControllerDeleteById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RolesFilter1} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesControllerApi
     */
    public rolesControllerFind(filter?: RolesFilter1, options?: AxiosRequestConfig) {
        return RolesControllerApiFp(this.configuration).rolesControllerFind(filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {RolesFilter} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesControllerApi
     */
    public rolesControllerFindById(id: number, filter?: RolesFilter, options?: AxiosRequestConfig) {
        return RolesControllerApiFp(this.configuration).rolesControllerFindById(id, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {Roles} [roles] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesControllerApi
     */
    public rolesControllerReplaceById(id: number, roles?: Roles, options?: AxiosRequestConfig) {
        return RolesControllerApiFp(this.configuration).rolesControllerReplaceById(id, roles, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {RolesPartial} [rolesPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesControllerApi
     */
    public rolesControllerUpdateAll(where?: { [key: string]: object; }, rolesPartial?: RolesPartial, options?: AxiosRequestConfig) {
        return RolesControllerApiFp(this.configuration).rolesControllerUpdateAll(where, rolesPartial, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {RolesPartial} [rolesPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesControllerApi
     */
    public rolesControllerUpdateById(id: number, rolesPartial?: RolesPartial, options?: AxiosRequestConfig) {
        return RolesControllerApiFp(this.configuration).rolesControllerUpdateById(id, rolesPartial, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RolesPermissionsControllerApi - axios parameter creator
 * @export
 */
export const RolesPermissionsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rolesPermissionsControllerCount: async (where?: { [key: string]: object; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/roles-permissions/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {NewRolesPermissions} [newRolesPermissions] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rolesPermissionsControllerCreate: async (newRolesPermissions?: NewRolesPermissions, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/roles-permissions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newRolesPermissions, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rolesPermissionsControllerDeleteById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('rolesPermissionsControllerDeleteById', 'id', id)
            const localVarPath = `/roles-permissions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RolesPermissionsFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rolesPermissionsControllerFind: async (filter?: RolesPermissionsFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/roles-permissions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {RolesPermissionsFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rolesPermissionsControllerFindById: async (id: number, filter?: RolesPermissionsFilter1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('rolesPermissionsControllerFindById', 'id', id)
            const localVarPath = `/roles-permissions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RolesPermissionsFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rolesPermissionsControllerGetPermissionsMatrix: async (filter?: RolesPermissionsFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/roles-permissions/matrix`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {RolesPermissions} [rolesPermissions] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rolesPermissionsControllerReplaceById: async (id: number, rolesPermissions?: RolesPermissions, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('rolesPermissionsControllerReplaceById', 'id', id)
            const localVarPath = `/roles-permissions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rolesPermissions, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {RolesPermissionsPartial} [rolesPermissionsPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rolesPermissionsControllerUpdateAll: async (where?: { [key: string]: object; }, rolesPermissionsPartial?: RolesPermissionsPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/roles-permissions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rolesPermissionsPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {RolesPermissionsPartial} [rolesPermissionsPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rolesPermissionsControllerUpdateById: async (id: number, rolesPermissionsPartial?: RolesPermissionsPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('rolesPermissionsControllerUpdateById', 'id', id)
            const localVarPath = `/roles-permissions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rolesPermissionsPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RolesPermissionsControllerApi - functional programming interface
 * @export
 */
export const RolesPermissionsControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RolesPermissionsControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rolesPermissionsControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rolesPermissionsControllerCount(where, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {NewRolesPermissions} [newRolesPermissions] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rolesPermissionsControllerCreate(newRolesPermissions?: NewRolesPermissions, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RolesPermissions>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rolesPermissionsControllerCreate(newRolesPermissions, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rolesPermissionsControllerDeleteById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rolesPermissionsControllerDeleteById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RolesPermissionsFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rolesPermissionsControllerFind(filter?: RolesPermissionsFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RolesPermissionsWithRelations>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rolesPermissionsControllerFind(filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {RolesPermissionsFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rolesPermissionsControllerFindById(id: number, filter?: RolesPermissionsFilter1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RolesPermissionsWithRelations>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rolesPermissionsControllerFindById(id, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RolesPermissionsFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rolesPermissionsControllerGetPermissionsMatrix(filter?: RolesPermissionsFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RolesWithRelations>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rolesPermissionsControllerGetPermissionsMatrix(filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {RolesPermissions} [rolesPermissions] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rolesPermissionsControllerReplaceById(id: number, rolesPermissions?: RolesPermissions, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rolesPermissionsControllerReplaceById(id, rolesPermissions, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {RolesPermissionsPartial} [rolesPermissionsPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rolesPermissionsControllerUpdateAll(where?: { [key: string]: object; }, rolesPermissionsPartial?: RolesPermissionsPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rolesPermissionsControllerUpdateAll(where, rolesPermissionsPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {RolesPermissionsPartial} [rolesPermissionsPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rolesPermissionsControllerUpdateById(id: number, rolesPermissionsPartial?: RolesPermissionsPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rolesPermissionsControllerUpdateById(id, rolesPermissionsPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RolesPermissionsControllerApi - factory interface
 * @export
 */
export const RolesPermissionsControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RolesPermissionsControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rolesPermissionsControllerCount(where?: { [key: string]: object; }, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.rolesPermissionsControllerCount(where, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NewRolesPermissions} [newRolesPermissions] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rolesPermissionsControllerCreate(newRolesPermissions?: NewRolesPermissions, options?: any): AxiosPromise<RolesPermissions> {
            return localVarFp.rolesPermissionsControllerCreate(newRolesPermissions, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rolesPermissionsControllerDeleteById(id: number, options?: any): AxiosPromise<any> {
            return localVarFp.rolesPermissionsControllerDeleteById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RolesPermissionsFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rolesPermissionsControllerFind(filter?: RolesPermissionsFilter, options?: any): AxiosPromise<Array<RolesPermissionsWithRelations>> {
            return localVarFp.rolesPermissionsControllerFind(filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {RolesPermissionsFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rolesPermissionsControllerFindById(id: number, filter?: RolesPermissionsFilter1, options?: any): AxiosPromise<RolesPermissionsWithRelations> {
            return localVarFp.rolesPermissionsControllerFindById(id, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RolesPermissionsFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rolesPermissionsControllerGetPermissionsMatrix(filter?: RolesPermissionsFilter, options?: any): AxiosPromise<Array<RolesWithRelations>> {
            return localVarFp.rolesPermissionsControllerGetPermissionsMatrix(filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {RolesPermissions} [rolesPermissions] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rolesPermissionsControllerReplaceById(id: number, rolesPermissions?: RolesPermissions, options?: any): AxiosPromise<any> {
            return localVarFp.rolesPermissionsControllerReplaceById(id, rolesPermissions, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {RolesPermissionsPartial} [rolesPermissionsPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rolesPermissionsControllerUpdateAll(where?: { [key: string]: object; }, rolesPermissionsPartial?: RolesPermissionsPartial, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.rolesPermissionsControllerUpdateAll(where, rolesPermissionsPartial, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {RolesPermissionsPartial} [rolesPermissionsPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rolesPermissionsControllerUpdateById(id: number, rolesPermissionsPartial?: RolesPermissionsPartial, options?: any): AxiosPromise<any> {
            return localVarFp.rolesPermissionsControllerUpdateById(id, rolesPermissionsPartial, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RolesPermissionsControllerApi - object-oriented interface
 * @export
 * @class RolesPermissionsControllerApi
 * @extends {BaseAPI}
 */
export class RolesPermissionsControllerApi extends BaseAPI {
    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesPermissionsControllerApi
     */
    public rolesPermissionsControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig) {
        return RolesPermissionsControllerApiFp(this.configuration).rolesPermissionsControllerCount(where, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NewRolesPermissions} [newRolesPermissions] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesPermissionsControllerApi
     */
    public rolesPermissionsControllerCreate(newRolesPermissions?: NewRolesPermissions, options?: AxiosRequestConfig) {
        return RolesPermissionsControllerApiFp(this.configuration).rolesPermissionsControllerCreate(newRolesPermissions, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesPermissionsControllerApi
     */
    public rolesPermissionsControllerDeleteById(id: number, options?: AxiosRequestConfig) {
        return RolesPermissionsControllerApiFp(this.configuration).rolesPermissionsControllerDeleteById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RolesPermissionsFilter} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesPermissionsControllerApi
     */
    public rolesPermissionsControllerFind(filter?: RolesPermissionsFilter, options?: AxiosRequestConfig) {
        return RolesPermissionsControllerApiFp(this.configuration).rolesPermissionsControllerFind(filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {RolesPermissionsFilter1} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesPermissionsControllerApi
     */
    public rolesPermissionsControllerFindById(id: number, filter?: RolesPermissionsFilter1, options?: AxiosRequestConfig) {
        return RolesPermissionsControllerApiFp(this.configuration).rolesPermissionsControllerFindById(id, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RolesPermissionsFilter} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesPermissionsControllerApi
     */
    public rolesPermissionsControllerGetPermissionsMatrix(filter?: RolesPermissionsFilter, options?: AxiosRequestConfig) {
        return RolesPermissionsControllerApiFp(this.configuration).rolesPermissionsControllerGetPermissionsMatrix(filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {RolesPermissions} [rolesPermissions] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesPermissionsControllerApi
     */
    public rolesPermissionsControllerReplaceById(id: number, rolesPermissions?: RolesPermissions, options?: AxiosRequestConfig) {
        return RolesPermissionsControllerApiFp(this.configuration).rolesPermissionsControllerReplaceById(id, rolesPermissions, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {RolesPermissionsPartial} [rolesPermissionsPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesPermissionsControllerApi
     */
    public rolesPermissionsControllerUpdateAll(where?: { [key: string]: object; }, rolesPermissionsPartial?: RolesPermissionsPartial, options?: AxiosRequestConfig) {
        return RolesPermissionsControllerApiFp(this.configuration).rolesPermissionsControllerUpdateAll(where, rolesPermissionsPartial, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {RolesPermissionsPartial} [rolesPermissionsPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesPermissionsControllerApi
     */
    public rolesPermissionsControllerUpdateById(id: number, rolesPermissionsPartial?: RolesPermissionsPartial, options?: AxiosRequestConfig) {
        return RolesPermissionsControllerApiFp(this.configuration).rolesPermissionsControllerUpdateById(id, rolesPermissionsPartial, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RolesRolesPermissionsControllerApi - axios parameter creator
 * @export
 */
export const RolesRolesPermissionsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {NewRolesPermissionsInRoles} [newRolesPermissionsInRoles] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rolesRolesPermissionsControllerCreate: async (id: number, newRolesPermissionsInRoles?: NewRolesPermissionsInRoles, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('rolesRolesPermissionsControllerCreate', 'id', id)
            const localVarPath = `/roles/{id}/roles-permissions`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newRolesPermissionsInRoles, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rolesRolesPermissionsControllerDelete: async (id: number, where?: { [key: string]: object; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('rolesRolesPermissionsControllerDelete', 'id', id)
            const localVarPath = `/roles/{id}/roles-permissions`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {{ [key: string]: object; }} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rolesRolesPermissionsControllerFind: async (id: number, filter?: { [key: string]: object; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('rolesRolesPermissionsControllerFind', 'id', id)
            const localVarPath = `/roles/{id}/roles-permissions`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {{ [key: string]: object; }} [where] 
         * @param {RolesPermissionsPartial} [rolesPermissionsPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rolesRolesPermissionsControllerPatch: async (id: number, where?: { [key: string]: object; }, rolesPermissionsPartial?: RolesPermissionsPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('rolesRolesPermissionsControllerPatch', 'id', id)
            const localVarPath = `/roles/{id}/roles-permissions`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rolesPermissionsPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RolesRolesPermissionsControllerApi - functional programming interface
 * @export
 */
export const RolesRolesPermissionsControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RolesRolesPermissionsControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {NewRolesPermissionsInRoles} [newRolesPermissionsInRoles] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rolesRolesPermissionsControllerCreate(id: number, newRolesPermissionsInRoles?: NewRolesPermissionsInRoles, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RolesPermissions>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rolesRolesPermissionsControllerCreate(id, newRolesPermissionsInRoles, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rolesRolesPermissionsControllerDelete(id: number, where?: { [key: string]: object; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rolesRolesPermissionsControllerDelete(id, where, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {{ [key: string]: object; }} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rolesRolesPermissionsControllerFind(id: number, filter?: { [key: string]: object; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RolesPermissions>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rolesRolesPermissionsControllerFind(id, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {{ [key: string]: object; }} [where] 
         * @param {RolesPermissionsPartial} [rolesPermissionsPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rolesRolesPermissionsControllerPatch(id: number, where?: { [key: string]: object; }, rolesPermissionsPartial?: RolesPermissionsPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rolesRolesPermissionsControllerPatch(id, where, rolesPermissionsPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RolesRolesPermissionsControllerApi - factory interface
 * @export
 */
export const RolesRolesPermissionsControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RolesRolesPermissionsControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {NewRolesPermissionsInRoles} [newRolesPermissionsInRoles] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rolesRolesPermissionsControllerCreate(id: number, newRolesPermissionsInRoles?: NewRolesPermissionsInRoles, options?: any): AxiosPromise<RolesPermissions> {
            return localVarFp.rolesRolesPermissionsControllerCreate(id, newRolesPermissionsInRoles, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rolesRolesPermissionsControllerDelete(id: number, where?: { [key: string]: object; }, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.rolesRolesPermissionsControllerDelete(id, where, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {{ [key: string]: object; }} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rolesRolesPermissionsControllerFind(id: number, filter?: { [key: string]: object; }, options?: any): AxiosPromise<Array<RolesPermissions>> {
            return localVarFp.rolesRolesPermissionsControllerFind(id, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {{ [key: string]: object; }} [where] 
         * @param {RolesPermissionsPartial} [rolesPermissionsPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rolesRolesPermissionsControllerPatch(id: number, where?: { [key: string]: object; }, rolesPermissionsPartial?: RolesPermissionsPartial, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.rolesRolesPermissionsControllerPatch(id, where, rolesPermissionsPartial, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RolesRolesPermissionsControllerApi - object-oriented interface
 * @export
 * @class RolesRolesPermissionsControllerApi
 * @extends {BaseAPI}
 */
export class RolesRolesPermissionsControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {NewRolesPermissionsInRoles} [newRolesPermissionsInRoles] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesRolesPermissionsControllerApi
     */
    public rolesRolesPermissionsControllerCreate(id: number, newRolesPermissionsInRoles?: NewRolesPermissionsInRoles, options?: AxiosRequestConfig) {
        return RolesRolesPermissionsControllerApiFp(this.configuration).rolesRolesPermissionsControllerCreate(id, newRolesPermissionsInRoles, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {{ [key: string]: object; }} [where] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesRolesPermissionsControllerApi
     */
    public rolesRolesPermissionsControllerDelete(id: number, where?: { [key: string]: object; }, options?: AxiosRequestConfig) {
        return RolesRolesPermissionsControllerApiFp(this.configuration).rolesRolesPermissionsControllerDelete(id, where, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {{ [key: string]: object; }} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesRolesPermissionsControllerApi
     */
    public rolesRolesPermissionsControllerFind(id: number, filter?: { [key: string]: object; }, options?: AxiosRequestConfig) {
        return RolesRolesPermissionsControllerApiFp(this.configuration).rolesRolesPermissionsControllerFind(id, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {{ [key: string]: object; }} [where] 
     * @param {RolesPermissionsPartial} [rolesPermissionsPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesRolesPermissionsControllerApi
     */
    public rolesRolesPermissionsControllerPatch(id: number, where?: { [key: string]: object; }, rolesPermissionsPartial?: RolesPermissionsPartial, options?: AxiosRequestConfig) {
        return RolesRolesPermissionsControllerApiFp(this.configuration).rolesRolesPermissionsControllerPatch(id, where, rolesPermissionsPartial, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SoapLogsControllerApi - axios parameter creator
 * @export
 */
export const SoapLogsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        soapLogsControllerCount: async (where?: { [key: string]: object; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/soap-logs/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {NewSoapLogs} [newSoapLogs] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        soapLogsControllerCreate: async (newSoapLogs?: NewSoapLogs, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/soap-logs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newSoapLogs, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        soapLogsControllerDeleteById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('soapLogsControllerDeleteById', 'id', id)
            const localVarPath = `/soap-logs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SoapLogsFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        soapLogsControllerFind: async (filter?: SoapLogsFilter1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/soap-logs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {SoapLogsFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        soapLogsControllerFindById: async (id: number, filter?: SoapLogsFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('soapLogsControllerFindById', 'id', id)
            const localVarPath = `/soap-logs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {SoapLogs} [soapLogs] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        soapLogsControllerReplaceById: async (id: number, soapLogs?: SoapLogs, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('soapLogsControllerReplaceById', 'id', id)
            const localVarPath = `/soap-logs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(soapLogs, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {SoapLogsPartial} [soapLogsPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        soapLogsControllerUpdateAll: async (where?: { [key: string]: object; }, soapLogsPartial?: SoapLogsPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/soap-logs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(soapLogsPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {SoapLogsPartial} [soapLogsPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        soapLogsControllerUpdateById: async (id: number, soapLogsPartial?: SoapLogsPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('soapLogsControllerUpdateById', 'id', id)
            const localVarPath = `/soap-logs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(soapLogsPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SoapLogsControllerApi - functional programming interface
 * @export
 */
export const SoapLogsControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SoapLogsControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async soapLogsControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.soapLogsControllerCount(where, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {NewSoapLogs} [newSoapLogs] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async soapLogsControllerCreate(newSoapLogs?: NewSoapLogs, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SoapLogs>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.soapLogsControllerCreate(newSoapLogs, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async soapLogsControllerDeleteById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.soapLogsControllerDeleteById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SoapLogsFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async soapLogsControllerFind(filter?: SoapLogsFilter1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SoapLogsWithRelations>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.soapLogsControllerFind(filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {SoapLogsFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async soapLogsControllerFindById(id: number, filter?: SoapLogsFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SoapLogsWithRelations>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.soapLogsControllerFindById(id, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {SoapLogs} [soapLogs] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async soapLogsControllerReplaceById(id: number, soapLogs?: SoapLogs, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.soapLogsControllerReplaceById(id, soapLogs, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {SoapLogsPartial} [soapLogsPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async soapLogsControllerUpdateAll(where?: { [key: string]: object; }, soapLogsPartial?: SoapLogsPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.soapLogsControllerUpdateAll(where, soapLogsPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {SoapLogsPartial} [soapLogsPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async soapLogsControllerUpdateById(id: number, soapLogsPartial?: SoapLogsPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.soapLogsControllerUpdateById(id, soapLogsPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SoapLogsControllerApi - factory interface
 * @export
 */
export const SoapLogsControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SoapLogsControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        soapLogsControllerCount(where?: { [key: string]: object; }, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.soapLogsControllerCount(where, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NewSoapLogs} [newSoapLogs] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        soapLogsControllerCreate(newSoapLogs?: NewSoapLogs, options?: any): AxiosPromise<SoapLogs> {
            return localVarFp.soapLogsControllerCreate(newSoapLogs, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        soapLogsControllerDeleteById(id: number, options?: any): AxiosPromise<any> {
            return localVarFp.soapLogsControllerDeleteById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SoapLogsFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        soapLogsControllerFind(filter?: SoapLogsFilter1, options?: any): AxiosPromise<Array<SoapLogsWithRelations>> {
            return localVarFp.soapLogsControllerFind(filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {SoapLogsFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        soapLogsControllerFindById(id: number, filter?: SoapLogsFilter, options?: any): AxiosPromise<SoapLogsWithRelations> {
            return localVarFp.soapLogsControllerFindById(id, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {SoapLogs} [soapLogs] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        soapLogsControllerReplaceById(id: number, soapLogs?: SoapLogs, options?: any): AxiosPromise<any> {
            return localVarFp.soapLogsControllerReplaceById(id, soapLogs, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {SoapLogsPartial} [soapLogsPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        soapLogsControllerUpdateAll(where?: { [key: string]: object; }, soapLogsPartial?: SoapLogsPartial, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.soapLogsControllerUpdateAll(where, soapLogsPartial, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {SoapLogsPartial} [soapLogsPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        soapLogsControllerUpdateById(id: number, soapLogsPartial?: SoapLogsPartial, options?: any): AxiosPromise<any> {
            return localVarFp.soapLogsControllerUpdateById(id, soapLogsPartial, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SoapLogsControllerApi - object-oriented interface
 * @export
 * @class SoapLogsControllerApi
 * @extends {BaseAPI}
 */
export class SoapLogsControllerApi extends BaseAPI {
    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SoapLogsControllerApi
     */
    public soapLogsControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig) {
        return SoapLogsControllerApiFp(this.configuration).soapLogsControllerCount(where, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NewSoapLogs} [newSoapLogs] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SoapLogsControllerApi
     */
    public soapLogsControllerCreate(newSoapLogs?: NewSoapLogs, options?: AxiosRequestConfig) {
        return SoapLogsControllerApiFp(this.configuration).soapLogsControllerCreate(newSoapLogs, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SoapLogsControllerApi
     */
    public soapLogsControllerDeleteById(id: number, options?: AxiosRequestConfig) {
        return SoapLogsControllerApiFp(this.configuration).soapLogsControllerDeleteById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SoapLogsFilter1} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SoapLogsControllerApi
     */
    public soapLogsControllerFind(filter?: SoapLogsFilter1, options?: AxiosRequestConfig) {
        return SoapLogsControllerApiFp(this.configuration).soapLogsControllerFind(filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {SoapLogsFilter} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SoapLogsControllerApi
     */
    public soapLogsControllerFindById(id: number, filter?: SoapLogsFilter, options?: AxiosRequestConfig) {
        return SoapLogsControllerApiFp(this.configuration).soapLogsControllerFindById(id, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {SoapLogs} [soapLogs] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SoapLogsControllerApi
     */
    public soapLogsControllerReplaceById(id: number, soapLogs?: SoapLogs, options?: AxiosRequestConfig) {
        return SoapLogsControllerApiFp(this.configuration).soapLogsControllerReplaceById(id, soapLogs, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {SoapLogsPartial} [soapLogsPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SoapLogsControllerApi
     */
    public soapLogsControllerUpdateAll(where?: { [key: string]: object; }, soapLogsPartial?: SoapLogsPartial, options?: AxiosRequestConfig) {
        return SoapLogsControllerApiFp(this.configuration).soapLogsControllerUpdateAll(where, soapLogsPartial, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {SoapLogsPartial} [soapLogsPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SoapLogsControllerApi
     */
    public soapLogsControllerUpdateById(id: number, soapLogsPartial?: SoapLogsPartial, options?: AxiosRequestConfig) {
        return SoapLogsControllerApiFp(this.configuration).soapLogsControllerUpdateById(id, soapLogsPartial, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SuccessLoginsControllerApi - axios parameter creator
 * @export
 */
export const SuccessLoginsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        successLoginsControllerCount: async (where?: { [key: string]: object; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/success-logins/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {NewSuccessLogins} [newSuccessLogins] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        successLoginsControllerCreate: async (newSuccessLogins?: NewSuccessLogins, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/success-logins`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newSuccessLogins, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        successLoginsControllerDeleteById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('successLoginsControllerDeleteById', 'id', id)
            const localVarPath = `/success-logins/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SuccessLoginsFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        successLoginsControllerFind: async (filter?: SuccessLoginsFilter1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/success-logins`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {SuccessLoginsFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        successLoginsControllerFindById: async (id: number, filter?: SuccessLoginsFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('successLoginsControllerFindById', 'id', id)
            const localVarPath = `/success-logins/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {SuccessLogins} [successLogins] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        successLoginsControllerReplaceById: async (id: number, successLogins?: SuccessLogins, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('successLoginsControllerReplaceById', 'id', id)
            const localVarPath = `/success-logins/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(successLogins, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {SuccessLoginsPartial} [successLoginsPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        successLoginsControllerUpdateAll: async (where?: { [key: string]: object; }, successLoginsPartial?: SuccessLoginsPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/success-logins`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(successLoginsPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {SuccessLoginsPartial} [successLoginsPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        successLoginsControllerUpdateById: async (id: number, successLoginsPartial?: SuccessLoginsPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('successLoginsControllerUpdateById', 'id', id)
            const localVarPath = `/success-logins/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(successLoginsPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SuccessLoginsControllerApi - functional programming interface
 * @export
 */
export const SuccessLoginsControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SuccessLoginsControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async successLoginsControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.successLoginsControllerCount(where, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {NewSuccessLogins} [newSuccessLogins] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async successLoginsControllerCreate(newSuccessLogins?: NewSuccessLogins, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessLogins>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.successLoginsControllerCreate(newSuccessLogins, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async successLoginsControllerDeleteById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.successLoginsControllerDeleteById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SuccessLoginsFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async successLoginsControllerFind(filter?: SuccessLoginsFilter1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SuccessLoginsWithRelations>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.successLoginsControllerFind(filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {SuccessLoginsFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async successLoginsControllerFindById(id: number, filter?: SuccessLoginsFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessLoginsWithRelations>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.successLoginsControllerFindById(id, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {SuccessLogins} [successLogins] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async successLoginsControllerReplaceById(id: number, successLogins?: SuccessLogins, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.successLoginsControllerReplaceById(id, successLogins, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {SuccessLoginsPartial} [successLoginsPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async successLoginsControllerUpdateAll(where?: { [key: string]: object; }, successLoginsPartial?: SuccessLoginsPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.successLoginsControllerUpdateAll(where, successLoginsPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {SuccessLoginsPartial} [successLoginsPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async successLoginsControllerUpdateById(id: number, successLoginsPartial?: SuccessLoginsPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.successLoginsControllerUpdateById(id, successLoginsPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SuccessLoginsControllerApi - factory interface
 * @export
 */
export const SuccessLoginsControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SuccessLoginsControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        successLoginsControllerCount(where?: { [key: string]: object; }, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.successLoginsControllerCount(where, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NewSuccessLogins} [newSuccessLogins] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        successLoginsControllerCreate(newSuccessLogins?: NewSuccessLogins, options?: any): AxiosPromise<SuccessLogins> {
            return localVarFp.successLoginsControllerCreate(newSuccessLogins, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        successLoginsControllerDeleteById(id: number, options?: any): AxiosPromise<any> {
            return localVarFp.successLoginsControllerDeleteById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SuccessLoginsFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        successLoginsControllerFind(filter?: SuccessLoginsFilter1, options?: any): AxiosPromise<Array<SuccessLoginsWithRelations>> {
            return localVarFp.successLoginsControllerFind(filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {SuccessLoginsFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        successLoginsControllerFindById(id: number, filter?: SuccessLoginsFilter, options?: any): AxiosPromise<SuccessLoginsWithRelations> {
            return localVarFp.successLoginsControllerFindById(id, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {SuccessLogins} [successLogins] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        successLoginsControllerReplaceById(id: number, successLogins?: SuccessLogins, options?: any): AxiosPromise<any> {
            return localVarFp.successLoginsControllerReplaceById(id, successLogins, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {SuccessLoginsPartial} [successLoginsPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        successLoginsControllerUpdateAll(where?: { [key: string]: object; }, successLoginsPartial?: SuccessLoginsPartial, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.successLoginsControllerUpdateAll(where, successLoginsPartial, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {SuccessLoginsPartial} [successLoginsPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        successLoginsControllerUpdateById(id: number, successLoginsPartial?: SuccessLoginsPartial, options?: any): AxiosPromise<any> {
            return localVarFp.successLoginsControllerUpdateById(id, successLoginsPartial, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SuccessLoginsControllerApi - object-oriented interface
 * @export
 * @class SuccessLoginsControllerApi
 * @extends {BaseAPI}
 */
export class SuccessLoginsControllerApi extends BaseAPI {
    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SuccessLoginsControllerApi
     */
    public successLoginsControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig) {
        return SuccessLoginsControllerApiFp(this.configuration).successLoginsControllerCount(where, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NewSuccessLogins} [newSuccessLogins] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SuccessLoginsControllerApi
     */
    public successLoginsControllerCreate(newSuccessLogins?: NewSuccessLogins, options?: AxiosRequestConfig) {
        return SuccessLoginsControllerApiFp(this.configuration).successLoginsControllerCreate(newSuccessLogins, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SuccessLoginsControllerApi
     */
    public successLoginsControllerDeleteById(id: number, options?: AxiosRequestConfig) {
        return SuccessLoginsControllerApiFp(this.configuration).successLoginsControllerDeleteById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SuccessLoginsFilter1} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SuccessLoginsControllerApi
     */
    public successLoginsControllerFind(filter?: SuccessLoginsFilter1, options?: AxiosRequestConfig) {
        return SuccessLoginsControllerApiFp(this.configuration).successLoginsControllerFind(filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {SuccessLoginsFilter} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SuccessLoginsControllerApi
     */
    public successLoginsControllerFindById(id: number, filter?: SuccessLoginsFilter, options?: AxiosRequestConfig) {
        return SuccessLoginsControllerApiFp(this.configuration).successLoginsControllerFindById(id, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {SuccessLogins} [successLogins] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SuccessLoginsControllerApi
     */
    public successLoginsControllerReplaceById(id: number, successLogins?: SuccessLogins, options?: AxiosRequestConfig) {
        return SuccessLoginsControllerApiFp(this.configuration).successLoginsControllerReplaceById(id, successLogins, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {SuccessLoginsPartial} [successLoginsPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SuccessLoginsControllerApi
     */
    public successLoginsControllerUpdateAll(where?: { [key: string]: object; }, successLoginsPartial?: SuccessLoginsPartial, options?: AxiosRequestConfig) {
        return SuccessLoginsControllerApiFp(this.configuration).successLoginsControllerUpdateAll(where, successLoginsPartial, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {SuccessLoginsPartial} [successLoginsPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SuccessLoginsControllerApi
     */
    public successLoginsControllerUpdateById(id: number, successLoginsPartial?: SuccessLoginsPartial, options?: AxiosRequestConfig) {
        return SuccessLoginsControllerApiFp(this.configuration).successLoginsControllerUpdateById(id, successLoginsPartial, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TranslationsControllerApi - axios parameter creator
 * @export
 */
export const TranslationsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        translationsControllerCount: async (where?: { [key: string]: object; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/translations/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {NewTranslations} [newTranslations] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        translationsControllerCreate: async (newTranslations?: NewTranslations, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/translations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newTranslations, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        translationsControllerDeleteById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('translationsControllerDeleteById', 'id', id)
            const localVarPath = `/translations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TranslationsFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        translationsControllerFind: async (filter?: TranslationsFilter1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/translations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {TranslationsFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        translationsControllerFindById: async (id: number, filter?: TranslationsFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('translationsControllerFindById', 'id', id)
            const localVarPath = `/translations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {Translations} [translations] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        translationsControllerReplaceById: async (id: number, translations?: Translations, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('translationsControllerReplaceById', 'id', id)
            const localVarPath = `/translations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(translations, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {TranslationsPartial} [translationsPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        translationsControllerUpdateAll: async (where?: { [key: string]: object; }, translationsPartial?: TranslationsPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/translations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(translationsPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {TranslationsPartial} [translationsPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        translationsControllerUpdateById: async (id: number, translationsPartial?: TranslationsPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('translationsControllerUpdateById', 'id', id)
            const localVarPath = `/translations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(translationsPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TranslationsControllerApi - functional programming interface
 * @export
 */
export const TranslationsControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TranslationsControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async translationsControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.translationsControllerCount(where, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {NewTranslations} [newTranslations] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async translationsControllerCreate(newTranslations?: NewTranslations, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Translations>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.translationsControllerCreate(newTranslations, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async translationsControllerDeleteById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.translationsControllerDeleteById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TranslationsFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async translationsControllerFind(filter?: TranslationsFilter1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TranslationsWithRelations>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.translationsControllerFind(filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {TranslationsFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async translationsControllerFindById(id: number, filter?: TranslationsFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TranslationsWithRelations>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.translationsControllerFindById(id, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {Translations} [translations] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async translationsControllerReplaceById(id: number, translations?: Translations, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.translationsControllerReplaceById(id, translations, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {TranslationsPartial} [translationsPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async translationsControllerUpdateAll(where?: { [key: string]: object; }, translationsPartial?: TranslationsPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.translationsControllerUpdateAll(where, translationsPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {TranslationsPartial} [translationsPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async translationsControllerUpdateById(id: number, translationsPartial?: TranslationsPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.translationsControllerUpdateById(id, translationsPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TranslationsControllerApi - factory interface
 * @export
 */
export const TranslationsControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TranslationsControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        translationsControllerCount(where?: { [key: string]: object; }, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.translationsControllerCount(where, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NewTranslations} [newTranslations] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        translationsControllerCreate(newTranslations?: NewTranslations, options?: any): AxiosPromise<Translations> {
            return localVarFp.translationsControllerCreate(newTranslations, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        translationsControllerDeleteById(id: number, options?: any): AxiosPromise<any> {
            return localVarFp.translationsControllerDeleteById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TranslationsFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        translationsControllerFind(filter?: TranslationsFilter1, options?: any): AxiosPromise<Array<TranslationsWithRelations>> {
            return localVarFp.translationsControllerFind(filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {TranslationsFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        translationsControllerFindById(id: number, filter?: TranslationsFilter, options?: any): AxiosPromise<TranslationsWithRelations> {
            return localVarFp.translationsControllerFindById(id, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {Translations} [translations] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        translationsControllerReplaceById(id: number, translations?: Translations, options?: any): AxiosPromise<any> {
            return localVarFp.translationsControllerReplaceById(id, translations, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {TranslationsPartial} [translationsPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        translationsControllerUpdateAll(where?: { [key: string]: object; }, translationsPartial?: TranslationsPartial, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.translationsControllerUpdateAll(where, translationsPartial, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {TranslationsPartial} [translationsPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        translationsControllerUpdateById(id: number, translationsPartial?: TranslationsPartial, options?: any): AxiosPromise<any> {
            return localVarFp.translationsControllerUpdateById(id, translationsPartial, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TranslationsControllerApi - object-oriented interface
 * @export
 * @class TranslationsControllerApi
 * @extends {BaseAPI}
 */
export class TranslationsControllerApi extends BaseAPI {
    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TranslationsControllerApi
     */
    public translationsControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig) {
        return TranslationsControllerApiFp(this.configuration).translationsControllerCount(where, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NewTranslations} [newTranslations] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TranslationsControllerApi
     */
    public translationsControllerCreate(newTranslations?: NewTranslations, options?: AxiosRequestConfig) {
        return TranslationsControllerApiFp(this.configuration).translationsControllerCreate(newTranslations, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TranslationsControllerApi
     */
    public translationsControllerDeleteById(id: number, options?: AxiosRequestConfig) {
        return TranslationsControllerApiFp(this.configuration).translationsControllerDeleteById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TranslationsFilter1} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TranslationsControllerApi
     */
    public translationsControllerFind(filter?: TranslationsFilter1, options?: AxiosRequestConfig) {
        return TranslationsControllerApiFp(this.configuration).translationsControllerFind(filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {TranslationsFilter} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TranslationsControllerApi
     */
    public translationsControllerFindById(id: number, filter?: TranslationsFilter, options?: AxiosRequestConfig) {
        return TranslationsControllerApiFp(this.configuration).translationsControllerFindById(id, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {Translations} [translations] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TranslationsControllerApi
     */
    public translationsControllerReplaceById(id: number, translations?: Translations, options?: AxiosRequestConfig) {
        return TranslationsControllerApiFp(this.configuration).translationsControllerReplaceById(id, translations, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {TranslationsPartial} [translationsPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TranslationsControllerApi
     */
    public translationsControllerUpdateAll(where?: { [key: string]: object; }, translationsPartial?: TranslationsPartial, options?: AxiosRequestConfig) {
        return TranslationsControllerApiFp(this.configuration).translationsControllerUpdateAll(where, translationsPartial, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {TranslationsPartial} [translationsPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TranslationsControllerApi
     */
    public translationsControllerUpdateById(id: number, translationsPartial?: TranslationsPartial, options?: AxiosRequestConfig) {
        return TranslationsControllerApiFp(this.configuration).translationsControllerUpdateById(id, translationsPartial, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UsersControllerApi - axios parameter creator
 * @export
 */
export const UsersControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerCount: async (where?: { [key: string]: object; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {NewUsers} [newUsers] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerCreate: async (newUsers?: NewUsers, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newUsers, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerDeleteById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('usersControllerDeleteById', 'id', id)
            const localVarPath = `/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UsersFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerFind: async (filter?: UsersFilter1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {UsersFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerFindById: async (id: number, filter?: UsersFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('usersControllerFindById', 'id', id)
            const localVarPath = `/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {Users} [users] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerReplaceById: async (id: number, users?: Users, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('usersControllerReplaceById', 'id', id)
            const localVarPath = `/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(users, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {UsersPartial} [usersPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerUpdateAll: async (where?: { [key: string]: object; }, usersPartial?: UsersPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(usersPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {UsersPartial} [usersPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerUpdateById: async (id: number, usersPartial?: UsersPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('usersControllerUpdateById', 'id', id)
            const localVarPath = `/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(usersPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersControllerApi - functional programming interface
 * @export
 */
export const UsersControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerCount(where, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {NewUsers} [newUsers] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerCreate(newUsers?: NewUsers, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Users>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerCreate(newUsers, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerDeleteById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerDeleteById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UsersFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerFind(filter?: UsersFilter1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UsersWithRelations>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerFind(filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {UsersFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerFindById(id: number, filter?: UsersFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UsersWithRelations>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerFindById(id, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {Users} [users] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerReplaceById(id: number, users?: Users, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerReplaceById(id, users, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {UsersPartial} [usersPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerUpdateAll(where?: { [key: string]: object; }, usersPartial?: UsersPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerUpdateAll(where, usersPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {UsersPartial} [usersPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerUpdateById(id: number, usersPartial?: UsersPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerUpdateById(id, usersPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UsersControllerApi - factory interface
 * @export
 */
export const UsersControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerCount(where?: { [key: string]: object; }, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.usersControllerCount(where, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NewUsers} [newUsers] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerCreate(newUsers?: NewUsers, options?: any): AxiosPromise<Users> {
            return localVarFp.usersControllerCreate(newUsers, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerDeleteById(id: number, options?: any): AxiosPromise<any> {
            return localVarFp.usersControllerDeleteById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UsersFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerFind(filter?: UsersFilter1, options?: any): AxiosPromise<Array<UsersWithRelations>> {
            return localVarFp.usersControllerFind(filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {UsersFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerFindById(id: number, filter?: UsersFilter, options?: any): AxiosPromise<UsersWithRelations> {
            return localVarFp.usersControllerFindById(id, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {Users} [users] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerReplaceById(id: number, users?: Users, options?: any): AxiosPromise<any> {
            return localVarFp.usersControllerReplaceById(id, users, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {UsersPartial} [usersPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerUpdateAll(where?: { [key: string]: object; }, usersPartial?: UsersPartial, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.usersControllerUpdateAll(where, usersPartial, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {UsersPartial} [usersPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerUpdateById(id: number, usersPartial?: UsersPartial, options?: any): AxiosPromise<any> {
            return localVarFp.usersControllerUpdateById(id, usersPartial, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersControllerApi - object-oriented interface
 * @export
 * @class UsersControllerApi
 * @extends {BaseAPI}
 */
export class UsersControllerApi extends BaseAPI {
    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersControllerApi
     */
    public usersControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig) {
        return UsersControllerApiFp(this.configuration).usersControllerCount(where, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NewUsers} [newUsers] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersControllerApi
     */
    public usersControllerCreate(newUsers?: NewUsers, options?: AxiosRequestConfig) {
        return UsersControllerApiFp(this.configuration).usersControllerCreate(newUsers, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersControllerApi
     */
    public usersControllerDeleteById(id: number, options?: AxiosRequestConfig) {
        return UsersControllerApiFp(this.configuration).usersControllerDeleteById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UsersFilter1} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersControllerApi
     */
    public usersControllerFind(filter?: UsersFilter1, options?: AxiosRequestConfig) {
        return UsersControllerApiFp(this.configuration).usersControllerFind(filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {UsersFilter} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersControllerApi
     */
    public usersControllerFindById(id: number, filter?: UsersFilter, options?: AxiosRequestConfig) {
        return UsersControllerApiFp(this.configuration).usersControllerFindById(id, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {Users} [users] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersControllerApi
     */
    public usersControllerReplaceById(id: number, users?: Users, options?: AxiosRequestConfig) {
        return UsersControllerApiFp(this.configuration).usersControllerReplaceById(id, users, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {UsersPartial} [usersPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersControllerApi
     */
    public usersControllerUpdateAll(where?: { [key: string]: object; }, usersPartial?: UsersPartial, options?: AxiosRequestConfig) {
        return UsersControllerApiFp(this.configuration).usersControllerUpdateAll(where, usersPartial, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {UsersPartial} [usersPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersControllerApi
     */
    public usersControllerUpdateById(id: number, usersPartial?: UsersPartial, options?: AxiosRequestConfig) {
        return UsersControllerApiFp(this.configuration).usersControllerUpdateById(id, usersPartial, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * VendedoresControllerApi - axios parameter creator
 * @export
 */
export const VendedoresControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vendedoresControllerCount: async (where?: { [key: string]: object; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/vendedores/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {NewVendedores} [newVendedores] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vendedoresControllerCreate: async (newVendedores?: NewVendedores, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/vendedores`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newVendedores, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vendedoresControllerDeleteById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('vendedoresControllerDeleteById', 'id', id)
            const localVarPath = `/vendedores/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {VendedoresFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vendedoresControllerFind: async (filter?: VendedoresFilter1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/vendedores`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {VendedoresFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vendedoresControllerFindById: async (id: number, filter?: VendedoresFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('vendedoresControllerFindById', 'id', id)
            const localVarPath = `/vendedores/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {Vendedores} [vendedores] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vendedoresControllerReplaceById: async (id: number, vendedores?: Vendedores, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('vendedoresControllerReplaceById', 'id', id)
            const localVarPath = `/vendedores/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vendedores, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {VendedoresPartial} [vendedoresPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vendedoresControllerUpdateAll: async (where?: { [key: string]: object; }, vendedoresPartial?: VendedoresPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/vendedores`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vendedoresPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {VendedoresPartial} [vendedoresPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vendedoresControllerUpdateById: async (id: number, vendedoresPartial?: VendedoresPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('vendedoresControllerUpdateById', 'id', id)
            const localVarPath = `/vendedores/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vendedoresPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VendedoresControllerApi - functional programming interface
 * @export
 */
export const VendedoresControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VendedoresControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async vendedoresControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.vendedoresControllerCount(where, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {NewVendedores} [newVendedores] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async vendedoresControllerCreate(newVendedores?: NewVendedores, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Vendedores>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.vendedoresControllerCreate(newVendedores, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async vendedoresControllerDeleteById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.vendedoresControllerDeleteById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {VendedoresFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async vendedoresControllerFind(filter?: VendedoresFilter1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VendedoresWithRelations>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.vendedoresControllerFind(filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {VendedoresFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async vendedoresControllerFindById(id: number, filter?: VendedoresFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VendedoresWithRelations>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.vendedoresControllerFindById(id, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {Vendedores} [vendedores] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async vendedoresControllerReplaceById(id: number, vendedores?: Vendedores, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.vendedoresControllerReplaceById(id, vendedores, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {VendedoresPartial} [vendedoresPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async vendedoresControllerUpdateAll(where?: { [key: string]: object; }, vendedoresPartial?: VendedoresPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.vendedoresControllerUpdateAll(where, vendedoresPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {VendedoresPartial} [vendedoresPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async vendedoresControllerUpdateById(id: number, vendedoresPartial?: VendedoresPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.vendedoresControllerUpdateById(id, vendedoresPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * VendedoresControllerApi - factory interface
 * @export
 */
export const VendedoresControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VendedoresControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vendedoresControllerCount(where?: { [key: string]: object; }, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.vendedoresControllerCount(where, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NewVendedores} [newVendedores] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vendedoresControllerCreate(newVendedores?: NewVendedores, options?: any): AxiosPromise<Vendedores> {
            return localVarFp.vendedoresControllerCreate(newVendedores, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vendedoresControllerDeleteById(id: number, options?: any): AxiosPromise<any> {
            return localVarFp.vendedoresControllerDeleteById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VendedoresFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vendedoresControllerFind(filter?: VendedoresFilter1, options?: any): AxiosPromise<Array<VendedoresWithRelations>> {
            return localVarFp.vendedoresControllerFind(filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {VendedoresFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vendedoresControllerFindById(id: number, filter?: VendedoresFilter, options?: any): AxiosPromise<VendedoresWithRelations> {
            return localVarFp.vendedoresControllerFindById(id, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {Vendedores} [vendedores] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vendedoresControllerReplaceById(id: number, vendedores?: Vendedores, options?: any): AxiosPromise<any> {
            return localVarFp.vendedoresControllerReplaceById(id, vendedores, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {VendedoresPartial} [vendedoresPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vendedoresControllerUpdateAll(where?: { [key: string]: object; }, vendedoresPartial?: VendedoresPartial, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.vendedoresControllerUpdateAll(where, vendedoresPartial, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {VendedoresPartial} [vendedoresPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vendedoresControllerUpdateById(id: number, vendedoresPartial?: VendedoresPartial, options?: any): AxiosPromise<any> {
            return localVarFp.vendedoresControllerUpdateById(id, vendedoresPartial, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VendedoresControllerApi - object-oriented interface
 * @export
 * @class VendedoresControllerApi
 * @extends {BaseAPI}
 */
export class VendedoresControllerApi extends BaseAPI {
    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VendedoresControllerApi
     */
    public vendedoresControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig) {
        return VendedoresControllerApiFp(this.configuration).vendedoresControllerCount(where, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NewVendedores} [newVendedores] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VendedoresControllerApi
     */
    public vendedoresControllerCreate(newVendedores?: NewVendedores, options?: AxiosRequestConfig) {
        return VendedoresControllerApiFp(this.configuration).vendedoresControllerCreate(newVendedores, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VendedoresControllerApi
     */
    public vendedoresControllerDeleteById(id: number, options?: AxiosRequestConfig) {
        return VendedoresControllerApiFp(this.configuration).vendedoresControllerDeleteById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VendedoresFilter1} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VendedoresControllerApi
     */
    public vendedoresControllerFind(filter?: VendedoresFilter1, options?: AxiosRequestConfig) {
        return VendedoresControllerApiFp(this.configuration).vendedoresControllerFind(filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {VendedoresFilter} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VendedoresControllerApi
     */
    public vendedoresControllerFindById(id: number, filter?: VendedoresFilter, options?: AxiosRequestConfig) {
        return VendedoresControllerApiFp(this.configuration).vendedoresControllerFindById(id, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {Vendedores} [vendedores] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VendedoresControllerApi
     */
    public vendedoresControllerReplaceById(id: number, vendedores?: Vendedores, options?: AxiosRequestConfig) {
        return VendedoresControllerApiFp(this.configuration).vendedoresControllerReplaceById(id, vendedores, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {VendedoresPartial} [vendedoresPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VendedoresControllerApi
     */
    public vendedoresControllerUpdateAll(where?: { [key: string]: object; }, vendedoresPartial?: VendedoresPartial, options?: AxiosRequestConfig) {
        return VendedoresControllerApiFp(this.configuration).vendedoresControllerUpdateAll(where, vendedoresPartial, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {VendedoresPartial} [vendedoresPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VendedoresControllerApi
     */
    public vendedoresControllerUpdateById(id: number, vendedoresPartial?: VendedoresPartial, options?: AxiosRequestConfig) {
        return VendedoresControllerApiFp(this.configuration).vendedoresControllerUpdateById(id, vendedoresPartial, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * VersionControllerApi - axios parameter creator
 * @export
 */
export const VersionControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        versionControllerCount: async (where?: { [key: string]: object; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/versions/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {NewVersion} [newVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        versionControllerCreate: async (newVersion?: NewVersion, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/versions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newVersion, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        versionControllerDeleteById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('versionControllerDeleteById', 'id', id)
            const localVarPath = `/versions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {VersionFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        versionControllerFind: async (filter?: VersionFilter1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/versions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {VersionFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        versionControllerFindById: async (id: number, filter?: VersionFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('versionControllerFindById', 'id', id)
            const localVarPath = `/versions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {Version} [version] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        versionControllerReplaceById: async (id: number, version?: Version, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('versionControllerReplaceById', 'id', id)
            const localVarPath = `/versions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(version, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {VersionPartial} [versionPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        versionControllerUpdateAll: async (where?: { [key: string]: object; }, versionPartial?: VersionPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/versions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (where !== undefined) {
                localVarQueryParameter['where'] = where;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(versionPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {VersionPartial} [versionPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        versionControllerUpdateById: async (id: number, versionPartial?: VersionPartial, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('versionControllerUpdateById', 'id', id)
            const localVarPath = `/versions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(versionPartial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VersionControllerApi - functional programming interface
 * @export
 */
export const VersionControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VersionControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async versionControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.versionControllerCount(where, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {NewVersion} [newVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async versionControllerCreate(newVersion?: NewVersion, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Version>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.versionControllerCreate(newVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async versionControllerDeleteById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.versionControllerDeleteById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {VersionFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async versionControllerFind(filter?: VersionFilter1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VersionWithRelations>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.versionControllerFind(filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {VersionFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async versionControllerFindById(id: number, filter?: VersionFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VersionWithRelations>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.versionControllerFindById(id, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {Version} [version] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async versionControllerReplaceById(id: number, version?: Version, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.versionControllerReplaceById(id, version, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {VersionPartial} [versionPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async versionControllerUpdateAll(where?: { [key: string]: object; }, versionPartial?: VersionPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoopbackCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.versionControllerUpdateAll(where, versionPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {VersionPartial} [versionPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async versionControllerUpdateById(id: number, versionPartial?: VersionPartial, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.versionControllerUpdateById(id, versionPartial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * VersionControllerApi - factory interface
 * @export
 */
export const VersionControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VersionControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        versionControllerCount(where?: { [key: string]: object; }, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.versionControllerCount(where, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NewVersion} [newVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        versionControllerCreate(newVersion?: NewVersion, options?: any): AxiosPromise<Version> {
            return localVarFp.versionControllerCreate(newVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        versionControllerDeleteById(id: number, options?: any): AxiosPromise<any> {
            return localVarFp.versionControllerDeleteById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VersionFilter1} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        versionControllerFind(filter?: VersionFilter1, options?: any): AxiosPromise<Array<VersionWithRelations>> {
            return localVarFp.versionControllerFind(filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {VersionFilter} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        versionControllerFindById(id: number, filter?: VersionFilter, options?: any): AxiosPromise<VersionWithRelations> {
            return localVarFp.versionControllerFindById(id, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {Version} [version] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        versionControllerReplaceById(id: number, version?: Version, options?: any): AxiosPromise<any> {
            return localVarFp.versionControllerReplaceById(id, version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {{ [key: string]: object; }} [where] 
         * @param {VersionPartial} [versionPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        versionControllerUpdateAll(where?: { [key: string]: object; }, versionPartial?: VersionPartial, options?: any): AxiosPromise<LoopbackCount> {
            return localVarFp.versionControllerUpdateAll(where, versionPartial, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {VersionPartial} [versionPartial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        versionControllerUpdateById(id: number, versionPartial?: VersionPartial, options?: any): AxiosPromise<any> {
            return localVarFp.versionControllerUpdateById(id, versionPartial, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VersionControllerApi - object-oriented interface
 * @export
 * @class VersionControllerApi
 * @extends {BaseAPI}
 */
export class VersionControllerApi extends BaseAPI {
    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VersionControllerApi
     */
    public versionControllerCount(where?: { [key: string]: object; }, options?: AxiosRequestConfig) {
        return VersionControllerApiFp(this.configuration).versionControllerCount(where, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NewVersion} [newVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VersionControllerApi
     */
    public versionControllerCreate(newVersion?: NewVersion, options?: AxiosRequestConfig) {
        return VersionControllerApiFp(this.configuration).versionControllerCreate(newVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VersionControllerApi
     */
    public versionControllerDeleteById(id: number, options?: AxiosRequestConfig) {
        return VersionControllerApiFp(this.configuration).versionControllerDeleteById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VersionFilter1} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VersionControllerApi
     */
    public versionControllerFind(filter?: VersionFilter1, options?: AxiosRequestConfig) {
        return VersionControllerApiFp(this.configuration).versionControllerFind(filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {VersionFilter} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VersionControllerApi
     */
    public versionControllerFindById(id: number, filter?: VersionFilter, options?: AxiosRequestConfig) {
        return VersionControllerApiFp(this.configuration).versionControllerFindById(id, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {Version} [version] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VersionControllerApi
     */
    public versionControllerReplaceById(id: number, version?: Version, options?: AxiosRequestConfig) {
        return VersionControllerApiFp(this.configuration).versionControllerReplaceById(id, version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {{ [key: string]: object; }} [where] 
     * @param {VersionPartial} [versionPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VersionControllerApi
     */
    public versionControllerUpdateAll(where?: { [key: string]: object; }, versionPartial?: VersionPartial, options?: AxiosRequestConfig) {
        return VersionControllerApiFp(this.configuration).versionControllerUpdateAll(where, versionPartial, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {VersionPartial} [versionPartial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VersionControllerApi
     */
    public versionControllerUpdateById(id: number, versionPartial?: VersionPartial, options?: AxiosRequestConfig) {
        return VersionControllerApiFp(this.configuration).versionControllerUpdateById(id, versionPartial, options).then((request) => request(this.axios, this.basePath));
    }
}


