/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { settings, NavisionControllerApi, MailControllerApi } from '@api/backend'
import { automaticDownload, csvmaker, displayErrorMsg, displaySuccessMsg, odataLogs, getCodVendedor } from '@utils'
import Swal from 'sweetalert2'
import { getUserCode, isParentUser } from '@src/auth/utils'

const prefix = 'payments'
const config = settings
export const GET_PAYMENT = `${prefix}/GET_PAYMENT`
export const GET_PAYMENT_PDF = `${prefix}/GET_PAYMENT_PDF`
export const GET_PAYMENTS = `${prefix}/GET_DATA`
export const GET_FILTROS_PAYMENTS = `${prefix}/GET_FILTROS_PAYMENTS`
export const DELETE_PAYMENT = `${prefix}/DELETE_PAYMENT`
export const INIT_REQUEST = `${prefix}/INIT_REQUEST`
export const INIT_PAYMENT_PDF = `${prefix}/INIT_PAYMENT_PDF`
export const INIT_DOWNLOAD_PAYMENT_PDF = `${prefix}/INIT_DOWNLOAD_PAYMENT_PDF`
export const DOWNLOAD_PAYMENT_PDF = `${prefix}/DOWNLOAD_PAYMENT_PDF`
export const SEND_PAYMENT = `${prefix}/SEND_PAYMENT`
export const SET_PAYMENT_FROM_ALLDATA = `${prefix}/SET_PAYMENT_FROM_ALLDATA`
export const SWITCH_BILLTO_SELLTO = `${prefix}/SWITCH_BILLTO_SELLTO`

const apiNavisionNew = new NavisionControllerApi(settings)
const apiMail = new MailControllerApi(settings)

export const initRequest = () => {
  return async (dispatch) => {
    dispatch({
      type: INIT_REQUEST
    })
  }
}

const descargaFichero = function (data) {
  const blob = new Blob([data], { type: 'text/csv' })
  const url = window.URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.setAttribute('href', url)
  a.setAttribute('download', '/abonos.csv')
  a.click()
}

export const descargarCsv = (params, intl) => {
  return async (dispatch) => {
    dispatch(initRequest())
    const { page = 1, perPage = 10, usuario, gerente = null, filtrosBusqueda } = params

    let vend = ''
    let cli = ''
    if (gerente == 'off') {
      if (usuario.tipoUsuario == 'V') {
        //Cuando es vendedor
        vend = usuario.codVendedorCliente
      } else {
        // Cuando es cliente
        cli = usuario.codVendedorCliente
        await getCodVendedor(cli).then(response => {
          vend = response[0].code
        })
      }
    } else {
      if (usuario.tipoUsuario == 'V') {
        vend = ''
      } else {
        await getCodVendedor(usuario.codVendedorCliente).then(response => {
          vend = response[0].code
        })
      }
    }

    let $filter
    const filterList = []
    const { customerNo, documentNo, shipToAddress, shipToCity, desde, hasta } = filtrosBusqueda || {};

    if (vend) {
      filterList.push(`salespersonCode eq '${vend}'`) //->Filtramos por el codigo exacto del vendedor, sin añadir *__*
    }

    if (cli) {
      filterList.push(`sellToCustomerNo eq '*${cli}*'`)
    }

    if (customerNo || documentNo || shipToAddress || shipToCity || desde || hasta) {

      if (customerNo) {
        filterList.push(`sellToCustomerNo eq '*${customerNo}*'`)
      }

      if (documentNo) {
        filterList.push(`documentNo eq '*${documentNo}*'`)
      }

      if (shipToAddress) {
        filterList.push(`shipToAddress eq '*${shipToAddress}*'`)
      }

      if (shipToCity) {
        filterList.push(`shiptoCity eq '*${shipToCity}*'`)
      }
      if (desde) {
        if (hasta) {
          filterList.push(`postingDate ge ${desde}`)
          filterList.push(`postingDate le ${hasta}`)
        } else {
          filterList.push(`postingDate eq ${desde}`)
        }
      }

      if (filterList.length) {
        $filter = filterList.join(' and ')
      }
    }

    $filter = filterList.join(' and ')
    const $top = perPage
    const $skip = (page - 1) * perPage
    const $orderby = 'documentNo desc'
    const $expand = 'salescrMemoLines'
    const $count = true
    Swal.fire({
      title: intl.formatMessage({ id: 'Generating csv' }),
      html: intl.formatMessage({ id: 'Please wait' }),
      timer: $top * 1.5,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading()
      }
    })

    await apiNavisionNew.navisionControllerGetendPoint('salesCrMemosHeader', $filter, $top, $skip, $orderby, $expand, $count).then((response) => {
      const { data } = response;
      let filteredData = [];
      if (data && data.value) {
        if (gerente === "off" || gerente === "" || gerente === null || gerente === undefined) {
          const salespersonCode = vend;
          filteredData = data.value.filter(item => item.salespersonCode === salespersonCode);
        } else if (gerente === "on") {
          filteredData = data.value;
        }
      } else {
        displayErrorMsg(intl.formatMessage({ id: 'A problem has occurred, contact the administrator' }))
        console.log('Error encontrado:', data.error);
      }

      const csvdata = csvmaker(filteredData)
      descargaFichero(csvdata)
      location.reload()
    })
  }
}

// ** Get data on page or row change
export const getData = (intl, params) => {
  return async (dispatch) => {
    const { page = 1, perPage = 10, usuario, gerente = null, filtrosBusqueda } = params

    try {
      let vend = ''
      let cli = ''
      if (gerente == 'off') {
        if (usuario.tipoUsuario == 'V') {
          //Cuando es vendedor
          vend = usuario.codVendedorCliente
        } else {
          // Cuando es cliente
          cli = usuario.codVendedorCliente
          await getCodVendedor(cli).then(response => {
            vend = response[0].code
          })
        }
      } else {
        if (usuario.tipoUsuario == 'V') {
          vend = ''
        } else {
          await getCodVendedor(usuario.codVendedorCliente).then(response => {
            vend = response[0].code
          })
        }
      }

      let $filter
      let total
      const $top = perPage
      const $skip = (page - 1) * perPage
      const $orderby = 'documentNo desc'
      const $expand = 'salescrMemoLines'
      const $count = true
      const filterList = []

      const { customerNo, documentNo, shipToAddress, shipToCity, desde, hasta } = filtrosBusqueda || {};

      if (vend) {
        filterList.push(`salespersonCode eq '${vend}'`) //->Filtramos por el codigo exacto del vendedor, sin añadir *__*
      }

      if (cli) {
        filterList.push(`sellToCustomerNo eq '*${cli}*'`)
      }

      if (customerNo || documentNo || shipToAddress || shipToCity || desde || hasta) {

        if (customerNo) {
          filterList.push(`sellToCustomerNo eq '*${customerNo}*'`)
        }

        if (documentNo) {
          filterList.push(`documentNo eq '*${documentNo}*'`)
        }

        if (shipToAddress) {
          filterList.push(`shipToAddress eq '*${shipToAddress}*'`)
        }

        if (shipToCity) {
          filterList.push(`shiptoCity eq '*${shipToCity}*'`)
        }
        if (desde) {
          if (hasta) {
            filterList.push(`postingDate ge ${desde}`)
            filterList.push(`postingDate le ${hasta}`)
          } else {
            filterList.push(`postingDate eq ${desde}`)
          }
        }

        if (filterList.length) {
          $filter = filterList.join(' and ')
        }
      }

      $filter = filterList.join(' and ')


      await apiNavisionNew.navisionControllerGetendPoint('salesCrMemosHeader', $filter, $top, $skip, $orderby, $expand, $count).then((response) => {
        const { data } = response;
        let filteredData = [];
        if (data && data.value) {
          if (gerente === "off" || gerente === "" || gerente === null || gerente === undefined) {
            const salespersonCode = vend;
            filteredData = data.value.filter(item => item.salespersonCode === salespersonCode);
          } else if (gerente === "on") {
            filteredData = data.value;
          }
        } else {
          displayErrorMsg(intl.formatMessage({ id: 'A problem has occurred, contact the administrator' }))
          console.log('Error encontrado:', data.error);
        }

        dispatch({
          type: GET_PAYMENTS,
          allData: filteredData,
          data: filteredData,
          totalPages: total || data['@odata.count'] || 0,
          params
        })

        //Guardamos los filtros de busqueda (Inputs)
        dispatch({
          type: GET_FILTROS_PAYMENTS,
          filtros: filtrosBusqueda
        })

        odataLogs(response.config.url, response.status, response.config.method, GET_PAYMENTS, config)
      }).catch((err) => odataLogs(err.message, "ERROR", "", GET_PAYMENTS, config))

    } catch (error) {
      console.log('Error consultando datos de Navision: ', error.message)
    }
  }

}

// ** Get invoice
export const getAbono = (params) => {
  return async (dispatch) => {
    const {
      page = 1,
      perPage = 1,
      documentNo = null
    } = params

    try {
      const $top = perPage
      const $skip = (page - 1) * perPage
      const $orderby = 'documentNo desc'
      const $expand = 'salescrMemoLines'
      const $count = undefined

      const $filter = `documentNo eq '${documentNo}'`

      await apiNavisionNew.navisionControllerGetendPoint('salesCrMemosHeader', $filter, $top, $skip, $orderby, $expand, $count).then(response => {
        const { data } = response
        dispatch({
          type: GET_PAYMENT,
          payment: data.value?.pop()
        })
        odataLogs(response.config.url, response.status, response.config.method, GET_PAYMENT, config)
      }).catch(err => odataLogs(err.message, "ERROR", "", GET_PAYMENT, config))
    } catch (err) {
      console.log(err.message)
    }
  }
}

export const consultarSiExisteAbono = (params) => {
  return async () => {
    const {
      page = 1,
      perPage = 1,
      documentNo = null
    } = params

    try {
      const $top = perPage
      const $skip = (page - 1) * perPage
      const $orderby = 'documentNo desc'
      const $expand = 'salescrMemoLines'
      const $count = undefined

      const $filter = `documentNo eq '${documentNo}'`
      return apiNavisionNew.navisionControllerGetendPoint('salesCrMemosHeader', $filter, $top, $skip, $orderby, $expand, $count).then(response => {
        return response
      }).catch(err => odataLogs(err.message, "ERROR", "", GET_PAYMENT, config))
    } catch (err) {
      console.error(err.message)
    }
  }
}

// ** Fetchea pdf y lo descarga
export const downloadAbonoPdf = (documentNo, processing, intl) => {
  return async (dispatch) => {
    try {
      if (processing && processing === true) dispatch(initRequest())
      const objNew = { DocNo: documentNo, DocType: 'ABONOVENTA' };
      const objJSON = JSON.stringify(objNew); // <-- creamos el JSON a enviar
      await apiNavisionNew.navisionControllerPostEndpointBC("TW_Functions_PrintDocument", objJSON).then((response) => {
        const { data } = response
        // Escapa con notificacion error si encuentra error en la respuesta de navision
        if (data.status !== 'OK') return displayErrorMsg(`${intl.formatMessage({ id: 'Error generating PDF in Navision' })}:${data.mensaje.message}`)
        dispatch({
          type: DOWNLOAD_PAYMENT_PDF,
          paymentPdf: data.mensaje,
          documentNo
        })
        automaticDownload(`${documentNo}.pdf`, `data:application/pdf;base64,${data.mensaje}`)
        displaySuccessMsg(intl.formatMessage({ id: 'Downloaded PDF' }))
        odataLogs(response.config.url, response.status, response.config.method, DOWNLOAD_PAYMENT_PDF, config)
      })
    } catch (err) {
      odataLogs(err.message, "ERROR", "", GET_PAYMENT_PDF, config)
      console.log(err.message)
    }
  }
}

// ** Fetchea PDF y lo envia por email
export const sendAbonoPdfEmail = (mailObject, abono, intl) => {
  return async (dispatch) => {
    try {
      Swal.fire({
        position: 'center',
        title: intl.formatMessage({ id: 'Process started on the server, a warning will be displayed on the screen at the end of the process.' }),
        icon: 'info'
      })
      const objNew = { DocNo: abono.toString(), DocType: 'ABONOVENTA' };
      const objJSON = JSON.stringify(objNew); // <-- creamos el JSON a enviar
      await apiNavisionNew.navisionControllerPostEndpointBC("TW_Functions_PrintDocument", objJSON).then((response) => {
        const { data } = response
        console.log(response)
        dispatch({
          type: DOWNLOAD_PAYMENT_PDF,
          paymentPdf: data,
          documentNo: abono.toString()
        })

        mailObject.attachments = [
          {
            filename: 'file.pdf',
            text: 'abono b2b',
            contentType: 'application/pdf',
            encoding: 'base64',
            content: data
          }
        ]

        apiMail.mailControllerSendMail(mailObject).then((response) => {
          dispatch({
            type: SEND_PAYMENT
          })
          odataLogs(response.config.url, response.status, response.config.method, SEND_PAYMENT, config)
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: intl.formatMessage({ id: 'E-mail sent' })
          })
        })
      })
    } catch (err) {
      odataLogs(err.message, "ERROR", "", SEND_PAYMENT, config)
      console.log(err.message)
    }
  }
}

// ** Precargar data del abono desde alldata
export const setAbonoFromAllData = (documentNo) => {
  return async (dispatch) => {
    dispatch({
      type: SET_PAYMENT_FROM_ALLDATA,
      data: documentNo
    })
  }
}

export const switchBilltoSellto = () => {
  return async (dispatch) => {
    dispatch({
      type: SWITCH_BILLTO_SELLTO
    })
  }
}