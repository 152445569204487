// ** Initial State
const initialState = {
    languages: [],
    allData: [],
    data: [],
    total: 1,
    params: {},
    selectedTranslation: undefined
}

const prefix = 'translations'

const translationsPermissions = (state = initialState, action) => {

    switch (action.type) {
        case `${prefix}/GET_LANGUAGES`:
            return { ...state, languages: action.data }
        case `${prefix}/GET_ALL_DATA`:
            return { ...state, allData: action.data }
        case `${prefix}/GET_DATA`:
            return {
                ...state,
                data: action.data,
                total: action.totalPages || 1,
                params: action.params
            }
        case `${prefix}/GET_DATA_COUNT`:
            return {
                ...state,
                total: action.total
            }
        case `${prefix}/GET`:
            return { ...state, selectedTranslation: action.data }
        case `${prefix}/ADD`:
            return { ...state }
        case `${prefix}/DELETE`:
            return { ...state }
        default:
            return { ...state }
    }
}
export default translationsPermissions
