/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { settings, CompaniesControllerApi } from '@api/backend'
import { displaySuccessMsg, odataLogs } from '@utils'
import { FormattedMessage, useIntl } from 'react-intl'
const prefix = 'admin/empresas'
const config = settings
export const INIT_COMPANY = `${prefix}/INIT_COMPANY`
export const GET_COMPANY = `${prefix}/GET_COMPANY`
export const GET_USER_COMPANY = `${prefix}/GET_USER_COMPANY`
export const GET_COMPANIES = `${prefix}/GET_DATA`
export const GET_COMPANIES_COUNT = `${prefix}/GET_DATA_COUNT`
export const ADD_COMPANY = `${prefix}/ADD`
export const DELETE_COMPANY = `${prefix}/DELETE`
export const UPDATE_COMPANY = `${prefix}/UPDATE`
export const INIT_REQUEST = `${prefix}/INIT_REQUEST`


const api = new CompaniesControllerApi(settings)

const getParams = (state) => state.admin.companies.params || []

export const getCompanies = () => {
  return async (dispatch) => {
    await api.companiesControllerFind().then(response => {
      dispatch({
        type: GET_COMPANIES,
        data: response.data
      })
      odataLogs(response.config.url, response.status, response.config.method, GET_COMPANIES, config)
    }).catch((err) => odataLogs(err.message, "ERROR", "", GET_COMPANIES, config))
  }
}

// ** Get data on page or row change
export const getData = (params) => {
  return async (dispatch) => {
    const { page = 1, perPage = 10, q = '', sortBy, search = '', status } = params

    const filter = {
      offset: (page - 1) * perPage,
      limit: perPage,
      skip: ((page - 1) * perPage),
      order: sortBy || "name ASC",
      where:{
        ['name']: { like: `%${q}%` }
      }
    }

    await api.companiesControllerFind(JSON.stringify(filter)).then(response => {
      dispatch({
        type: GET_COMPANIES,
        data: response.data,
        params
      })
      odataLogs(response.config.url, response.status, response.config.method, GET_COMPANIES, config)
    }).catch((err) => odataLogs(err.message, "ERROR", "", GET_COMPANIES, config))
    await api.companiesControllerCount(JSON.stringify(filter.where)).then(response => {
      dispatch({
        type: GET_COMPANIES_COUNT,
        total: response.data.count || 0
      })
      odataLogs(response.config.url, response.status, response.config.method, GET_COMPANIES_COUNT, config)
    }).catch((err) => odataLogs(err.message, "ERROR", "", GET_COMPANIES_COUNT, config))
  }
}

export const initCompany = () => {
  return async (dispatch) => {
    dispatch({
      type: GET_COMPANY,
      selectedCompany: null
    })
  }
}

// ** Get Company
export const getCompany = (id) => {
  return async (dispatch) => {
    const filter = {

    }
    await api.companiesControllerFindById(id, JSON.stringify(filter)).then(response => {
      // Lo seteo en  localStorage, ya que  necesito esa variable en utils.js y no puedo utilizar ahi el store(FUNCIONA)
      localStorage.setItem('empresaName', response.data.name)
      dispatch({
        type: GET_COMPANY,
        selectedCompany: response.data
      })
      odataLogs(response.config.url, response.status, response.config.method, GET_COMPANY, config)
    }).catch((err) => odataLogs(err.message, "ERROR", "", GET_COMPANY, config))
  }
}

// ** Get Company
export const getUserCompany = (id) => {
  return async (dispatch) => {
    const filter = {

    }
    await api.companiesControllerFindById(id, JSON.stringify(filter)).then(response => {
      dispatch({
        type: GET_USER_COMPANY,
        data: response.data
      })
      odataLogs(response.config.url, response.status, response.config.method, GET_USER_COMPANY, config)
    }).catch((err) => odataLogs(err.message, "ERROR", "", GET_USER_COMPANY, config))
  }
}

// ** Add new company
export const addCompany = (company, intl) => {
  return async (dispatch, getState) => {
    await api.companiesControllerCreate(company).then(response => {
      dispatch({
        type: ADD_COMPANY,
        company
      })
      odataLogs(response.config.url, response.status, response.config.method, ADD_COMPANY, config)
    }).then(() => {
      displaySuccessMsg(intl.formatMessage({ id: "Company created"}))
      dispatch(getData(getParams(getState())))
    }).catch((err) => odataLogs(err.message, "ERROR", "", ADD_COMPANY, config))
  }
}

// ** Update company
export const updateCompany = (id, company, intl) => {
  return (dispatch, getState) => {
    api.companiesControllerUpdateById(id, company).then(response => {
      dispatch({
        type: UPDATE_COMPANY,
        selectedCompany: company
      })
      odataLogs(response.config.url, response.status, response.config.method, UPDATE_COMPANY, config)
    }).then(() => {
      displaySuccessMsg(intl.formatMessage({ id: "Updated data."}))
      dispatch(getData(getParams(getState())))
    }).catch((err) => odataLogs(err.message, "ERROR", "", UPDATE_COMPANY, config))
  }
}

// ** Delete company
export const deleteCompany = (id) => {
  return (dispatch, getState) => {
    api
      .companiesControllerDeleteById(id)
      .then(response => {
        dispatch({
          type: DELETE_COMPANY
        })
        odataLogs(response.config.url, response.status, response.config.method, DELETE_COMPANY, config)
      })
      .then(() => {
        dispatch(getData(getState().admin.companies))
      }).catch((err) => odataLogs(err.message, "ERROR", "", DELETE_COMPANY, config))
  }
}