import {GET_INVOICES, GET_INVOICE, INIT_REQUEST, DELETE_INVOICE, SEND_INVOICE, GET_INVOICE_PDF, INIT_INVOICE_PDF, DOWNLOAD_INVOICE_PDF, SET_INVOICE_FROM_ALLDATA, SWITCH_BILLTO_SELLTO} from '../actions'

const initialState = {
  data: [],
  total: 1,
  params: {},
  allData: [],
  invoice: undefined,
  invoicePdf: undefined,
  documentNoPdf: '',
  processing: false,
  showFromHijos: false
}


const invoiceReducer = (state = initialState, action) => {
  switch (action.type) {
    case INIT_REQUEST:
      return {
        ...state,
        processing: true
      }
    case GET_INVOICES:
      return {
        ...state,
        allData: action.allData,
        data: action.data,
        total: action.totalPages,
        params: action.params,
        processing: false
      }
    case GET_INVOICE:
      return {
        ...state,
        invoice: action.invoice,
        processing: false
      }
    case GET_INVOICE_PDF:
      return {
        ...state,
        invoicePdf: action.invoicePdf,
        documentNoPdf: action.documentNo
      }
    case INIT_INVOICE_PDF:
      return {
        ...state,
        invoicePdf: undefined
      }
    case DELETE_INVOICE:
      return {...state}
    case SEND_INVOICE:
      return {
        ...state,
        processing: false
      }
    case DOWNLOAD_INVOICE_PDF:
      return {
        ...state,
        invoicePdf: action.invoicePdf,
        documentNoPdf: action.documentNo,
        processing: false
      }
    case SET_INVOICE_FROM_ALLDATA:
      return {
        ...state,
        invoice: state.allData.find(invoice => invoice.documentNo === action.data)
      }
    case SWITCH_BILLTO_SELLTO:
    return {
      ...state,
      showFromHijos: !state.showFromHijos
    }
    default:
      return {...state}
  }
}
export default invoiceReducer
