/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { displaySuccessMsg, getUserData, odataLogs } from '@utils'
import { settings, UsersControllerApi, NavisionControllerApi, ClientesControllerApi, VendedoresControllerApi } from '@api/backend'
import Swal from 'sweetalert2'
import { handleLogout } from '@store/actions/auth'

const prefix = 'usuarios/'
export const INIT_USER = `${prefix}/INIT_USER`
export const GET_USER = `${prefix}/GET_USER`
export const GET_VENDEDORES = `${prefix}/GET_VENDEDORES`
export const GET_CLIENTES = `${prefix}/GET_CLIENTES`
export const GET_USERS_NAV = `${prefix}/GET_USERS_VAV`
export const GET_USERS = `${prefix}/GET_DATA`
export const GET_USERS_COUNT = `${prefix}/GET_DATA_COUNT`
export const ADD_USER = `${prefix}/ADD`
export const DELETE_USER = `${prefix}/DELETE`
export const UPDATE_USER = `${prefix}/UPDATE`
export const GET_TARIFAS = `${prefix}/GET_TARIFAS`
export const SET_TARIFA = `${prefix}/SET_TARIFA`
export const SET_CLIENTETARIFA = `${prefix}/SET_CLIENTETARIFA`
export const GET_VENDEDORES_CLIENTES = `${prefix}/GET_VENDEDORES_CLIENTES`
export const INIT_REQUEST = `${prefix}/INIT_REQUEST`

const api = new UsersControllerApi(settings)
const apiNavisionNew = new NavisionControllerApi(settings)
const clienteasApi = new ClientesControllerApi(settings)
const vendedoresApi = new VendedoresControllerApi(settings)
const config = settings

const getParams = (state) => state.admin.users.params || []
const getParamsClienteTarifaSelected = (state) => state.admin.users || []

// ** Get data on page or row change
export const getData = (params) => {
  return async (dispatch) => {
    const { page = 1, perPage = 10, q = '', sortBy, search = '', role, status } = params

    const whereFilters = {}
    if (search) {
      whereFilters['or'] = [
        { ['email']: { like: `%${search}%`, options: 'i' } },
        { ['userName']: { like: `%${search}%`, options: 'i' } },
        { ['code']: { like: `%${search}%`, options: 'i' } }
      ]
    }

    if (role) {
      whereFilters['rolesId'] = { eq: Number(role) }
    }

    if (status) {
      switch (status) {
        case 'inactive':
          whereFilters['deleted'] = { eq: 1 }
          break
        case 'active':
          whereFilters['deleted'] = { eq: 0 }
          break
      }
    }

    const filter = {
      offset: (page - 1) * perPage,
      limit: perPage,
      skip: ((page - 1) * perPage),
      order: sortBy || "userName ASC",
      where: { ...whereFilters },
      include: [
        {
          relation: 'roles'
        },
        {
          relation: 'languages'
        }
      ]
    }

    await api.usersControllerFind(JSON.stringify(filter)).then(response => {
      dispatch({
        type: GET_USERS,
        data: response.data,
        params
      })
    })
    await api.usersControllerCount(JSON.stringify(filter.where)).then(response => {
      dispatch({
        type: GET_USERS_COUNT,
        total: response.data.count || 0
      })
    })
  }
}

export const initUser = () => {
  return async (dispatch) => {
    dispatch({
      type: GET_USER,
      selectedUser: null
    })
  }
}

export const getNavUsersData = () => {
  return async () => {
    const { data } = await apiNavisionNew.navisionControllerGetendPoint('querySalesPersons')
    return data
  }
}

export const obtenerNavUsersData = (usuarioSeleccionado, idEmpresa) => {
  return async () => {
    if (!idEmpresa) {
      idEmpresa = usuarioSeleccionado?.companyId ? usuarioSeleccionado.companyId : ""
    }
    const { data } = await apiNavisionNew.navisionControllerGetVendedoresPorIdEmpresa('querySalesPersons', idEmpresa)
    return data
  }
}

export const obtenerUsuario = (id) => {
  return async () => {
    const filter = {
      include: [
        {
          relation: 'roles'
        },
        {
          relation: 'languages'
        }
      ]
    }
    return api.usersControllerFindById(id, JSON.stringify(filter)).then(response => {
       return response.data
    })
  }
}


// ** Get User
export const getUser = (id) => {
  return async (dispatch, getState) => {
    const filter = {
      include: [
        {
          relation: 'roles'
        },
        {
          relation: 'languages'
        }
      ]
    }
    let codVendedor = ""
    let usuario
    await api.usersControllerFindById(id, JSON.stringify(filter)).then(response => {
      dispatch({
        type: GET_USER,
        selectedUser: response.data
      })
      usuario = response.data
      codVendedor = response.data.codVendedorCliente
      odataLogs(response.config.url, response.status, response.config.method, GET_USER, config)
    }).catch((err) => odataLogs(err.message, "ERROR", "", GET_USER, config))
    if (codVendedor) {
      //
      //Obtenemos los datos de los vendedores
      //
      const cod = getUserData()
      const filter = {where: {
        ['type']:'Customer by SalesPerson',
        ['companyId']:usuario.companyId}
      }
      const { data: vendedores } = await vendedoresApi.vendedoresControllerFind(JSON.stringify(filter))
      const arrVendedores = []
      vendedores?.map(cliente => {
        if (cliente.code === codVendedor) {
          arrVendedores.push(cliente)
        }
      })
      //
      //Obtenemos los datos de los clientes que pueden ver stock
      //
      const filterStock = {where: {
        ['allowStock']:'true',
        ['companyId']:usuario.companyId}
      }
      const { data: clienteStock } = await clienteasApi.clientesControllerFind(JSON.stringify(filterStock))
      const arrClientesStock = []
      clienteStock?.map(cliente => {
        for (let i = 0; i < arrVendedores.length; i++) {
          if (arrVendedores[i].relatedCode === cliente.customerNo) {
            arrClientesStock.push(arrVendedores[i])
          }
        }
      })
      const arrClientesStockSinDuplicados = []
      arrClientesStock.forEach(elemento => {
        if (!arrClientesStockSinDuplicados.includes(elemento)) {
          arrClientesStockSinDuplicados.push(elemento);
        }
      });
      dispatch({
        type: GET_VENDEDORES_CLIENTES,
        vendedoresClientes: arrClientesStockSinDuplicados
      })
      // Al recargar, pilla siempre estos valores, por lo tanto controlamos que ya tenga o no
      if (getParamsClienteTarifaSelected(getState()).clienteSelected === "" ||
        getParamsClienteTarifaSelected(getState()).clienteSelected === undefined ||
        getParamsClienteTarifaSelected(getState()).clienteSelected === null) {
        dispatch({
          type: SET_CLIENTETARIFA,
          clienteSelected: usuario.clienteDefecto
        })
      }
      if (getParamsClienteTarifaSelected(getState()).tarifaSelected === "" ||
        getParamsClienteTarifaSelected(getState()).tarifaSelected === undefined ||
        getParamsClienteTarifaSelected(getState()).tarifaSelected === null) {
        dispatch({
          type: SET_TARIFA,
          tarifaSelected: usuario.tarifaDefecto
        })
      }

    }
  }
}

export const getArrClientesVendedores = (codVendedor) => {
  return async (dispatch) => {
    //
    //Obtenemos los datos de los vendedores
    //
    const cod = getUserData()
    const filter = {where: {
      ['type']:'Customer by SalesPerson',
      ['companyId'] : cod.companyId}
    }
    const { data: vendedores } = await vendedoresApi.vendedoresControllerFind(JSON.stringify(filter))
    const arrVendedores = []
    vendedores?.map(cliente => {
      if (cliente.code === codVendedor) {
        arrVendedores.push(cliente)
      }
    })
    //
    //Obtenemos los datos de los clientes que pueden ver stock
    //
    const filterStock = {where: {
      ['allowStock']:'true',
      ['companyId'] : cod.companyId}
    }
    const { data: clienteStock } = await clienteasApi.clientesControllerFind(JSON.stringify(filterStock))
    const arrClientesStock = []
    clienteStock?.forEach(cliente => {
      const vendedorExistente = arrVendedores.find(vendedor => vendedor.relatedCode === cliente.customerNo);
      //
      // Verifica si el vendedor no está presente en arrClientesStock antes de agregarlo
      //
      if (vendedorExistente && !arrClientesStock.some(existing => existing.relatedCode === vendedorExistente.relatedCode)) {
        arrClientesStock.push(vendedorExistente);
      }
    });
    dispatch({
      type: GET_VENDEDORES_CLIENTES,
      vendedoresClientes: arrClientesStock
    })
  }
}

export const getVendedoresyClientes = () => {
  return async (dispatch) => {
    //
    // Saco los clientes si esta seleccionado un vendedor
    //
    const cod = getUserData()
    const companyId = cod?.companyId ? cod.companyId : ""
    const filter = {where: {
      ['allowStock']:`true`,
      ['companyId'] : companyId ? companyId : ""}
    }
    const { data: clientes } = await clienteasApi.clientesControllerFind(JSON.stringify(filter))
    const arrCliente = []
    clientes?.map((cliente) => {
      if (!arrCliente.find(arrCli => arrCli.customerNo === cliente.customerNo)) {
        cliente = {
          customerNo: cliente.customerNo,
          name: cliente.name
        }
        arrCliente.push(cliente)
      }
    })
    //
    // Saco los vendedores
    //
    const { data: vendedores } = await apiNavisionNew.navisionControllerGetendPoint('querySalesPersons')
    try {
      dispatch({
        type: `${prefix}/GET_VENDEDORES`,
        vendedores,
        arrCliente
      })
    } catch (err) {
      console.error(err)
    }
  }
}

export const obtenerListaClientes = (usuarioSeleccionado, idEmpresa) => {
  return async () => {
    let companyId = 0
    if (idEmpresa) {
      companyId = idEmpresa  
    } else {
      companyId = usuarioSeleccionado?.companyId ? usuarioSeleccionado.companyId : ""
    }
    
    const filter = {where: {
      ['allowStock']:`true`,
      ['companyId'] : companyId ? companyId : ""}
    }

    return await clienteasApi.clientesControllerFind(JSON.stringify(filter)).then(response => {
    const arrCliente = []
     response.data?.map((cliente) => {
      if (!arrCliente.find(arrCli => arrCli.customerNo === cliente.customerNo)) {
        cliente = {
          customerNo: cliente.customerNo,
          name: cliente.name
        }
        arrCliente.push(cliente)
      }
    })
     return arrCliente
  })
  }
}

export const obtenerListaVendedores = (usuarioSeleccionado, idEmpresa) => {
  return async () => {
    if (!idEmpresa) {
      idEmpresa = usuarioSeleccionado?.companyId ? usuarioSeleccionado.companyId : ""
    }
    return await apiNavisionNew.navisionControllerGetVendedoresPorIdEmpresa('querySalesPersons', idEmpresa)
  }
}

export const obtenerArrClientesVendedores = (codVendedor, usuarioSeleccionado, idEmpresa) => {
  return async () => {
    //
    //Obtenemos los datos de los vendedores
    //
    let companyId = 0
    if (idEmpresa) {
      companyId = idEmpresa
    } else {
      companyId = usuarioSeleccionado?.companyId ? usuarioSeleccionado.companyId : ""
    }
    const filter = {where: {
      ['type']:'Customer by SalesPerson',
      ['companyId'] : companyId}
    }
    const { data: vendedores } = await vendedoresApi.vendedoresControllerFind(JSON.stringify(filter))
    const arrVendedores = []
    vendedores?.map(cliente => {
      if (cliente.code === codVendedor) {
        arrVendedores.push(cliente)
      }
    })
    //
    //Obtenemos los datos de los clientes que pueden ver stock
    //
    const filterStock = {where: {
      ['allowStock']:'true',
      ['companyId'] : companyId}
    }
    const { data: clienteStock } = await clienteasApi.clientesControllerFind(JSON.stringify(filterStock))
    const arrClientesStock = []
    clienteStock?.forEach(cliente => {
      const vendedorExistente = arrVendedores.find(vendedor => vendedor.relatedCode === cliente.customerNo);
      //
      // Verifica si el vendedor no está presente en arrClientesStock antes de agregarlo
      //
      if (vendedorExistente && !arrClientesStock.some(existing => existing.relatedCode === vendedorExistente.relatedCode)) {
        arrClientesStock.push(vendedorExistente);
      }
    });

    return arrClientesStock
  }
}

export const obtenerTarifas = (num) => {
  return async () => {
    // Obtengo el numero del cliente seleccionado
    const cod = getUserData()
    const companyId = cod?.companyId ? cod.companyId : ""
    const filter = {where: {
      ['type']:'Customer Price Group by Customer',
      ['code']:`${num}`,
      ['companyId'] : companyId}
    }
    console.log(JSON.stringify(filter))
    const {data: tarifas} = await vendedoresApi.vendedoresControllerFind(JSON.stringify(filter))
    console.log('Tarifas: ', tarifas)

    return tarifas
  }
}

export const getTarifas = (num) => {
  return async (dispatch) => {
    // Obtengo el numero del cliente seleccionado
    const cod = getUserData()
    const companyId = cod?.companyId ? cod.companyId : ""
    const filter = {where: {
      ['type']:'Customer Price Group by Customer',
      ['code']:`${num}`,
      ['companyId'] : companyId}
    }
    console.log(JSON.stringify(filter))
    await vendedoresApi.vendedoresControllerFind(JSON.stringify(filter)).then(response => {
      console.log(response.data)
      dispatch({
        type: GET_TARIFAS,
        tarifas: response.data
      })
    })
  }
}

export async function getTarifasReturn (num) {
  // Obtengo el numero del cliente seleccionado
  const cod = getUserData()
  const companyId = cod?.companyId ? cod.companyId : ""
  const filter = {where: {
    ['type']:'Customer Price Group by Customer',
    ['code']:`${num.value || num}`,
    ['companyId'] : companyId
    }
  }
  console.log(JSON.stringify(filter))
  const datos = await vendedoresApi.vendedoresControllerFind(JSON.stringify(filter)).then(response => {
    return response.data
  })

  return datos
}

export const setClienteTarifa = (numCliente) => {
  return async (dispatch) => {
    // Obtengo el numero del cliente seleccionado
    dispatch({
      type: SET_CLIENTETARIFA,
      clienteSelected: numCliente.value
    })
  }
}

export const setTarifaSelected = (tarifa) => {
  return async (dispatch) => {
    // Obtengo el numero del cliente seleccionado
    dispatch({
      type: SET_TARIFA,
      tarifaSelected: tarifa.value
    })
  }
}

// ** Add new user
export const addUser = (user, intl) => {
  return async (dispatch, getState) => {
    try {
      await api.usersControllerCreate(user).then(response => {
        dispatch({
          type: ADD_USER,
          user
        })
      }).then(() => {
        displaySuccessMsg(intl.formatMessage({ id: "User created"}))
        dispatch(getData(getParams(getState())))
      }).catch(err => console.log(err))
    } catch (err) {
      console.log(err)
    }
  }
}

// ** Update user
export const updateUser = (id, intl, user) => {
  const usuarioEnSesion = getUserData()
  return (dispatch, getState) => { 
    api.usersControllerUpdateById(id, user).then(response => {
      dispatch({
        type: UPDATE_USER,
        selectedUser: user
      })
    }).then(() => {
      // displaySuccessMsg(intl.formatMessage({ id: "Data updated. Log out and log back in"}))
      if (usuarioEnSesion?.id === id) {
        dispatch(getData(getParams(getState())))
        Swal.fire({
          position: 'center',
          icon: 'warning',
          title: `${intl.formatMessage({ id: "Los datos han sido actualizados."})}`,
          text: 'La sesión se cerrará a continuación. Por favor, vuelva a iniciar sesión.',
          showConfirmButton: true,
          // showCancelButton: true,
          cancelButtonColor: '#808080',
          confirmButtonText: 'Aceptar',
          // cancelButtonText: 'Cancelar',
          allowOutsideClick: false
        }).then((result) => {
            if (result.isConfirmed) {
              dispatch(handleLogout()); // Dispara la acción de Redux para cerrar sesión
            }
        })
      } else {
        displaySuccessMsg(intl.formatMessage({ id: "Datos actualizados correctamente"}))
        dispatch(getData(getParams(getState())))
      }
      
    }).catch(err => console.log(err))
  }
}
//
//Actualizamos la información en MySql sin devolver mensaje de OK o KO
//
export const updateUserSinMensaje = (id, user) => {
  return (dispatch, getState) => { 
    api.usersControllerUpdateById(id, user).then(response => {
      dispatch({
        type: UPDATE_USER,
        selectedUser: user
      })
    }).catch(err => console.log(err))
  }
}

// ** Delete user
export const deleteUser = (id, intl) => {
  return (dispatch, getState) => {
    const user = {
      userDeleted: 1,
      deleted: true
    }
    api.usersControllerUpdateById(id, user).then(response => {
      displaySuccessMsg(intl.formatMessage({ id: "User deleted"}))
      dispatch({
        type: DELETE_USER
      })
    }).then(() => {
      dispatch(getData(getParams(getState())))
    }).catch(err => console.log(err))
  }
}

