import { ADD_LANGUAGE, DELETE_LANGUAGE, GET_ALL_LANGUAGES, GET_LANGUAGE, GET_LANGUAGES, GET_LANGUAGES_COUNT, UPDATE_LANGUAGE } from '../action'

// ** Initial State
const initialState = {
  allData: [],
  data: [],
  total: 1,
  params: {},
  selectedLanguage: null
}

const languages = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_LANGUAGES:
      return { ...state, allData: action.data }
    case GET_LANGUAGES:
      return {
        ...state,
        data: action.data,
        params: action.params
      }
    case GET_LANGUAGES_COUNT:
      return {
        ...state,
        total: action.totalPages
      }
    case GET_LANGUAGE:
      return { ...state, selectedLanguage: action.selectedLanguage }
    case ADD_LANGUAGE:
      return { ...state }
    case DELETE_LANGUAGE:
      return { ...state }
    case UPDATE_LANGUAGE:
      return { ...state }
    default:
      return { ...state }
  }
}
export default languages
