import {GET_DELIVERY_NOTES, GET_DELIVERY_NOTE, INIT_REQUEST, GET_DELIVERY_NOTE_PDF, INIT_DELIVERY_NOTE_PDF, DOWNLOAD_DELIVERY_NOTE_PDF, SEND_DELIVERY_NOTE, SET_DELIVERY_NOTE_FROM_ALLDATA, SWITCH_BILLTO_SELLTO} from '../actions'

const initialState = {
  data: [],
  total: 1,
  params: {},
  allData: [],
  deliveryNote: undefined,
  deliveryNotePdf: undefined,
  documentNoPdf: '',
  processing: false,
  showFromHijos: false
}


const deliveryNoteReducer = (state = initialState, action) => {
  switch (action.type) {
    case INIT_REQUEST:
      return {
        ...state,
        processing: true
      }
    case GET_DELIVERY_NOTES:
      return {
        ...state,
        processing: false,
        allData: action.allData,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case GET_DELIVERY_NOTE:
      return {
        ...state,
        deliveryNote: action.deliveryNote,
        deliveryNotePdf: null
      }
    case GET_DELIVERY_NOTE_PDF:
      return {
        ...state,
        deliveryNotePdf: action.deliveryNotePdf,
        documentNoPdf: action.documentNo
      }
    case INIT_DELIVERY_NOTE_PDF:
      return {
        ...state,
        deliveryNotePdf: undefined
      }
    case DOWNLOAD_DELIVERY_NOTE_PDF:
      return {
        ...state,
        deliveryNotePdf: action.deliveryNotePdf,
        documentNoPdf: action.documentNo,
        processing: false
      }
    case SEND_DELIVERY_NOTE:
      return {
        ...state,
        processing: false
      }
    case SET_DELIVERY_NOTE_FROM_ALLDATA:
      return {
        ...state,
        deliveryNote: state.allData.find(deliveryNote => deliveryNote.documentNo === action.data)
      }
    case SWITCH_BILLTO_SELLTO:
      return {
        ...state,
        showFromHijos: !state.showFromHijos
      }
    default:
      return {...state}
  }
}
export default deliveryNoteReducer
