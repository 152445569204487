import { 
    INIT_REQUEST,
    GET_ALL_COMISIONES, 
    GET_COMISIONES_COUNT,
    GET_DETALLE_COMISIONES,
    GET_DETALLE_COMISIONES_COUNT,
    GET_FILTROS_COMISIONES
    } from '../action'

// ** Initial State
const initialState = {
  data: [],
  total: 1,
  params: {},
  dataDetalle: [],
  totalDetalle: 1,
  filtros: {},
  processing: false
}

const Comisiones = (state = initialState, action) => {
  switch (action.type) {
    case INIT_REQUEST:
      return {
      ...state,
      processing: true
    }
    case GET_ALL_COMISIONES:
      return { ...state, data: action.data, processing: false
    }
    case GET_COMISIONES_COUNT:
      return {...state, total: action.totalPages
    }
    case GET_DETALLE_COMISIONES:
      return { ...state, dataDetalle: action.dataDetalle
    }
    case GET_DETALLE_COMISIONES_COUNT:
      return {...state, totalDetalle: action.totalPagesDetalle
    }
    case GET_FILTROS_COMISIONES:
      return {
          ...state,
          filtros: action.filtros
      }
    default:
      return { ...state }
  }
}
export default Comisiones
