/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
const initialState = {
  products: [],
  productsPage: [],
  unfilteredProducts: [],
  allProducts: [],
  categories: [],
  families: [],
  atributos: {},
  wishlist: [],
  productDetail: {},
  params: {},
  users: [],
  portes: [],
  provincias: [],
  usersFilter: [],
  marcas: [],
  searchFilters: {
    term: '',
    family: '',
    subfamily: '',
    subfamily2: '',
    brands: [],
    atts: [],
    price: null,
    estado: []
  },
  totalProducts: 0,
  newShipToCode: null,
  cart: [],
  customerAddresses: [],
  shippingAddress: {
    name: "", address: "", city: "", postCode: "", country: "", contact: "", phoneNo: "", eMail: "", countryRegion: ""
  },
  salesQuoteIdentifier: undefined,
  yourReference: undefined,
  fechaRequerida: undefined,
  adressTypeShop: "",
  newAddressActive: false
}

const prefix = 'ecommerce'

const ecommerceReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${prefix}/SET_CATEGORIES`:
      return { ...state, categories: action.categories }
    case `${prefix}/SET_FAMILIES`:
      return { ...state, families: action.familias }
    case `${prefix}/SET_ATRIBUTOS`:
      return { ...state, atributos: action.atributos }
    case `${prefix}/GET_ALL_PRODUCTS`:
      return { ...state, unfilteredProducts: action.data, allProducts: action.data, totalProducts: action.data.length }
    case `${prefix}/GET_FILTERED_PRODUCTS`:
      return { ...state,  allProducts: action.data }
    case `${prefix}/REFRESH_PRODUCTS`:
      return { ...state, products: action.products, allProducts: action.allProducts, totalProducts: action.allProducts.length, params: action.params }
    case `${prefix}/GET_PRODUCTS`:
      return { ...state, products: action.slicedProducts, params: action.params, allProducts: action.allProducts, totalProducts: action.allProducts.length }
    case `${prefix}/GET_PRODUCT`:
      return { ...state, productDetail: action.data }
    case `${prefix}/GET_PRODUCTS_PAG`:
      return { ...state, productsPage: action.data }
    case `${prefix}/SET_PRODUCTS`:
      return { ...state, allProducts: action.data, totalProducts: action.data.length, params: { ...state.params, page: 1, perPage: 9 } }
    case `${prefix}/GET_CUSTOMER_ADDRESSES`:
      return { ...state, customerAddresses: action.data }
    case `${prefix}/NEW_SHIPPING_ADDRESS`:
      return { ...state, newShipToCode: action.data }
    case `${prefix}/SET_SHIPPING_ADDRESS`:
      return { ...state, shippingAddress: action.data, newAddressActive: false }
    case `${prefix}/SET_MARCAS`:
      return { ...state, marcas: action.data}
    case `${prefix}/CLEAR_SHIPPING_ADDRESS`:
      return {
        ...state,
        shippingAddress: {
          name: "", address: "", city: "", postCode: "", country: "", contact: "", phoneNo: "", eMail: "", countryRegion: ""
        }
      }
    case `${prefix}/SET_FILTERS`:
      return { ...state, searchFilters: { ...state.searchFilters, ...action.data } }
    case `${prefix}/CLEAR_FILTERS`:
      return {
        ...state,
        searchFilters: initialState.searchFilters,
        allProducts: state.unfilteredProducts,
        params: { ...state.params, q: "" }
      }
    case `${prefix}/GET_CART`:
      return { ...state, cart: action.data }
    case `${prefix}/GET_PORTES`:
      return { ...state, portes: action.data, provincias: action.prov }
    case `${prefix}/CLEAR_CART`:
      return { ...state, cart: [] }
    case `${prefix}/DELETE_CART_ITEM`:
      return { ...state, cart: action.data }
    case `${prefix}/ADD_TO_CART`:
      return { ...state, cart: action.data }
    case `${prefix}/SWITCH_QTY`:
      return { ...state, cart: action.data }
    case `${prefix}/UPDATE_PVC`:
      return { ...state, cart: action.data }
    case `${prefix}/SWITCH_PVR`:
      return { ...state, cart: action.data }
    case `${prefix}/CHANGE_RATE`:
      return { ...state, rate: action.data }
    case `${prefix}/GERENTE_SUPER`:
      return { ...state, gerenteSuper: action.data }
    case `${prefix}/SET_SALES_QUOTE_CART`:
      return { ...state, salesQuoteIdentifier: action.salesQuoteIdentifier, yourReference: action.yourReference, fechaRequerida: action.fechaRequerida }
    case `${prefix}/CLEAR_SALES_QUOTE_CART`:
      return { ...state, salesQuoteIdentifier: undefined }
    case `${prefix}/ADRESS_TYPE_SHOP`:
      return { ...state, adressTypeShop: action.data }
    case `${prefix}/NEW_ADRESS_ACTIVE`:
      return { ...state, newAddressActive: action.data }
    default:
      return state
  }
}

export default ecommerceReducer
