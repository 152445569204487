import useJwt from '@src/@core/auth/jwt/useJwt'
import { NavisionControllerApi, settings, ClientesControllerApi } from '@api/backend'

const apiNavisionNew = new NavisionControllerApi(settings)
const clienteasApi = new ClientesControllerApi(settings)

/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */
// eslint-disable-next-line arrow-body-style
export const isUserLoggedIn = () => {
  return localStorage.getItem('userData') && localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName)
}

export const getUserData = () => JSON.parse(localStorage.getItem('userData'))

//!!getUserCode() ==> retorna true si tiene codigo, retorna false si no tiene codigo(= es admin)
export const getUserCode = () => JSON.parse(localStorage.getItem('userData')).clienteDefecto

export const isParentUser = async (dataCode) => {
  // Para los casos de que no exista codigo para un usuario registrado
  if (dataCode == undefined) {
    return true
  } else {  
    const code = dataCode ? dataCode : JSON.parse(localStorage.getItem('userData')).code
    const codCompany = localStorage.getItem('empresaID')
    if (code) {
      const filter = {where: {
        ['customerNo']:`${code}`,
        ['companyId'] : codCompany
        }
      }
      const {data} = await clienteasApi.clientesControllerFind(JSON.stringify(filter))
      if (data.length > 0) {
        const {billToCustomerNo, customerNo} = data[0]
        // Es padre
        if (billToCustomerNo === customerNo) {
          return true
        }
        if (billToCustomerNo === "") {
          return true
        }
        // Es hijo
        if (billToCustomerNo !== customerNo) {
          return false
        }
      } else {
        return false
      }
    } else {
      return null
    }
  }
}

// Se utiliza para saber si usuario es admin o no (permisos)
export const isAdmin = () => {
  const userData = JSON.parse(localStorage.getItem('userData'))

  if (userData?.roles?.name === "Admin") return true
  else return false
}

/**
 * This function is used for demo purpose route navigation
 * In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 * Please note role field is just for showing purpose it's not used by anything in frontend
 * We are checking role just for ease
 * NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
  if (userRole.toLowerCase() === 'admin') return '/'
  if (userRole.toLowerCase() === 'client') return { name: 'access-control' }
  return { name: 'auth-login' }
}

export const parseRolesPermissions = (rolesPermissions) => {
  if (!rolesPermissions) return undefined
  return rolesPermissions.map(permission => {
    return {
      action: permission.controllerName,
      subject: permission.moduleName,
      fields: [permission.actionName]
    }
  })
}
