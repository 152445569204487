/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { settings, AnnouncementsControllerApi, AnnouncementsAnnouncementCategoryControllerApi, CategoriesControllerApi, AnnouncementsAnnouncementMetaControllerApi } from '@api/backend'
import { ERROR_FEEDBACK } from '@store/actions'
import { odataLogs } from '@utils'

const prefix = 'admin/anuncios'
const config = settings

export const INIT_ANNOUNCEMENT = `${prefix}/INIT_ANNOUNCEMENT`
export const GET_ANNOUNCEMENT = `${prefix}/GET_ANNOUNCEMENT`
export const GET_ANNOUNCEMENTS = `${prefix}/GET_DATA`
export const GET_ANNOUNCEMENTS_COUNT = `${prefix}/GET_DATA_COUNT`
export const GET_ANNOUNCEMENTS_RECENT = `${prefix}/GET_ANNOUNCEMENTS_RECENT`
export const ADD_ANNOUNCEMENT = `${prefix}/ADD`
export const DELETE_ANNOUNCEMENT = `${prefix}/DELETE`
export const UPDATE_ANNOUNCEMENT = `${prefix}/UPDATE`
export const ADD_ANNOUNCEMENT_CATEGORIES = `${prefix}/ADD_ANNONUCEMENT_CATEGORIES`
export const DELETE_ANNOUNCEMENT_CATEGORIES = `${prefix}/DELETE_ANNONUCEMENT_CATEGORIES`
export const ADD_ANNOUNCEMENT_META = `${prefix}/ADD_ANNONUCEMENT_META`
export const DELETE_ANNOUNCEMENT_META = `${prefix}/DELETE_ANNONUCEMENT_META`
export const GET_CATEGORIES = `${prefix}/GET_CATEGORIES`
export const INIT_REQUEST = `${prefix}/INIT_REQUEST`

const api = new AnnouncementsControllerApi(settings)
const apiCategories = new CategoriesControllerApi(settings)
const apiAnnouncementCategories = new AnnouncementsAnnouncementCategoryControllerApi(settings)
const apiAnnouncementMeta = new AnnouncementsAnnouncementMetaControllerApi(settings)

const getParams = (state) => state.admin.announcements.params || []

// ** Get all Data
export const getAllData = () => {
    return async (dispatch) => {
        const filter = {
            include: [
                {
                    relation: 'categories'
                }
            ]
        }
        await api.announcementsControllerFind(JSON.stringify(filter)).then(response => {
            dispatch({
                type: `${prefix}/GET_ALL_DATA`,
                data: response.data
            })
            odataLogs(response.config.url, response.status, response.config.method, `${prefix}/GET_ALL_DATA`, config)
        }).catch((err) => odataLogs(err.message, "ERROR", "", `${prefix}/GET_ALL_DATA`, config))
    }
}

// ** Get Categories
export const getCategories = () => {
    return async (dispatch) => {

        await apiCategories.categoriesControllerFind().then(response => {
            dispatch({
                type: GET_CATEGORIES,
                categories: response.data
            })
            odataLogs(response.config.url, response.status, response.config.method, GET_CATEGORIES, config)
        }).catch((err) => odataLogs(err.message, "ERROR", "", GET_CATEGORIES, config))
    }
}

// ** Add Announcement Categories
export const createAnnouncementCategories = (id, categories) => {
    return async (dispatch) => {

        await Promise.all(
            categories.map(category => {
                if (category.id) {
                    const newCategory = {
                        categoryId: category.id
                    }
                    return apiAnnouncementCategories.announcementsAnnouncementCategoryControllerCreate(id, newCategory)
                }
                return null
            })
        )
            .then(result => {
                odataLogs(result[0]?.config.url, result[0]?.status, result[0]?.config.method, "admin/anuncios/store/action/index/createAnnouncementCategories", config)
            })
            .catch(err => {
                console.log(err)
                dispatch({
                    type: ERROR_FEEDBACK,
                    message: err.message
                })
                odataLogs(err.message, "ERROR", "", ERROR_FEEDBACK, config)
            })
    }
}

// ** Delete Announcement Categories
export const deleteAnnouncementCategories = (id) => {
    return async (dispatch) => {

        await apiAnnouncementCategories.announcementsAnnouncementCategoryControllerDelete(id).then(response => {
            dispatch({
                type: DELETE_ANNOUNCEMENT_CATEGORIES,
                categories: []
            })
            odataLogs(response.config.url, response.status, response.config.method, DELETE_ANNOUNCEMENT_CATEGORIES, config)
        })
            .catch(err => {
                console.log(err)
                dispatch({
                    type: ERROR_FEEDBACK,
                    message: err.message
                })
                odataLogs(err.message, "ERROR", "", ERROR_FEEDBACK, config)
            })

    }
}

// ** Add Announcement Metas
export const createAnnouncementMetas = (id, metas) => {
    return async (dispatch) => {
        await Promise.all(
            metas.map(meta => {
                const newMeta = {
                    announcementId: id,
                    key: meta.content,
                    content: meta.key
                    // Agregando datos del dia antes del nombre para que no de error de que ya exista
                }
                return apiAnnouncementMeta.announcementsAnnouncementMetaControllerCreate(id, newMeta)
            })
        )
            .then(result => {
                odataLogs(result[0]?.config.url, result[0]?.status, result[0]?.config.method, "admin/anuncios/store/action/index/createAnnouncementMetas", config)

            })
            .catch(err => {
                console.log(err)
                dispatch({
                    type: ERROR_FEEDBACK,
                    message: err.message
                })
                odataLogs(err.message, "ERROR", "", ERROR_FEEDBACK, config)
            })
    }
}

// ** Delete Announcement Meta
export const deleteAnnouncementMetas = (id) => {
    return async (dispatch) => {
        await apiAnnouncementMeta.announcementsAnnouncementMetaControllerDelete(id).then(response => {
            dispatch({
                type: DELETE_ANNOUNCEMENT_META,
                metas: []
            })
            odataLogs(response.config.url, response.status, response.config.method, DELETE_ANNOUNCEMENT_META, config)
        })
            .catch(err => {
                console.log(err)
                dispatch({
                    type: ERROR_FEEDBACK,
                    message: err.message
                })
                odataLogs(err.message, "ERROR", "", ERROR_FEEDBACK, config)
            })
    }
}

// ** Get data on page or row change
export const getData = (params) => {

    return async (dispatch) => {
        const { page = 1, perPage = 30, q = '', sortBy } = params

        const filter = {
            offset: (page - 1) * perPage,
            limit: perPage,
            skip: ((page - 1) * perPage),
            order: sortBy || "title ASC",
            where: {
                ['title']: { like: `%${q}%` }
            },
            include: [
                {
                    relation: 'categories'
                },
                {
                    relation: 'announcementMetas'
                },
                {
                    relation: 'author'
                }
            ]

        }

        await api.announcementsControllerFind(JSON.stringify(filter)).then(response => {
            dispatch({
                type: GET_ANNOUNCEMENTS,
                data: response.data,
                params
            })
            odataLogs(response.config.url, response.status, response.config.method, GET_ANNOUNCEMENTS, config)
        }).catch((err) => odataLogs(err.message, "ERROR", "", GET_ANNOUNCEMENTS, config))

        await api.announcementsControllerCount(JSON.stringify(filter.where)).then(response => {
            dispatch({
                type: GET_ANNOUNCEMENTS_COUNT,
                totalPages: response.data.count || 0
            })
            odataLogs(response.config.url, response.status, response.config.method, GET_ANNOUNCEMENTS_COUNT, config)
        }).catch((err) => odataLogs(err.message, "ERROR", "", GET_ANNOUNCEMENTS_COUNT, config))
    }
}

export const initAnnouncement = () => {
    return async (dispatch) => {
        dispatch({
            type: GET_ANNOUNCEMENT
        })
    }
}
// ** Get Announcement
export const getAnnouncement = (id) => {
    return async (dispatch) => {
        const filter = {
            include: [
                {
                    relation: 'categories'
                },
                {
                    relation: 'author'
                },
                {
                    relation: 'announcementMetas'
                }
            ]
        }
        await api.announcementsControllerFindById(id, JSON.stringify(filter)).then(response => {
            dispatch({
                type: GET_ANNOUNCEMENT,
                announcement: response.data
            })
            odataLogs(response.config.url, response.status, response.config.method, GET_ANNOUNCEMENT, config)
        }).catch((err) => odataLogs(err.message, "ERROR", "", GET_ANNOUNCEMENT, config))

    }
}

// ** Get Announcement by slug
export const getAnnouncementBySlug = (slug) => {
    return async (dispatch) => {
        const filter = {
            where: {
                ['slug']: { eq: slug }
            },
            include: [
                {
                    relation: 'categories'
                },
                {
                    relation: 'author'
                },
                {
                    relation: 'announcementMetas'
                }
            ]
        }
        await api.announcementsControllerFind(JSON.stringify(filter)).then(response => {
            dispatch({
                type: GET_ANNOUNCEMENT,
                announcement: response.data
            })
            odataLogs(response.config.url, response.status, response.config.method, GET_ANNOUNCEMENT, config)
        })
            .catch((err) => odataLogs(err.message, "ERROR", "", GET_ANNOUNCEMENT, config))
    }
}

// ** Add new Announcement
export const addAnnouncement = (announcement, categories, metas) => {
    return (dispatch, getState) => {
        api.announcementsControllerCreate(announcement).then(response => {
            dispatch({
                type: ADD_ANNOUNCEMENT,
                announcement: response.data
            })
            odataLogs(response.config.url, response.status, response.config.method, ADD_ANNOUNCEMENT, config)
            return response.data
        })
            .then((announcement) => {
                if (announcement.id && categories.length > 0) {
                    dispatch(createAnnouncementCategories(announcement.id, categories))
                }
                if (announcement.id && metas.length > 0) {
                    const metasList = metas.map(meta => {
                        return {
                            announcementId: announcement.id,
                            key: meta.id,
                            content: meta.name
                        }
                    })
                    dispatch(createAnnouncementMetas(announcement.id, metasList))
                }
                dispatch(getData(getParams(getState()) || []))
            })
            .catch((err) => odataLogs(err.message, "ERROR", "", ADD_ANNOUNCEMENT, config))

    }
}

// ** Update a Announcement
export const updateAnnouncement = (id, announcement, categories, metas) => {
    return (dispatch, getState) => {
        Promise.all([apiAnnouncementCategories.announcementsAnnouncementCategoryControllerDelete(id)]).then(async () => {
            try {
                const response = await api.announcementsControllerUpdateById(id, announcement)
                dispatch({
                    type: UPDATE_ANNOUNCEMENT,
                    announcement
                })
                odataLogs(response.config.url, response.status, response.config.method, UPDATE_ANNOUNCEMENT, config)
            } catch (err) {
                return odataLogs(err.message, "ERROR", "", UPDATE_ANNOUNCEMENT, config)
            }
        })
            .then(() => {
                if (categories.length > 0) {
                    dispatch(createAnnouncementCategories(id, categories))
                }
                if (metas.length > 0) {
                    const metasList = metas.map(meta => {
                        return {
                            announcementId: announcement.id,
                            key: meta.id,
                            content: meta.name
                        }
                    })
                    dispatch(createAnnouncementMetas(id, metasList))
                }
            })
            .then(() => {
                dispatch(getData(getParams(getState())))
            })
            .catch(err => console.error(err))
    }
}

// ** Delete a Announcements
export const deleteAnnouncement = (id) => {
    return (dispatch, getState) => {
        Promise.all([
            apiAnnouncementCategories.announcementsAnnouncementCategoryControllerDelete(id),
            apiAnnouncementMeta.announcementsAnnouncementMetaControllerDelete(id)
        ])
            .then(() => api.announcementsControllerDeleteById(id))
            .then(response => {
                dispatch({
                    type: DELETE_ANNOUNCEMENT
                })
                odataLogs(response.config.url, response.status, response.config.method, DELETE_ANNOUNCEMENT, config)
            })
            .then(() => {
                dispatch(getData(getParams(getState())))
            })
            .catch((err) => odataLogs(err.message, "ERROR", "", DELETE_ANNOUNCEMENT, config))
    }
}
