/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { NewLanguages, LanguagesControllerApi, LanguagesPartial, settings } from '@api/backend'
import { odataLogs, displaySuccessMsg } from '@utils'
import { FormattedMessage, useIntl } from 'react-intl'
const prefix = 'admin/languages'
const config = settings

export const GET_ALL_LANGUAGES = `${prefix}/GET_ALL_DATA`
export const GET_LANGUAGES = `${prefix}/GET_DATA`
export const GET_LANGUAGES_COUNT = `${prefix}/GET_DATA_COUNT`
export const GET_LANGUAGE = `${prefix}/GET_LANGUAGE`
export const ADD_LANGUAGE = `${prefix}/ADD`
export const UPDATE_LANGUAGE = `${prefix}/UPDATE`
export const DELETE_LANGUAGE = `${prefix}/DELETE`

const api = new LanguagesControllerApi(settings)
const getParams = (state) => state.admin.languages.params || []

// ** Get all Data
export const getAllData = () => {
  return async (dispatch) => {
    await api.languagesControllerFind().then(response => {
      dispatch({
        type: GET_ALL_LANGUAGES,
        data: response.data
      })
      odataLogs(response.config.url, response.status, response.config.method, GET_ALL_LANGUAGES, config)
    }).catch((err) => odataLogs(err.message, "ERROR", "", GET_ALL_LANGUAGES, config))
  }
}

// ** Get data on page or row change
export const getData = (params) => {
  return async (dispatch) => {
    const { page, perPage, q, sortBy } = params

    const filter = {
      offset: (page - 1) * perPage,
      limit: perPage,
      skip: ((page - 1) * perPage),
      order: sortBy || "name ASC",
      where: {
        ['name']: { like: `%${q}%` }
      }
    }

    await api.languagesControllerFind(JSON.stringify(filter)).then(response => {
      dispatch({
        type: GET_LANGUAGES,
        data: response.data,
        params
      })
      odataLogs(response.config.url, response.status, response.config.method, GET_LANGUAGES, config)
    }).catch((err) => odataLogs(err.message, "ERROR", "", GET_LANGUAGES, config))

    await api.languagesControllerCount(JSON.stringify(filter.where)).then(response => {
      dispatch({
        type: GET_LANGUAGES_COUNT,
        totalPages: response.data.count || 0
      })
      odataLogs(response.config.url, response.status, response.config.method, GET_LANGUAGES_COUNT, config)
    }).catch((err) => odataLogs(err.message, "ERROR", "", GET_LANGUAGES_COUNT, config))
  }
}

// ** Get Language
export const getLanguage = (id) => {
  return async (dispatch) => {
    await api.languagesControllerFindById(id).then(response => {
      dispatch({
        type: GET_LANGUAGE,
        selectedLanguage: response.data
      })
      odataLogs(response.config.url, response.status, response.config.method, GET_LANGUAGE, config)
    })
      .catch((err) => odataLogs(err.message, "ERROR", "", GET_LANGUAGE, config))
  }
}

// ** Add new language
export const addLanguage = (language, intl) => {
  return (dispatch, getState) => {
    api.languagesControllerCreate(language).then(response => {
      dispatch({
        type: ADD_LANGUAGE,
        language
      })
      odataLogs(response.config.url, response.status, response.config.method, ADD_LANGUAGE, config)
    })
      .then(() => {
        displaySuccessMsg(intl.formatMessage({ id: "Role created"}))
        dispatch(getData(getParams(getState())))
        dispatch(getAllData())
      })
      .catch((err) => odataLogs(err.message, "ERROR", "", ADD_LANGUAGE, config))

  }
}

// ** Update a  language
export const updateLanguage = (id, language, intl) => {
  return (dispatch, getState) => {
    api.languagesControllerUpdateById(id, language).then(response => {
      dispatch({
        type: UPDATE_LANGUAGE,
        language
      })
      odataLogs(response.config.url, response.status, response.config.method, UPDATE_LANGUAGE, config)
    })
      .then(() => {
        displaySuccessMsg(intl.formatMessage({ id: "Updated role"}))
        dispatch(getData(getParams(getState())))
        dispatch(getAllData())
      })
      .catch((err) => odataLogs(err.message, "ERROR", "", UPDATE_LANGUAGE, config))

  }
}

// ** Delete language
export const deleteLanguage = (id, intl) => {
  return (dispatch, getState) => {

    api
      .languagesControllerDeleteById(id)
      .then(response => {
        dispatch({
          type: DELETE_LANGUAGE
        })
        odataLogs(response.config.url, response.status, response.config.method, DELETE_LANGUAGE, config)
      })
      .then(() => {
        displaySuccessMsg(intl.formatMessage({ id: "Rol eliminado"}))
        dispatch(getData(getParams(getState())))
        dispatch(getAllData())
      })
      .catch((err) => odataLogs(err.message, "ERROR", "", DELETE_LANGUAGE, config))

  }
}
