import { GET_ANNOUNCEMENT, GET_ANNOUNCEMENTS, GET_ANNOUNCEMENTS_COUNT, GET_ANNOUNCEMENTS_RECENT, GET_CATEGORIES } from '../action'

// ** Initial State
const initialState = {
    allData: [],
    data: [],
    categories: [],
    total: 1,
    params: {},
    recentAnnouncements: [],
    announcement: undefined,
    processing: false
}

const announcements = (state = initialState, action) => {
    switch (action.type) {
        // case `${prefix}/GET_ALL_DATA`:
        //     return { ...state, allData: action.data }
        case GET_ANNOUNCEMENTS:
            return {
                ...state,
                data: action.data,
                params: action.params
            }
        case GET_ANNOUNCEMENTS_COUNT:
            return {
                ...state,
                total: action.totalPages
            }
        case GET_ANNOUNCEMENTS_RECENT:
            return {
                ...state,
                recentAnnouncements: action.data
            }
        case GET_ANNOUNCEMENT:
            const [announcement] = action.announcement
            return { ...state, announcement }
        case GET_CATEGORIES:
            return {
                ...state,
                categories: action.categories
            }
        default:
            return { ...state }
    }
}
export default announcements
