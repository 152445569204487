import { ADD_ROLE, DELETE_ROLE, GET_ALL_ROLES, GET_ROLE, GET_ROLES, GET_ROLES_COUNT, UPDATE_ROLE } from '../action'

// ** Initial State
const initialState = {
  allData: [],
  data: [],
  total: 1,
  params: {},
  selectedRole: null
}

const roles = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_ROLES:
      return { ...state, allData: action.data }
    case GET_ROLES:
      return {
        ...state,
        data: action.data,
        params: action.params
      }
    case GET_ROLES_COUNT:
      return {
        ...state,
        total: action.totalPages
      }
    case GET_ROLE:
      return { ...state, selectedRole: action.selectedRole }
    case ADD_ROLE:
      return { ...state }
    case DELETE_ROLE:
      return { ...state }
    case UPDATE_ROLE:
      return { ...state }
    default:
      return { ...state }
  }
}
export default roles
