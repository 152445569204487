import { INIT_REQUEST, GET_PRODUCTO_DISPONIBLE, GET_PRODUCTOS_DISPONIBLES, GET_PRODUCTOS_DISPONIBLES_COUNT, GET_ALL_PRODUCTOS_DISPONIBLES, GET_FILTROS_PRODUCTOS_DISPONIBLES } from '../action'
// ** Initial State
const initialState = {
    allData: [],
    data: [],
    total: 1,
    params: {},
    filtros: {},
    processing: false
}

const productosdisponibles = (state = initialState, action) => {
    switch (action.type) {
        case INIT_REQUEST:
            return {
            ...state,
            processing: true
          }
        case GET_PRODUCTOS_DISPONIBLES:
            return {
                ...state,
                data: action.data,
                params: action.params,
                processing: false
            }
        case GET_PRODUCTOS_DISPONIBLES_COUNT:
            return {
                ...state,
                total: action.totalPages
            }
        case GET_PRODUCTO_DISPONIBLE:
            return { ...state, sat: action.sat }
        case GET_ALL_PRODUCTOS_DISPONIBLES:
            return {
                ...state,
                allData: action.data
            }
        case GET_FILTROS_PRODUCTOS_DISPONIBLES:
            return {
                ...state,
                filtros: action.filtros
            }
        default:
            return { ...state }
    }
}
export default productosdisponibles
