import { ADD_COMPANY, DELETE_COMPANY, GET_COMPANY, GET_COMPANIES, INIT_COMPANY, INIT_REQUEST, GET_COMPANIES_COUNT, GET_USER_COMPANY } from "../action"

// ** Initial State
const initialState = {
  allData: [],
  data: [],
  total: 1,
  params: {},
  selectedCompany: null,
  userCompany: null,
  processing: false
}

const prefix = 'admin/empresas'

const companies = (state = initialState, action) => {
  switch (action.type) {
    case `${prefix}/GET_ALL_DATA`:
      return { ...state, allData: action.data }
    case INIT_REQUEST:
      return {
        ...state,
        processing: true
      }
    case INIT_COMPANY:
      return { ...state, selectedCompany: {} }
    case GET_COMPANIES:
      return {
        ...state,
        data: action.data,
        total: action.data.length || 0,
        //params: action.params,
        processing: false
      }
    case GET_COMPANY:
      return { ...state, selectedCompany: action.selectedCompany }
    case GET_USER_COMPANY:
    return { ...state, userCompany: action.data }
    case ADD_COMPANY:
      return { ...state }
    case DELETE_COMPANY:
      return { ...state }
    case GET_COMPANIES_COUNT:
      return { ...state, total: action.total }
    default:
      return { ...state }
  }
}
export default companies
