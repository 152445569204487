/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { settings, PromotionsControllerApi } from '@api/backend'
import { odataLogs } from '@utils'

const prefix = 'promotion'

export const INIT_PROMOTION = `${prefix}/INIT_PROMOTION`
export const GET_PROMOTION = `${prefix}/GET_PROMOTION`
export const GET_PROMOTIONS = `${prefix}/GET_DATA`
export const GET_PROMOTIONS_COUNT = `${prefix}/GET_DATA_COUNT`
export const GET_PROMOTIONS_SPECIAL = `${prefix}/GET_PROMOTIONS_SPECIAL`
export const INIT_REQUEST = `${prefix}/INIT_REQUEST`
const config = settings
const api = new PromotionsControllerApi(settings)

// ** Get data on page or row change
export const getData = (params) => {
    return async (dispatch) => {
        const { page = 1, perPage = 30, q = '', sortBy } = params
        const filter = {
            offset: (page - 1) * perPage,
            limit: perPage,
            skip: ((page - 1) * perPage),
            order: sortBy || "createdAt DESC",
            where: { ['title']: { like: `%${q}%` } },
            include: [
                { relation: 'promotionMetas' },
                { relation: 'author'}
            ]
        }

        await api.promotionsControllerFind(JSON.stringify(filter)).then(response => {
            dispatch({
                type: GET_PROMOTIONS,
                data: response.data,
                params
            })
            odataLogs(response.config.url, response.status, response.config.method, GET_PROMOTIONS, config)
        }).catch((err) => odataLogs(err.message, "ERROR", "", GET_PROMOTIONS, config))

        await api.promotionsControllerCount(JSON.stringify(filter.where)).then(response => {
            dispatch({
                type: GET_PROMOTIONS_COUNT,
                totalPages: response.data.count || 0
            })
            odataLogs(response.config.url, response.status, response.config.method, GET_PROMOTIONS_COUNT, config)
        }).catch((err) => odataLogs(err.message, "ERROR", "", GET_PROMOTIONS_COUNT, config))
    }
}

// ** Get special promotions on page or row change
export const getSpecialPromotions = (params) => {
    return async (dispatch) => {
        const { page = 1, perPage = 30, q = '', sortBy } = params
        const filter = {
            offset: (page - 1) * perPage,
            limit: perPage,
            skip: ((page - 1) * perPage),
            order: sortBy || "publishedAt DESC",
            where: {
                ['title']: { like: `%${q}%` },
                ['special']: { eq: 1 }
            },
            include: [
                {
                    relation: 'promotionMetas'
                },
                {
                    relation: 'author'
                }
            ]
        }

        await api.promotionsControllerFind(JSON.stringify(filter)).then(response => {
            dispatch({
                type: GET_PROMOTIONS_SPECIAL,
                data: response.data,
                params
            })
            odataLogs(response.config.url, response.status, response.config.method, GET_PROMOTIONS_SPECIAL, config)
        }).catch((err) => odataLogs(err.message, "ERROR", "", GET_PROMOTIONS_SPECIAL, config))

    }
}

// ** Init Promotion
export const initPromotion = () => {
    return async (dispatch) => {
        dispatch({
            type: GET_PROMOTION
        })
    }
}

// ** Get Promotion
export const getPromotion = (id) => {
    return async (dispatch) => {
        const filter = {
            include: [
                {
                    relation: 'author'
                },
                {
                    relation: 'promotionMetas'
                }
            ]
        }
        await api.promotionsControllerFindById(id, JSON.stringify(filter)).then(response => {
            dispatch({
                type: GET_PROMOTION,
                promotion: response.data
            })
            odataLogs(response.config.url, response.status, response.config.method, GET_PROMOTION, config)
        })
        .catch((err) => odataLogs(err.message, "ERROR", "", GET_PROMOTION, config))
    }
}

// ** Get Promotion by slug
export const getPromotionBySlug = (slug) => {
    return async (dispatch) => {
        const filter = {
            where: {
                ['slug']: { eq: slug }
            },
            include: [
                {
                    relation: 'author'
                },
                {
                    relation: 'promotionMetas'
                }
            ]
        }
        await api.promotionsControllerFind(JSON.stringify(filter)).then(response => {
            const [promotion] = response.data
            dispatch({
                type: GET_PROMOTION,
                promotion
            })
            odataLogs(response.config.url, response.status, response.config.method, GET_PROMOTION, config)
        })
        .catch((err) => odataLogs(err.message, "ERROR", "", GET_PROMOTION, config))
    }
}

export const updatePromotionBySlug = (slug, cliente) => {
    return async () => {
        const filter = {
            where: {
                ['slug']: { eq: slug }
            },
            include: [
                {
                    relation: 'author'
                },
                {
                    relation: 'promotionMetas'
                }
            ]
        }
        await api.promotionsControllerFind(JSON.stringify(filter)).then(async response => {
            const [promotion] = response.data
            const visto = promotion.vistoPor || ''
            if (!visto.includes(cliente)) {
                const newPromotion = promotion
                newPromotion.vistoPor = visto.concat(';').concat(cliente)
                if (newPromotion.parentId == null) { newPromotion.parentId = 0 }
                newPromotion.metaTitle = newPromotion.metaTitle?.toString()
                delete newPromotion.author
                delete newPromotion.promotionMetas
                await api.promotionsControllerUpdateById(promotion.id, newPromotion)
            }
        })
        .catch((err) => odataLogs(err.message, "ERROR", "", GET_PROMOTION, config))
    }
}
