/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { settings, ConexionSatControllerApi, NavisionControllerApi, UsersControllerApi } from '@api/backend'
import { useSelector } from 'react-redux'
import { odataLogs, deGMTaFechaLocal } from '@utils'
import { getUserData } from '@src/auth/utils'
const prefix = 'admin/sats'
const config = settings
export const GET_SAT = `${prefix}/GET_DATA`
export const GET_SATS = `${prefix}/GET_DATAS`
export const GET_SATS_COUNT = `${prefix}/GET_DATA_COUNT`
export const GET_ALL_SATS = `${prefix}/GET_ALL_DATAS`
export const GET_FILTROS_SATS = `${prefix}/GET_FILTROS_DATAS`

const api = new UsersControllerApi(settings)
const apiSat = new ConexionSatControllerApi(settings)
const apiNavisionNew = new NavisionControllerApi(settings)

// ** Get data on page or row change
export const getData = (params) => {

    return async (dispatch) => {
        const { page = 1, perPage = 10, q = '', sortBy, search = '', role, status, tipoCheck } = params
        //const user = getUserData();
        const currentSAT = localStorage.getItem("currentSat")

        if (currentSAT !== '' && currentSAT !== 'null') {
            //const filtro = `$filter=_msdyn_preferredresource_value eq ${user.sat}${filtroCheck}`
            const filtro = `$filter=_msdyn_preferredresource_value eq ${currentSAT}`
            const limit = perPage
            const skip = ((page - 1) * perPage)
            // const order = sortBy || "createdAt DESC"
            const order = "&$orderby=createdon desc"
            const select = "?$select=msdyn_name,msdyn_systemstatus, createdon, msdyn_address1, msdyn_postalcode, svan_satobservations, _msdyn_preferredresource_value, msdyn_city,svan_repairable,_svan_responsiblesattechnician_value,\
                                    svan_ordersparepart,svan_ownsparepart,svan_sparepartshipped,_msdyn_substatus_value,_msdyn_pricelist_value,svan_refusalreason,svan_substatus,_msdyn_primaryincidenttype_value,\
                                    msdyn_stateorprovince&$expand=msdyn_serviceterritory($select=name),\
                                    svan_responsiblecontactid($select=fullname,telephone1,mobilephone),\
                                    msdyn_serviceaccount($select=name,telephone1),\
                                    msdyn_msdyn_workorder_bookableresourcebooking_WorkOrder($select=_bookingstatus_value,duration,endtime,starttime,svan_notes,createdon;$orderby=createdon desc;$top=1),\
                                    msdyn_billingaccount($select=name),\
                                    msdyn_servicerequest($select=title,ticketnumber,svan_invoiceid,productserialnumber,svan_invoicedate,svan_guarantee,svan_extraguarantee,svan_relapse,description,statuscode;$expand=productid($select=name,productnumber))";
            await apiSat.conexionSatControllerGetendPoint("msdyn_workorders", select, filtro, limit, skip, order).then(response => {

                response.data.value.map(element => {
                    element.createdon = element.createdon ? deGMTaFechaLocal(element.createdon) : ''
                    element.msdyn_msdyn_workorder_bookableresourcebooking_WorkOrder_0_createdon = element.msdyn_msdyn_workorder_bookableresourcebooking_WorkOrder_0_createdon ? deGMTaFechaLocal(element.msdyn_msdyn_workorder_bookableresourcebooking_WorkOrder_0_createdon) : ''
                    element.msdyn_msdyn_workorder_bookableresourcebooking_WorkOrder_0_endtime = element.msdyn_msdyn_workorder_bookableresourcebooking_WorkOrder_0_endtime ? deGMTaFechaLocal(element.msdyn_msdyn_workorder_bookableresourcebooking_WorkOrder_0_endtime) : ''
                    element.msdyn_msdyn_workorder_bookableresourcebooking_WorkOrder_0_starttime = element.msdyn_msdyn_workorder_bookableresourcebooking_WorkOrder_0_starttime ? deGMTaFechaLocal(element.msdyn_msdyn_workorder_bookableresourcebooking_WorkOrder_0_starttime) : ''
                    element['createdon@OData.Community.Display.V1.FormattedValue'] = element['createdon@OData.Community.Display.V1.FormattedValue'] ? deGMTaFechaLocal(element['createdon@OData.Community.Display.V1.FormattedValue']) : ''
                    element['msdyn_msdyn_workorder_bookableresourcebooking_WorkOrder_0_createdon@OData.Community.Display.V1.FormattedValue'] = element['msdyn_msdyn_workorder_bookableresourcebooking_WorkOrder_0_createdon@OData.Community.Display.V1.FormattedValue'] ? deGMTaFechaLocal(element['msdyn_msdyn_workorder_bookableresourcebooking_WorkOrder_0_createdon@OData.Community.Display.V1.FormattedValue']) : ''
                    element['msdyn_msdyn_workorder_bookableresourcebooking_WorkOrder_0_endtime@OData.Community.Display.V1.FormattedValue'] = element['msdyn_msdyn_workorder_bookableresourcebooking_WorkOrder_0_endtime@OData.Community.Display.V1.FormattedValue'] ? deGMTaFechaLocal(element['msdyn_msdyn_workorder_bookableresourcebooking_WorkOrder_0_endtime@OData.Community.Display.V1.FormattedValue']) : ''
                    element['msdyn_msdyn_workorder_bookableresourcebooking_WorkOrder_0_starttime@OData.Community.Display.V1.FormattedValue'] = element['msdyn_msdyn_workorder_bookableresourcebooking_WorkOrder_0_starttime@OData.Community.Display.V1.FormattedValue'] ? deGMTaFechaLocal(element['msdyn_msdyn_workorder_bookableresourcebooking_WorkOrder_0_starttime@OData.Community.Display.V1.FormattedValue']) : ''
               });

                //Obtenemos todas las Ordenes de trabajo y filtramos para quitar las canceladas y las de problema resuelto
                const quitamosCanceladosYResueltos = response.data.value.filter(obj => obj['msdyn_servicerequest_statuscode@OData.Community.Display.V1.FormattedValue'] !== 'Problema resuelto' && obj['msdyn_servicerequest_statuscode@OData.Community.Display.V1.FormattedValue'] !== 'Cancelado');
                
                //Obtenemos todas las Ordenes de trabajo y filtramos solo las ordenes que NO estan liquidadas
                const todasLasNOLiquidadas = quitamosCanceladosYResueltos.filter(obj => obj.msdyn_systemstatus !== 690970004)
                const NoLoquidadasPaginadas = todasLasNOLiquidadas.slice(0, limit); // -> Filtra solo primera pagina
                console.log('Ordenes de trabajo: ', NoLoquidadasPaginadas)

                //Guardamos los datos que se van a mostrar en la pagina
                dispatch({
                    type: GET_SATS,
                    data: NoLoquidadasPaginadas,
                    params
                })

                //Guardamos el numero de registros
                dispatch({
                    type: GET_SATS_COUNT,
                    totalPages: todasLasNOLiquidadas.length || 0
                    //totalPages: response.data.contador || 0
                })

                //Guardamos todas las Ordenes de trabajo//
                dispatch({
                    type: GET_ALL_SATS,
                    data: response.data.value
                })

                odataLogs(response.config.url, response.status, response.config.method, GET_SATS, config)
            }).catch((err) => odataLogs(err.message, "ERROR", "", GET_SATS, config))
        } else {
            // -> Si NO tiene SAT guardamos la lista de ordenes vacia
            dispatch({
                type: GET_SATS,
                data: [], // 
                params
            })

            dispatch({
                type: GET_SATS_COUNT,
                totalPages: 0
            })
        }

    }
}

export const getSATsBD = (uidSat) => {
    return async () => {
        localStorage.setItem("currentSat", uidSat);
        const filter = {
            where: {
                ['sat']: { neq: null } // Verifica que 'SAT' no sea null
            },
            fields: { id: true, userName: true, sat: true, tipoUsuario: true, codVendedorCliente: true, clienteDefecto: true, tarifaDefecto: true }
        }
        return await api.usersControllerFind(JSON.stringify(filter))
    }
}

export const cambiarPagina = (params) => {
    return async (dispatch) => {
        const { datos } = params

        //Obtenemos todas las Ordenes de trabajo y filtramos para quitar las canceladas y las de problema resuelto
        const quitamosCanceladosYResueltos = datos.filter(obj => obj['msdyn_servicerequest_statuscode@OData.Community.Display.V1.FormattedValue'] !== 'Problema resuelto' && obj['msdyn_servicerequest_statuscode@OData.Community.Display.V1.FormattedValue'] !== 'Cancelado');

        //Guardamos los datos que se van a mostrar en la pagina
        dispatch({
            type: GET_SATS,
            data: quitamosCanceladosYResueltos,
            params
        })
    }
}

export const datosFiltroInputsYCheckbox = (params) => {
    return async (dispatch) => {
        const { datos, perPage, filtrosBusqueda } = params
        const limit = perPage

        //Obtenemos todas las Ordenes de trabajo y filtramos para quitar las canceladas y las de problema resuelto
        const quitamosCanceladosYResueltos = datos.filter(obj => obj['msdyn_servicerequest_statuscode@OData.Community.Display.V1.FormattedValue'] !== 'Problema resuelto' && obj['msdyn_servicerequest_statuscode@OData.Community.Display.V1.FormattedValue'] !== 'Cancelado');

        const datosFiltrados = quitamosCanceladosYResueltos.slice(0, limit); // -> Filtra solo primera pagina

        //Guardamos los datos que se van a mostrar en la pagina
        dispatch({
            type: GET_SATS,
            data: datosFiltrados
            // params
        })

        //Guardamos el numero de registros
        dispatch({
            type: GET_SATS_COUNT,
            totalPages: datos.length || 0
        })

        //Guardamos los filtros de busqueda (Inputs)
        dispatch({
            type: GET_FILTROS_SATS,
            filtros: filtrosBusqueda
        })
    }
}

export const muestraPendientesAceptacion = (params) => {
    const user = getUserData();
    return async (dispatch) => {

        const { page = 1, perPage = 30, q = '', sortBy } = params

        const filter = {
            offset: (page - 1) * perPage,
            limit: perPage,
            skip: ((page - 1) * perPage),
            order: sortBy || "",
            where: {
                ['title']: { like: `%${q}%` }
            }
        }

        await apiSat.conexionSatControllerGetendPoint("msdyn_workorders", `?_msdyn_preferredresource_value eq ${user.sat}`).then(response => {
            console.log(dispatch({
                type: GET_SATS,
                data: response.data.value,
                params
            }))
            odataLogs(response.config.url, response.status, response.config.method, GET_SATS, config)
        }).catch((err) => odataLogs(err.message, "ERROR", "", GET_SATS, config))

        await apiSat.conexionSatControllerGetendPoint("msdyn_workorders", `?_msdyn_preferredresource_value eq ${user.sat}`).then(response => {
            dispatch({
                type: GET_SATS_COUNT,
                totalPages: response.data.value.length || 0
            })
            /*    odataLogs(response.config.url, response.status, response.config.method, GET_SATS_COUNT, config)*/
        }).catch((err) => odataLogs(err.message, "ERROR", "", GET_SATS_COUNT, config))
    }
}

export const updateSatWorkorders = (id, jsonaEnviar) => {
    return async () => {
        return await apiSat.conexionSatControllerPatch("msdyn_workorders", `(${id})`, jsonaEnviar)
    }
}

export const updateIncidents = (id, jsonaEnviar) => {
    return async () => {
        return await apiSat.conexionSatControllerPatch("incidents", `(${id})`, jsonaEnviar)
    }
}

export const getArchivosByCaso = (idCaso) => {
    return async () => {
        return await apiSat.conexionSatControllerGetArchivos(idCaso)
    }
}

export const subirArchivoASharePoint = (idCaso, archivo) => {
    return async () => {
        return await apiSat.conexionSatControllerSubirArchivo(idCaso, archivo);
    }
}

export const eliminarArchivoASharePoint = (idCaso, archivo) => {
    return async () => {
        return await apiSat.conexionSatControllerDeleteArchivo(idCaso, archivo);
    }
}

export const descargarArchivoASharePoint = (idCaso, archivo) => {
    return async () => {
        return await apiSat.conexionSatControllerDownloadArchivo(idCaso, archivo);
    }
}

export const getReservas = (params) => {
    return async () => {
        const { page = 1, perPage = 10, satId } = params;
        console.log('estoy en getReservas API: satId: ', satId, 'por pagina: ', perPage)
        const select = "?$select=starttime,endtime,duration,svan_notes,_bookingstatus_value";
        const filtro = `$filter=_msdyn_workorder_value eq ${satId}`
        const limit = perPage
        const skip = ((page - 1) * perPage)
        // const order = "createdAt DESC"
        const order = ""

        return await apiSat.conexionSatControllerGetendPoint("bookableresourcebookings", select, filtro, limit, skip, order).then(response => {

            response.data.value.map(element => {
                element.createdon = element.createdon ? deGMTaFechaLocal(element.createdon) : ''
                element.endtime = element.endtime ? deGMTaFechaLocal(element.endtime) : ''
                element.starttime = element.starttime ? deGMTaFechaLocal(element.starttime) : ''
                element['starttime@OData.Community.Display.V1.FormattedValue'] = element['starttime@OData.Community.Display.V1.FormattedValue'] ? deGMTaFechaLocal(element['starttime@OData.Community.Display.V1.FormattedValue']) : ''
                element['endtime@OData.Community.Display.V1.FormattedValue'] = element['endtime@OData.Community.Display.V1.FormattedValue'] ? deGMTaFechaLocal(element['endtime@OData.Community.Display.V1.FormattedValue']) : ''
                element.msdyn_msdyn_workorder_bookableresourcebooking_WorkOrder_0_createdon = element.msdyn_msdyn_workorder_bookableresourcebooking_WorkOrder_0_createdon ? deGMTaFechaLocal(element.msdyn_msdyn_workorder_bookableresourcebooking_WorkOrder_0_createdon) : ''
                element.msdyn_msdyn_workorder_bookableresourcebooking_WorkOrder_0_endtime = element.msdyn_msdyn_workorder_bookableresourcebooking_WorkOrder_0_endtime ? deGMTaFechaLocal(element.msdyn_msdyn_workorder_bookableresourcebooking_WorkOrder_0_endtime) : ''
                element.msdyn_msdyn_workorder_bookableresourcebooking_WorkOrder_0_starttime = element.msdyn_msdyn_workorder_bookableresourcebooking_WorkOrder_0_starttime ? deGMTaFechaLocal(element.msdyn_msdyn_workorder_bookableresourcebooking_WorkOrder_0_starttime) : ''
                element['createdon@OData.Community.Display.V1.FormattedValue'] = element['createdon@OData.Community.Display.V1.FormattedValue'] ? deGMTaFechaLocal(element['createdon@OData.Community.Display.V1.FormattedValue']) : ''
                element['msdyn_msdyn_workorder_bookableresourcebooking_WorkOrder_0_createdon@OData.Community.Display.V1.FormattedValue'] = element['msdyn_msdyn_workorder_bookableresourcebooking_WorkOrder_0_createdon@OData.Community.Display.V1.FormattedValue'] ? deGMTaFechaLocal(element['msdyn_msdyn_workorder_bookableresourcebooking_WorkOrder_0_createdon@OData.Community.Display.V1.FormattedValue']) : ''
                element['msdyn_msdyn_workorder_bookableresourcebooking_WorkOrder_0_endtime@OData.Community.Display.V1.FormattedValue'] = element['msdyn_msdyn_workorder_bookableresourcebooking_WorkOrder_0_endtime@OData.Community.Display.V1.FormattedValue'] ? deGMTaFechaLocal(element['msdyn_msdyn_workorder_bookableresourcebooking_WorkOrder_0_endtime@OData.Community.Display.V1.FormattedValue']) : ''
                element['msdyn_msdyn_workorder_bookableresourcebooking_WorkOrder_0_starttime@OData.Community.Display.V1.FormattedValue'] = element['msdyn_msdyn_workorder_bookableresourcebooking_WorkOrder_0_starttime@OData.Community.Display.V1.FormattedValue'] ? deGMTaFechaLocal(element['msdyn_msdyn_workorder_bookableresourcebooking_WorkOrder_0_starttime@OData.Community.Display.V1.FormattedValue']) : ''
           });
           return response
        })
    }
}

export const crearReserva = (fechaInicio, fechaFin, recurso, ordenDeTrabajo, notas) => {
    return async () => {
        return await apiSat.conexionSatControllerReserva(fechaInicio, fechaFin, recurso, ordenDeTrabajo, notas);
    }
}

export const actualizarReserva = (id, jsonaEnviar) => {
    return async () => {
        return await apiSat.conexionSatControllerPatch("bookableresourcebookings", `(${id})`, jsonaEnviar)
    }
}

export const getEstadosDeLaReserva = () => {
    return async () => {
        const select = "?$select=name,bookingstatusid";
        const orderBy = "&$orderby=name%20asc";
        return await apiSat.conexionSatControllerGetEndpointReservas("bookingstatuses", select, "", orderBy, "")
    }
}

export const getUltimaReserva = (id) => {
    return async () => {
        const select = "?$select=_bookingstatus_value,duration,endtime,starttime,svan_notes,createdon";
        const filter = `&$filter=(_msdyn_workorder_value eq ${id})`;
        const orderBy = "&$orderby=createdon desc";
        const top = "&$top=1";
        return await apiSat.conexionSatControllerGetEndpointReservas("bookableresourcebookings", select, filter, orderBy, top).then(response => {

            response.data.value.map(element => {
                element.createdon = element.createdon ? deGMTaFechaLocal(element.createdon) : ''
                element.endtime = element.endtime ? deGMTaFechaLocal(element.endtime) : ''
                element.starttime = element.starttime ? deGMTaFechaLocal(element.starttime) : ''
                element['starttime@OData.Community.Display.V1.FormattedValue'] = element['starttime@OData.Community.Display.V1.FormattedValue'] ? deGMTaFechaLocal(element['starttime@OData.Community.Display.V1.FormattedValue']) : ''
                element['endtime@OData.Community.Display.V1.FormattedValue'] = element['endtime@OData.Community.Display.V1.FormattedValue'] ? deGMTaFechaLocal(element['endtime@OData.Community.Display.V1.FormattedValue']) : ''
                element.msdyn_msdyn_workorder_bookableresourcebooking_WorkOrder_0_createdon = element.msdyn_msdyn_workorder_bookableresourcebooking_WorkOrder_0_createdon ? deGMTaFechaLocal(element.msdyn_msdyn_workorder_bookableresourcebooking_WorkOrder_0_createdon) : ''
                element.msdyn_msdyn_workorder_bookableresourcebooking_WorkOrder_0_endtime = element.msdyn_msdyn_workorder_bookableresourcebooking_WorkOrder_0_endtime ? deGMTaFechaLocal(element.msdyn_msdyn_workorder_bookableresourcebooking_WorkOrder_0_endtime) : ''
                element.msdyn_msdyn_workorder_bookableresourcebooking_WorkOrder_0_starttime = element.msdyn_msdyn_workorder_bookableresourcebooking_WorkOrder_0_starttime ? deGMTaFechaLocal(element.msdyn_msdyn_workorder_bookableresourcebooking_WorkOrder_0_starttime) : ''
                element['createdon@OData.Community.Display.V1.FormattedValue'] = element['createdon@OData.Community.Display.V1.FormattedValue'] ? deGMTaFechaLocal(element['createdon@OData.Community.Display.V1.FormattedValue']) : ''
                element['msdyn_msdyn_workorder_bookableresourcebooking_WorkOrder_0_createdon@OData.Community.Display.V1.FormattedValue'] = element['msdyn_msdyn_workorder_bookableresourcebooking_WorkOrder_0_createdon@OData.Community.Display.V1.FormattedValue'] ? deGMTaFechaLocal(element['msdyn_msdyn_workorder_bookableresourcebooking_WorkOrder_0_createdon@OData.Community.Display.V1.FormattedValue']) : ''
                element['msdyn_msdyn_workorder_bookableresourcebooking_WorkOrder_0_endtime@OData.Community.Display.V1.FormattedValue'] = element['msdyn_msdyn_workorder_bookableresourcebooking_WorkOrder_0_endtime@OData.Community.Display.V1.FormattedValue'] ? deGMTaFechaLocal(element['msdyn_msdyn_workorder_bookableresourcebooking_WorkOrder_0_endtime@OData.Community.Display.V1.FormattedValue']) : ''
                element['msdyn_msdyn_workorder_bookableresourcebooking_WorkOrder_0_starttime@OData.Community.Display.V1.FormattedValue'] = element['msdyn_msdyn_workorder_bookableresourcebooking_WorkOrder_0_starttime@OData.Community.Display.V1.FormattedValue'] ? deGMTaFechaLocal(element['msdyn_msdyn_workorder_bookableresourcebooking_WorkOrder_0_starttime@OData.Community.Display.V1.FormattedValue']) : ''
           });
           return response
        })
    }
}

export const getSubEstados = () => {
    return async () => {
        const select = "?$select=msdyn_name,msdyn_workordersubstatusid";
        const filter = `&$filter=(msdyn_systemstatus eq 690970003 and msdyn_workordersubstatusid eq 'da10cd31-97e9-ed11-8849-000d3ad7d42c' or msdyn_workordersubstatusid eq 'a5c53828-97e9-ed11-8849-000d3ad7d42c')`;
        const orderBy = "&$orderby=msdyn_name asc";
        return await apiSat.conexionSatControllerGetEndpointReservas("msdyn_workordersubstatuses", select, filter, orderBy, "")
    }
}

// export const getListGeneralServiciosYCostes = (id) => {
//     return async () => {
//         const select = "?$select=msdyn_workorderid&$expand=msdyn_pricelist($select=pricelevelid;$expand=price_level_product_price_levels($select=amount,productpricelevelid;$expand=productid($select=name)))";
//         const filter = `&$filter=(msdyn_workorderid  eq ${id}) and (msdyn_pricelist/pricelevelid ne null)`;
//         const orderBy = "&$orderby=msdyn_workorderid asc";
//         const top = "";
//         return await apiSat.conexionSatControllerGetEndpointReservas("msdyn_workorders", select, filter, orderBy, top)
//     }
// }

export const getListGeneralServiciosYCostesParteUno = (id) => { //-> (1/3)
    return async () => {
        const select = "?$select=msdyn_servicerequest&$expand=msdyn_servicerequest($select=productid;$expand=productid($select=_svan_characteristicid_value))";
        const filter = `&$filter=(msdyn_workorderid eq ${id}) and (msdyn_servicerequest/incidentid ne null)`;
        const orderBy = "";
        const top = "";
        return await apiSat.conexionSatControllerGetEndpointReservas("msdyn_workorders", select, filter, orderBy, top)
    }
}

export const getListGeneralServiciosYCostesParteDos = (characteristicId) => { //-> (2/3)
    return async () => {
        const select = "?$select=productid";
        const filter = `&$filter=(characteristicid eq ${characteristicId})`;
        const orderBy = "";
        const top = "";
        return await apiSat.conexionSatControllerGetEndpointReservas("svan_product_characteristicset", select, filter, orderBy, top)
    }
}

export const getListGeneralServiciosYCostesParteTres = (idPriceList, listProductId) => { //-> (3/3)
    return async () => {
        const listaComoString = JSON.stringify(listProductId);
        const select = "?$select=svan_orderno,_uomid_value,_pricelevelid_value,pricingmethodcode,amount,_productid_value&$expand=productid($select=name,svan_isstandard)";
        const filter = `&$filter=(_pricelevelid_value eq ${idPriceList} and Microsoft.Dynamics.CRM.In(PropertyName='productid',PropertyValues=${listaComoString})) and (productid/productid ne null)`;
        const orderBy = "&$orderby=svan_orderno asc";
        const top = "";
        return await apiSat.conexionSatControllerGetEndpointReservas("productpricelevels", select, filter, orderBy, top)
    }
}

export const getServiciosExistentesOrdenTrabajo = (id) => {
    return async () => {
        const select = "?$select=msdyn_totalamount,msdyn_description&$expand=msdyn_service($select=name, productid, svan_isstandard)";
        const filter = `&$filter=(_msdyn_workorder_value eq ${id})`;
        const orderBy = "";
        const top = "";
        return await apiSat.conexionSatControllerGetEndpointReservas("msdyn_workorderservices", select, filter, orderBy, top)
    }
}

export const crearServicio = (servicio, idOrdenTrabajo, idListaPreciosOrden, servicioExtra, descripcionExtra, costoExtra) => {
    return async () => {
        return await apiSat.conexionSatControllerServicio(servicio, idOrdenTrabajo, idListaPreciosOrden, servicioExtra, descripcionExtra, costoExtra);
    }
}

export const eliminarServicio = (workorderServiceId) => {
    return async () => {
        return await apiSat.conexionSatControllerDeleteServicio(workorderServiceId);
    }
}

export const actualizarServicioExtra = (id, jsonaEnviar) => {
    return async () => {
        return await apiSat.conexionSatControllerPatch("msdyn_workorderservices", `(${id})`, jsonaEnviar)
    }
}

export const getDatosOrdenFinalizada = (idIncident) => {
    return async () => {
        const select = "?$select=incidentid, title, prioritycode, svan_directcustomer, _customerid_value,responsiblecontactid,\
                        casetypecode, svan_subtype, productid, msdyn_incidenttype, svan_downloaddate, svan_invoice, svan_quantity,\
                        svan_guarantee, svan_extraguarantee, svan_relapse, description,svan_attachedboardfeatures,\
                        svan_attachedpurchaseinvoice,svan_serialnumberverification,svan_attacheddocumentationverification";
        const filter = `&$filter=(incidentid eq ${idIncident})`;
        const orderBy = "";
        const top = "";
        return await apiSat.conexionSatControllerGetEndpointReservas("incidents", select, filter, orderBy, top)
    }
}

export const getMotivosDelFalloParteUno = (idIncident) => {
    return async () => {
        const select = "?$select=productid&$expand=productid($select=_svan_characteristicid_value)";
        const filter = `&$filter=(incidentid eq ${idIncident})`;
        const orderBy = "";
        const top = "";
        return await apiSat.conexionSatControllerGetEndpointReservas("incidents", select, filter, orderBy, top)
    }
}

export const getMotivosDelFalloParteDos = (characteristicId) => {
    return async () => {
        const select = "?$select=svan_fault,msdyn_name,svan_characteristicid";
        const filter = `&$filter=_svan_characteristicid_value eq ${characteristicId}`;
        const orderBy = "&$orderby=svan_fault,msdyn_name";
        const top = "";
        return await apiSat.conexionSatControllerGetEndpointReservas("msdyn_resolutions", select, filter, orderBy, top)
    }
}

export const getTecnicos = (idRecurso) => {
    return async () => {
        const select = `?$select=fullname,contactid`;
        const filter = `&$filter=(_svan_bookableresource_value eq ${idRecurso})`;
        const orderBy = "&$orderby=fullname";
        const top = "";
        return await apiSat.conexionSatControllerGetEndpointReservas("contacts", select, filter, orderBy, top)
    }
}

export const postSubEstado = (idOrden, idSubEstado) => {
    return async () => {
        return await apiSat.conexionSatControllerPostSubEstado(idOrden, idSubEstado);
    }
}

export const getSubEstadoDeUnaOrden = (idOrden) => {
    return async () => {
        const select = `?$select=_msdyn_substatus_value&$filter=msdyn_workorderid eq '${idOrden}'`;
        const filter = "";
        const orderBy = "";
        const top = "";
        return await apiSat.conexionSatControllerGetEndpointReservas("msdyn_workorders", select, filter, orderBy, top)
    }
}

export const getIncidentIdParaResolucion = (idTipoAveria, idOrdenTrabajo) => {
    return async () => {
        const select = "?$select=msdyn_workorderincidentid";
        const filter = `&$filter=(_msdyn_incidenttype_value eq ${idTipoAveria} and _msdyn_workorder_value eq ${idOrdenTrabajo})`;
        const orderBy = "";
        const top = "";
        return await apiSat.conexionSatControllerGetEndpointReservas("msdyn_workorderincidents", select, filter, orderBy, top)
    }
}

export const postResolucionOrdenTrabajo = (idOrden, idTipoAveria, idResolucion) => {
    return async () => {
        return await apiSat.conexionSatControllerPostResolucionOrdenTrabajo(idOrden, idTipoAveria, idResolucion);
    }
}

export const getResolucionFalloAveria = (idOrde) => {
    return async () => {
        const select = `?$select=_msdyn_workorderincident_value&$expand=msdyn_Resolution($select=msdyn_name,svan_fault)`;
        const filter = `&$filter=_msdyn_workorder_value eq ${idOrde}`;
        const orderBy = "";
        const top = "";
        return await apiSat.conexionSatControllerGetEndpointReservas("msdyn_workorderresolutions", select, filter, orderBy, top)
    }
}

export const getRepuestosBC = (nombreProducto) => {
    return async () => {
        const select = "";
        const filter = `?$filter=parentItemNo eq '${nombreProducto}'`;
        const orderBy = "";
        const top = "";
        return await apiSat.conexionSatControllerGetRepuestosBC("repuestos", select, filter, orderBy, top)
    }
}

export const hacerPedidoBC = (newSalesOrder) => {
    return async () => {
        return await apiNavisionNew.navisionControllerPostEndpointBC("TW_Functions_InsertSalesOrder", newSalesOrder)
    }
}

export const getGUIDRepuesto = (referenciaRepuesto) => {
    return async () => {
        const select = "?$select=productid";
        const filter = `&$filter=(productnumber eq '${referenciaRepuesto}')`;
        const orderBy = "";
        const top = "";
        return await apiSat.conexionSatControllerGetEndpointReservas("products", select, filter, orderBy, top)
    }
}

export const guardarRepuestoEnCRM = (guidRepuesto, orden, guidListaPrecios, cantAlmacenPropio, cantAlmacenSvan, sumaCantidades) => {
    return async () => {
        return await apiSat.conexionSatControllerGuardarRepuestoCRM(guidRepuesto, orden, guidListaPrecios, cantAlmacenPropio, cantAlmacenSvan, sumaCantidades);
    }
}

export const getRepuestosGuardadosEnCRM = (idOrdenTrabajo) => {
    return async () => {
        const select = "?$select=msdyn_totalamount,msdyn_description,svan_qtyinownwarehouse,svan_qtyinsvanwarehouse&$expand=msdyn_product($select=name, productid, productnumber)";
        const filter = `&$filter=(_msdyn_workorder_value eq ${idOrdenTrabajo})`;
        const orderBy = "";
        const top = "";
        return await apiSat.conexionSatControllerGetEndpointReservas("msdyn_workorderproducts", select, filter, orderBy, top)
    }
}

export const getListaRepuestosSustitutos = (repuestoID, code) => {

    return async (dispatch) => {
        const page = 1, perPage = 10
        const limit = perPage
        const skip = ((page - 1) * perPage)
        // const order = sortBy || "createdAt DESC"
        const order = ''
        const expand = '';
        const count = true;
        
        let filtro = ''
        const listaDeFiltros = []

        listaDeFiltros.push(`no eq '${repuestoID}'`)
        filtro = listaDeFiltros.join(' and ')

        const data = await apiNavisionNew.navisionControllerGetendPoint('itemSubstitution', filtro, limit, skip, order, expand, count).then(response => {
            
            const todosLosRegistros = response.data.value
            const registrosPaginados = todosLosRegistros.slice(0, limit); // -> Filtra solo primera pagina
            return registrosPaginados
        }).catch((err) => odataLogs(err.message, "ERROR", "", '', config))

        const listaReferenciasRepuestos = data.map(repuesto => repuesto.substituteNo);
        const stringRefsSeparadasConPipe = listaReferenciasRepuestos.join('|');

        const jsonParaObtenerStock = {
            //pCustomer,
            pCustomer: code,
            pcustomerPriceGroup: '',
            pItem: stringRefsSeparadasConPipe,
            pRepuestos: true
        }  

        const objJSON = JSON.stringify(jsonParaObtenerStock) // <-- creamos el JSON a enviar
        const dataStock  = await apiNavisionNew.navisionControllerPostEndpointBC("TW_Functions_QueryExternalStock", objJSON)

        if (dataStock.data.status === "OK") {
            const jsonData = JSON.parse(dataStock.data.mensaje);
            const listaDeRepuestos = data.map(objRepuesto => {
                const objStockRepuesto = jsonData.Items.find(objeto => objeto.Model === objRepuesto.substituteNo);// Buscar la referencia del repuesto
    
                if (objStockRepuesto) {
                    return { // -> Combinamos los campos de lstRepuestosBC con 2 campos de lstStocksRepuestosBC para sacar el Stock del repuesto
                        ...objRepuesto,
                        ...objStockRepuesto, // -> Campos de lstRepuestosBC
                        stockPropio: objStockRepuesto.InventoryDeposit,
                        stockSvan: objStockRepuesto.InventoryAvailable,
                        descripcionSustituto: objStockRepuesto.Description
                    };
                } else {
                    return { ...objRepuesto };
                }
            });
            return listaDeRepuestos
        } else {
            const resultJson = JSON.stringify({ status: "EMPTY", mensaje: 'NO STOCK'});
            console.log('ERROR: Consultando el stock del producto sustitutivo: ', data)
            return JSON.parse(resultJson);
        }
    }
}