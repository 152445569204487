// ** UseJWT import to get config
import useJwt from '@src/auth/jwt/useJwt'
import { isParentUser, parseRolesPermissions } from '@src/auth/utils'
import { emptyCache } from '@utils'
import { getCompany } from '../../../views/desplegable-admin/empresas/store/action'
import { getNavUsersData, updateUserSinMensaje } from '@src/views/usuario/store/action'
import { changeGerenteSuper } from '@src/views/productos/store/actions'

const config = useJwt.jwtConfig
const prefix = 'auth'

// ** Handle User Logout
export const handleLogout = () => {
  return dispatch => {

    // Limpiar el localStorage
    localStorage.clear();
    // Limpiar la caché
    emptyCache()
    dispatch({ type: `${prefix}/LOGOUT`, [config.storageTokenKeyName]: null, [config.storageRefreshTokenKeyName]: null })

  }
}

export const handleLogoutasync = async () => {
  return dispatch => {
    dispatch({ type: `${prefix}/LOGOUT`, [config.storageTokenKeyName]: null, [config.storageRefreshTokenKeyName]: null })
    localStorage.clear()
    emptyCache()
  }
}

// ** Handle User Login
export const handleLogin = data => {
  return async dispatch => {
    await handleLogoutasync()
    await dispatch(changeGerenteSuper('off'))
    //emptyCache() //Limpiar cache cuando se hace rebuild
    await dispatch({
      type: `${prefix}/LOGIN`,
      data,
      config,
      [config.storageTokenKeyName]: data[config.storageTokenKeyName],
      [config.storageRefreshTokenKeyName]: data[config.storageRefreshTokenKeyName]
    })
    // Obtengo la compañia para trabajar con ella mas adelante
    //dispatch(getCompany(data.companyId))
    localStorage.setItem('empresaID', data.companyId)
    let isParent
    if (data.tipoUsuario === "V") {
      isParent = await isParentUser(data.clienteDefecto)
    }
    if (data.tipoUsuario === "C") {
      isParent = await isParentUser(data.codVendedorCliente)
    }
    // ** Add to user, accessToken & refreshToken to localStorage
    localStorage.setItem('userData', JSON.stringify({ ...data, ability: parseRolesPermissions(data.roles.rolesPermissions), isParent }))
    localStorage.setItem(config.storageTokenKeyName, data.accessToken)
    localStorage.setItem(config.storageRefreshTokenKeyName, data.refreshToken)

    if (data.tipoUsuario === "V") {
      const response = await dispatch(getNavUsersData())
      if (response !== undefined) {
        //
        //Buscamos el código de vendedor 
        //
        const salePerson = response.value.find((el) => el.code === data.codVendedorCliente)
        let usuario 
        //
        //Si el valor del descuento de BC está establecido lo pongo, en caso contrario le pongo un 0
        //
        if (salePerson !== null && salePerson !== undefined) {
          usuario = {
            maximoDescuento: salePerson.maxdiscountallowed
          }
        } else {
          usuario = {
            maximoDescuento: 0
          }
        }
        //
        //Actualizo la información en la BBDD
        //
        dispatch(updateUserSinMensaje(Number(data.id), usuario))
      }
    }
    return true
  }
}


// ** Handle setear permisos
export const handlePermisos = (permisos) => {
  return dispatch => {
    dispatch({ type: `${prefix}/PERMISOS`, data: permisos })
  }
}
