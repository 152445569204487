/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { NewRoles, RolesControllerApi, RolesPartial, settings } from '@api/backend'
import { odataLogs, displaySuccessMsg } from '@utils'
import { FormattedMessage, useIntl } from 'react-intl'

const prefix = 'admin/roles'
const config = settings

export const GET_ALL_ROLES = `${prefix}/GET_ALL_DATA`
export const GET_ROLES = `${prefix}/GET_DATA`
export const GET_ROLES_COUNT = `${prefix}/GET_DATA_COUNT`
export const GET_ROLE = `${prefix}/GET_ROLE`
export const ADD_ROLE = `${prefix}/ADD`
export const UPDATE_ROLE = `${prefix}/UPDATE`
export const DELETE_ROLE = `${prefix}/DELETE`

const api = new RolesControllerApi(settings)

const getParams = (state) => state.admin.roles.params || []

// ** Get all Data
export const getAllData = () => {
  return async (dispatch) => {
    await api.rolesControllerFind().then(response => {
      dispatch({
        type: GET_ALL_ROLES,
        data: response.data
      })
      odataLogs(response.config.url, response.status, response.config.method, GET_ALL_ROLES, config)
    }).catch((err) => odataLogs(err.message, "ERROR", "", GET_ALL_ROLES, config))
  }
}

// ** Get data on page or row change
export const getData = (params) => {
  return async (dispatch) => {
    const { page, perPage, q, sortBy } = params

    const filter = {
      offset: (page - 1) * perPage,
      limit: perPage,
      skip: ((page - 1) * perPage),
      order: sortBy || "name ASC",
      where: {
        ['name']: { like: `%${q}%` }
      }
    }

    await api.rolesControllerFind(JSON.stringify(filter)).then(response => {
      dispatch({
        type: GET_ROLES,
        data: response.data,
        params
      })
      odataLogs(response.config.url, response.status, response.config.method, GET_ROLES, config)
    }).catch((err) => odataLogs(err.message, "ERROR", "", GET_ROLES, config))

    await api.rolesControllerCount(JSON.stringify(filter.where)).then(response => {
      dispatch({
        type: GET_ROLES_COUNT,
        totalPages: response.data.count || 0
      })
      odataLogs(response.config.url, response.status, response.config.method, GET_ROLES_COUNT, config)
    }).catch((err) => odataLogs(err.message, "ERROR", "", GET_ROLES_COUNT, config))
  }
}

// ** Get Role
export const getRole = (id) => {
  return async (dispatch) => {
    await api.rolesControllerFindById(id).then(response => {
      dispatch({
        type: GET_ROLE,
        selectedRole: response.data
      })
      odataLogs(response.config.url, response.status, response.config.method, GET_ROLE, config)
    })
      .catch((err) => odataLogs(err.message, "ERROR", "", GET_ROLE, config))
  }
}

// ** Add new role
export const addRole = (intl, role) => {
  return (dispatch, getState) => {
    api.rolesControllerCreate(role).then(response => {
      dispatch({
        type: ADD_ROLE,
        role
      })
      odataLogs(response.config.url, response.status, response.config.method, ADD_ROLE, config)
    })
      .then(() => {
        displaySuccessMsg(intl.formatMessage({ id: "Role created"}))
        dispatch(getData(getParams(getState())))
        dispatch(getAllData())
      })
      .catch((err) => odataLogs(err.message, "ERROR", "", ADD_ROLE, config))

  }
}

// ** Update a  role
export const updateRole = (id, intl, role) => {
  return (dispatch, getState) => {
    api.rolesControllerUpdateById(id, role).then(response => {
      dispatch({
        type: UPDATE_ROLE,
        role
      })
      odataLogs(response.config.url, response.status, response.config.method, UPDATE_ROLE, config)
    })
      .then(() => {
        displaySuccessMsg(intl.formatMessage({ id: "Updated role"}))
        dispatch(getData(getParams(getState())))
        dispatch(getAllData())
      })
      .catch((err) => odataLogs(err.message, "ERROR", "", UPDATE_ROLE, config))

  }
}

// ** Delete role
export const deleteRole = (id, intl) => {
  return (dispatch, getState) => {

    api
      .rolesControllerDeleteById(id)
      .then(response => {
        dispatch({
          type: DELETE_ROLE
        })
        odataLogs(response.config.url, response.status, response.config.method, DELETE_ROLE, config)
      })
      .then(() => {
        displaySuccessMsg(intl.formatMessage({ id: "Role eliminated"}))
        dispatch(getData(getParams(getState())))
        dispatch(getAllData())
      })
      .catch((err) => odataLogs(err.message, "ERROR", "", DELETE_ROLE, config))

  }
}
