// AutoLogout.js
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { handleLogin, handleLogout } from '@store/actions/auth'
import Swal from 'sweetalert2'

const AutoLogout = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    // Define el tiempo de inactividad en milisegundos (por ejemplo, 15 minutos)
    const inactivityTimeout1H = 60 * 60 * 1000; // 1 hora
    const inactivityTimeout1 = 1 * 60 * 1000; // 1 minuto
    let iniciado = localStorage.getItem("iniciado")
    //Controlamos la una variable iniciadora para que el codigo no se resetee cada vez que se recarga la pagina.
    if (iniciado == "true") { 
      iniciado = ""
    } else {
      localStorage.setItem("iniciado", "true")
    }
    iniciado = localStorage.getItem("iniciado")
    if (iniciado == "true") {
      localStorage.setItem("iniciado", "false")
      const timeoutId = setTimeout(() => {
        // Programa la desconexión después del tiempo de inactividad
      Swal.fire({
          position: 'center',
          icon: 'warning',
          title: 'Cierre de sesion automatico',
          text: 'La sesion se va cerrar',
          showConfirmButton: true,
          showCancelButton: true,
          cancelButtonColor: '#808080',
          confirmButtonText: 'Aceptar',
          cancelButtonText: 'Cancelar',
          allowOutsideClick: false
      }).then((result) => {
          if (result.isConfirmed) {
            dispatch(handleLogout()); // Dispara la acción de Redux para cerrar sesión
          } else {
            window.location.reload(); // Recarga la página si se hace clic en Cancelar
          }
      })
      }, inactivityTimeout1H);
  
      return () => {
        clearTimeout(timeoutId);
      };
    } else {
      console.log("esta inicializado ya, el codigo esta en global.js")
    }

    
  }, [dispatch]);

  return null; // No renderiza nada, ya que es un componente de inicialización
};

export default AutoLogout;