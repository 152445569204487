/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import Swal from "sweetalert2"
import Cookies from 'js-cookie' // Para leer el valor de las Cookies
import {
  settings,
  PasswordChangesControllerApi,
  FailedLoginsControllerApi,
  OdataLogsControllerApi,
  SuccessLoginsControllerApi,
  LogNotFoundControllerApi,
  NavisionControllerApi,
  VendedoresControllerApi,
  ClientesControllerApi
} from '@api/backend'
// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = obj => Object.keys(obj).length === 0
// ** Returns K format from a number
export const kFormatter = num => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num)
// ** Converts HTML to string
export const htmlToString = html => html.replace(/<\/?[^>]+(>|$)/g, '')
// ** Checks if the passed date is today
const isToday = date => {
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

export function isJson(str) {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}

export function devuelveBasePath() {

  let REACT_APP_BACKEND_BASEPATH = process.env.REACT_APP_BACKEND_BASEPATH_LOCAL
  if (process.env.REACT_APP_ENTORNO == "DEV") {
    REACT_APP_BACKEND_BASEPATH = process.env.REACT_APP_BACKEND_BASEPATH_DEV
  }
  if (process.env.REACT_APP_ENTORNO == "PRO") {
    REACT_APP_BACKEND_BASEPATH = process.env.REACT_APP_BACKEND_BASEPATH_PRO
  }
  return REACT_APP_BACKEND_BASEPATH

}

export const eliminaRepetidos = (arr) => {
  const resultArray = []
  arr.forEach(el => {
    if (resultArray.find(item => item.slug == el.slug) == undefined) {
      resultArray.push(el)
    }
  })
  return resultArray
}

export function devuelveBasePathPim() {

  let REACT_APP_BACKEND_BASEPATH_PIM = process.env.REACT_APP_BACKEND_BASEPATH_PIM_LOCAL
  if (process.env.REACT_APP_ENTORNO == "DEV") {
    REACT_APP_BACKEND_BASEPATH_PIM = process.env.REACT_APP_BACKEND_BASEPATH_PIM_DEV
  }
  if (process.env.REACT_APP_ENTORNO == "PRO") {
    REACT_APP_BACKEND_BASEPATH_PIM = process.env.REACT_APP_BACKEND_BASEPATH_PIM_PRO
  }
  return REACT_APP_BACKEND_BASEPATH_PIM

}

export function getPrecioFormato(precio) {
  if ((precio == undefined) || (isNaN(precio))) return "0.00"
  const cadenaPrecio = precio.toString()
  let enteros = cadenaPrecio
  let decimales = "00"
  if (cadenaPrecio.includes(".")) {
    enteros = cadenaPrecio.split(".")[0]
    // decimales = cadenaPrecio.split(".")[1].substring(0, 2)
    decimales = (`${cadenaPrecio.split(".")[1]}00`).substring(0, 2)
  } else {
    decimales = "00"
  }
  const cadenaLen = enteros.length
  if (cadenaLen > 3) {
    const izqComa = enteros.substring(0, cadenaLen - 3)
    const derComa = enteros.substring(cadenaLen - 3, cadenaLen)
    enteros = izqComa.concat(",").concat(derComa)
  }
  return enteros.concat(".").concat(decimales)
}

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (value, formatting = { month: '2-digit', day: '2-digit', year: 'numeric' }) => {
  try {
    if (!value) return value
    if (value === '0001-01-01') return ''
    // return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
    return new Intl.DateTimeFormat('es-ES', formatting).format(new Date(value))
  } catch (err) {
    console.log(err)
  }
}

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value)
  let formatting = { month: 'short', day: 'numeric' }

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: 'numeric', minute: 'numeric' }
  }

  // return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
  return new Intl.DateTimeFormat('es-ES', formatting).format(new Date(value))
}

// ** Returns YYYY-MM-DD date
export const formatDateToDatabase = (value) => {
  const date = new Date(value)

  const year = date.getFullYear()

  let month = (1 + date.getMonth()).toString()
  month = month.length > 1 ? month : `0${month}`

  let day = date.getDate().toString()
  day = day.length > 1 ? day : `0${day}`

  return `${year}-${month}-${day}`
}

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => localStorage.getItem('userData')
export const getUserData = () => JSON.parse(localStorage.getItem('userData'))

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
  // if (userRole === 'admin') return '/anuncios/list'
  // if (userRole !== 'admin') return '/anuncios/list'
  if (userRole) return '/anuncios/list'
  return '/login'
}

// ** React Select Theme Colors
export const selectThemeColors = theme => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: '#7367f01a', // for option hover bg-color
    primary: '#7367f0', // for selected option bg-color
    neutral10: '#7367f0', // for tags bg-color
    neutral20: '#ededed', // for input border-color
    neutral30: '#ededed' // for input hover border-color
  }
})

// ** Capitalize First letter
export const capitalizeFirstLetter = (string) => (
  string ? `${string.charAt(0).toUpperCase()}${string.slice(1)}` : ''
)

// ** Slugify
export const slugify = (...args) => {
  const value = args.join(' ')

  return value
    .normalize('NFD') // split an accented letter in the base letter and the acent
    .replace(/[\u0300-\u036f]/g, '') // remove all previously split accents
    .toLowerCase()
    .trim()
    .replace(/[^a-z0-9]/g, '') // remove all chars not letters, numbers and spaces (to be replaced)
    .replace(/\s+/g, '-') // separator
}

// Price, Quantity & Ammount
export const getFormattedPrice = (price) => (
  // new Intl.NumberFormat(undefined, {
  //   style: 'currency',
  //   currency: 'EUR'
  // }).format(price)
  price ? price.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : ''
)

export const getFormattedQuantity = (quantity = 0) => (
  quantity ? quantity : ''
)
export const getFormattedAmmount = (price, quantity) => (
  (price && quantity) ? `${((price * quantity)).toFixed(2)} €` : ''
)

export const getPrecioTotalconDescuento = (precio, cantidad = 1, descuento = 0) => {
  // Convierte el descuento a un número decimal si es un porcentaje
  const descuentoDecimal = parseFloat(descuento / 100);
  // Calcula el precio total sin descuento
  const precioTotalSinDescuento = parseFloat(precio * cantidad);
  // Calcula el monto de descuento
  const montoDescuento = precioTotalSinDescuento * descuentoDecimal;
  // Calcula el precio total con descuento
  const precioTotalconDescuento = parseFloat(precioTotalSinDescuento - montoDescuento);
  return precioTotalconDescuento.toFixed(2);
}

// O J O: ESTO LO HIZO ISABEL -> BORRAR ESTE COMENTARIO SI TODO VA BIEN EN MASTER
/* Devuelve la baseImponible con el IVA aplicado */
export const getImporteConIva = (baseImponible, valorIva = 21) => {  // Si no recibe valorIva, toma por defecto 21%
  const ivaDecimal = parseFloat(valorIva / 100);  // Convierte el IVA a su valor decimal
  const importeConIva = parseFloat(baseImponible + (baseImponible * ivaDecimal)); // Cálculo del importe con el IVA aplicado
  return importeConIva.toFixed(2); // Importe al que se le ha aplicado el IVA, ajustado a dos cifras decimales
}

// O J O: ESTO LO HIZO ISABEL -> BORRAR ESTE COMENTARIO SI TODO VA BIEN EN MASTER
/* Devuelve la Cuota impuesto que es el resultado de aplicar el IVA a la baseImponible */
export const getCuotaImpuesto = (baseImponible, valorIva = 21) => {  // Si no recibe valorIva, toma por defecto 21%
  const ivaDecimal = parseFloat(valorIva / 100);  // Convierte el IVA a su valor decimal
  const cuotaImpuesto = parseFloat(baseImponible * ivaDecimal); // Cálculo del importe con el IVA aplicado
  return cuotaImpuesto.toFixed(2); // Importe al que se le ha aplicado el IVA, ajustado a dos cifras decimales
}

export const automaticDownload = (filename, data) => {
  const hiddenElement = document.createElement('a')
  hiddenElement.href = data
  hiddenElement.target = '_blank'
  hiddenElement.download = filename
  hiddenElement.click()
  setTimeout(() => hiddenElement.remove(), 4000)
}

export const displaySuccessMsg = (msg, displayTime) => {
  if (displayTime) {
    return Swal.fire({
      position: 'center',
      icon: 'success',
      showCloseButton: false,
      timer: displayTime,
      title: msg
    })
  } else {
    return Swal.fire({
      position: 'center',
      icon: 'success',
      title: msg,
      allowOutsideClick: false
    })
  }
}

export const displayInfoMsg = (msg, displayTime) => {
  if (displayTime) {
    return Swal.fire({
      position: 'center',
      icon: 'info',
      showCloseButton: false,
      timer: displayTime,
      title: msg
    })
  } else {
    return Swal.fire({
      position: 'center',
      icon: 'info',
      title: msg
    })
  }
}

export const displayErrorMsg = (msg, displayTime) => {
  if (displayTime) {
    return Swal.fire({
      position: 'center',
      icon: 'error',
      showCloseButton: false,
      timer: displayTime,
      title: msg
    })
  } else {
    return Swal.fire({
      position: 'center',
      icon: 'error',
      title: msg
    })
  }
}

export const capitalize = (string) => {
  if (string != undefined) {
    const loweredString = string.toLowerCase()
    return loweredString.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())
  } else return ""
}

export const testJSON = (text) => {
  if (typeof text !== "string") {
    return false
  }
  try {
    JSON.parse(text)
    return true
  } catch (error) {
    return false
  }
}

export function inRange(x, min, max) {
  const minVal = min ? min : 0
  const maxVal = max ? max : 99999
  return x >= minVal && x <= maxVal
}

export function getYoutubeId(url) {
  const regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
  const match = url.match(regExp)
  if (match && match[2].length == 11) {
    return match[2]
  } else {
    return null
  }
}

export function getPimUrl(url) {
  return `http://51.75.242.235:8080/media/download/${url.replaceAll("/", "%252F")}`
}

export function getUsableFilesUrl(url) {
  return `${devuelveBasePath()}/${url}`
}

export function makeParrafos(str, divisor, className) {
  return str.split(divisor).map((it, i) => {
    if (it.length > 0) return <div className={className} key={`x${i}`}>{it}</div>
    else return null
  })
}

export function fetchAndDownload(url, filename) {
  return fetch(url, {
    method: 'GET'
  })
    .then((response) => response.blob())
    .then((blob) => {
      // Create blob link to download
      const urlBlob = window.URL.createObjectURL(
        new Blob([blob])
      )

      let extensionArchivo = '';
      const parts = url.split('/');
      const nombreArchivo = parts[parts.length - 1];

      const extensionParts = nombreArchivo.split('.');
      const extension = extensionParts[extensionParts.length - 1];
      if (extension === 'docx') {
        extensionArchivo = extension;
      } else {
        const mimeType = blob.type;
        extensionArchivo = mimeType.split("/").pop();
      }

      const link = document.createElement('a')
      link.href = urlBlob
      link.setAttribute(
        'download',
        `${`${filename}.${extensionArchivo}`}`
      )
      // Append to html link element page
      document.body.appendChild(link)
      // Start download
      link.click()
      // Clean up and remove the link
      link.parentNode.removeChild(link)
    })
}

export function cortarCerosExcedentes(str) {
  //console.log(str, "-", typeof str)
  if (!str.includes(".")) return str

  return parseFloat(str) * 1
}

export function isMobileScreen() {
  return window.matchMedia("(min-width: 500px)").matches
}

export function emptyCache() {
  if ('caches' in window) {
    caches.keys().then((names) => {
      // Delete all the cache files
      names.forEach(name => {
        caches.delete(name)
      })
    })

    // Makes sure the page reloads. Changes are only visible after you refresh.
    window.location.reload(true)
  }
}

export function scrollToTopSmooth() {
  window.scrollTo({ top: 0, behavior: 'smooth' })
}

export function notFoundLog(url, settings) {
  const api = new LogNotFoundControllerApi(settings)
  const fecha = new Date
  const usuario = localStorage.getItem('userData')
  const empresaName = localStorage.getItem('empresaName')
  // Le sumo 2 horas, para que este en la zona  horaria correcta
  fecha.setHours(fecha.getHours() + 2)
  const log = new Object()
  log.createdAt = fecha.toISOString()
  log.userId = JSON.parse(usuario).id.toString()
  log.userName = JSON.parse(usuario).userName
  log.userRolesId = JSON.parse(usuario).rolesId.toString()
  log.empresaName = empresaName
  log.url = url
  api.logNotFoundControllerCreate(log)
}

export function failedLog(settings) {
  const api = new FailedLoginsControllerApi(settings)
  const fecha = new Date
  const usuario = localStorage.getItem('userData')
  // Le sumo 2 horas, para que este en la zona  horaria correcta
  fecha.setHours(fecha.getHours() + 2)
  const log = new Object()
  log.usersId = usuario ? parseInt(JSON.parse(usuario).id) : 0
  log.ipAddress = localStorage.getItem('ipUser')
  log.createdAt = fecha.toISOString()

  api.failedLoginsControllerCreate(log)
}

export function successLog(ipUser, userAgent, settings) {
  const api = new SuccessLoginsControllerApi(settings)
  const fecha = new Date
  const usuario = localStorage.getItem('userData')
  // Le sumo 2 horas, para que este en la zona  horaria correcta
  fecha.setHours(fecha.getHours() + 2)
  const log = new Object()
  log.usersId = parseInt(JSON.parse(usuario).id)
  log.ipAddress = ipUser
  log.userAgent = userAgent
  log.createdAt = fecha.toISOString()

  api.successLoginsControllerCreate(log)
}

export function odataLogs(url, resultado, tipo, endpoint, settings) {
  return true
  /*const api = new OdataLogsControllerApi(settings)
  const log = new Object()
  const fecha = new Date
  // Le sumo 2 horas, para que este en la zona  horaria correcta
  fecha.setHours(fecha.getHours() + 2)
  const nombreEmpresa = localStorage.getItem('empresaName')
  // Monto el objeto con estructura para la BD
  log.url = url
  log.fecha = fecha.toISOString()
  log.result = resultado.toString()
  if (getUserData() !== null) {
    log.userId = getUserData().id
  } else {
    log.userId = "NO ENCONTRADO"
  }
  // log.userId = getUserData().id
  log.empresaName = nombreEmpresa
  log.endPoint = endpoint
  log.type = tipo

  api.odataLogsControllerCreate(log)*/
}

export function passwordChanges(settings) {
  const api = new PasswordChangesControllerApi(settings)
  const fecha = new Date
  const usuario = localStorage.getItem('userData')
  // Le sumo 2 horas, para que este en la zona  horaria correcta
  fecha.setHours(fecha.getHours() + 2)
  const log = new Object()
  log.usersId = parseInt(JSON.parse(usuario).id)
  log.ipAddress = localStorage.getItem('ipUser')
  log.userAgent = localStorage.getItem('userAgent')
  log.createdAt = fecha.toISOString()

  api.passwordChangesControllerCreate(log)
}

export const recuperarTarifas = () => {

  const vendedoresApi = new VendedoresControllerApi(settings)

  let cliente = document.getElementById("cliente")
  if (!cliente) {
    cliente = document.getElementById("clienteModal")
  }
  // Obtengo el numero del cliente seleccionado
  const numCliente = cliente.selectedOptions[0]?.value
  //const $filter = `type eq 'Customer Price Group by Customer'`
  const filter = {
    where: {
      ['type']: 'Customer Price Group by Customer'
    }
  }
  let arrClientes = ""
  vendedoresApi.vendedoresControllerFind(JSON.stringify(filter)).then(response => {
    response.data.map(cliente => {
      if (cliente.code === numCliente) {
        arrClientes = cliente
      }
    })
    // Lo paso a stringify, para luego parsearlo
    localStorage.setItem('tarifas', arrClientes.relatedCode)
  })
}

export const validarEmail = (email) => {
  const regE = new RegExp(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,4})+$/)
  return regE.test(email)
}
//
//Si le pasamos una fecha local y con formato 2023-10-05T13:50:49.035Z la convierte a GMT y mantiene el formato
//
export const deFechaLocalaGMT = (fechaLocalISO) => {

  // Convertir el timestamp en un objeto Date
  const fechaTimestamp = new Date(fechaLocalISO);

  // Obtener la diferencia en minutos entre GMT (UTC) y la hora local
  const diferenciaGMTMinutos = Math.abs(fechaTimestamp.getTimezoneOffset())

  // Restar la diferencia en minutos al timestamp
  const fechaLocal = new Date(fechaTimestamp.getTime() - (diferenciaGMTMinutos * 60 * 1000));

  // Formatear la fecha local de nuevo en formato ISO 8601
  return fechaLocal.toISOString()
}
//
//Convierte fecha GMT a Local
//
export const deGMTaFechaLocal = (fechaLocalISO) => {
  //
  //Si le pasamos una fecha en GMT y con formato 2023-10-05T13:50:49.035Z la convierte a local y mantiene el formato
  //        
  if (fechaLocalISO.indexOf('T') >= 0) {
    // Convertir el timestamp en un objeto Date
    const fechaTimestamp = new Date(fechaLocalISO);

    // Obtener la diferencia en minutos entre GMT (UTC) y la hora local
    const diferenciaGMTMinutos = Math.abs(fechaTimestamp.getTimezoneOffset())

    // Restar la diferencia en minutos al timestamp
    const fechaLocal = new Date(fechaTimestamp.getTime() + (diferenciaGMTMinutos * 60 * 1000));

    // Formatear la fecha local de nuevo en formato ISO 8601
    return fechaLocal.toISOString()
  }
  //
  //Si le pasamos una fecha en GMT y con formato 05/10/2023 8:24 la convierte a local y mantiene el formato
  //
  if (fechaLocalISO.indexOf('T') >= 0) {
    // Separar la fecha y la hora
    const [fecha, hora] = fechaLocalISO.split(' ');

    // Separar el día, mes y año
    const [dia, mes, año] = fecha.split('/');

    // Separar la hora y los minutos
    const [horaNum, minutosNum] = hora.split(':');

    // Crear un objeto Date con la fecha y la hora
    const fechaLocal = new Date(año, mes - 1, dia, horaNum, minutosNum);

    // Obtener la diferencia en minutos entre GMT (UTC) y la hora local
    const diferenciaGMTMinutos = Math.abs(fechaLocal.getTimezoneOffset());

    // Restar la diferencia en minutos al timestamp
    fechaLocal.setTime(fechaLocal.getTime() + (diferenciaGMTMinutos * 60 * 1000));

    // Formatear la fecha local en el formato deseado
    const diaLocal = fechaLocal.getDate().toString().padStart(2, '0');
    const mesLocal = (fechaLocal.getMonth() + 1).toString().padStart(2, '0');
    const añoLocal = fechaLocal.getFullYear();
    const horaLocal = fechaLocal.getHours().toString().padStart(2, '0');
    const minutosLocal = fechaLocal.getMinutes().toString().padStart(2, '0');

    return `${diaLocal}/${mesLocal}/${añoLocal} ${horaLocal}:${minutosLocal}`;
  }
  if ((fechaLocalISO.indexOf('T') < 0) && (fechaLocalISO.indexOf(' ') < 0)) {
    return fechaLocalISO
  }
}


export async function getClientes(codVendedor) {
  try {
    const cod = getUserData()
    const vendedoresApi = new VendedoresControllerApi(settings)
    const clienteasApi = new ClientesControllerApi(settings)
    const filter = {
      where: {
        ['code']: codVendedor,
        ['companyId'] : cod.companyId
      }
    }
    const { data: vendedores } = await vendedoresApi.vendedoresControllerFind(JSON.stringify(filter))
    const arrVendedores = []
    vendedores?.map(cliente => {
      if (cliente.code === codVendedor) {
        arrVendedores.push(cliente)
      }
    })
    //
    //Obtenemos los datos de los clientes que pueden ver stock
    //
    const filterStock = {
      where: {
        ['allowStock']: 'true',
        ['companyId'] : cod.companyId
      }
    }
    const { data: clienteStock } = await clienteasApi.clientesControllerFind(JSON.stringify(filterStock))
    const arrClientesStock = []
    clienteStock?.map(cliente => {
      for (let i = 0; i < arrVendedores.length; i++) {
        if (arrVendedores[i].relatedCode === cliente.customerNo) {
          arrClientesStock.push(arrVendedores[i])
        }
      }
    })
    const arrClientesStockSinDuplicados = []
    arrClientesStock.forEach(elemento => {
      if (!arrClientesStockSinDuplicados.includes(elemento)) {
        arrClientesStockSinDuplicados.push(elemento);
      }
    });
    return arrClientesStockSinDuplicados
  } catch (err) {
    console.error(err)
  }
}

// llama al endpoint clientesfiltrados que internamente tiene un filtro que marce indico que agregaramos para solo obtener los clientes padre
// O B T I E N E - C L I E N T E S - P A D R E
export async function getClientesFiltrados(gerente = 'off', codVendedor) {
  try {
    const cod = getUserData()
    const vendedoresApi = new VendedoresControllerApi(settings)
    const clienteasApi = new ClientesControllerApi(settings)
    let vendedores
    const arrClientesStock = []
    if (gerente === 'on') { //-> Si el ojo esta abierto, obtenemos todos los vendedores.
      vendedores = await vendedoresApi.vendedoresControllerFind()
      
      const { data: clienteStock } = await clienteasApi.clientesControllerObtenerClientesFiltrados(cod.companyId)

      clienteStock?.map(cliente => {
        for (let i = 0; i < vendedores.data.length; i++) {
          if (vendedores.data[i].relatedCode === cliente.customerNo) {
            arrClientesStock.push(vendedores.data[i])
          }
        }
      })

    } else { //-> Si el OJO esta cerrado, filtramos por codigo vendedor
      const filter = {
        where: {
          ['code']: codVendedor,
          ['companyId'] : cod.companyId
        }
      }
      vendedores = await vendedoresApi.vendedoresControllerFind(JSON.stringify(filter))

      const arrVendedores = []
      vendedores.data?.map(cliente => {
        if (cliente.code === codVendedor) {
          arrVendedores.push(cliente)
        }
      })

      const { data: clienteStock } = await clienteasApi.clientesControllerObtenerClientesFiltrados(cod.companyId)

      clienteStock?.map(cliente => {
        for (let i = 0; i < arrVendedores.length; i++) {
          if (arrVendedores[i].relatedCode === cliente.customerNo) {
            arrClientesStock.push(arrVendedores[i])
          }
        }
      })
    }

    // E L I M I N A M O S - D U P L I C A D O S
    // Y - R E T O R N A M O S - S O L O - C A M P O S - N E C E S A R I O S
    const itemsUnicos = {};
    const arrClientesStockSinDuplicados = arrClientesStock.filter(item => {
      if (!itemsUnicos[item.relatedCode]) {
        itemsUnicos[item.relatedCode] = true;
        return true; //-> Si el relatedCode no se ha agregado, lo añadimos al objeto y devolvemos true para mantenerlo en la lista resultante.
      }
      return false; //-> Si ya existe, devolvemos false para filtrarlo.
    }).map(({ id, code, description, relatedCode }) => ({ id, code, description, relatedCode }));

    return arrClientesStockSinDuplicados
  } catch (err) {
    console.error(err)
  }
}

// O B T I E N E - C L I E N T E S - H I J O
export async function getClientesFiltradosHijos(gerente = 'off', codVendedor) {
  try {
    const cod = getUserData()
    const vendedoresApi = new VendedoresControllerApi(settings)
    const clienteasApi = new ClientesControllerApi(settings)
    let vendedores
    const arrClientesStock = []
    if (gerente === 'on') { //-> Si el ojo esta abierto, obtenemos todos los vendedores.
      vendedores = await vendedoresApi.vendedoresControllerFind()

      const { data: clienteStock } = await clienteasApi.clientesControllerObtenerClientesFiltradosHijos(cod.companyId)

      clienteStock?.map(cliente => {
        for (let i = 0; i < vendedores.data.length; i++) {
          if (vendedores.data[i].relatedCode === cliente.customerNo) {
            arrClientesStock.push(vendedores.data[i])
          }
        }
      })

    } else { //-> Si el OJO esta cerrado, filtramos por codigo vendedor
      const filter = {
        where: {
          ['code']: codVendedor,
          ['companyId'] : cod.companyId
        }
      }
      vendedores = await vendedoresApi.vendedoresControllerFind(JSON.stringify(filter))

      const arrVendedores = []
      vendedores.data?.map(cliente => {
        if (cliente.code === codVendedor) {
          arrVendedores.push(cliente)
        }
      })

      const { data: clienteStock } = await clienteasApi.clientesControllerObtenerClientesFiltradosHijos(cod.companyId)

      clienteStock?.map(cliente => {
        for (let i = 0; i < arrVendedores.length; i++) {
          if (arrVendedores[i].relatedCode === cliente.customerNo) {
            arrClientesStock.push(arrVendedores[i])
          }
        }
      })
    }

    // E L I M I N A M O S - D U P L I C A D O S
    // Y - R E T O R N A M O S - S O L O - C A M P O S - N E C E S A R I O S
    const itemsUnicos = {};
    const arrClientesStockSinDuplicados = arrClientesStock.filter(item => {
      if (!itemsUnicos[item.relatedCode]) {
        itemsUnicos[item.relatedCode] = true;
        return true; //-> Si el relatedCode no se ha agregado, lo añadimos al objeto y devolvemos true para mantenerlo en la lista resultante.
      }
      return false; //-> Si ya existe, devolvemos false para filtrarlo.
    }).map(({ id, code, description, relatedCode }) => ({ id, code, description, relatedCode }));

    return arrClientesStockSinDuplicados
  } catch (err) {
    console.error(err)
  }
}

export async function getClientesGerente(codVendedor, tipoUsuario) {
  try {
    const cod = getUserData()
    const vendedoresApi = new VendedoresControllerApi(settings)
    const clienteasApi = new ClientesControllerApi(settings)
    if (tipoUsuario == 'V') {
      const filter = {
        where: {
          ['type']: 'Customer by SalesPerson',
          ['companyId'] : cod.companyId
        }
      }
      const { data: vendedores } = await vendedoresApi.vendedoresControllerFind(JSON.stringify(filter))
      return vendedores
    } else {
      const cod = getUserData()
      const filter = {
        where: {
          ['relatedCode']: codVendedor,
          ['companyId'] : cod.companyId
        }
      }
      const { data: vendedores } = await vendedoresApi.vendedoresControllerFind(JSON.stringify(filter))
      const arrVendedores = []
      vendedores?.map(cliente => {
        if (cliente.relatedCode === codVendedor) {
          arrVendedores.push(cliente)
        }
      })
      //
      //Obtenemos los datos de los clientes que pueden ver stock
      //
      const filterStock = {
        where: {
          ['allowStock']: 'true',
          ['companyId'] : cod.companyId
        }
      }
      const { data: clienteStock } = await clienteasApi.clientesControllerFind(JSON.stringify(filterStock))
      const arrClientesStock = []
      clienteStock?.map(cliente => {
        for (let i = 0; i < arrVendedores.length; i++) {
          if (arrVendedores[i].relatedCode === cliente.customerNo) {
            arrClientesStock.push(arrVendedores[i])
          }
        }
      })
      const arrClientesStockSinDuplicados = []
      arrClientesStock.forEach(elemento => {
        if (!arrClientesStockSinDuplicados.includes(elemento)) {
          arrClientesStockSinDuplicados.push(elemento);
        }
      });
      return arrClientesStockSinDuplicados
    }

  } catch (err) {
    console.error(err)
  }
}

export async function getCodVendedor(codVendedor) {
  try {
    const cod = getUserData()
    const vendedoresApi = new VendedoresControllerApi(settings)
    const filter = {
      where: {
        ['relatedCode']: codVendedor,
        ['companyId'] : cod.companyId
      }
    }
    const { data: vendedores } = await vendedoresApi.vendedoresControllerFind(JSON.stringify(filter))
    const arrVendedores = []
    vendedores?.map(cliente => {
      if (cliente.relatedCode === codVendedor) {
        arrVendedores.push(cliente)
      }
    })

    return arrVendedores
  } catch (err) {
    console.error(err)
  }
}
/** S E L E C C I O N A R   I D I O M A   Y   F O R M A T O   R E G I O N A L **/
/* Esta función 'seleccionarIdiomaYFormatoRegional' selecciona un idioma basado en las cookies del navegador o si no encuentra idioma en las cookies, utiliza el idioma predeterminado del navegador, como terecera opción si no hay ningún idioma disponible, establece un valor predeterminado. Luego lo mapea a su equivalente en idioma regional. El idioma formateado resultante se devuelve como resultado de la función.
*/
export function seleccionarIdiomaYFormatoRegional() {
  // Leer el idioma almacenado en las Cookies
  const idiomaCookies = Cookies.get('idioma');
  let idiomaSeleccionado = navigator.language || 'es';  // Idioma que toma por defecto si no hay Cookie 'idioma'
  // Si existe el valor idiomaCookies lo asigna a idiomaSeleccionado
  if (idiomaCookies) {
    idiomaSeleccionado = idiomaCookies;
  }
  // Mapear el idioma seleccionado a su equivalente en formato regional
  // En caso que se seleccione en el desplegable de idiomas Portugués o Francés, como no están todavía, el .pdf lo descargará en inglés
  const idiomasRegionales = {
    es: 'es-ES',  // Español
    en: 'en-EN',   // Inglés (posiblemente sea en-GB)
    pt: 'pt-PT',   // Portugués
    fr: 'fr-FR'   // Francés
  };
  // Formatear el idioma 'es' -> 'es-ES' o 'en' -> 'en-EN'
  return idiomasRegionales[idiomaSeleccionado] || 'es-ES'; // Valor por defecto 'es-ES'
}

/** C S V M A K E R **/
/* Esta función 'csvmaker' genera un archivo .csv a partir de los datos de entrada que llegan desde Ofertas, Pedidos, Albaranes o Facturas, además de Cartera en Pedidos|Cartera y Ofertas|Cartera. La función toma en cuenta el tipoDeDocumento que llega, realiza cálculos específicos y formatea los datos para generar el archivo .csv
*/
const traducciones = [
  { clave: "@odata.etag", valor: "ETag" },
  { clave: "documentType", valor: "Tipo de documento" },
  { clave: "documentNo", valor: "Numero de documento" },
  { clave: "sellToCustomerNo", valor: "Numero de cliente" },
  { clave: "sellToCustomerName", valor: "Nombre del cliente" },
  { clave: "selltoAddress", valor: "Direccion de envio" },
  { clave: "selltoAddress2", valor: "Direccion de envio 2" },
  { clave: "selltoCity", valor: "Ciudad de envio" },
  { clave: "selltoCounty", valor: "Provincia de envio" },
  { clave: "selltoPostCode", valor: "Codigo postal de envio" },
  { clave: "selltoCountryRegionCode", valor: "Codigo de region/pais de envio" },
  { clave: "postingDate", valor: "Fecha de publicacion" },
  { clave: "orderDate", valor: "Fecha de pedido" },
  { clave: "status", valor: "Estado" },
  { clave: "paymentMethodCode", valor: "Codigo de metodo de pago" },
  { clave: "paymentTermsCode", valor: "Codigo de terminos de pago" },
  { clave: "shipToAddress", valor: "Direccion de envio" },
  { clave: "shipToAddress2", valor: "Direccion de envio 2" },
  { clave: "shiptoCity", valor: "Ciudad de envio" },
  { clave: "shipToCounty", valor: "Provincia de envio" },
  { clave: "shipToPostCode", valor: "Codigo postal de envio" },
  { clave: "shipToCountryCode", valor: "Codigo de pais de envio" },
  { clave: "billtoCustomerNo", valor: "Numero de cliente de facturacion" },
  { clave: "billtoName", valor: "Nombre de facturacion" },
  { clave: "billToAddress", valor: "Direccion de facturacion" },
  { clave: "billToAddress2", valor: "Direccion de facturacion 2" },
  { clave: "billtoCity", valor: "Ciudad de facturacion" },
  { clave: "billToCounty", valor: "Provincia de facturacion" },
  { clave: "billToPostCode", valor: "Codigo postal de facturacion" },
  { clave: "billToCountryCode", valor: "Codigo de pais de facturacion" },
  { clave: "shipped", valor: "Enviado" },
  { clave: "yourReference", valor: "Su referencia" },
  { clave: "programedOrder", valor: "Pedido programado" },
  { clave: "specialOrder", valor: "Pedido especial" },
  { clave: "depositOrder", valor: "Pedido de deposito" },
  { clave: "salespersonCode", valor: "Codigo de vendedor" },
  { clave: "requestedDeliveryDate", valor: "Fecha de entrega solicitada" },
  { clave: "quoteStatus", valor: "Estado de la cotizacion" },
  { clave: "customerPriceGroup", valor: "Grupo de precio del cliente" },
  { clave: "quoteValidUntilDate", valor: "Fecha de validez de la cotizacion" },
  { clave: "volumen", valor: "Volumen" },
  { clave: "postes", valor: "Postes" },
  { clave: "ediDocument", valor: "Documento EDI" },
  { clave: "shipmentMethodCode", valor: "Codigo de metodo de envio" },
  { clave: "amount", valor: "Cantidad" },
  { clave: "amountIncludingVAT", valor: "Cantidad con IVA incluido" },
  { clave: "sumQuantity", valor: "Cantidad total" },
  { clave: "sumQuantityShipped", valor: "Cantidad total enviada" },
  { clave: "quoteNo", valor: "Numero de Oferta" },
  { clave: "orderNo", valor: "Numero de Pedido" },
  { clave: "trackStatus", valor: "Estado de seguimiento" }
];

export function csvmaker(data) {
  // Obtención de encabezados y definición de variables
  const headersDePrimeraFilaDeDatos = Object.keys(data[0])  // Obtiene los headers de data[0]
  const headersDePrimeraFilaTraducidos = headersDePrimeraFilaDeDatos.map(header => {
    const traduccion = traducciones.find(traduccion => traduccion.clave === header);
    return traduccion ? traduccion.valor : header;
  });
  const csvRows = []
  let tipoDeDocumento = '';  // Para diferenciar si llega de ofertas, pedidos, albaranes o facturas
  let headerQueEstaEnTipoDeDocumento = ''; // Declara una variable para almacenar el nombre de la cabecera a eliminar
  const valorMinimoParaNoIncluirPortes = 750; // Valor mínimo a partir del cual no se incluyen los portes
  let sumaTotal = 0;
  let valorPortes = 0;
  let sumaTotalArticulosSinPortes = 0;

  // Según el header que llega, se definen las variables 'tipoDeDocumento' y 'headerQueEstaEnTipoDeDocumento'. Únicamente los componentes Ofertas, Pedidos, Albaranes y Facturas contienen está linea, por lo tanto si se llama a la función 'csvmaker' desde Cartera, saltará estos if y los cálculos para obtener sumaTotal de más adelante
  // tipoDeDocumento: se define para separar las operaciones posteriores que haya que hacer específicamente en cada caso
  // headerQueEstaEnTipoDeDocumento: almacena el nombre de la cabecera que luego se va a eliminar del .csv
  if (headersDePrimeraFilaTraducidos.includes('salesShipmentLines')) {
    tipoDeDocumento = 'albaranes';
    headerQueEstaEnTipoDeDocumento = 'salesShipmentLines';
  } else if (headersDePrimeraFilaTraducidos.includes('salesOrderLines')) {
    tipoDeDocumento = 'pedidos';
    headerQueEstaEnTipoDeDocumento = 'salesOrderLines';
  } else if (headersDePrimeraFilaTraducidos.includes('salesInvoiceLines')) {
    tipoDeDocumento = 'facturas';
    headerQueEstaEnTipoDeDocumento = 'salesInvoiceLines';
  } else if (headersDePrimeraFilaTraducidos.includes('salesQuoteLines')) {
    tipoDeDocumento = 'ofertas';
    headerQueEstaEnTipoDeDocumento = 'salesQuoteLines'
  } else if (headersDePrimeraFilaTraducidos.includes('salescrMemoLines')) {
    tipoDeDocumento = 'abonos';
    headerQueEstaEnTipoDeDocumento = 'salescrMemoLines'
  }

  // Elimina del header, la cabecera correspondiente según el tipoDeDocumento que llega
  if (headerQueEstaEnTipoDeDocumento) { // Comprueba si existe esa cabecera
    const indiceAEliminar = headersDePrimeraFilaTraducidos.indexOf(headerQueEstaEnTipoDeDocumento); // Utiliza la variable headerQueEstaEnTipoDeDocumento para eliminar la cabecera
    if (indiceAEliminar !== -1) {
      headersDePrimeraFilaTraducidos.splice(indiceAEliminar, 1); // Elimina la cabecera
    }
    headersDePrimeraFilaTraducidos.push('total') // Agregamos la cabecera 'total'
  }

  // Operación específica para el tipoDeDocumento 'ofertas' ya que tiene una columna más en el .csv que es el 'total sin portes'
  if (tipoDeDocumento === 'ofertas') {
    // En los header que llegan de BC para 'ofertas', hay un header que pone 'postes' pero debería poner 'portes' se hace el cambio para que el .csv muestre 'portes'
    if (headersDePrimeraFilaTraducidos.includes('postes')) {   // Verifica si el header 'postes' existe en los datos que llegan
      const indiceAReemplazar = headersDePrimeraFilaTraducidos.indexOf('postes');  // Busca el índica de la cabecera 'postes'
      headersDePrimeraFilaTraducidos[indiceAReemplazar] = 'portes'; // Reemplazamos 'postes' por 'portes'
    }
    headersDePrimeraFilaTraducidos.push('total sin portes'); // Agregamos la cabecera 'total sin portes'
  }

  csvRows.push(headersDePrimeraFilaTraducidos.splice(1, headersDePrimeraFilaTraducidos.length - 1).join(';')) // Añade los headersDePrimeraFilaTraducidos separados por ;
  let values
  Object.values(data).forEach(value => {

    // Realiza las diferentes operaciones en función del tipoDeDocumento y se calcula 'sumaTotal' porque si no, al descargar el csv, pone [object]
    if (tipoDeDocumento === 'albaranes') {
      sumaTotal = parseFloat(value.salesShipmentLines.reduce((valorAcumulado, valorActual) => valorAcumulado + (((valorActual.unitPrice || 0) * (valorActual.quantity || 0)) - ((valorActual.unitPrice || 0) * (valorActual.quantity || 0) * (valorActual.lineDiscountPercent || 0) / 100)), 0) || 0);  // Calcula la sumaTotal con el descuento aplicado
      delete value.salesShipmentLines;  // Eliminamos la columna

    } else if (tipoDeDocumento === 'pedidos') {
      sumaTotal = parseFloat(value.salesOrderLines.reduce((valorAcumulado, valorActual) => valorAcumulado + (valorActual.quantity * valorActual.unitPrice), 0) || 0); // Calcula la sumaTotal
      delete value.salesOrderLines; // Eliminamos la columna 

    } else if (tipoDeDocumento === 'facturas') {
      sumaTotal = parseFloat((value.salesInvoiceLines.reduce((valorAcumulado, valorActual) => valorAcumulado + (valorActual.lineAmount * (1 + (valorActual.vatPercent / 100))), 0) || 0));  // Calcula la sumaTotal de facturas teniendo en cuenta el IVA
      delete value.salesInvoiceLines; // Eliminamos la columna

    } else if (tipoDeDocumento === 'ofertas') {
      // Suma todas las líneas que llegan, excepto los portes, que llegan en una línea con propiedad->no y valor->'PORTES'
      sumaTotalArticulosSinPortes = value.salesQuoteLines.filter(linea => linea.no !== 'PORTES').reduce((valorAcumulado, valorActual) => valorAcumulado + (valorActual.quantity * valorActual.unitPrice), 0) || 0;

      valorPortes = (value.salesQuoteLines.find(linea => linea.no === 'PORTES') ?? { lineAmount: 0 }).lineAmount; // Obtiene el valor de la línea 'PORTES', es decir, si la propiedad 'no' tienen como valor 'PORTES', si no, devuelve 0

      // Hay un valor mínimo para el que si los artículos comprados superan esa cifra, entonces no se incluyen los portes
      if (sumaTotalArticulosSinPortes > valorMinimoParaNoIncluirPortes) {
        sumaTotal = parseFloat(sumaTotalArticulosSinPortes) // Obtiene la sumaTotal
      } else {
        sumaTotal = parseFloat(sumaTotalArticulosSinPortes) + parseFloat(valorPortes)   // Por no superar el valorMinimoParaNoIncluirPortes, se incluyen lor portes
      }
      delete value.salesQuoteLines; // Eliminamos la columna
    } else if (tipoDeDocumento === 'abonos') {
      sumaTotal = parseFloat((value.salescrMemoLines.reduce((valorAcumulado, valorActual) => valorAcumulado + (valorActual.lineAmount * (1 + (valorActual.vatPercent / 100))), 0) || 0));  // Calcula la sumaTotal de facturas teniendo en cuenta el IVA
      delete value.salescrMemoLines; // Eliminamos la columna
    }

    values = Object.values(value);
    // Devuelve el mismo array 'values' pero cambiando el punto y poniendo coma en el campo que sea un número
    values = values.map(val => {
      if (typeof val === 'number') {
        return val.toFixed(3).replace('.', ',');  // Reemplaza los puntos que hay, por comas, para seguir la notación numérica española
      }
      return val;
    });
    // Comprueba si existe la sumTotal para añadirla al .csv
    if (sumaTotal) {
      const sumaTotalFormatoCambiado = sumaTotal.toFixed(3).replace('.', ',');  // Reemplaza los puntos que hay, por comas
      values.push(sumaTotalFormatoCambiado);
      // Operación específica para el tipoDeDocumento 'ofertas' ya que tiene una columna más en el .csv que es 'total sin portes'
      if (tipoDeDocumento === 'ofertas') {
        const sumaTotalArticulosSinPortesFormatoCambiado = sumaTotalArticulosSinPortes.toFixed(3).replace('.', ','); // Reemplaza los puntos que hay, por comas
        values.push(sumaTotalArticulosSinPortesFormatoCambiado);
      }
    }
    csvRows.push(values.splice(1, values.length - 1).join(';'))
  })
  return csvRows.join('\n')
}
export async function isset(variable) {
  return typeof variable !== 'undefined' && variable !== null;
}
export function formatearPrecio(precio) {
  return precio.toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 2, useGrouping: true });
}

export async function estilosAventanasSweetAlert() {

  const estilosPersonalizados = `
        .estilos-boton-confirm {
            margin-right: 10px !important;
        }
        .estilos-boton-deny {
            margin-right: 10px !important;
        }
        .estilos-boton-cancel {
            margin-top: 10px !important;
        }`;

  return estilosPersonalizados;
}

export const modeloProductoParaRepuesto = {
  Model: '', 
  Description: '', 
  Mark: '', 
  CategoryDescription: '',
  BarCode: '',
  brand: '',
  category: '',
  CustomerSalesDiscount: '',
  CustomerSalesPrice: '',
  descripcionAecoc: '',
  description: '',
  EntryDate1: '',
  EntryDate2: '',
  EntryDate3: '',
  EntryQty1: '',
  EntryQty2: '',
  EntryQty3: '',
  family: '',
  fecha_creacion_producto: '',
  fecha_mod_producto: '',
  fullDescription: '',
  id: 0,
  imagen: '',
  InventoryAvailable: '0',
  InventoryDeposit: '0',
  keywords: '',
  model: '',
  name: '',
  PVC: '',
  PVC_OriginalBC: '',
  PVR: '',
  PVRSalesPrice: '',
  qty: 0,
  referenciaAgrupacion: '',
  sku: '',
  slug: '',
  status: '',
  Status: '',
  volumen: ''
}
export function getAccessToken() {
  return JSON.parse(localStorage.getItem('userData'))?.accessToken
}
