/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios from 'axios'
import { devuelveBasePathPim } from '@utils'

async function getResult () {
    const response = await axios.post(`${devuelveBasePathPim()}/auth/login`, {
      mail: process.env.REACT_APP_PIM_USERNAME,
      password: process.env.REACT_APP_PIM_PASSWORD
    })
    const result = response
    return result
}

if (!localStorage.getItem("bearerPim")) {
    getResult().then(result => {
        localStorage.setItem("bearerPim", result.data.token)
    })
}

const instance = () => { 
    if (!localStorage.getItem("bearerPim")) {
        getResult().then(result => {
            localStorage.setItem("bearerPim", result.data.token)
        })
    }
    return axios.create({baseURL: devuelveBasePathPim(), headers: {Authorization: `Bearer ${localStorage.getItem("bearerPim")}`}}) 
}

export default instance