import { ADD_PROMOTION, ADD_PROMOTION_META, DELETE_PROMOTION, DELETE_PROMOTION_META, GET_PROMOTION, GET_PROMOTIONS, GET_PROMOTIONS_COUNT, INIT_PROMOTION, UPDATE_PROMOTION } from '../action'
// ** Initial State
const initialState = {
    allData: [],
    data: [],
    total: 1,
    params: {},
    promotion: undefined,
    processing: false
}

const promotions = (state = initialState, action) => {
    switch (action.type) {
        case GET_PROMOTIONS:
            return {
                ...state,
                data: action.data,
                params: action.params
            }
        case GET_PROMOTIONS_COUNT:
            return {
                ...state,
                total: action.totalPages
            }
        case INIT_PROMOTION:
            return { ...state, promotion: {} }
        case GET_PROMOTION:
            return { ...state, promotion: action.promotion }
        case ADD_PROMOTION:
            return { ...state, promotion: action.promotion }
        case DELETE_PROMOTION:
            return { ...state }
        case UPDATE_PROMOTION:
            return { ...state }
        case ADD_PROMOTION_META:
            return { ...state }
        case DELETE_PROMOTION_META:
            return { ...state }

        default:
            return { ...state }
    }
}
export default promotions
