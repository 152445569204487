import {GET_SALES_QUOTES, GET_SALES_QUOTE, INIT_REQUEST, GET_SALES_QUOTE_PDF, INIT_SALES_QUOTE_PDF, DOWNLOAD_SALES_QUOTE_PDF, SEND_SALES_QUOTE, SET_SALES_QUOTE_FROM_ALLDATA, SWITCH_BILLTO_SELLTO} from '../actions'

const initialState = {
  data: [],
  total: 1,
  params: {},
  allData: [],
  salesQuote: undefined,
  salesQuotePdf: undefined,
  documentNoPdf: '',
  processing: false,
  showFromHijos: false,
  observacion: {},
  portes: []
}


const salesQuoteReducer = (state = initialState, action) => {
  switch (action.type) {
    case INIT_REQUEST:
      return {
        ...state,
        processing: true
      }
    case GET_SALES_QUOTES:
      return {
        ...state,
        processing: false,
        allData: action.allData,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case GET_SALES_QUOTE:
      return {
        ...state,
        salesQuote: action.salesQuote,
        observacion: action.observacion,
        portes: action.portes,
        salesQuotePdf: null
      }
    case GET_SALES_QUOTE_PDF:
      return {
        ...state,
        salesQuotePdf: action.salesQuotePdf,
        documentNoPdf: action.documentNo
      }
    case INIT_SALES_QUOTE_PDF:
      return {
        ...state,
        salesQuotePdf: undefined
      }
    case DOWNLOAD_SALES_QUOTE_PDF:
      return {
        ...state,
        salesQuotePdf: action.salesQuotePdf,
        documentNoPdf: action.documentNo,
        processing: false
      }
    case SEND_SALES_QUOTE:
      return {
        ...state,
        processing: false
      }
    case SET_SALES_QUOTE_FROM_ALLDATA:
      return {
        ...state,
        salesQuote: state.allData.find(salesQuote => salesQuote.documentNo === action.data)
      }
    case SWITCH_BILLTO_SELLTO:
      return {
        ...state,
        showFromHijos: !state.showFromHijos
      }
    default:
      return {...state}
  }
}
export default salesQuoteReducer
