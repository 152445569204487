/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios from 'axios'
import { automaticDownload, formatDateToDatabase, displayErrorMsg, odataLogs, seleccionarIdiomaYFormatoRegional, csvmaker } from '@src/utility/utils'
import { settings, MailControllerApi, ObservacionesControllerApi, NavisionControllerApi } from '@api/backend'
import Swal from 'sweetalert2'
import { getUserCode, isParentUser } from '@src/auth/utils'
import { FormattedMessage, useIntl } from 'react-intl'


const prefix = 'order'
const config = settings
export const GET_ORDER = `${prefix}/GET_ORDER`
export const GET_OBSERVACIONES = `${prefix}/GET_OBSERVACIONES`
export const GET_ORDER_PDF = `${prefix}/GET_ORDER_PDF`
export const DOWNLOAD_ORDER_PDF = `${prefix}/DOWNLOAD_ORDER_PDF`
export const GET_ORDERS = `${prefix}/GET_DATA`
export const DELETE_ORDER = `${prefix}/DELETE_ORDER`
export const INIT_REQUEST = `${prefix}/INIT_REQUEST`
export const INIT_ORDER_PDF = `${prefix}/INIT_ORDER_PDF`
export const INIT_DOWNLOAD_ORDER_PDF = `${prefix}/INIT_DOWNLOAD_ORDER_PDF`
export const SEND_ORDER = `${prefix}/SEND_ORDER`
export const SET_ORDER_FROM_ALLDATA = `${prefix}/SET_ORDER_FROM_ALLDATA`
export const SWITCH_BILLTO_SELLTO = `${prefix}/SWITCH_BILLTO_SELLTO`

// API Backend instance
const apiMail = new MailControllerApi(settings)
const apiObs = new ObservacionesControllerApi(settings)
const apiNavisionNew = new NavisionControllerApi(settings)


// ** INIT REQUEST
export const initRequest = () => {
  return async (dispatch) => {
    dispatch({
      type: INIT_REQUEST
    })
  }
}

export const getObservaciones = () => {
  return async (dispatch) => {
    const {data: dataObservaciones} = await apiObs.observacionesControllerFind()
    dispatch({
      type: GET_OBSERVACIONES,
      data: dataObservaciones
    })
  }
}
/*  Código obsoleto. Se ha creado la función csvmaker en /utility/utils.js para englobar las funciones csvmaker que hay, en una sola
const csvmaker = function (data) {
  const csvRows = []
  const headers = Object.keys(data[0])

  const indiceAEliminar = headers.indexOf('salesOrderLines');
  if (indiceAEliminar !== -1) {
    headers.splice(indiceAEliminar, 1); // Eliminamos la cabecera (salesOrderLines)
  }
  headers.push('total') // Agregamos la cabecera (total)
  let values
  csvRows.push(headers.splice(1, headers.length - 1).join(';'))
  Object.values(data).forEach(value => {

    const sumaTotal = (value.salesOrderLines.reduce((p, c) => p + (c.quantity * c.unitPrice), 0) || 0).toFixed(2); // Se añade este código porque si no, al descargar el csv, pone [object], de ese modo calcula el total y además, reemplaza los puntos que hay, por comas, para seguir la notación numérica española

    delete value.salesOrderLines; // Eliminamos la columna (salesOrderLines)
    values = Object.values(value)
    values.push(sumaTotal) // Agregamos la columna (total)
    csvRows.push(values.splice(1, values.length - 1).join(';'))
  })
  return csvRows.join('\n')
}
*/
const descargaFichero = function (data) {
  const blob = new Blob([data], { type: 'text/csv' })
  const url = window.URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.setAttribute('href', url)
  a.setAttribute('download', '/pedidos.csv')
  a.click()
}

export const descargarCsv = (params, intl) => {
  return async (dispatch) => {
    dispatch(initRequest())
    const {
      page = 1,
      perPage = 10,
      documentNo = null,
      postingDate = null,
      shipmentDate = null,
      shipToCity = null,
      shipToAddress = null,
      sellToCustomerName = null,
      customerNo = null,
      vendedor = null,
      gerente = null
    } = params

    const filterList = []
    if (documentNo) {
      filterList.push(`documentNo eq '*${documentNo}*'`)
    }
    if (postingDate && postingDate.length) {
      if (postingDate.length === 2) {
        filterList.push(`postingDate ge ${formatDateToDatabase(postingDate[0])}`)
        filterList.push(`postingDate le ${formatDateToDatabase(postingDate[1])}`)
      } else {
        filterList.push(`postingDate eq ${formatDateToDatabase(postingDate[0])}`)
      }
    }
    if (shipmentDate && shipmentDate.length) {
      if (shipmentDate.length === 2) {
        filterList.push(`shipmentDate ge ${formatDateToDatabase(shipmentDate[0])}`)
        filterList.push(`shipmentDate le ${formatDateToDatabase(shipmentDate[1])}`)
      } else {
        filterList.push(`shipmentDate eq ${formatDateToDatabase(shipmentDate[0])}`)
      }
    }
    if (shipToCity) {
      filterList.push(`shiptoCity eq '*${shipToCity}*'`)
    }
    if (shipToAddress) {
      filterList.push(`shipToAddress eq '*${shipToAddress}*'`)
    }
    if (sellToCustomerName) {
      filterList.push(`sellToCustomerName eq '*${sellToCustomerName}*'`)
    }
    if (gerente === "off" || gerente === "" || gerente === null || gerente === undefined) {
      if (vendedor) {
        filterList.push(`salespersonCode eq '${vendedor}'`) //->Filtramos por el codigo exacto del vendedor, sin añadir *__*
      }
    }
    if (customerNo) {
      filterList.push(`sellToCustomerNo eq '*${customerNo}*'`)
    }
    
    const $filter = filterList.join(' and ')
    const $top = perPage
    const $skip = (page - 1) * perPage
    const $orderby = 'documentNo desc'
    const $expand = 'salesOrderLines'
    const $count = true
    Swal.fire({
      title: intl.formatMessage({ id: 'Generating csv' }),
      html: intl.formatMessage({ id:  'Please wait'}),
      timer: $top * 1.5,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading()
      }
    })
    await apiNavisionNew.navisionControllerGetendPoint('salesOrderHeader', $filter, $top, $skip, $orderby, $expand, $count).then((response) => {
      const {data} = response
      const csvdata = csvmaker(data.value)
      descargaFichero(csvdata)
      location.reload()
    })    
  }
}

// ** Get Orders List
export const getData = (intl, params) => {
  return async (dispatch, getState) => {
    dispatch(initRequest())
    const {
      page = 1,
      perPage = 10,
      status = null,
      orderNo = null,
      postingDate = null,
      shipmentDate = null,
      shipToCity = null,
      shipToAddress = null,
      ean = null,
      description = null,
      productCode = null,
      sellToCustomerName = null,
      customerNo = null,
      vendedor = null,
      gerente = null
    } = params

    try {
      let $filter
      let total
      let $top = perPage
      let $skip = (page - 1) * perPage
      const $orderby = 'documentNo desc'
      const $expand = 'salesOrderLines'
      const $count = true
      const filterList = []
      const customerNoFilter = getUserCode()
      const isParent = await isParentUser()

      // Filtros para Lineas
      if (ean || description || productCode || vendedor || customerNo) {
        const filterLines = [...filterList]

        if (ean) {
          filterLines.push(`no eq '${ean}'`)
        }
        if (description) {
          filterLines.push(`description eq '*${description}*'`)
        }
        if (productCode) {
          filterLines.push(`barCode eq '*${productCode}*'`)
        }
        //if (gerente === 'off' || gerente === "" || gerente === null || gerente === undefined) {
          if (vendedor) {
            filterList.push(`salespersonCode eq '${vendedor}'`) //->Filtramos por el codigo exacto del vendedor, sin añadir *__*
          }
        //}
        //if (gerente === "off" || gerente === "" || gerente === null || gerente === undefined) {
          if (customerNo) {
            filterList.push(`sellToCustomerNo eq '*${customerNo}*'`)
          }
        //}

        // Join array de Filtros y reemplazo de campos 'shiptoCity' a 'shipToCity' por diferencia entre cabecera y lineas
        const $filterLines = filterLines.join(' and ')
          .replace('documentNo', 'no') //? CREO QUE NO VA
          .replace('shiptoCity', 'shipToCity')
        // Consulta API de query (líneas y cabecera): Máximo 100000 registros
        let ids = []
        if ($filterLines != '') {
          const linesData = await apiNavisionNew.navisionControllerGetendPoint('salesOrderLines', $filterLines, 100000, 0, undefined, undefined, undefined)
          ids = [...new Set(linesData.data.value?.map(line => line.no))]
        }
        // Obtener array único de Documentos
        if (ids.length > 0) {
          // Variables de Paginacion cuando busca lineas
          const start = ((page - 1) * perPage)
          const end = start + perPage
          // Calcula total de registros para paginación
          total = ids.length
          // Obtener la parte del array de DocumentNo para la pagina solicitada
          const idsPaginated = total > perPage ? ids.slice(start, end) : ids
          // Agrega filtros por los documentos de la pagina
          const list = idsPaginated.map(ids => `documentNo eq '${ids}'`).join(' or ')
          if (list) {
            // Agrega filtros por los documentos de la pagina y modifica parametros de paginación
            filterList.push(`(${list})`)
            $top = perPage
            $skip = 0
          }
        }
        if (status || orderNo || postingDate || shipmentDate || shipToCity || shipToAddress || customerNoFilter || sellToCustomerName) {
          if (customerNoFilter) {
            if (isParent) {
              const showFromHijos = getState().salesQuote.showFromHijos
              if (showFromHijos) {
                filterList.push(`billtoCustomerNo eq '*${customerNoFilter}*'`)
                // filterList.push(`sellToCustomerNo <> '*${customerNo}*'`)
              } // else filterList.push(`sellToCustomerNo eq '*${customerNoFilter}*'`)
            } // else filterList.push(`sellToCustomerNo eq '*${customerNoFilter}*'`)
          }
          if (orderNo) {
            filterList.push(`documentNo eq '*${orderNo}*'`)
          }
          if (postingDate && postingDate.length) {
            if (postingDate.length === 2) {
              filterList.push(`orderDate ge ${formatDateToDatabase(postingDate[0])}`)
              filterList.push(`orderDate le ${formatDateToDatabase(postingDate[1])}`)
            } else {
              filterList.push(`orderDate eq ${formatDateToDatabase(postingDate[0])}`)
            }
          }
          if (shipmentDate && shipmentDate.length) {
            if (shipmentDate.length === 2) {
              filterList.push(`shipmentDate ge ${formatDateToDatabase(shipmentDate[0])}`)
              filterList.push(`shipmentDate le ${formatDateToDatabase(shipmentDate[1])}`)
            } else {
              filterList.push(`shipmentDate eq ${formatDateToDatabase(shipmentDate[0])}`)
            }
          }
          if (shipToCity) {
            filterList.push(`shiptoCity eq '*${shipToCity}*'`)
          }
          if (shipToAddress) {
            filterList.push(`shipToAddress eq '*${shipToAddress}*'`)
          }
          if (sellToCustomerName) {
            filterList.push(`billtoName eq '*${sellToCustomerName}*'`)
          }
          if (filterList.length) {
            $filter = filterList.join(' and ')
          }
        }
        $filter = filterList.join(' and ')
      }
      await apiNavisionNew.navisionControllerGetendPoint('salesOrderHeader', $filter, $top, $skip, $orderby, $expand, $count).then((response) => {
        const { data } = response
        dispatch({
          type: GET_ORDERS,
          allData: data.value,
          data: data.value,
          totalPages: total || data['@odata.count'] || 0,
          params
        })
        odataLogs(response.config.url, response.status, response.config.method, GET_ORDERS, config)
      }).catch((err) => odataLogs(err.message, "ERROR", "", GET_ORDERS, config))
    } catch (err) {
      Swal.fire({
        title: intl.formatMessage({ id: "Error in Navision"}),
        icon: "error"
      })
      console.error(err.message)
    }
  }
}

// ** Get Order
export const getOrder = (params) => {
  return async (dispatch) => {
    const {
      page = 1,
      perPage = 1,
      orderNo = null
    } = params

    try {
      const $top = perPage
      const $skip = (page - 1) * perPage
      const $orderby = 'documentNo desc'
      const $expand = 'salesOrderLines'
      const $count = undefined

      const $filter = `documentNo eq '*${orderNo}*'`

      await apiNavisionNew.navisionControllerGetendPoint('salesOrderHeader', $filter, $top, $skip, $orderby, $expand, $count).then((response) => {
        const { data } = response
        dispatch({
          type: GET_ORDER,
          order: data.value?.pop()
        })
        odataLogs(response.config.url, response.status, response.config.method, GET_ORDER, config)
      }).catch((err) => odataLogs(err.message, "ERROR", "", GET_ORDER, config))
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const consultarSiExistePedido = (params) => {
  return async () => {
    const {
      page = 1,
      perPage = 1,
      orderNo = null
    } = params

    try {
      const $top = perPage
      const $skip = (page - 1) * perPage
      const $orderby = 'documentNo desc'
      const $expand = 'salesOrderLines'
      const $count = undefined
      const $filter = `documentNo eq '*${orderNo}*'`
      return await apiNavisionNew.navisionControllerGetendPoint('salesOrderHeader', $filter, $top, $skip, $orderby, $expand, $count).then((response) => {
        return response
      }).catch((err) => odataLogs(err.message, "ERROR", "", GET_ORDER, config))
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const descargarCsvCartera = (params, intl) => {
  return async (dispatch) => {
    dispatch(initRequest())
    const {
      page = 1,
      perPage = 10,
      customerNo = null,
      vendedor = null,
      gerente = null
    } = params

    const filterList = []
    if (gerente === "off" || gerente === "" || gerente === null || gerente === undefined) {
      if (vendedor) {
        filterList.push(`salespersonCode eq '${vendedor}'`) //->Filtramos por el codigo exacto del vendedor, sin añadir *__*
      }
    }
    if (customerNo) {
      filterList.push(`sellToCustomerNo eq '*${customerNo}*'`)
    }
    
    const $filter = filterList.join(' and ')
    const $top = perPage
    const $skip = (page - 1) * perPage
    const $orderby = 'documentNo desc'
  
    Swal.fire({
      title: intl.formatMessage({ id: 'Generating csv' }),
      html: intl.formatMessage({ id:  'Please wait'}),
      timer: $top * 1.5,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading()
      }
    })
    await apiNavisionNew.navisionControllerGetendPoint('salesOrderLines', $filter, $top, $skip, $orderby, undefined, undefined).then((response) => {
      const { data } = response
      const csvdata = csvmaker(data.value)
      descargaFichero(csvdata)
      location.reload()
    })
  }
}

// ** Send Order
export const sendOrder = (id) => {
  return (dispatch, getStore) => {
    dispatch({
      type: SEND_ORDER,
      selected: id
    })
  }
}

// ** Delete Order
export const deleteOrder = (id) => {
  return (dispatch, getStore) => {
    axios.delete('/apps/pedidos/delete', { id }).then((res) => {
      dispatch({
        type: DELETE_ORDER
      })
      odataLogs(res.config.url, res.status, res.config.method, DELETE_ORDER, config)
    }).then(() => dispatch(getData(getStore().order.params)))
      .catch((err) => odataLogs(err.message, "ERROR", "", DELETE_ORDER, config))
  }
}

// ** INIT PDF Pedido
export const initOrderPdf = () => {
  return async (dispatch) => {
    dispatch({
      type: INIT_ORDER_PDF
    })
  }
}

// ** Obtener PDF Pedido
export const getOrderPdf = (documentNo) => {
  return async (dispatch) => {
    try {
      const objNew = { DocNo: documentNo, DocType: 'PEDIDOVENTA' };
      const objJSON = JSON.stringify(objNew); // <-- creamos el JSON a enviar
      await apiNavisionNew.navisionControllerPostEndpointBC("TW_Functions_PrintDocument", objJSON).then((response) => {
        const { data } = response
        dispatch({
          type: GET_ORDER_PDF,
          orderPdf: data
        })
        odataLogs(response.config.url, response.status, response.config.method, GET_ORDER_PDF, config)
      })
    } catch (err) {
      odataLogs(err.message, "ERROR", "", GET_ORDER_PDF, config)
      console.error(err.message)
    }
  }
}

export const initDownloadOrderPdf = () => {
  return async (dispatch) => {
    dispatch({
      type: INIT_DOWNLOAD_ORDER_PDF
    })
  }
}

// ** Descargar PDF orden
export const downloadOrderPdf = (orderNoPdf, processing, intl) => {
  return async (dispatch) => {
    try {
      

      if (processing && processing === true) dispatch(initRequest())
      const OrderNo = orderNoPdf
      //const objNew = { DocNo: OrderNo, DocType: 'PEDIDOVENTA' };
      const objNew = { DocNo: OrderNo, DocType: 'PEDIDOVENTA', idioma: seleccionarIdiomaYFormatoRegional() }; // Llama a la función que devuelve el idioma seleccionado por el usuario en el desplegable de la web
      const objJSON = JSON.stringify(objNew); // <-- creamos el JSON a enviar
      await apiNavisionNew.navisionControllerPostEndpointBC("TW_Functions_PrintDocument", objJSON).then((response) => {
        const { data } = response

        // Escapa con notificacion error si encuentra error en la respuesta de navision
        if (data.status !== 'OK') return displayErrorMsg(`${intl.formatMessage({ id: 'Error generating PDF in Navision'})}:${data.mensaje.message}`)
        
        dispatch({
          type: DOWNLOAD_ORDER_PDF,
          orderPdf: data.mensaje,
          orderNo: OrderNo
        })
        odataLogs(response.config.url, response.status, response.config.method, DOWNLOAD_ORDER_PDF, config)
        automaticDownload(`${OrderNo}.pdf`, `data:application/pdf;base64,${data.mensaje}`)
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: intl.formatMessage({ id: 'Downloaded PDF'})
        })
      })
    } catch (err) {
      odataLogs(err.message, "ERROR", "", DOWNLOAD_ORDER_PDF, config)
      console.error(err.message)
    }
  }
}

// ** Enviar order PDF por correo
export const sendOrderPdfEmail = (mailObject, OrderNo, intl) => {
  return async (dispatch) => {
    try {
      Swal.fire({
        position: 'center',
        title: intl.formatMessage({ id: 'Process started on the server, a warning will be displayed on the screen at the end of the process.'}),
        icon: 'info'
      })
      await dispatch(getOrderPdf(OrderNo))
      await apiMail.mailControllerSendMail(mailObject).then((response) => {
        dispatch({
          type: SEND_ORDER
        })
        odataLogs(response.config.url, response.status, response.config.method, SEND_ORDER, config)
      })
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: intl.formatMessage({ id: 'E-mail sent'})
      })
    } catch (err) {
      odataLogs(err.message, "ERROR", "", SEND_ORDER, config)
      console.error(err.message)
    }
  }
}

// ** Precargar data del order desde alldata
export const setOrderFromAllData = (documentNo) => {
  return async (dispatch) => {
    dispatch({
      type: SET_ORDER_FROM_ALLDATA,
      data: documentNo
    })
  }
}

export const switchBilltoSellto = () => {
  return async (dispatch) => {
    dispatch({
      type: SWITCH_BILLTO_SELLTO
    })
  }
}
