/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { NavisionControllerApi, settings } from '@api/backend'
import { formatDateToDatabase, odataLogs } from '@utils'
import Swal from 'sweetalert2'

const prefix = 'admin/comisiones'
const config = settings

export const INIT_REQUEST = `${prefix}/INIT_REQUEST`
export const GET_ALL_COMISIONES = `${prefix}/GET_ALL_COMISIONES`
export const GET_COMISIONES_COUNT = `${prefix}/GET_COMISIONES_COUNT`
export const GET_DETALLE_COMISIONES = `${prefix}/GET_DETALLE_COMISIONES`
export const GET_DETALLE_COMISIONES_COUNT = `${prefix}/GET_DETALLE_COMISIONES_COUNT`
export const GET_FILTROS_COMISIONES = `${prefix}/GET_FILTROS_COMISIONES`

const apiNavisionNew = new NavisionControllerApi(settings)

// ** INIT REQUEST
export const initRequest = () => {
  return async (dispatch) => {
    dispatch({
      type: INIT_REQUEST
    })
  }
}

// ** GetData de todos los datos de Comisiones
export const getData = (params, intl) => {
    return async (dispatch, getState) => {
      try {
        dispatch(initRequest())
        const { page = 1, perPage = 10, filtrosBusqueda, codVendedor, gerente } = params

        const limit = perPage
        const skip = ((page - 1) * perPage)
        // const order = sortBy || "createdAt DESC"
        const order = ''
        const expand = '';
        const count = true;
        
        const { customerNo, customerName, salesPersonCode } = filtrosBusqueda || {};

        let filtro = ''
        const listaDeFiltros = []
        if (codVendedor && gerente === 'off') {
          listaDeFiltros.push(`salesPersonCode eq '${codVendedor}'`)
        }

        if (customerNo || customerName || salesPersonCode) {
            if (customerNo) {
              listaDeFiltros.push(`customerNo eq '*${customerNo}*'`)
            }
            if (customerName) {
              listaDeFiltros.push(`customerName eq '*${customerName}*'`)
            }
            if (salesPersonCode) {
              listaDeFiltros.push(`salesPersonCode eq '*${salesPersonCode}*'`)
            }
        }
        
        filtro = listaDeFiltros.join(' and ')
        await apiNavisionNew.navisionControllerGetendPoint('commissionLedgerEntry', filtro, limit, skip, order, expand, count).then((response) => {
          const { data } = response
          dispatch({
              type: GET_ALL_COMISIONES,
              data: data.value
          })
          
          //Guardamos el numero de registros
          dispatch({
            type: GET_COMISIONES_COUNT,
            totalPages: data['@odata.count'] || 0
          })

          //Guardamos los filtros de busqueda (Inputs)
          dispatch({
            type: GET_FILTROS_COMISIONES,
            filtros: filtrosBusqueda
          })

          odataLogs(response.config.url, response.status, response.config.method, GET_ALL_COMISIONES, config)
        }).catch((err) => odataLogs(err.message, "ERROR", "", GET_ALL_COMISIONES, config))

      } catch (err) {
        Swal.fire({
          title: intl.formatMessage({ id: "Error in Navision"}),
          icon: "error"
        })
      }
    }
}

// ** GetData de todos los datos del detalle de las Comisiones
export const getDataDetalle = (params, intl) => {
  return async (dispatch, getState) => {
    try {
      const count = true;
      await apiNavisionNew.navisionControllerGetendPoint('commissionLedgerEntryDet', count).then((response) => {
        const { data } = response
        dispatch({
            type: GET_DETALLE_COMISIONES,
            dataDetalle: data.value
        })
        
        //Guardamos el numero de registros
        dispatch({
          type: GET_DETALLE_COMISIONES_COUNT,
          totalPagesDetalle: data['@odata.count'] || 0
        })

        odataLogs(response.config.url, response.status, response.config.method, GET_ALL_COMISIONES, config)
      }).catch((err) => odataLogs(err.message, "ERROR", "", GET_ALL_COMISIONES, config))

    } catch (err) {
      Swal.fire({
        title: intl.formatMessage({ id: "Error in Navision"}),
        icon: "error"
      })
    }
  }
}